import React from 'react'

import { LAYBY } from 'components/orders/constants'

import Heading from 'components/shared/Heading'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

import orderHasPayments from 'components/order/helpers/orderHasPayment'

const CheckoutPaymentHeadline = ({ owed, refundAmount = 0, order, animation, isRefund }) => {
  switch (order.orderType) {
    case LAYBY:
      return (
        <Heading animation={animation}>
          {isRefund && <>Amount to refund {getFormattedPrice(refundAmount)}</>}
          {!isRefund && <>Amount remaining {getFormattedPrice(owed)}</>}
        </Heading>
      )
    default:
      if (orderHasPayments(order)) return (
        <Heading animation={animation}>
          {isRefund && <>Amount to refund {getFormattedPrice(refundAmount)}</>}
          {!isRefund && <>Amount remaining {getFormattedPrice(owed)}</>}
        </Heading>
      )
      else return (
        <Heading animation={animation}>
          {isRefund && <>Amount to refund {getFormattedPrice(refundAmount)}</>}
          {!isRefund && <>Amount to pay {getFormattedPrice(owed)}</>}
        </Heading>
      )
  }
}

export default CheckoutPaymentHeadline

import React, { useState, useRef, useEffect } from 'react'

import { MEDIUM, COLUMN } from 'components/constants'
import SearchInput from 'components/shared/SearchInput'
import ButtonMenu from 'components/shared/ButtonMenu'

const AutocompleteSearch = ({
  placeholder,
  id,
  results = [], // Should pass a structure [{id:, headline:, subheadline: }]
  size = MEDIUM,
  defaultValue,
  defaultValueId = null,
  maxHeight = 180,
  onClick,
  onChange,
}) => {
  const [showResults, setShowResults] = useState(false)
  const [onFocus, setOnFocus] = useState(false)
  const container = useRef(null)

  useEffect(() => {
    if (defaultValueId !== null && results.length > 0) {
      const defaultInputValue = results.find((result) => result.id === defaultValueId)

      // TODO: Investigate if this can be converted to use a state instead
      container.current.querySelector('input').value = defaultInputValue.headline
    }
  }, [])

  return (
    <div className='relative' ref={container}>
      <SearchInput
        className='border'
        placeholder={placeholder}
        defaultValue={defaultValue}
        onFocus={() => setOnFocus(true)}
        size={size}
        id={id}
        onChange={(value) => {
          onChange(value)
          setShowResults(true)
        }}
      />
      {onFocus && results.length > 0 && showResults && (
        <ButtonMenu
          className='bg-shade-0 rounded-bottom-3 border shadow-3 absolute z-3 overflow-y-scroll x-0'
          style={{ maxHeight }}
          direction={COLUMN}
          id="autocomplete_search_results"
        >
          {results.map((result) => (
            <button
              type='button'
              key={result.id}
              className='flex expand-x justify-space-between text-left p-4'
              onClick={() => {
                container.current.querySelector('input').value = result.headline
                onClick(result.id)
                setShowResults(false)
              }}
            >
              <span className='font-size-4 font-weight-2'>
                {result.headline}
              </span>
              <span className='font-size-3 color-shade-4'>
                {result.subheadline}
              </span>
            </button>
          ))}
        </ButtonMenu>
      )}
    </div>
  )
}

export default AutocompleteSearch

export default async function authenticate(payload, onSuccess, onError) {
  const options = {
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {'Content-Type': 'application/json'},
  }
  const response = await fetch('/v1/session', options)

  if (response.ok) {
    const { token } = await response.json()

    window.localStorage.setItem('apiToken', token)
    onSuccess()
  }
  else onError()
}

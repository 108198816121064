import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import {
  LARGE,
  HUGE,
  BRAND,
  NEUTRAL,
  DOLLAR,
  CASH,
  SLIDE_UP,
} from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import getRoundedTotals from 'components/orders/helpers/getRoundedTotals'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import isTouchScreen from 'components/helpers/isTouchScreen'
import getRoundedAmount from 'components/checkout/helpers/getRoundedAmount'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

export default function CashPaymentForm({
  center = true,
  layby = false,
  theme = BRAND,
  className,
  onSubmitPayment,
  drawerError,
  payments = [],
  owed = 0,
  animation,
  store = {},
  order,
  syncingOrder = false,
  applyRounding = true,
  isRefund = false,
}) {
  const [amountInput, setAmountInput] = useState(null)
  const [amountOwed, setAmountOwed] = useState(owed) // May be rounded amount

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('amount').focus()
    if (applyRounding === true) setAmountOwed(getRoundedAmount(owed, store))
  }, [applyRounding])

  function submit(amountTendered) {
    // Don't round if cash payment is not enough to cover total amount
    if (amountTendered < amountOwed) {
      onSubmitPayment({ amountTendered, paymentType: CASH })
    }

    // If the cash payment is enough to cover total amount we want
    // to apply cash rounding (if applicable) and add the difference
    // to the cart as an adjustment line item.
    else onSubmitPayment({
      amountTendered,
      amountOwed,
      paymentType: CASH,
      roundingDifference: getRoundingDifference()
    })
  }

  function getRoundingDifference() {
    // The rounded amountOwed minus the original owed amount
    // Example: An order total of $19.99 may be rounded to $20.00
    // leaving a rounding difference of $0.01
    if (applyRounding) return amountOwed - owed
  }

  return (
    <Article
      className={classNames({
        'expand': true,
        [className]: className,
      })}
      gridTemplateRows='1fr auto'
      animation={animation}>
      <Container maxWidth={800} padding={5} gap={3} center={center}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon type={CASH} size={LARGE} theme={theme} shade={3} />
          Cash
        </span>
        {isRefund === true && layby === false && <Heading>Amount refunded</Heading>}
        {isRefund === false && layby === true && <Heading>Payment amount</Heading>}
        {isRefund === false && layby === false && <Heading>Amount received</Heading>}
        <div>
          <span>
            <Field
              id='amount'
              name='amount'
              label='Custom amount'
              width={350}
              border={true}
              icon={DOLLAR}
              theme={NEUTRAL}
              type='number'
              onChange={(value) => {
                setAmountInput(value ? parseFloat(value) : null)
              }}
            />
          </span>
        </div>
      </Container>
      {drawerError} 
      <ButtonMenu
        className='gap-1'
        size={HUGE}
        animation={SLIDE_UP}
        theme={theme}
        shade={6}>
        {amountInput === null && (amountOwed !== getRoundedTotals(amountOwed)[0] || isRefund) && (
          <Button
            className='flex-basis-0 grow gap'
            size={HUGE}
            shade={5}
            theme={theme}
            disabled={layby || syncingOrder}
            onClick={() => submit(amountOwed)}>
            {(isRefund === false) && (
              <span>
                {layby ? 'Amount remaining' : 'Amount to pay'}&nbsp;
                {getFormattedPrice(convertToAbsoluteAmountValue(amountOwed))}
              </span>
            )}
            {isRefund === true && (
              <span>
                Amount to refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(amountOwed))}
              </span>
            )}
          </Button>
        )}
        {amountInput !== null && (
          <Button
            className='flex-basis-0 grow gap'
            size={HUGE}
            theme={theme}
            shade={5}
            disabled={syncingOrder}
            onClick={() => submit(amountInput)}
          >
            {isRefund === false && <>Submit payment&nbsp;{getFormattedPrice(amountInput)}</>}
            {isRefund === true && <>Submit refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(amountInput))}</>}
          </Button>
        )}
        {
          isRefund === false &&
          amountInput === null &&
          layby === false &&
          payments.length === 0 &&
          getRoundedTotals(amountOwed).map((amount, index) => (
            <Button
              className='flex-basis-0 grow'
              size={HUGE}
              key={index}
              shade={5}
              theme={theme}
              disabled={syncingOrder}
              onClick={() => submit(amount)}>
              {getFormattedPrice(amount)}
            </Button>
          ))}
      </ButtonMenu>
    </Article>
  )
}

import React from 'react'
import classNames from 'classnames'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const OrderAmountLineItem = ({
  amount,
  className,
  label,
  dataAttributeName
}) => {
  return (
    <div
      {...dataAttributeName}
      className={classNames({
        'sc-receipt-total flex row justify-space-between': true,
        [className]: className,
      })}
    >
      <span>
        {label} 
      </span>
      <span>
        {getFormattedPrice(amount)}
      </span>
    </div>
  )
}

export default OrderAmountLineItem

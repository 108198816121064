import React, { useRef, useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'

import { LEFT, RIGHT, CENTER } from 'components/constants'

import Expand from 'components/shared/Expand'
import ProductCard from './ProductCard'
import ProductConfigurationForm from './ProductConfigurationForm'

const WIDTH = 380
const HEIGHT = 380

const ProductCardSelector = ({
  product = {},
  variants = [],
  disabled = false,
  context = null,
  outlet = {},
  onSelect,
  onMinimise,
  onExpand,
}) => {
  const container = useRef(null)
  const [expanded, setExpanded] = useState(false)

  useEffect(() => {
    return () => document.removeEventListener('click', outOfBounds)
  }, [])

  function select(variant) {
    onSelect(variant)
    minimise()
  }

  function expand(event) {
    event.stopPropagation()
    setExpanded(true)
    if (onExpand) onExpand()
    document.addEventListener('click', outOfBounds)
  }

  function minimise() {
    setExpanded(false)
    if (onMinimise) onMinimise()
    document.removeEventListener('click', outOfBounds)
  }

  function outOfBounds({ target }) {
    if (target.type === 'radio' || target.type === 'button') return
    if (container.current && container.current.contains(target)) return
    else minimise()
  }

  return (
    <div
      ref={container}
      className='expand-x'
      style={{ maxHeight: '40vh' }}
    >
      <ProductCard
        product={product}
        variants={variants}
        outlet={outlet}
        disabled={disabled}
        onClick={(event) => {
          if (isConfigurable(product)) expand(event)
          else select(product)
        }}
      />
      {isConfigurable(product) && (
        <AnimatePresence>
          {expanded === true && (
            <Expand
              className='expand-y z-2 overflow-y-scroll'
              orientation={getOrientation()}
              origin={container.current}
              targetWidth={WIDTH}
              targetHeight={HEIGHT}
            >
              <ProductConfigurationForm
                product={product}
                variants={variants}
                onSubmit={(product) => select(product)}
                onDefocus={() => minimise()}
              />
            </Expand>
          )}
        </AnimatePresence>
      )}
    </div>
  )

  function isConfigurable() {
    return (
      variants.length > 0 ||
      (
        product.variable_pricing_amounts &&
        product.variable_pricing_amounts.length > 0
      )
    )
  }

  function getOrientation() {
    const cardPos = container.current.offsetLeft
    const centerX = (context || window).offsetWidth / 2
    const quarter = centerX / 4

    if (cardPos > quarter && cardPos < 3 * quarter) return CENTER
    else if (cardPos > centerX) return RIGHT
    else return LEFT
  }
}

export default ProductCardSelector

import React from 'react'
import classNames from 'classnames'

import { BASE, LARGE } from 'constants/size'

import RadioInput from 'components/shared/RadioInput'
import ProductLineItem from './ProductLineItem'

import getFormattedPrice from 'components/helpers/getFormattedPrice'
import convertToFractional from 'components/products/helpers/convertToFractionalAmount'

const ProductPricingSelector = ({
  name,
  product = {},
  size = BASE,
  onDefocus,
  onBack,
  onSelect,
}) => {

  function handleSelect(event) {
    if (onSelect) onSelect(event.target.value)
  }

  function handleBlur(index) {
    if (index === product.variable_pricing_amounts.length - 1) onDefocus()
  }

  return (
    <>
      <ProductLineItem
        onClick={() => onBack()}
        product={product}
        size={size}
      />
      {product.variable_pricing_amounts.map((amount, index) => {
        amount = convertToFractional(amount)
        return (
          <RadioInput
            className={classNames({
              'hover:bg-shade-1 font-weight-3 input-height-3 flex align-items-center': true,
              'ps-4': size === BASE,
              'ps-5': size === LARGE,
            })}
            name={name}
            size={size}
            key={amount.fractional}
            value={amount.fractional}
            onBlur={() => handleBlur(index)}
            onChange={(event) => handleSelect(event)}
          >{getFormattedPrice(amount.fractional, true)}</RadioInput>
        )})
      }
    </>
  )
}

export default ProductPricingSelector

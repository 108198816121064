import React from 'react'

import getFormattedPrice from 'components/helpers/getFormattedPrice'
import hasVariablePricing from '../helpers/hasVariablePricing'
import getVariablePriceRange from '../helpers/getVariablePriceRange'

const ProductPrice = ({ product }) => {
  return (
    <span className='font-weight-2 no-shrink pointer-events-x'>
      {hasVariablePricing(product) === false && getFormattedPrice(product.price)}
      {hasVariablePricing(product) && getVariablePriceRange(product.variable_pricing_amounts)}
    </span>
  )
}

export default ProductPrice

import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'

import CUSTOMERS from 'data/dummy/customers'

import { USE_DUMMY_DATA } from 'constants/settings'
import { NEUTRAL, COLUMN } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'
import { NEXT, BACK } from 'components/constants'

import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchCustomers } from 'data/api/fetchCustomers'
import hasVersionDiff from 'data/helpers/hasVersionDiff'
import setLocalStorage from 'data/helpers/setLocalStorage'
import getLocalStorage from 'data/helpers/getLocalStorage'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import Container from 'components/shared/Container'
import ButtonMenu from 'components/shared/ButtonMenu'
import CustomerAddressBlock from 'components/customers/CustomerAddressBlock'
import CustomerButtonLineItem from 'components/customers/CustomerButtonLineItem'
import CustomerOrdersBlock from 'components/customers/CustomerOrdersBlock'
import Heading from 'components/shared/Heading'
import Main from 'components/shared/Main'
import Record from 'components/shared/Record'
import StickyHeader from 'components/shared/StickyHeader'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import SearchInput from 'components/shared/SearchInput'

import getCustomerOrders from 'components/orders/helpers/getCustomerOrders'
import getSearchStr from 'components/helpers/getSearchStr'
import isCustomerMatch from 'components/customers/helpers/isCustomerMatch'
import getTruncatedText from 'components/helpers/getTruncatedText'

// This is no longer used because customers are no longer stored on-device.
// Leaving it here for now, but it would require re-work to do a lookup
// on the backend.
const Customers = () => {
  const orders = useSelector((state) => state.orders)

  const [animation, setAnimation] = useState(null)
  const [customer, setCustomer] = useState({})
  const [customers, setCustomers] = useState([])
  const [filteredCustomers, setFilteredCustomers] = useState([])

  useEffect(() => {
    fetchCustomers(
      // Success
      (customers) => {
        setCustomers(customers)
        setFilteredCustomers(customers)
      },
      // Offline
      () => {
        // TODO: Message to notify when offline
        setCustomers([])
        setFilteredCustomers([])
      }
    )
  }, [])

  function filter(string = '') {
    if (string.length === 0) {
      setFilteredCustomers(customers)
    } else {
      setFilteredCustomers(
        Object.keys(customers).map((key) => (
          isCustomerMatch(getSearchStr(string), customers[key]) ?
            customers[key] : null
        )).filter((customer) => customer !== null)
      )
    }
  }

  return (
    <View shade={1}>
      <Topbar shade={0} theme={NEUTRAL} />
      {Object.keys(customer).length === 0 && (
        <Main scroll={true} animation={animation}>
          <ButtonMenu className='p-2'>
            <SearchInput
              id='customer-filter'
              className='pe-2'
              placeholder='Filter customers by name, email or phone'
              onChange={(value) => filter(value)}
            />
          </ButtonMenu>
          <Article className='ps-3 pb-4' scroll={true}>
            {Object.keys(filteredCustomers).length > 0 && (
              <ButtonMenu
                className='shadow-3 rounded-3 gap-1px'
                shade={2}
                direction={COLUMN}
                theme={NEUTRAL}>
                {Object.keys(filteredCustomers).map((key) => (
                  <CustomerButtonLineItem
                    gridTemplateColumns='repeat(3, 1fr) 40px'
                    key={key}
                    customer={filteredCustomers[key]}
                    first={key === 0}
                    last={key === Object.keys(filteredCustomers).length - 1}
                    onClick={() => {
                      setAnimation(NEXT)
                      setCustomer(customer)
                    }}
                  />
                ))}
              </ButtonMenu>
            )}
            {filteredCustomers.length === 0 && (
              <div className='p-4 font-2 font-weight-2'>
                No customer records found
              </div>
            )}
          </Article>
        </Main>
      )}

      {Object.keys(customer).length > 0 && (
        <Main scroll={true} theme={NEUTRAL} shade={0} animation={animation}>
          <Article gridTemplateRows='auto 1fr' theme={NEUTRAL} shade={0}>
            <StickyHeader>
              <ButtonMenu className='justify-space-between ps-3 pt-2'>
                <Button
                  className='bg-white'
                  shade={0}
                  rounded={3}
                  shadow={2}
                  padding={5}
                  theme={CUSTOMER}
                  onClick={() => {
                    setAnimation(BACK)
                    setCustomer({})
                  }}>
                  Back
                </Button>
                <Button
                  shade={3}
                  shadow={2}
                  padding={5}
                  theme={CUSTOMER}>
                  Customer actions
                </Button>
              </ButtonMenu>
            </StickyHeader>
            <Container className='pe-5' padding={5} gap={4}>
              <Heading size={5}>
                {customer.firstName} {customer.lastName}
              </Heading>
              <section className='bg-shade-1 rounded-3 flex gap-5 p-4'>
                {customer.phone && (
                  <Record label='Phone'>{customer.phone}</Record>
                )}
                {customer.email && (
                  <Record label='Email'>{getTruncatedText(customer.email)}</Record>
                )}
              </section>
              <CustomerAddressBlock
                address1={customer.address1}
                address2={customer.address2}
                city={customer.city}
                state={customer.state}
              />
              <CustomerOrdersBlock
                className='shadow-2 rounded-3 overflow-hidden'
                customer={customer}
                orders={getCustomerOrders(customer, orders)}
              />
            </Container>
          </Article>
        </Main>
      )}
    </View>
  )
}

export default Customers
import React from 'react'
import { useDispatch } from 'react-redux'

import { BRAND } from 'components/constants'
import { LARGE } from 'components/constants'

import Button from 'components/shared/Button'
import ResetError from 'components/system/actions/ResetError'

const Error = () => {
  const dispatch = useDispatch()

  return (
    <div className='fixed expand flex gap-3 col bg-brand-5 align-items-center justify-center pe-8'>
      <div className='mb-4 color-shade-0'>
        <h1>Uh oh, something went wrong.</h1>
      </div>
      <div>
        <Button
          onClick={() => dispatch(ResetError())}
          theme={BRAND}
          size={LARGE}
          shade={2}
          shadow={3}
          padding={7}
        >
          Reset data
        </Button>
      </div>
    </div>
  )
}

export default Error

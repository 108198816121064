export default [
  {
    id: 1,
    name: 'Register 1',
    connected: false
  },
  {
    id: 2,
    name: 'Register 2',
    connected: false
  },
  {
    id: 3,
    name: 'Register 3',
    connected: false
  }
]

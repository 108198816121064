// Views

export const CUSTOMER = 'CUSTOMER'
export const CUSTOMER_CONTACT = 'CUSTOMER_CONTACT'
export const CUSTOMER_EMAIL = 'CUSTOMER_EMAIL'
export const CUSTOMER_MATCH = 'CUSTOMER_MATCH'
export const CUSTOMER_NAME = 'CUSTOMER_NAME'
export const CUSTOMER_PHONE = 'CUSTOMER_PHONE'
export const CUSTOMER_VIEWS = [CUSTOMER, CUSTOMERS]
export const CUSTOMERS = 'CUSTOMERS'
export const DISCOUNT = 'DISCOUNT'

// Actions

export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const CREATE_CUSTOMER = 'CREATE_CUSTOMER'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const SET_CUSTOMERS = 'SET_CUSTOMERS'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'

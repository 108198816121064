import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LARGE, HUGE } from 'components/constants'
import { BRAND, COLUMN } from 'components/constants'
import { CHEQUE, DOLLAR } from 'components/constants'
import { SLIDE_UP } from 'components/constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Input from 'components/shared/Input'
import FieldLabel from 'components/shared/FieldLabel'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const ChequeForm = ({
  animation,
  className,
  drawerError = null,
  center = true,
  onSubmit,
  theme = BRAND,
  payments = [],
  syncingOrder = false,
  owed,
}) => {
  const [amount, setAmount] = useState(null)
  const [details, setDetails] = useState(null)

  useEffect(() => {
    if (isTouchScreen() === false) {
      document.getElementById('details').focus()
    }
  }, [])

  function submit() {
    onSubmit({
      amountTendered: amount || owed,
      paymentDetails: details,
      paymentType: CHEQUE,
    })
  }

  return (
    <Form
      gridTemplateRows='1fr auto'
      animation={animation}
      className={classNames({
        'expand': true,
        [className]: className,
      })}
    >
      <Container center={center} maxWidth={800} gap={3}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon type={CHEQUE} size={LARGE} theme={theme} shade={3} />
          Cheque
        </span>
        <Heading>
          {payments.length > 1 && 'Payment ' + payments.length + 1}
          {payments.length === 0 && 'Amount received'}
        </Heading>
        <div className='flex gap-2'>
          <span>
            <FieldLabel targetId='details'>
              Cheque details
            </FieldLabel>
            <Input
              id='details'
              name='details'
              className='border'
              width={300}
              onChange={(value) => setDetails(value)}
            />
          </span>
          <span>
            <FieldLabel>
              Custom amount
            </FieldLabel>
            <Input
              name='amount'
              className='border'
              type='number'
              label='Custom amount'
              inputMode='decimal'
              pattern='[0-9]*'
              icon={DOLLAR}
              width={300}
              onChange={(value) => setAmount(value ? parseFloat(value) : null)}
            />
          </span>
        </div>
      </Container>
      {drawerError}
      <ButtonMenu
        direction={COLUMN}
        animation={SLIDE_UP}
        theme={theme}>
        <Button
          shade={5}
          theme={theme}
          size={HUGE}
          disabled={syncingOrder}
          onClick={() => submit()}>
          Accept payment&nbsp;
          {amount && getFormattedPrice(amount)}
          {amount === null && getFormattedPrice(owed)}
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default ChequeForm

import React, { useState, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'

import { MEDIUM, HUGE } from 'constants/size'
import { BRAND, NOTIFY, NEUTRAL, SUCCESS } from 'components/constants'
import { TRANSFER } from 'components/fulfillments/constants'
import { REQUESTED, PICKED, READY_FOR_PICKUP, COMPLETE, objectStatusProperies } from 'components/fulfillments/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Overlay from 'components/shared/Overlay'
import Tag from 'components/shared/Tag'

import FulfillmentStatusBlock from './fulfillmentStatusBlock'
import FulfillmentCustomerBlock from './fulfillmentCustomerBlock'
import FulfillmentItemsBlock from './fulfillmentItemsBlock'
import FulfillmentSummaryModal from './FulfillmentSummaryModal'
import FulfillmentLineItem from 'components/fulfillments/shared/FulfillmentLineItem'

import getDateLocalTimezone from 'components/helpers/getDateLocalTimezone'
import getFriendlyName from 'components/fulfillments/helpers/getFriendlyName'

const Fulfillment = ({
  dummyData = {},
  relatedFulfillments = [],
  relatedOrder,
  customer = "Jane Placeholder",
  onBack,
  onPrintPackingSlip,
  onSwitchFulfillment,
}) => {

  const [data, setData] = useState(dummyData)
  const [orderOwing, setOrderOwing] = useState(false)
  const [buttonActive, setButtonActive] = useState(false)
  const [overlayActive, setOverlayActive] = useState(false)
  const [changeNote, setChangeNote] = useState(false)
  const [noteError, setNoteError] = useState(false)

  const fulfillmentItems = data.order_items;
  const handleUpdate = (values) => {
    setButtonActive(false);
    setNoteError(false)
    for (const id in values) {
      let item = fulfillmentItems.find((el) => el.id == id)
      values[id] > 0 && setButtonActive(true)
      item[objectStatusProperies[data.status]] = values[id]
    }
  }

  function isInDeficit(item, status) {
    switch(status) {
      case REQUESTED:
        return item.quantityPicked < item.quantity
      case PICKED:
        return item.quantityPacked < item.quantityPicked
      case READY_FOR_PICKUP:
        return item.quantityReleased < item.quantityPacked
    }
  }

  const handleFulfillmentButtonClick = (confirmationOccurred) => {
    if (confirmationOccurred === false) {
      let hasError = false;
      if(!data.note && !hasError) {
        for (let k = 0; k < fulfillmentItems.length; k++) {
          if(isInDeficit(fulfillmentItems[k], data.status)) {
            hasError = true
            setNoteError(true)
            document.getElementById('notes').focus
            document.getElementById('notes').scrollIntoView({behavior: 'smooth', block: 'center'})
            break;
          }
        }
      }
      if(hasError === false) {
        setNoteError(false)
        setOverlayActive(true)
      }
    } else {
      const updatedData = data;
      updatedData['order_items'] = fulfillmentItems
      data.status === READY_FOR_PICKUP && (updatedData['status'] = COMPLETE)
      data.status === PICKED && (updatedData['status'] = READY_FOR_PICKUP)
      data.status === REQUESTED && (updatedData['status'] = PICKED)
      setButtonActive(false)
      setData(() => updatedData)
      document.getElementById('fulfillment-detail').scrollTo({ top: 0, behavior: 'smooth' });
    }
  }

  function handleCloseModal() {
    setOverlayActive(false)
    document.getElementById('numberpickerInput_1').focus()
  }

  function handleChangeNote(e) {
    e.preventDefault();
    const updatedData = data;
    updatedData['note'] = e.target[0].value;
    setData(() => updatedData)
    setNoteError(false)
    setChangeNote(false)
  }

  useEffect(() => {
    if (data.status === READY_FOR_PICKUP && relatedOrder && relatedOrder['payments']) {
      let count = 0;
      relatedOrder['payments'].forEach((payment) => {
        count += payment.amount
      })
      setOrderOwing(relatedOrder.total - count);
    }
  }, [data.status])
  
  return (
      <Article
        className='bg-gradient relative expand z-0'
        gridTemplateRows='1fr'
      >
      {overlayActive &&
        <AnimatePresence>
          <Overlay
            opacity={.75}
          >
            <FulfillmentSummaryModal
              data={fulfillmentItems}
              type={data.type}
              status={data.status}
              onCloseFulfillment={() => onSwitchFulfillment({})}
              onConfirm={() => handleFulfillmentButtonClick(true)}
              onClose={() => handleCloseModal()}
            >
            </FulfillmentSummaryModal>
          </Overlay>
        </AnimatePresence>
      }
      <div className='flex col overflow-y-scroll'>
        <ButtonMenu className='absolute top-0 x-0 p-2 z-1 justify-space-between'>
          <Button
            shade={0}
            rounded={4}
            size={MEDIUM}
            theme={BRAND}
            onClick={() => onBack()}
          >
            Back
          </Button>
          {(data.status === READY_FOR_PICKUP || data.status === COMPLETE) && (
            <Button
              shade={3}
              rounded={4}
              size={MEDIUM}
              theme={TRANSFER}
              onClick={() => onPrintPackingSlip()}
            >
              Print Packing Slip
            </Button>
          )}
        </ButtonMenu>
        <Container
          scroll={true}
          gap={5}
          maxWidth={1000}
          className='expand pe-8'
          id="fulfillment-detail"
        >
        <header className='flex col gap-3'>
          <h1 className='font-size-9 hide-print'>
            {data.order_id}
          </h1>
          <div className='flex align-items-center gap-3'>
            <Tag
              className='hide-print rounded-3 ps-2 pulse'
              theme={data.type}
              text={getFriendlyName(data.type)}
            />
            <strong className='font-size-3 color-shade-5'>
              Created {getDateLocalTimezone(data.created)}
            </strong>
          </div>
        </header>
        <section className='flex gap-3'>
          <FulfillmentStatusBlock
            data={data}
            orderOwing={orderOwing}
            className='bg-shade-1 rounded-2 p-4 grow'
          />
          {customer && Object.keys(customer).length > 0 && (
            <FulfillmentCustomerBlock
              className='bg-shade-1 rounded-2 p-4'
              customer={customer}
            />
          )}
        </section>
        <div className='flex col bg-shade-0 shadow-2 rounded-2'>
          <FulfillmentItemsBlock 
            orderItems={data.order_items} 
            type={data.type} 
            status={data.status}
            orderOwing={orderOwing}
            fulfillmentUpdate={(values) => handleUpdate(values)} 
          />
        </div>
        <div className='bg-shade-0 shadow-2 rounded-2' id="notes">
          <h3 className="font-size-2 text-center me-2">Notes:</h3>
          <form className="font-size-2 flex col ps-3 pb-3" onSubmit={(e) => {handleChangeNote(e)}}>
            <textarea
              className={`bg-shade-1 border-x p-5 font-size-2 rounded-3 expand-x ${noteError && "theme-notify-3"}`}
              style={{ appearance: 'none', outline: 'none', borderRadius: !noteError ? "12px" : "12px 12px 0 0" }}
              defaultValue={data.note}
              theme={noteError ? NOTIFY : NEUTRAL}
              shade={3}
              onChange={() => {setNoteError(false); setChangeNote(true)}}
              rows={3}
              id='order-notes'
            ></textarea>
            {noteError &&
              <h3 className="font-size-2 theme-notify-2 text-center p-2 rounded-bottom-3">You must enter a note to continue</h3>
            }
            {changeNote &&
              <Button 
                type="submit" 
                size={'SMALL'} 
                shade={3}
                theme={BRAND}
              >Save</Button>
            }
          </form>
        </div>
        {relatedFulfillments.length > 0 &&
          <div className='flex col bg-shade-0 shadow-2 rounded-2'>
            <h3 className="font-size-2 text-center me-2">Other holds and transfers associated with this order:</h3>
            {relatedFulfillments.map((ffment) => (
              <FulfillmentLineItem key={ffment.id} fulfillment={ffment} onSetFulfillment={() => onSwitchFulfillment(ffment)} />
            ))
            }
          </div>
        }
        
      </Container>
      {(!orderOwing && buttonActive) && 
        <ButtonMenu id='footer' theme={BRAND} className={"flex"}>
          <Button
            theme={data.status === READY_FOR_PICKUP ? SUCCESS : BRAND}
            disabled={!buttonActive}
            className="shrink"
            onClick={() => {
              buttonActive && handleFulfillmentButtonClick(false)
            }}
            size={HUGE}
            rounded={0}
            shade={5}
            padding={7}
          >
            {data.status === READY_FOR_PICKUP ? "Release items to customer" : "Next"}
          </Button>
        </ButtonMenu>
      }
    </div>
  </Article>
    
  )
}

export default Fulfillment
import React from 'react'

import ORDERS from 'data/dummy/orders'
import SHIFTS from 'data/dummy/shifts'
import USERS from 'data/dummy/users'

import { COLUMN } from 'components/constants'

import ButtonMenu from 'components/shared/ButtonMenu'
import Container from './shared/Container'
import Stage from './shared/Stage'
import OrderFiltersMenu from 'components/orders/OrderFiltersMenu'
import OrderLineItem from 'components/orders/shared/OrderLineItem'
import OrderShifts from 'components/orders/OrderShifts'

export default function RecentOrders({ orders }) {
  return (
    <Container heading='6. Recent orders'>
      <Stage
        center={true}
        overflow={true}
        height={100}
        heading='Filters menu'
        subheading='OrderFilterMenu.jsx'
      >
        <OrderFiltersMenu
          onFilterType={() => null}
          onFilterByKeyword={() => null}
        />
      </Stage>
      <Stage
        heading='List of orders grouped by shift'
        subheading='OrderShifts.jsx'
      >
        <OrderShifts
          users={USERS}
          orders={ORDERS}
          shifts={SHIFTS}
          recentShiftIds={[1, 2]}
        />
      </Stage>
      <Stage
        heading='Order line item'
        subheading='OrderLineItem.jsx'
      >
        <ButtonMenu
          className='rounded-3 shadow-3 gap-1px'
          direction={COLUMN}
        >
          <OrderLineItem
            className='expand-x'
            order={orders[0]}
          />
          <OrderLineItem
            className='expand-x'
            order={orders[1]}
          />
        </ButtonMenu>
      </Stage>
    </Container>
  )
}

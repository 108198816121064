import React from 'react'
import classNames from 'classnames'

import { XXLARGE } from 'components/constants'
import { COLUMN } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'
import { SCALE_IN, SLIDE_UP } from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import Record from 'components/shared/Record'

import getCustomerFullName from 'components/customers/helpers/getCustomerFullName'

const OrderCustomerModal = ({
  animation = SCALE_IN,
  customer = {},
  innerAnimation,
  maxWidth,
  size = XXLARGE,
  theme = CUSTOMER,
  shade = 2,
  rounded = 3,
  shadow = 2,
  onBack,
  onSubmit,
  zIndex,
}) => {
  return (
    <Article
      gridTemplateRows='1fr auto'
      rounded={rounded}
      theme={theme}
      shade={shade}
      shadow={shadow}
      animation={animation}
      maxWidth={maxWidth}
      className={classNames({
        'expand-x': true,
        ['z-' + zIndex]: !!zIndex
      })}>
      <Container className='pe-6' animation={innerAnimation}>
        <Heading size={size}>{getCustomerFullName(customer)}</Heading>
          {(customer.phone || customer.email) && (
            <section className='gap-xxlarge flex row mb-6'>
              <Record label='Phone' className='bold'>
                {customer.phone}
              </Record>
              <Record label='Email' className='bold'>
                {customer.email}
              </Record>
            </section>
          )}
      </Container>
      <ButtonMenu direction={COLUMN}>
        <Button
          id='park-order-button'
          className='expand-x'
          rounded={0}
          shade={3}
          animation={SLIDE_UP}
          size={size}
          onClick={onSubmit}
          onBlur={onBack}
          theme={theme}>
          Remove customer
        </Button>
      </ButtonMenu>
    </Article>
  )
}

export default OrderCustomerModal

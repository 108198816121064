import { PARKED, LAYBY, INCOMPLETE } from 'components/orders/constants'

export default function canCheckout(order) {
  if (order === undefined) return false
  return (
    order.orderType === PARKED ||
    order.orderType === LAYBY ||
    order.orderType === INCOMPLETE
  )
}

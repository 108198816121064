import React, { useEffect, useState, useContext } from 'react'

import { FulFillmentsContext } from 'components/fulfillments/Provider'
import { SMALL, BRAND } from 'components/constants'

import FulfillmentLineItem from './shared/FulfillmentLineItem'
import Button from 'components/shared/Button'

const FulfillmentsList = ({
  fulfillmentsData,
  onSetFulfillment,
}) => {

  const { typeFilters, keywordFilter, clearFilters } = useContext(FulFillmentsContext)
  const [ parsedData, setParsedData ] = useState(fulfillmentsData)

  function parseKeyword(keyword, dataSource = fulfillmentsData) {
    const filterWord = keyword.toLowerCase(),
          data = dataSource.filter(item => 
              item.order_id.toLowerCase().includes(filterWord) || 
              item.type.toLowerCase().includes(filterWord) ||
              item.status.toLowerCase().includes(filterWord)
            )
    return data
  }

  useEffect (() => {
    let filterData = fulfillmentsData;
    keywordFilter.length && (filterData = parseKeyword(keywordFilter))
    typeFilters.length && (filterData = filterData.filter(item => typeFilters.includes(item.type)))
    setParsedData(filterData)
  }, [keywordFilter, typeFilters])

  function renderProducts() {
    if(parsedData.length) {
      return parsedData.map((ffment) => (
        <FulfillmentLineItem key={ffment.id} fulfillment={ffment} onSetFulfillment={() => onSetFulfillment(ffment)} />
      ))
    } else {
      return (<div className="text-center me-3">
          <p>Sorry.. no products match your search filters</p>
          <Button
            className="ms-auto me-2"
            shade={5}
            theme={BRAND}
            size={SMALL}
            onClick={clearFilters}
          >Reset Filters</Button>
        </div>)
    }
  }

  return (
    <div className="flex col no-shrink z-0 overflow-y-scroll ps-3 pb-3">
      <div className="rounded-2 theme-neutral-0">
        <div className="gap-2 p-3">
         {renderProducts()}
        </div>
      </div>
    </div>
    
  )
}

export default FulfillmentsList
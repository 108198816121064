import React from 'react'

import Record from 'components/shared/Record'
import ProductPricingDropdown from './ProductPricingDropdown'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const ProductPriceBreakdown = ({
  product = {},
  className,
  onSetPrice,
}) => {
  return (
    <span className={className}>
      {product.variable_pricing_amounts.length > 0 && (
        <ProductPricingDropdown
          product={product}
          onSetPrice={(price) => onSetPrice(price)}
        />
      )}
      {product.variable_pricing_amounts.length === 0 && (
        <Record label='Unit price' className='ps-3' data-unit-price>
          {getFormattedPrice(product.price)}
        </Record>
      )}
      <Record label='Cost price'>
        {getFormattedPrice(product.cost_price)}
      </Record>
      <Record label='Min sell price'>
        {getFormattedPrice(product.minimum_sell_price)}
      </Record>
    </span>
  )
}

export default ProductPriceBreakdown

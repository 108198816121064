import React from 'react'
import classNames from 'classnames'

import { BASE } from 'components/constants'
import getTruncatedText from 'components/helpers/getTruncatedText'

const Headline = ({
  className,
  children = 'Headline',
  subheadline,
  charLimit = 30,
}) => {
  return (
    <div
      className={classNames({
        'sc-headline flex col gap-1': true,
        [className]: className,
      })}>
      <strong className='font-weight-3'>
        {getTruncatedText(children, charLimit)}
      </strong>
      {subheadline && (
        <div className={classNames({
          'flex gap-2 opacity-07 font-weight-2': true,
        })}>
          {Array.isArray(subheadline) && (
            <ul className='flex gap-3 capitalize'>
              {subheadline.filter(s => !!s).map(text => (
                <li>{text}</li>
              ))}
            </ul>
          )}
          {Array.isArray(subheadline) === false && (
            <span>{subheadline}</span>
          )}
        </div>
      )}

    </div>
  )
}

export default Headline

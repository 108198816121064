import React from 'react'
import classNames from 'classnames'

import { BRAND } from 'components/constants'
import { MEDIUM } from 'components/constants'
import { COLUMN } from 'components/constants'
import { PAYMENT } from 'components/checkout/constants'
import { CUSTOMER } from 'components/customers/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import UserCard from 'components/users/shared/UserCard'

import getCustomerDisplayName from 'components/customers/helpers/getCustomerDisplayName'

const CheckoutNavMenu = ({
  user = {},
  className,
  theme = BRAND,
  customer = {},
  canAddCustomer = true,
  backDisabled = false,
  onBack,
  onShowCustomer,
  onAddCustomer,
  onShowOrderActions,
}) => {
  return (
    <ButtonMenu
      id='checkout-nav-menu'
      className={classNames({
        'p-3 justify-space-between': true,
        [className]: className,
      })}
    >
      <span className='flex'>
        <Button
          shade={0}
          rounded={4}
          padding={5}
          tabIndex={1}
          size={MEDIUM}
          theme={theme}
          disabled={backDisabled}
          onClick={() => onBack()}
        >
          Back
        </Button>
        {Object.keys(user).length > 0 && (
          <UserCard user={user} orientation={COLUMN} />
        )}
      </span>
      <span className='flex gap-2'>
        {Object.keys(customer).length > 0 && (
          <Button
            shade={3}
            size={MEDIUM}
            theme={CUSTOMER}
            onClick={() => onShowCustomer()}
          >
            {getCustomerDisplayName(customer)}
          </Button>
        )}
        {canAddCustomer && Object.keys(customer).length === 0 && (
          <Button
            shade={3}
            theme={CUSTOMER}
            size={MEDIUM}
            onClick={() => onAddCustomer()}>
            Add customer
          </Button>
        )}
        {onShowOrderActions && (
          <Button
            rounded={3}
            shade={3}
            padding={5}
            size={MEDIUM}
            theme={theme}
            onClick={() => onShowOrderActions()}
          >
            Order actions
          </Button>
        )}
      </span>
    </ButtonMenu>
  )
}

export default CheckoutNavMenu

import { REGULAR_PRODUCT } from 'components/constants'
import { GST } from './tax'

export const CASH_ROUNDING = {
  id: 1,
  scid: 1,
  key: 'cash_rounding',
  name: 'StoreConnect Cash Rounding'
}

export const CERAMIC_VASE_MASTER = {
  id: 2,
  is_master: true,
  code: 'BC0000001',
  barcode: '0000001',
  name: 'Ceramic vase',
  price: 40,
  cost_price: 20,
  minimum_sell_price: 30,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  variants: [2, 3],
  taxes: [{ ...GST, total: 44 }],
}

export const LARGE_CERAMIC_VASE = {
  id: 3,
  is_master: false,
  code: 'BC0000002',
  barcode: '0000002',
  name: 'Large ceramic vase',
  price: 80,
  cost_price: 60,
  minimum_sell_price: 70,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [
    {
      id: 1,
      location: 'Sydney',
      count: 100,
    },
    {
      id: 2,
      location: 'Melbourne',
      count: 50,
    },
    {
      id: 3,
      location: 'Melbourne',
      count: 1000,
    },
  ],
  variants: [],
  taxes: [{ ...GST, total: 88 }],
}

export const SMALL_CERAMIC_VASE = {
  id: 4,
  is_master: false,
  code: 'BC0000003',
  barcode: '0000003',
  name: 'Small ceramic vase',
  price: 40,
  cost_price: 20,
  minimum_sell_price: 30,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  // stock: [
  //   {
  //     id: 'sydney',
  //     count: 100,
  //   },
  //   {
  //     id: 'melbourne',
  //     count: 50,
  //   },
  // ],
  variants: [],
  taxes: [{ ...GST, total: 44 }],
}

export const SWEATER_MASTER = {
  id: 5,
  is_master: true,
  code: 'BC0000004',
  barcode: '0000004',
  name: 'Sweater',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [5, 6, 7, 8, 9],
  taxes: [{ ...GST, total: 132 }],
}

export const SWEATER_S = {
  id: 6,
  is_master: false,
  code: 'BC0000005',
  barcode: '0000005',
  name: 'Sweater in S',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const SWEATER_M = {
  id: 7,
  is_master: false,
  code: 'BC0000006',
  barcode: '0000006',
  name: 'Sweater in M',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const SWEATER_L = {
  id: 8,
  is_master: false,
  code: 'BC0000007',
  barcode: '0000007',
  name: 'Sweater in L',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const SWEATER_XL = {
  id: 9,
  is_master: false,
  code: 'BC0000008',
  barcode: '0000008',
  name: 'Sweater in XL',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const SWEATER_2XL = {
    id: 10,
    is_master: false,
    code: 'BC0000009',
    barcode: '0000009',
    name: 'Sweater in 2XL',
    price: 120,
    cost_price: 50,
    minimum_sell_price: 80,
    has_serial_number: false,
    type: REGULAR_PRODUCT,
    stock: [],
    variants: [],
    taxes: [{ ...GST, total: 132 }],
}

export const SNEAKERS_MASTER = {
  id: 11,
  is_master: true,
  code: 'BC0000010',
  barcode: '0000010',
  name: 'Sneakers',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const MUG = {
  id: 12,
  is_master: true,
  code: 'BC0000011',
  barcode: '0000011',
  name: 'Mug',
  price: 120,
  cost_price: 50,
  minimum_sell_price: 80,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 132 }],
}

export const SOAP = {
  id: 13,
  is_master: true,
  code: 'BC0000012',
  barcode: '0000012',
  name: 'Aesop Coriander Cleansing Soap',
  price: 50,
  cost_price: 20,
  minimum_sell_price: 30,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 55 }],
}

export const MACBOOK = {
  id: 14,
  is_master: true,
  code: 'BC0000013',
  barcode: '0000013',
  name: 'Macbook Pro',
  price: 3200,
  cost_price: 1000,
  minimum_sell_price: 1200,
  has_serial_number: true,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 3520 }],
}

export const LONG_NAME = {
  id: 15,
  is_master: true,
  code: 'BC0000014',
  barcode: '0000014',
  name: 'Lorem ipsum dolor sit amet consectetur etiam eu scelerisque purus sollicitudin',
  price: 1200,
  cost_price: 500,
  minimum_sell_price: 800,
  has_serial_number: false,
  type: REGULAR_PRODUCT,
  stock: [],
  variants: [],
  taxes: [{ ...GST, total: 1320 }],
}

export default [
  CERAMIC_VASE_MASTER,
  LARGE_CERAMIC_VASE,
  SMALL_CERAMIC_VASE,
  SWEATER_MASTER,
  SWEATER_S,
  SWEATER_M,
  SWEATER_L,
  SWEATER_XL,
  SWEATER_2XL,
  SNEAKERS_MASTER,
  MUG,
  SOAP,
  MACBOOK,
  LONG_NAME,
]

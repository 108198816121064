import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { AnimatePresence } from 'framer-motion'

import { ORDERS } from 'components/orders/constants'
import { CHECKOUT } from 'components/checkout/constants'
import { LARGE } from 'components/constants'
import { NEUTRAL, BRAND } from 'components/constants'
import { BACK, NEXT } from 'components/constants'
import { COMPLETE } from 'components/orders/constants'

import useSyncOrders from 'hooks/useSyncOrders'
import useSyncShifts from 'hooks/useSyncShifts'
import useSyncProducts from 'hooks/useSyncProducts'

import Main from 'components/shared/Main'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Order from 'components/order'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'

import OrderFiltersMenu from 'components/orders/OrderFiltersMenu'
import OrderSearch from 'components/orders/OrderSearch'
import OrderList from 'components/orders/OrderList'
import SetOrder from 'components/orders/actions/SetOrder'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import ResetOrder from 'components/orders/actions/ResetOrder'

import getOrdersByStore from 'components/orders/helpers/getOrdersByStore'
import SetCartItems from 'components/checkout/actions/SetCartItems'

import getOrderTheme from 'components/orders/helpers/getOrderTheme'
import getSearchStr from 'components/helpers/getSearchStr'
import getShiftIdsFromOrders from 'components/orders/helpers/getShiftIdsFromOrders'
import isCustomerMatch from 'components/customers/helpers/isCustomerMatch'

// The Orders component fetches orders and shifts from the
// server and syncs them with the client state. Orders and
// shifts are polled for changes. See: hooks/useSyncOrders

const Orders = () => {
  const dispatch = useDispatch()
  const { shifts } = useSyncShifts({ poll: true })
  const { products } = useSyncProducts({ poll: false })
  const {
    orders,
    createOrUpdateOrder,
    abandonParkedOrder,
    syncingOrder,
  } = useSyncOrders({ poll: true })

  const taxes = useSelector((state) => state.taxes)
  const store = useSelector((state) => state.store)
  const customer = useSelector((state) => state.customer)
  const order = useSelector((state) => state.order)

  const [storeOrders, setStoreOrders] = useState(getOrdersByStore(store.id, orders))
  const [shiftIds, setShiftIds] = useState([])
  const [keyword, setKeyword] = useState('')
  const [animation, setAnimation] = useState(null)
  const [orderType, setOrderType] = useState(null)
  const [showSearch, setShowSearch] = useState(false)
  const [viewableOrders, setViewableOrders] = useState([])

  // This makes sure that whenever Orders page loads, redux order is empty
  useEffect(() => {
    if (order) resetOrderStates()
  }, [])

  useEffect(() => {
    setShiftIds(shifts.map((shift) => shift.id))
  }, [])

  // The Orders component fetches orders that include those
  // belonging to other stores. Therefore, we need to filter
  // the orders by the current store id when they change.
  useEffect(() => {
    const storeOrders = getOrdersByStore(store.id, orders)

    setStoreOrders(storeOrders)
    setViewableOrders(storeOrders)
    setShiftIds(getShiftIdsFromOrders(storeOrders))
  }, [orders])

  // Watchout for filter changes
  useEffect(() => {
    let filteredOrders = storeOrders

    if (keyword.length > 0) {
      filteredOrders = getOrdersByKeyword(keyword, storeOrders)
    }

    if (orderType !== null) {
      if (orderType === COMPLETE) {
        filteredOrders = storeOrders.filter((order) => {
          if (!order.orderType) return true
          return order.orderType.toUpperCase() === orderType
        })
      } else {
        filteredOrders = storeOrders.filter((o) =>
          o.orderType ? o.orderType.toUpperCase() === orderType.toUpperCase() : null
        )
      }
    }

    setViewableOrders(filteredOrders)
  }, [keyword, orderType])

  // Checkout the existing order and transition to the CHECKOUT view
  // where users can create additional payments to complete an order
  function checkout(order) {
    dispatch(SetOrder(order))
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewParents([ORDERS]))
    dispatch(SetViewId(CHECKOUT))
  }

  function resetOrderStates() {
    dispatch(SetOrder({}))
    dispatch(SetCartItems([]))
  }

  function getOrdersByKeyword(keyword, orders = []) {
    if (keyword)
      return orders.filter((order) => {
        return isMatch(keyword, order) ? order : null
      })
    else return orders
  }

  function isMatch(keyword, order) {
    const string = getSearchStr(keyword)
    const status = getSearchStr(order.orderType)
    const headline = getSearchStr(order.reference_number || '')

    return (
      headline.includes(string) ||
      status.includes(string) ||
      isCustomerMatch(string, order.customer)
    )
  }

  // This will be shared between the selected order here and for the
  // selected order that will be inside the <OrderSearch /> component.
  function buildOrderProps(order) {
    return {
      animation: animation,
      customer: customer,
      order: order,
      taxInclusive: store.taxInclusive,
      store: store,
      taxes: taxes,
      products: products,
      theme: getOrderTheme(order.orderType),
      syncingOrder: syncingOrder,
      onApplyAdjustment: (order, successCallback) => {
        createOrUpdateOrder(order,
          () => {
            dispatch(SetOrder(order))
            successCallback()
          },
        )
      },
      onCheckout: (order) => checkout(order),
      onAbandon: () => {
        abandonParkedOrder(order.id)
        dispatch(ResetOrder())
      },
      onBack: () => {
        setAnimation(BACK)
        dispatch(ResetOrder())
      },
      onEmail: (address) => {
        createOrUpdateOrder({
          ...order,
          checkout_email: address,
          send_email: true,
          sync: true,
        })
      }
    }
  }

  if (Object.keys(order).length > 0)
    return (
      <View shade={1} theme={NEUTRAL} animation={animation}>
        <Order {...buildOrderProps(order) }/>
      </View>
    )

  if (showSearch) {
    return (
      <OrderSearch
        store={store}
        orderProps={(order) => buildOrderProps(order)}
        onBack={() => {
          dispatch(ResetOrder())
          setShowSearch(false)
        }}
      />
    )
  }

  return (
    <View className='bg-gradient-2' theme={NEUTRAL} animation={animation}>
      <Topbar className='border-bottom' />
      <Main className='expand-x' animation={animation}>
        {/* {fetching && <Sync />} */}
        <OrderFiltersMenu
          className='bg-shade-1 pe-2 ps-3 flex wrap'
          keyword={keyword}
          orderType={orderType}
          onFilterType={(type) => setOrderType(type)}
          onFilterByKeyword={(keyword) => setKeyword(keyword)}
        />
        <OrderList
          withFilters={keyword.length > 0 || orderType !== null}
          animation={animation}
          orders={viewableOrders}
          onSetOrder={(order) => {
            setAnimation(NEXT)
            dispatch(SetOrder(order))
          }}
        />
      </Main>
      <AnimatePresence>
        {showSearch === false && (
          <ButtonMenu
            className='absolute z-4 flex justify-center pe-5 pointer-events-x'
            style={{ bottom: 'var(--sc-spacing-5)', left: 0, right: 0 }}
          >
            <Button
              shade={5}
              rounded={4}
              theme={BRAND}
              size={LARGE}
              className='shadow-3 pointer-events-all'
              style={{ width: 380 }}
              onClick={() => setShowSearch(true)}
            >
              Search order records
            </Button>
          </ButtonMenu>
        )}
      </AnimatePresence>
    </View>
  )
}

export default Orders

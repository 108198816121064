import React from 'react'
import classNames from 'classnames'

import { NOTIFY, BRAND } from 'components/constants'

import Tag from 'components/shared/Tag'
import getStockCountByOutlet from 'components/products/helpers/getStockCountByOutlet'

const ProductStockList = ({ outlets = [], product = {} }) => {
  return (
    <ul
      data-product-stock
      style={{ minHeight: 80, maxHeight: 120 }}
      className={classNames({
        'bg-shade-1 color-shade-7 font-size-3 font-weight-2 rounded-2': true,
        'flex col align-products-start justify-center gap-2 wrap p-4': true,
      })}>
      {outlets.map((outlet, index) => {
        const stockCount = getStockCountByOutlet(outlet.id, product.stock)

        return (
          <li
            key={index}
            data-outlet-stock={outlet.id}
            className='flex third'>
            <span
              className='truncate font-size-2 font-weight-2 color-shade-5'
              style={{ width: 130 }}
            >
              {outlet.name}
            </span>
            <span>
              <Tag
                shade={3}
                theme={stockCount === 0 ? NOTIFY : BRAND}
                text={stockCount}
              />
            </span>
          </li>
        )
      })}
    </ul>
  )
}

export default ProductStockList

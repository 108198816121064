import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { TINY, SMALL, MEDIUM, LARGE, XLARGE, XXLARGE, HUGE } from 'components/constants'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'
import getAnimation from 'helpers/getAnimation'

const Button = ({
  buttonRef,
  animation,
  center = true,
  children,
  className,
  delay,
  disabled = false,
  fixedHeight = true,
  id,
  onClick,
  padding,
  rounded,
  shade = 0,
  shadow = 0,
  size = MEDIUM,
  style,
  tabIndex,
  theme,
  type = 'button',
  ...data
}) => {
  return (
    <motion.button
      id={id}
      ref={buttonRef}
      type={type}
      disabled={disabled}
      onClick={onClick}
      tabIndex={tabIndex}
      className={classNames({
        'focus:outline relative flex align-items-center no-shrink': true,
        'justify-center': center === true,
        'expand-x': size === XXLARGE || size === HUGE,
        ['shadow-' + shadow]: shadow > 0,
        [getThemeStyleClass(theme, shade)]: true,
        [getPaddingClasses()]: true,
        [getBorderRadius()]: true,
        [getFontSize()]: true,
        [className]: className,
      })}
      style={{
        ...style,
        height: getHeight(),
        minHeight: getHeight(),
        minWidth: getHeight(),
      }}
      {...data}
      {...getAnimation(animation, delay)}>
      {children}
    </motion.button>
  )

  function getPaddingClasses() {
    if (padding !== undefined && fixedHeight === true)
      return 'ps-' + padding
    if (padding !== undefined && fixedHeight === false)
      return 'p-' + padding
    switch (size) {
      case TINY:
        return 'ps-4'
       case SMALL:
      case MEDIUM:
        return 'ps-5'
      case LARGE:
        return 'ps-7'
      case XLARGE:
      case XXLARGE:
        return 'ps-7'
    }
  }

  function getBorderRadius() {
    if (rounded !== undefined)
      return 'rounded-' + rounded
    switch (size) {
      case TINY:
      case SMALL:
        return 'rounded-3'
      case MEDIUM:
      case LARGE:
        return 'rounded-3'
      case XLARGE:
        return 'rounded-4'
    }
  }

  function getFontSize() {
    switch (size) {
      case TINY:
        return 'font-size-3 font-weight-3'
      case SMALL:
        return 'font-size-3 font-weight-3'
      case MEDIUM:
        return 'font-size-4 font-weight-3'
      case LARGE:
        return 'font-size-5 font-weight-3'
      case XLARGE:
        return 'font-size-6 font-weight-3'
      case XXLARGE:
      case HUGE:
        return 'font-size-8 font-weight-2'
    }
  }

  function getHeight() {
    if (fixedHeight === false) return
    switch (size) {
      case TINY:
        return 'var(--sc-input-height-2)'
      case SMALL:
        return 'var(--sc-input-height-2)'
      case MEDIUM:
        return 'var(--sc-input-height-3)'
      case LARGE:
        return 'var(--sc-input-height-4)'
      case XLARGE:
        return 'var(--sc-input-height-5)'
      case XXLARGE:
        return 'var(--sc-input-height-7)'
      case HUGE:
        return 'var(--sc-input-height-8)'
    }
  }
}

export default Button

import React from 'react'

import { BRAND, COLUMN } from 'components/constants'
import { MEDIUM, XXLARGE } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import getUserDisplayName from 'components/users/helpers/getUserDisplayName'

const UserCard = ({
  onClick,
  orientation = COLUMN,
  size = MEDIUM,
  theme,
  padding = 3,
  shade,
  user = {},
  id,
}) => {
  switch (orientation) {
    case COLUMN:
      return (
        <Button
          rounded={3}
          id={id}
          padding={padding}
          onClick={onClick}
          size={size}
          theme={theme}
          shade={shade}
          className='gap-2'>
          {getUserDisplayName(user)}
        </Button>
      )
    default:
      return (
        <button
          id={`user-card-#{user.id}`}
          onClick={onClick}
          className='bg-shade-0 color-shade-5 hover:bg-shade-1 flex col gap-3 align-items-center justify-center shadow-3 rounded-4 p-5'
          style={{ width: '200px', height: '200px' }}>
          <Icon size={XXLARGE} shade={3} theme={BRAND} type={CUSTOMER} />
          <strong className='font-size-5 capitalize'>{getUserDisplayName(user)}</strong>
        </button>
      )
  }
}

export default UserCard

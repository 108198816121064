import React from 'react'
import classNames from 'classnames'
import Record from 'components/shared/Record'

const FulfillmentCustomerBlock = ({
  className,
  customer = undefined
}) => {
  return (
    <div   
      className={classNames({
        'sc-order-summary flex align-items-center gap-6 pe-4 ps-5': true,
        'bg-shade-1 rounded-3': true,
        [className]: className,
      })}>
        <Record label='Customer:' className="ml-auto">
          {customer}
        </Record>
    </div>
  )
}

export default FulfillmentCustomerBlock

import classNames from 'classnames'
import React, { useState, useEffect } from 'react'

import { BRAND, TINY, INCREMENT, DECREMENT } from 'components/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

const NumberPicker = ({
  min = 0,
  max = 999999999,
  step = 1,
  width = 30,
  gridTemplateColumns = `58px ${width}px 58px`,
  theme = BRAND,
  handleCount,
  reset,
  tabIndex,
  focusOnLoad = false,
  disabled
}) => {
  const [count, setCount] = useState(min);

  useEffect (() => {
    handleCount(count)
  }, [count])

  useEffect(()=> {
    setCount(min)
  }, [reset])

  const incrementValue = (adding) => {
    let currentVal = count
    if (adding && (currentVal + step) <= max) {
      currentVal += step;
    } else if (!adding && (currentVal - step) >= min) {
      currentVal -= step;
    }
    currentVal !== count && setCount(currentVal);
  }

  const handleInput = (value) => {
    setCount((prevCount) => {
      const currentVal = value !== ""
        ? Math.min(Math.max(parseInt(value), min), max)
        : "";
      return currentVal !== prevCount ? currentVal : prevCount;
    });
  };

  

  return (
    <div className={"grid ms-auto"} style={{gridTemplateColumns: gridTemplateColumns, gridGap: "5px",}}>
      <Button
        shade={5}
        theme={theme}
        size={TINY}
        disabled={disabled}
        aria-label="Decrement value"
        onClick={() => incrementValue(false)}
        rounded={2}>
        <Icon type={DECREMENT} size={20} />
      </Button>
      <input 
        role="spinbutton" 
        inputMode="numeric"
        disabled={disabled}
        className={classNames({
          'pe-1 expand text-center border rounded-2': true,
        })}
        type="text"
        id={"numberpickerInput_" + tabIndex}
        value={count}
        style={{width: "auto"}}
        tabIndex={tabIndex}
        autoFocus={focusOnLoad}
        onChange={(input) => handleInput(input.target.value)}
      />
      <Button
        shade={5}
        theme={theme}
        size={TINY}
        disabled={disabled}
        aria-label="Increment value"
        onClick={() => incrementValue(true)}
        rounded={2}>
        <Icon type={INCREMENT} size={20} />
      </Button>
    </div>
  )
}

export default NumberPicker
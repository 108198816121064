import React from 'react'

import {
  BRAND,
  NEUTRAL,
  CHEVRON,
  BANK,
  COLUMN,
  CARD,
  CARD_INTEGRATED,
  CASH,
  CHEQUE,
  TRANSFER,
  MEDIUM,
  LARGE,
  XLARGE,
  VOUCHER
} from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Headline from 'components/shared/Headline'
import Icon from 'components/shared/Icon'
import LineItem from 'components/shared/LineItem'

const CheckoutPaymentMenu = ({
  animation,
  className,
  theme = BRAND,
  isRefund = false,
  onClick,
}) => {

  let availablePaymentMethods = [CARD, CASH]
  if (window.tyroClient) {
    availablePaymentMethods = [CARD_INTEGRATED, ...availablePaymentMethods]
  }
  if (!isRefund) {
    availablePaymentMethods = [...availablePaymentMethods, TRANSFER, CHEQUE, VOUCHER]
  }

  function typeToIcon(type) {
    switch (type) {
      case CARD_INTEGRATED:
        return CARD
      case TRANSFER:
        return BANK
      default:
        return type.toUpperCase()
    }
  }

  function typeToLabel(type) {
    switch (type) {
      case CARD:
        return 'Card (Manual)'
      case CARD_INTEGRATED:
        return 'Card (Tyro)'
      default:
        return type.toLowerCase()
    }
  }

  return (
    <ButtonMenu
      direction={COLUMN}
      className={className}
      animation={animation}>
      {availablePaymentMethods.map((type, index) => (
        <Button
          className='bg-transparent'
          shade={0}
          padding={4}
          theme={NEUTRAL}
          size={LARGE}
          key={index}
          id={'payment-type-' + type.toLowerCase()}
          onClick={() => onClick(type)}
        >
          <LineItem gridTemplateColumns='auto 1fr auto' size={MEDIUM} gap={3}>
            <Icon
              shade={3}
              size={XLARGE}
              theme={theme}
              type={typeToIcon(type)}
            />
            <Headline className='capitalize' size={LARGE}>
              {typeToLabel(type)}
            </Headline>
          </LineItem>
          <Icon type={CHEVRON} size={17} rotate={270} />
        </Button>
      ))}
    </ButtonMenu>
  )
}

export default CheckoutPaymentMenu

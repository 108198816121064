export const SHIPMENTS = 'SHIPMENTS'
export const SHIPMENT = 'SHIPMENT'


// Deprecated status
export const IN_TRANSIT = 'In transit'
export const REQUEST = 'Request'
export const ACCEPTED = 'Accepted'
export const REJECTED = 'Rejected'
export const READY = 'Ready'

// Actions
export const MARK_SHIPMENT_READ = 'MARK_SHIPMENT_READ'
export const SET_SHIPMENT = 'SET_SHIPMENT'
export const SET_SHIPMENTS = 'SET_SHIPMENTS'
export const SET_SHIPMENT_STATUS = 'SET_SHIPMENT_STATUS'
export const SET_NOTIFY_SHIPMENTS_HOLDING = 'SET_NOTIFY_SHIPMENTS_HOLDING'
export const SET_NOTIFY_SHIPMENTS_INCOMING = 'SET_NOTIFY_SHIPMENTS_INCOMING'

// Types
export const INCOMING = 'INCOMING'
export const OUTGOING = 'OUTGOING'
export const HOLDING = 'HOLDING'

import React, { useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import getDatabase from 'data/getDatabase'

import { BRAND, NEUTRAL, NOTIFY, BACK, NEXT, LARGE } from 'components/constants'
import { DISCONNECT_REGISTER } from 'constants/views'
import { START_SHIFT } from 'components/shifts/constants'
import { PARKED } from 'components/orders/constants'
import { SHIFT_USER } from 'components/shifts/constants'
import { PASSCODE } from 'components/users/constants'

import authenticatedFetch from 'data/api/authenticatedFetch'
import getLocalStorage from 'data/helpers/getLocalStorage'

import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import ButtonMenu from 'components/shared/ButtonMenu'
import SetViewId from 'components/navigation/actions/SetViewId'
import ShiftUser from 'components/shifts/StartShiftFlow/ShiftUser'
import PasscodeForm from 'components/users/PasscodeForm'

import Main from 'components/shared/Main'
import Button from 'components/shared/Button'

const DisconnectRegister = ({ initialAnimation }) => {
  const dispatch = useDispatch()
  const [animation, setAnimation] = useState(initialAnimation)
  const [viewId, setViewId] = useState(null)
  const [user, setUser] = useState(null)
  const users = useSelector((state) => state.users)
  const register = useSelector((state) => state.register)

  const [orders] = useState(getLocalStorage('orders', []))
  const [shifts] = useState(getLocalStorage('shifts', []))

  const ordersNotSynced = orders.filter((order) => order.sync === true)
  const parkedOrders = orders.filter((order) => order.orderType === PARKED)
  const shiftsNotSynced = shifts.filter((shift) => shift.sync === true)

  function disconnectRegister() {
    authenticatedFetch(
      'registers/' + register.id,
      {
        method: 'PATCH',
        body: JSON.stringify({ connected: false }),
      },
      (_json) => {
        window.localStorage.clear()
        getDatabase().delete()
        window.location.reload()
      }
    )
  }

  function back() {
    dispatch(SetViewId(START_SHIFT))
  }

  switch (viewId) {
    case PASSCODE:
      return (
        <PasscodeForm
          animation={animation}
          user={user}
          onSuccess={() => setViewId(DISCONNECT_REGISTER)}
          onBack={() => {
            setViewId(SHIFT_USER)
            setAnimation(BACK)
          }}
        />
      )
    case DISCONNECT_REGISTER:
      return (
        <View theme={NEUTRAL} shade={1} animation={animation}>
          <Topbar
            padding={3}
            transitional={true}
            className='ps-4'
            onBack={() => {
              dispatch(SetViewId(START_SHIFT))
            }}
          />
          <Main center={true} maxWidth={600} gap={3} className="flex col justify-center expand height-minus-topbar flex col">
            <div className="flex col align-center">
              <header className="mb-3">
                <h2>Disconnect Register</h2>
              </header>
              <section className='relative z-0 expand-x overflow-y-auto'>
                <h3 className="color-notify-5 mb-3 font-size-5">Warning: Any parked orders or unsynchronised records will be lost!</h3>
                <p className="mb-1">Parked Orders: <strong>{parkedOrders.length}</strong></p>
                <p className="mb-1">Unsynchronised Orders: <strong>{ordersNotSynced.length}</strong></p>
                <p className="mb-1">Unsynchronised Shifts: <strong>{shiftsNotSynced.length}</strong></p>
                <ButtonMenu className='gap-2 rounded mt-5'>
                  <Button
                    theme={NOTIFY}
                    shade={3}
                    shadow={true}
                    rounded={4}
                    padding={5}
                    size={LARGE}
                    onClick={disconnectRegister}
                  >
                    Wipe data and disconnect
                  </Button>
                  <Button
                    theme={BRAND}
                    shade={5}
                    rounded={4}
                    padding={5}
                    size={LARGE}
                    onClick={back}
                  >
                    Cancel
                  </Button>
                </ButtonMenu>
              </section>
            </div>
          </Main>
        </View>
      )
    default:
      return (
        <ShiftUser
          fetchingUsers={false}
          users={users}
          onBack={() => {
            dispatch(SetViewId(START_SHIFT))
          }}
          onSetUser={(user) => {
            setUser(user)
            setAnimation(NEXT)
            setViewId(PASSCODE)
          }}
        />
      )
  }
}

export default DisconnectRegister

export default function getProductPricingDrop(product) {
  return { 
    __data: {},
    bonus_earn_points: product.bonus_earn_points,
    "can_earn_points?": product.can_earn_points,
    "can_purchase_with_currency?": product.can_purchase_with_currency,
    "can_purchase_with_points?": product.can_purchase_with_points,
    checkout_earn_points: product.checkout_earn_points,
    checkout_points: product.checkout_points,
    checkout_price: product.checkout_price,
    deposit_amount: product.deposit_amount,
    deposit_points: product.deposit_points, 
    deposit_required: product.deposit_required,
    earn_points: product.earn_points,
    "fixed_term_subscription?": product.fixed_term_subscription,
    "has_price?": product.has_price,
    "hide_price?": product.hide_price,
    hide_price_text: product.hide_price_text,
    "on_sale?": product.on_sale,
    original_points: product.original_points,
    original_price: product.original_price,
    points: product.points,
    points_bonus: product.points_bonus,
    "points_sale?": product.points_sale,
    // Multiplying by quantity to match LineItem pricing
    price: product.price * product.quantity,
    price_range: product.price_range,
    product: product,
    purchase_points: product.purchase_points,
    sale_price: product.sale_price,
    sale_purchase_points: product.sale_purchase_points,
    "subscription?": product.subscription,
    subscription_term: product.subscription_term,
    subscription_term_count: product.subscription_term_count,
    subscription_term_unit: product.subscription_term_unit,
    subscription_total_points: product.subscription_total_points,
    subscription_total_price: product.subscription_total_price,
    total_earn_points: product.total_earn_points,
    "use_currency?": product.use_currency,
    "use_points?": product.use_points,
    "variable_pricing?": product.variable_pricing,
    variable_pricing_amounts: product.variable_pricing_amounts,
    variable_pricing_custom_allowed: product.variable_pricing_custom_allowed,
  }
}
import React, { useState, useEffect } from 'react'

import { NEUTRAL, NEXT } from 'components/constants'
import { DEBOUNCE_DELAY } from 'components/constants'

import { queryOrders } from 'data/api/queryOrders'
import { queryCustomers } from 'data/api/queryCustomers'
import { fetchStores } from 'data/api/fetchStores'

import View from 'components/shared/View'
import Order from 'components/order'
import OrderRecords from './OrderRecords'
import OrderSearchForm from './OrderSearchForm'
import Overlay from 'components/shared/Overlay'

import useInputDebounce from 'hooks/useInputDebounce'
import usePrevious from 'hooks/usePrevious'

const OrderSearch = ({
  orderProps,
  store = {},
  onBack,
}) => {
  const [results, setResults] = useState([])
  const [order, setOrder] = useState(null)
  const [stores, setStores] = useState([])
  const [animation, setAnimation] = useState(null)
  const [customers, setCustomers] = useState([])
  const [showResults, setShowResults] = useState(false)
  const [showDateRange, setShowDateRange] = useState(false)
  const [searching, setSearching] = useState(false)
  const [fetchingCustomers, setFetchingCustomers] = useState(false)
  const [storeKeyword, setStoreKeyword] = useState(store.name || '')
  const [customerKeyword, setCustomerKeyword] = useState('')
  const [filters, setFilters] = useState({
    startDate: null,
    endDate: null,
    customerId: '',
    storeId: store.id || ''
  })

  const debouncedCustomerKeyword = useInputDebounce(customerKeyword, DEBOUNCE_DELAY)
  const previousDebouncedCustomerKeyword = usePrevious(debouncedCustomerKeyword)

  useEffect(() => {
    if (debouncedCustomerKeyword.length === 0) return
    if (debouncedCustomerKeyword !== previousDebouncedCustomerKeyword) {
      setFetchingCustomers(true)
      queryCustomers(debouncedCustomerKeyword, (customers) => {
        setCustomers(customers)
        setFetchingCustomers(false)
      })
    }
  }, [debouncedCustomerKeyword, previousDebouncedCustomerKeyword])

  useEffect(() => {
    if (navigator.onLine === false) return // TODO: handle offline
    fetchStores((stores) => setStores(stores))
  }, [])

  function onSearch() {
    setSearching(true)
    queryOrders(filters,
      (orders) => {
        const sortedOrders = orders.sort((orderA, orderB) => new Date(orderB.created) - new Date(orderA.created))
        setShowResults(true)
        setResults(sortedOrders)
        setSearching(false)
      },
    )
  }

  function renderContent() {
    if (showResults === false) {
      return (
        <Overlay
          opacity={0.95}
          onClose={onBack}
        >
          <OrderSearchForm
            width={800}
            height={600}
            shadow={4}
            rounded={5}
            stores={stores}
            customers={customerKeyword.length > 0 ? customers : []}
            loading={searching}
            onBack={() => onBack()}
            storeKeyword={storeKeyword}
            customerKeyword={customerKeyword}
            filters={filters}
            fetchingCustomer={fetchingCustomers}
            showDateRange={showDateRange}
            onSetShowDateRange={() => setShowDateRange(!showDateRange)}
            onUpdateStoreKeyword={setStoreKeyword}
            onUpdateCustomerKeyword={setCustomerKeyword}
            onSetFilters={(filters) => setFilters(filters)}
            onSearch={onSearch}
          />
        </Overlay>
      )
    } else {
      if (order !== null) {
        return (
          <View shade={1} theme={NEUTRAL} animation={animation}>
            <Order
              {...orderProps(order)}
              onBack={() => {
                setOrder(null)
              }}
            />
          </View>
        )
      } else {
        return (
          <OrderRecords
            orders={results}
            stores={stores}
            customers={customers}
            params={filters}
            onSetOrder={(order) => {
              setOrder(order)
              setAnimation(NEXT)
            }}
            onBack={() => setShowResults(false)}
          />
        )
      }
    }
  }

  return renderContent()
}

export default OrderSearch

import { USE_DUMMY_DATA } from 'constants/settings'
import SUPPLIERS from 'data/dummy/suppliers'

import authenticatedFetch from './authenticatedFetch'
import { log } from 'dev/log'

export function fetchSuppliers(
  query = '',
  onSuccess,
  onOffline,
  onError = () => alert('Error fetching suppliers'),
) {
  log('Fetching suppliers...')

  if (USE_DUMMY_DATA) return onSuccess(SUPPLIERS)

  if (navigator.onLine) {
    authenticatedFetch('supplier_searches',
      {
        method: 'POST',
        body: JSON.stringify({ query }),
      },
      // Sucess
      ({ suppliers }) => onSuccess(suppliers),
      // Error
      () => onError()
    )
  }
  else onOffline()
}

import React from 'react'


import { BRAND, COLUMN } from 'components/constants'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'

export default function StyleGuideNav({ onClick }) {
  return (
    <ButtonMenu
      rounded={3}
      direction={COLUMN}
    >
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(1)}
      >
        1. Themes
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(2)}
      >
        2. Buttons & Inputs
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(3)}
      >
        3. Accordions & Dropdowns
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(4)}
      >
        4. Layouts
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(5)}
      >
        5. Order
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(6)}
      >
        6. Recent orders
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(7)}
      >
        7. Order search
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(8)}
      >
        8. Cart
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(9)}
      >
        9. Checkout
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(10)}
      >
        10. Products
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(11)}
      >
        11. Users
      </Button>
      <Button
        shade={0}
        padding={4}
        theme={BRAND}
        className='justify-start'
        onClick={() => onClick(12)}
      >
        12. Shifts
      </Button>
    </ButtonMenu>
  )
}

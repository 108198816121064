import React from 'react'

import { NEUTRAL, COLUMN } from 'components/constants'

import Article from 'components/shared/Article'
import ButtonMenu from 'components/shared/ButtonMenu'
import SupplierLineItem from 'components/suppliers/SupplierLineItem'
import Loader from 'components/shared/Loader'
import Main from 'components/shared/Main'
import Empty from 'components/shared/Empty'

const SupplierList = ({
  animation,
  loading,
  onSupplierClick,
  query = '',
  suppliers = [],
  searched
}) => {
  return (
    <Main
      scroll={true}
      animation={animation}
    >
      <Article className='ps-3 pb-4'>
        {suppliers.length > 0 && (
          <ButtonMenu
            className='rounded-3 gap-1px'
            shade={2}
            direction={COLUMN}
            theme={NEUTRAL}
          >
            {suppliers.map((supplier, index) => (
              <SupplierLineItem
                key={supplier.id}
                supplier={supplier}
                gridTemplateColumns='repeat(2, 1fr) 40px'
                first={index === 0}
                last={index === suppliers.length - 1}
                onClick={(supplier) => {
                  onSupplierClick(supplier)
                }}
              />
            ))}
          </ButtonMenu>
        )}
        {loading && (
          <div className='p-6'>
            <Loader />
          </div>
        )}
        {!loading && suppliers.length === 0 && searched && (
          <Empty message={`No supplier records found for "${query}"`} />
        )}
        {!loading && !searched && suppliers.length === 0 && (
          <Empty message='No supplier records found' />
        )}
      </Article>
    </Main>
  )
}

export default SupplierList
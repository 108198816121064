import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import classNames from 'classnames'

import { SMALL } from 'constants/size'
import { BRAND } from 'constants/theme'
import { LOCK } from 'components/constants'

import ButtonMenu from 'components/shared/ButtonMenu'
import OnlineStatus from 'components/shared/OnlineStatus'
import UserCard from 'components/users/shared/UserCard'
import StoreCard from 'components/stores/shared/StoreCard'

import LockButton from './LockButton'
import SubtleButton from './SubtleButton'
import SidebarInitButton from './SidebarInitButton'

import { fetchOutlets } from 'data/api/fetchOutlets'
import { fetchStores } from 'data/api/fetchStores'
import { fetchTaxes } from 'data/api/fetchTaxes'
import { fetchUsers } from 'data/api/fetchUsers'

import Lock from 'components/navigation/actions/Lock'
import ClearSyncInProgress from 'components/online/actions/ClearSyncInProgress'
import SetOutlets from 'components/outlets/actions/SetOutlets'
import SetOutlet from 'components/outlets/actions/SetOutlet'
import SetStore from 'components/stores/actions/SetStore'
import SetStores from 'components/stores/actions/SetStores'
import SetSyncInProgress from 'components/online/actions/SetSyncInProgress'
import SetTaxes from 'components/taxes/actions/SetTaxes'
import SetUsers from 'components/users/actions/SetUsers'
import SetViewId from 'components/navigation/actions/SetViewId'
import ShowSidebar from 'components/navigation/actions/ShowSidebar'

import useSyncProducts from 'hooks/useSyncProducts'
import useSyncPrintableTemplates from 'hooks/useSyncPrintableTemplates'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Topbar = ({
  className,
  shade = 0,
  theme = BRAND,
  transitional = false,
  onBack,
  onNext,
}) => {
  const dispatch = useDispatch()
  const online = useSelector((state) => state.online)
  const syncInProgress = useSelector((state) => state.syncInProgress)
  const syncing = Object.values(syncInProgress).some((v) => v)
  const store = useSelector((state) => state.store)
  const outlet = useSelector((state) => state.outlet)
  const user = useSelector((state) => state.user)
  const { fetchAndSync } = useSyncProducts({ poll: false })
  const { fetchPrintableTemplates } = useSyncPrintableTemplates({ poll: true })

  function lock() {
    dispatch(Lock())
    dispatch(SetViewId(LOCK))
  }

  function forceSync() {
    dispatch(ClearSyncInProgress())
    const resources = ['outlets', 'products', 'stores', 'taxes', 'users', 'printable_labels']
    resources.forEach((resource) => {
      dispatch(SetSyncInProgress(resource, true))
    })
    //TODO: Fullfilments
    // Orders?
    fetchOutlets((outlets) => {
      dispatch(SetOutlets(outlets))
      const outlet = outlets.find((outlet) => outlet.current)
      if (outlet) dispatch(SetOutlet(outlet))
      dispatch(SetSyncInProgress('outlets', false))
    })
    fetchAndSync(1, () => {
      dispatch(SetSyncInProgress('products', false))
    })
    // Shifts?
    fetchStores((stores) => {
      dispatch(SetStores(stores))
      const store = stores.find((store) => store.current)
      if (store) dispatch(SetStore(store))
      dispatch(SetSyncInProgress('stores', false))

    })
    //TODO: suppliers?
    fetchTaxes((taxes) => {
      dispatch(SetTaxes(taxes))
      dispatch(SetSyncInProgress('taxes', false))
    })
    fetchUsers((users) => {
      dispatch(SetUsers(users))
      dispatch(SetSyncInProgress('users', false))
    })
    fetchPrintableTemplates(() => {
      dispatch(SetSyncInProgress('printable_labels', false))
    }) 
  }

  return (
    <header id='topbar' className={getStyleClasses()}>
      <ButtonMenu className='grow align-items-center gap-3'>
        {transitional === false && (
          <SidebarInitButton onClick={() => dispatch(ShowSidebar())} />
        )}
        {onBack && transitional && (
          <SubtleButton onClick={() => onBack()}>Back</SubtleButton>
        )}
        <StoreCard
          size={SMALL}
          store={store}
          outlet={outlet}
        />
      </ButtonMenu>
      <ButtonMenu className='justify-end align-items-center'>
        <OnlineStatus online={online} syncing={syncing} onClick={forceSync} />
        {Object.keys(user).length > 0 && (
          <UserCard id='top-bar-user' user={user} />
        )}
        {transitional === false && <LockButton onLock={lock}/>}
        {onNext && transitional && (
          <SubtleButton onClick={() => onNext()}>Next</SubtleButton>
        )}
      </ButtonMenu>
    </header>
  )

  function getStyleClasses() {
    return classNames({
      'topbar-height expand-x ps-2 z-2 flex row align-items-center justify-space-between relative no-shrink': true,
      [getThemeStyleClass(theme, shade)]: theme,
      [className]: className,
    })
  }
}

export default Topbar

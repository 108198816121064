export function money(context, { params: params, options: options } ) {
  let number = params[0];
  let args = {};

  args.style = 'currency';
  args.currency = context.globals.currency;
  args.currencyDisplay = 'narrowSymbol';

  if (options.compact !== undefined) {
    args.trailingZeroDisplay = !!options.compact ? 'stripIfInteger' : 'auto';
  } else {
    if (options.precision !== undefined) {
      if (options.precision !== "auto") {
        args.maximumFractionDigits = options.precision;
      }
    } else if (Number.isInteger(number)) {
      // set custom precision for integers, otherwise leave out for default behaviour
    }
  }

  return new Intl.NumberFormat(context.globals.locale, args).format(number);
}

export function points(context, { params: params, options: options } ) {
  // untested. note that the string "pts" should be localized - prob should include a POS section in the translations and use here.
  let number = params[0];

  return `${number} pts`;
}

export function number(context, { params: params, options: options }) {
  // untested
  if (options.compact === undefined) {
    options.compact = options.precision === undefined;
  }

  options.strip_insignificant_zeros = !!options.compact;

  return number.toFixed(options.precision || 0);
}

export function parseArguments(args) {
  let params = [];
  let options = {};

  for (const arg of args) {
    if (typeof arg === 'object' && arg.length === 2) {
      options[arg[0]] = arg[1];
    }
    else {
      params.push(arg);
    }
  };

  return { params: params, options: options };
}

export const filters = {
  money: money,
  points: points,
  number: number,
}

// features to test:
// - each tag type and that it's attributes/params come through
// - the toggle tags
// - the options are validated, and converted to lowercase values
// - keywords (tags and attribute names) are case insensitive
// - comments are ignored
// - extra whitespace in template is ignored
// - braces {} are ignored inside tags if escaped with \
// - commas are ignored in splittable attributes if escaped with \
// - chars can be escaped in values

const rmlConfig = {
  document: {
    attributes: {
      wordWrap: { type: "boolean", default: false },
    },
  },
  center: {},
  image: {
    attributes: {
      dither: { type: "keyword", options: ["threshold", "bayer", "floydsteinberg", "atkinson"], default: "threshold" },
      height: { type: "number" },
      size: { type: "number" },
      src: { type: "string" },
      width: { type: "number" },
    },},
  left: {},
  line: {},
  rule: {
    attributes: {
      width: { type: "number" },
      line: { type: "keyword", options: ["solid", "dashed"], default: "dashed" },
      style: { type: "keyword", options: ["single", "double"], default: "single" },
    }
  },
  table: {
    attributes: {
      align: { type: "keyword", options: ["left", "right"], split: true },
      cols: { type: "number" },
      row: { type: "string", split: true, multiple: true, key: "rows" },
      margin: { type: "number" },
      width: { type: "number", split: true },
    },
  },
  text: {
    param: { type: "string" },
  },
  bold: {
    toggle: true,
  },
  italic: {
    toggle: true,
  },
  underline: {
    toggle: true,
  },
  invert: {
    toggle: true,
  },
  small: {
    toggle: true,
  },
  height: {
    param: { type: "number", options: [1, 2, 3, 4, 5, 6], default: 1 },
  },
  width: {
    param: { type: "number", options: [1, 2, 3, 4, 5, 6], default: 1 },
  },
  barcode: {
    attributes: {
      type: { type: "keyword", options: ["upca", "ean13", "ean8", "code39", "code128"] },
      data: { type: "string" },
      height: { type: "number", default: 50},
      position: { type: "keyword", options: ["none", "above", "below", "both"] },
    }
  },
  qrcode: {
    attributes: {
      data: { type: "string" },
      level: { type: "keyword", options: ["l", "m", "q", "h"], default: "l" },
      model: { type: "keyword", options: ["1", "2"], default: "1" },
      size: { type: "number", options: [1, 2, 3, 4, 5, 6, 7, 8], default: 6 },
    }
  }
}

export default function parseTPML(input) {
  const commands = []

  let regex = /(?:\s*(?<!\\)(?:\\\\)*{\s*(?<key>\w+)[\s\n]*(?<attrs>(?:[^}]|\\})*)(?<!\\)(?:\\\\)*})|(?<comment>\s*{#[^}]+\s*})|(?<line>[^\n]+)/gmi

  let matches = [...input.matchAll(regex)]

  for (const match of matches) {
    if (match.groups.comment)
      continue

    let command = parseCommand(match, rmlConfig)
    if (command)
      commands.push(command)
  }

  return commands
}

function parseCommand(match, config) {
  let command

  if (match.groups.key) {
    let key = camelize(match.groups.key)
    if (config[key]) {
      if (config[key].attributes) {
        command = commandWithAttributes(key, match, config)
      } else if (config[key].param) {
        command = commandWithParam(key, match, config)
      } else {
        command = { name: key }
      }
    } else {
      let toggleName = key.substring(3)

      if (key.startsWith("end") && config[toggleName] && config[toggleName].toggle) {
        command = endCommand(toggleName)
      } else {
        command = unknownCommand(match)
      }
    }
  } else if (match.groups.line) {
    command = lineCommand(match)
  }

  return command
}

function commandWithAttributes(key, match, config) {
  let command = {
    name: key,
  }

  let attributes = parseAttributes(match.groups.attrs, config[key])
  if (attributes)
    command.attributes = attributes

  return command
}

function commandWithParam(key, match, config) {
  return {
    name: key,
    value: castValue(match.groups.attrs, config[key].param) || config[key].param.default,
  }
}

function endCommand(name) {
  return {
    name: name,
    off: true,
  }
}

function lineCommand(match) {
  return {
    name: "line",
    value: match.groups.line,
  }
}

function unknownCommand(match) {
  return {
    name: "unknown",
    key: match.groups.key,
    attributes: match.groups.attrs
  }
}

function parseAttributes(input, config) {
  const attributes = {}
  let regex = /\s*(?<key>[^=\s]+)\s*=\s*(?:(?<number>[\d]+)|(?<keyword>[\w\-]+)|(["'])(?<string>.*?(?<!\\))\4|\[(?<array>.*(?<!\\))\])/gi
  let matches = [...input.matchAll(regex)]

  for (const match of matches) {
    let key = camelize(match.groups.key)

    if (!config.attributes[key])
      continue

    let rawValue = match.groups.number || match.groups.keyword || match.groups.string || match.groups.array
    let value = castValue(rawValue, config.attributes[key])

    if (value === null)
      continue

    if (config.attributes[key].multiple) {
      key = config.attributes[key].key || key
      if (!attributes[key])
        attributes[key] = []
      attributes[key].push(value)
    } else {
      attributes[key] = value
    }
  }

  // check for default values
  for (const key in config.attributes) {
    if (config.attributes[key].default !== undefined && attributes[key] === undefined)
      attributes[key] = config.attributes[key].default
  }

  return attributes
}

function castValue(value, config) {
  if (!value) return ""
  // replace all escaped characters with the original
  value = value.replace(/\\(.)/g, (m, chr) => chr)

  if (config.split) {
    // split on commas, but only if they're not inside quotes
    return value.match(/(['"].*?['"]|[^"',\s]+)(?=\s*,|\s*$)/g).map(
      bit => {
        return cast(bit, config.type, config.options)
      }
    )
  } else {
    return cast(value, config.type, config.options)
  }
}

function cast(value, type, options) {
  switch (camelize(type)) {
    case "number":
      if (value === "*")
        return value

      if (!options)
        return Number(value)

      return options.includes(Number(value)) ? Number(value) : null
    case "boolean":
      return value === "true"
    case "keyword":
      if (!options)
        return value

      return options.includes(value.toLowerCase()) ? value.toLowerCase() : null
    case "string":
      return value.trim().replace(/^"|"$/g, "").replace(/^'|'$/g, "")
  }
}

function camelize(str) {
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
}
import React from 'react'

import { BRAND, NEUTRAL } from 'components/constants'
import { XXLARGE } from 'components/constants'

import STORES_DATA from 'data/dummy/stores'
import USER_DATA from 'data/dummy/users'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'
import Form from 'components/shared/Form'
import View from 'components/shared/View'
import Topbar from 'components/navigation/Topbar'

export default function Layouts() {
  return (
    <Container heading='Layouts'>
      <Stage>
        <div
          className='bg-shade-0 rounded-2 overflow-hidden shadow-3 expand-x'
          style={{ height: 400 }}
        >
          <Topbar />
          <Container center={true}>
          </Container>
        </div>
      </Stage>

      <Stage height={500}>
        <View
          shade={5}
          theme={BRAND}
          className='rounded-3'>
          <Topbar />
        </View>
      </Stage>

      <Stage height={500}>
        <Form
          shade={0}
          rounded={3}
          shadow={3}
          theme={NEUTRAL}
          gridTemplateRows='auto 1fr auto'
          className='expand'
          onSubmit={() => null}
        >
          <ButtonMenu className='p-3 justify-space-between'>
            <Button
              shade={0}
              rounded={4}
              theme={BRAND}
            >
              Back
            </Button>
            <Button
              shade={3}
              rounded={4}
              theme={BRAND}
            >
              Secondary button
            </Button>
          </ButtonMenu>
          <Container center={true}>
          </Container>
          <ButtonMenu>
            <Button
              shade={5}
              theme={BRAND}
              size={XXLARGE}
              type='submit'
            >
              Primary button
            </Button>
          </ButtonMenu>
        </Form>
      </Stage>
    </Container>
  )
}

export default function getUserFullName(user) {
  if (!user || (!user.firstName && !user.lastName)) {
    return null
  }

  const firstName = (user.firstName || '').trim()
  const lastName = (user.lastName || '').trim()

  return `${firstName} ${lastName}`.trim()
}

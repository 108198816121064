import { UPDATE_TYRO } from 'constants/settings'

export default function UpdateTyro(printTyroReceipt, alwaysPrintMerchantReceipt) {
  return {
    type: UPDATE_TYRO,
    printTyroReceipt,
    alwaysPrintMerchantReceipt
  }
}


import { STORE_A } from 'constants/dummy'
import { CARD, CASH } from 'components/checkout/constants'
import { COMPLETE } from 'components/orders/constants'
import CUSTOMERS from 'data/dummy/customers'
import PRODUCTS from 'data/dummy/products'
import FULFILLMENTS from 'data/dummy/fulfillments'
import { USER_ANG, USER_ANDY } from 'data/dummy/users'

import {
  SWEATER_S,
  SWEATER_M,
  LARGE_CERAMIC_VASE,
} from './products'

import {
  TAKE_AWAY_LARGE_CERAMIC_VASE,
  TAKE_AWAY_LARGE_CERAMIC_VASE2,
  TAKE_AWAY_LARGE_CERAMIC_VASE3,
  TRANSFER_SWEATER_M_SYD_TO_MEL,
  TRANSFER_SWEATERS_SYD_TO_NY,
  CLICK_AND_COLLECT_SWEATER
} from 'data/dummy/fulfillments'


export default [
  {
    id: 1,
    reference_number: 'SC0000001',
    created: new Date('2023-01-01').valueOf(),
    shiftId: 1,
    storeId: STORE_A.id,
    customer: CUSTOMERS[1],
    total: 160,
    notes: 'Proin nec lacus ac sem accumsan porta. Aliquam feugiat posuere lectus ac aliquet. Nam vestibulum rutrum posuere. Nullam elementum dictum massa quis dapibus. Quisque id magna lobortis, semper massa nec, pretium felis. Cras iaculis est non magna dapibus, vel dapibus arcu eleifend. Suspendisse egestas quam at dolor venenatis blandit. Integer auctor ornare diam ut lacinia. Vivamus in metus orci. Integer efficitur nulla at nibh sollicitudin, at faucibus mauris commodo. Integer quam nisi, bibendum eget enim in, pellentesque luctus ligula.',
    orderType: null,
    taxInclusive: false,
    user: USER_ANG,
    items: [
      {
        ...LARGE_CERAMIC_VASE,
        quantity: 1,
        serial_numbers: [],
      },
    ],
    fulfillments: [
      TAKE_AWAY_LARGE_CERAMIC_VASE,
    ],
    payments: [
      {
        created: new Date('2023-01-01').valueOf(),
        type: CASH,
        amount: 100
      }, {
        created: new Date('2023-01-01').valueOf(),
        type: CARD,
        amount: 60
      }
    ]
  },

  {
    id: 2,
    reference_number: 'SC0000002',
    storeId: STORE_A.id,
    customer: CUSTOMERS[2],
    created: new Date('2023-01-15').valueOf(),
    shiftId: 1,
    total: 14664, // TODO: Remove and calc from order items
    orderType: COMPLETE,
    taxInclusive: false,
    user: USER_ANDY,
    fulfillments: [
      TRANSFER_SWEATERS_SYD_TO_NY,
      TRANSFER_SWEATER_M_SYD_TO_MEL,
    ],
    items: [
      {
        ...SWEATER_M, // Just store ID?
        productId: 7,
        quantity: 100,
        requires_serial: false,
        serial_numbers: []
      },
      {
        ...SWEATER_S,
        productId: 6,
        quantity: 20,
        requires_serial: false,
        serial_numbers: []
      }
    ],
    payments: [
      {
        created: new Date('2023-01-15').valueOf(),
        type: CARD,
        amount: 14664,
      }
    ]
  },

  {
    id: 3,
    reference_number: 'SC0000003',
    created: new Date('2023-01-20').valueOf(),
    storeId: STORE_A.id,
    user: USER_ANDY,
    shiftId: 1,
    total: 1320,
    orderType: null,
    taxInclusive: false,
    items: [
      {
        ...SWEATER_M,
        quantity: 100,
        serial_numbers: [],
      },
      {
        ...SWEATER_S,
        quantity: 20,
        serial_numbers: [],
      },
    ],
    fulfillments: [
      FULFILLMENTS[1],
      FULFILLMENTS[2]
    ],
    payments: [
      {
        created: new Date('2023-01-20').valueOf(),
        type: CARD,
        amount: 1320,
      }
    ]
  },

  {
    id: 4,
    reference_number: 'SC0000004',
    created: new Date('2023-02-01').valueOf(),
    storeId: STORE_A.id,
    user: USER_ANDY,
    shiftId: 2,
    total: 160,
    orderType: null,
    taxInclusive: false,
    items: [
      {
        ...PRODUCTS[4],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      },
      {
        ...PRODUCTS[5],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      }
    ],
    payments: [
      {
        created: new Date('2023-02-01').valueOf(),
        type: CARD,
        amount: 160,
      }
    ]
  },
  {
    id: 5,
    reference_number: 'ABCDEFG123456',
    created: new Date('2024-02-01').valueOf(),
    storeId: STORE_A.id,
    user: USER_ANDY,
    shiftId: 2,
    total: 160,
    orderType: null,
    taxInclusive: false,
    items: [
      {
        ...PRODUCTS[4],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      },
      {
        ...PRODUCTS[5],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      }
    ],
    fulfillments: [
      TAKE_AWAY_LARGE_CERAMIC_VASE,
      TAKE_AWAY_LARGE_CERAMIC_VASE2,
      TAKE_AWAY_LARGE_CERAMIC_VASE3,
    ],
    payments: [
      {
        created: new Date('2023-02-01').valueOf(),
        type: CARD,
        amount: 120,
      },
      {
        created: new Date('2023-02-01').valueOf(),
        type: CARD,
        amount: 40,
      }
    ]
  },
  {
    id: 6,
    reference_number: 'YeTAn0tH3r0rd3r',
    created: new Date('2024-02-01').valueOf(),
    storeId: STORE_A.id,
    user: USER_ANDY,
    shiftId: 2,
    total: 2000,
    orderType: null,
    taxInclusive: false,
    items: [
      {
        ...PRODUCTS[4],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      },
      {
        ...PRODUCTS[5],
        quantity: 1,
        requires_serial: false,
        serial_numbers: []
      }
    ],
    fulfillments: [
      CLICK_AND_COLLECT_SWEATER
    ],
    payments: [
      {
        created: new Date('2023-02-01').valueOf(),
        type: CARD,
        amount: 120.15,
      }
    ]
  },
]
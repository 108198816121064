import React from 'react'

import Ripple from 'components/shared/ripple'
import ExpandableListItem from 'components/shared/ExpandableListItem'
import ProductExpandedDetails from './ProductExpandedDetails'
import ProductTableRow from './ProductTableRow'

const ProductListItem = ({
  limitedColumns = false,
  expanded = false,
  selectable = false,
  selected = false,
  gridTemplateColumns,
  onAddCart,
  onExpand,
  onMinimise,
  outlets = [],
  product = {},
}) => {

  function handleClick(event) {
    if (expanded) return
    Ripple(event)
    onAddCart(product)
  }

  return (
    <ExpandableListItem
      expanded={expanded}
      id={product.id}
      onExpand={() => onExpand()}
      onMinimise={() => onMinimise()}
      limitedColumns={limitedColumns}
      renderLineItem={() => (
        <ProductTableRow
          limitedColumns={limitedColumns}
          selectable={selectable}
          selected={selected}
          gridTemplateColumns={gridTemplateColumns}
          outlets={outlets}
          product={product}
          onClick={(event) => handleClick(event)}
        />
      )}
      renderExpandedSection={() => {
        if (limitedColumns === true) return null

        return (
          <ProductExpandedDetails
            outlets={outlets}
            product={product}
            onAddCart={(product) => onAddCart(product)}
          />
        )
      }}
    />
  )
}

export default ProductListItem


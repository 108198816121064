import React from 'react'

import { SMALL, MEDIUM } from 'components/constants'
import { BRAND } from 'components/constants'
import { LOCK } from 'components/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

const LockTerminalButton = ({ onLock }) => {
  return (
    <Button
      padding={3}
      size={SMALL}
      className='gap-2'
      onClick={onLock}
    >
      <Icon
        shade={3}
        type={LOCK}
        size={MEDIUM}
        theme={BRAND}
      />
      Lock register
    </Button>
  )
}

export default LockTerminalButton

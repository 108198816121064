import React from 'react'
import classNames from 'classnames'
import { parsePhoneNumber } from 'libphonenumber-js'

import { COUNTRY_CODE } from 'components/constants'

import Icon from 'components/shared/Icon'
import ButtonLineItem from 'components/shared/ButtonLineItem'

import getCustomerFullName from 'components/customers/helpers/getCustomerFullName'
import getTruncatedText from 'components/helpers/getTruncatedText'

const CustomerButtonLineItem = ({
  customer,
  className,
  children,
  gridTemplateColumns = '1fr 1fr 1fr auto',
  last = false,
  first = false,
  icon,
  onClick,
}) => {
  return (
    <ButtonLineItem
      padding={5}
      gridTemplateColumns={gridTemplateColumns}
      onClick={() => onClick(customer)}
      className={classNames({
        'rounded-bottom-3': last === true,
        'rounded-top-3': first === true,
        [className]: className,
      })}>
      <strong className='font-size-3 font-weight-3'>
        {getCustomerFullName(customer)}
      </strong>
      {!customer.phone && (
        <span className='font-size-3 font-weight-2 opacity-07 ps-base'>
          {customer.email && getTruncatedText(customer.email, 30)}
        </span>
      )}
      {customer.phone && (
        <span className='font-size-3 font-weight-2 opacity-07 ps-base'>
          {customer.phone &&
            parsePhoneNumber(customer.phone, COUNTRY_CODE).formatNational()}
        </span>
      )}
      {children}
      {icon && <Icon size={24} type={icon} />}
    </ButtonLineItem>
  )
}

export default CustomerButtonLineItem

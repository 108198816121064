import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { BRAND } from 'components/constants'
import { LARGE, HUGE } from 'components/constants'
import { DOLLAR } from 'components/constants'
import { NEXT } from 'components/constants'
import { START_SHIFT } from './constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Heading from 'components/shared/Heading'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'

import SetViewId from 'components/navigation/actions/SetViewId'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import ResetViewParents from 'components/navigation/actions/ResetViewParents'
import ResetShift from './actions/ResetShift'
import ResetUser from 'components/users/actions/ResetUser'
import SetShift from './actions/SetShift'
import ShowSidebar from 'components/navigation/actions/ShowSidebar'

import { CASH } from '../constants'
import getShiftById from './helpers/getShiftById'
import getShiftOrders from '../orders/helpers/getShiftOrders'
import getPaymentTotalsByType from '../orders/helpers/getPaymentTotalsByType'
import getFormattedPrice from '../helpers/getFormattedPrice'
import { syncShift } from 'data/api/syncShift'

const EndShiftFlow = ({
  initialAnimation = NEXT,
  theme = BRAND,
  shade = 5,
}) => {
  const dispatch = useDispatch()
  const register = useSelector((state) => state.register)
  const shift = useSelector((state) => state.shift)
  const orders = useSelector((state) => state.orders)
  const shiftOrders = getShiftOrders(shift.id, orders)
  const paymentTotals = getPaymentTotalsByType(shiftOrders, shift.startBalance)

  const [amounts, setAmounts] = useState({})

  useEffect(() => {
    let initialAmounts = {}
    Object.keys(paymentTotals).forEach((type) => initialAmounts[type] = paymentTotals[type].toFixed(2))
    setAmounts(initialAmounts)
  }, [])

  function updateState(endedShift, synced) {
    dispatch(SetShift({ ...endedShift, sync: !!synced }))
    dispatch(ResetUser())
    dispatch(ResetShift())
    dispatch(ResetViewParents())
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewId(START_SHIFT))
  }

  function end() {
    const endedAt = new Date().toISOString()
    const endedShift = {...shift,
        endBalance: amounts[CASH],
        endedAt: endedAt,
        balances: amounts,
        sync: true
      }

    syncShift(register, endedShift,
      () => updateState(endedShift, true),
      () => updateState(endedShift, false),
      () => updateState(endedShift, false)
    )
  }

  return (
    <View
      theme={theme}
      shade={shade}
      animation={initialAnimation}
    >
      <Topbar
        transitional={true}
        padding={2}
        theme={theme}
        shade={shade}
        onBack={() => dispatch(ShowSidebar())}
      />
      <Form className='expand' onSubmit={end}>
        <Container maxWidth={700} gap={4} center={true}>
          <Heading theme={theme}>
            Enter the closing balances
          </Heading>

            { Object.keys(paymentTotals).map((type) => (
              <div key={type} >
                {type} (Calculated: {getFormattedPrice(paymentTotals[type])})
                <Field
                  type='number'
                  id={'endBalance' + type}
                  key={type}
                  step='.01'
                  shade={2}
                  border={true}
                  shadow={true}
                  required={true}
                  icon={DOLLAR}
                  theme={BRAND}
                  size={LARGE}
                  showRequiredLabel={false}
                  defaultValue={paymentTotals[type]}
                  onChange={value => setAmounts({...amounts, [type]: value})}
                />
              </div>
              ))
            }
        </Container>
        <Button
          type='submit'
          size={HUGE}
          theme={theme}
          shade={6}>
          Confirm
        </Button>
      </Form>
    </View>
  )
}

export default EndShiftFlow

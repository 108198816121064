import React from 'react'
import classNames from 'classnames'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Theme = ({ id = BRAND, shade = 5 }) => {
  return (
    <div
      style={{ height: 300 }}
      className={classNames({
        'flex col expand-x align-items-center justify-center overflow-hidden': true,
        'font-size-4 font-weight-3 rounded-3': true,
        [getThemeStyleClass(id, shade)]: true,
      })}
    >
        <span>shade {shade}</span>
    </div>
  )
}

export default Theme

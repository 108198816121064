import React from 'react'
import classNames from 'classnames'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const CheckoutPrice = ({ className, discount, price, quantity }) => {
  return (
    <div
      className={classNames({
        'sc-checkout-price flex col gap-1 align-items-end font-size-2': true,
        [className]: className !== undefined,
      })}>
      {discount > 0 && (
        <span className='font-weight-2'>
          {getFormattedPrice(price * quantity - discount)}
        </span>
      )}
      {discount > 0 && (
        <span className='font-weight-2 flex align-items-center gap-2'>
          <strong
            className='bg-notify-3 color-notify-6 rounded-1'
            style={{ padding: '1px 3px' }}
          >
            -{getFormattedPrice(discount)}
          </strong>
          <span className='color-shade-5'>
            {getFormattedPrice(price * quantity)}
          </span>
        </span>
      )}
      {!discount && (
        <strong className='text-right font-weight-2'>
          {getFormattedPrice(price * quantity)}
        </strong>
      )}
    </div>
  )
}

export default CheckoutPrice

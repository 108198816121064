import React from 'react'
import classNames from 'classnames'

import { PRODUCT_COLUMNS, LIMITED_PRODUCT_COLUMNS, MAX_PRODUCTS_SHOWN } from 'constants/settings'

import Article from 'components/shared/Article'
import ListHeader from 'components/shared/ListHeader'
import ProductListItem from './ProductListItem'
import Loader from 'components/shared/Loader'
import Checkbox from 'components/shared/Checkbox'

const ProductList = ({
  showMainCheckbox = true,
  batchSelectable = false,
  limitedColumns = false,
  className,
  focusedProductId,
  outlets = [],
  products = [],
  loading = false,
  selectedProducts = [],
  showOverlay = false,
  onMainCheckboxChange,
  onAddCart,
  onSetFocusedProductId,
  onSetOverlay,
}) => {
  const gridTemplateColumns = getGridColumns(
    limitedColumns ? LIMITED_PRODUCT_COLUMNS : PRODUCT_COLUMNS,
    outlets
  )

  function getGridColumns(settings, outlets) {
    if (limitedColumns) {
      return [
        ...Object.keys(settings).map(key => settings[key]),
      ].join(' ')
    }

    return [
      ...Object.keys(settings).map(key => settings[key]),
      ...outlets.slice(0, 3).map(_outlet => settings.stock)
    ].join(' ')
  }

  return (
    <Article
      id='product-list'
      gridTemplateRows='auto 1fr'
      scroll={showOverlay === false}
      className={classNames({
        'mt-2': true,
        'expand': true,
        [className]: className,
      })}
    >
      <ListHeader gridTemplateColumns={gridTemplateColumns}>
        <span>
          {batchSelectable && showMainCheckbox && (
            <Checkbox onChange={onMainCheckboxChange} />
          )}
          Product
        </span>
        <span>Price</span>
        {!limitedColumns && (
          outlets.slice(0, 4).map((outlet, index) => {
            return (
              <span className='truncate p-2' key={index}>
                {outlet.name}
              </span>
            )
          })
        )}
      </ListHeader>

      {products.length > 0 && (
        <ul className='bg-shade-0 flex col gap-1px'>
          {products.slice(0, MAX_PRODUCTS_SHOWN).map((product) => (
            <ProductListItem
              key={product.id}
              product={product}
              outlets={outlets}
              selected={selectedProducts.map((_product) => _product.id).includes(product.id)}
              selectable={batchSelectable}
              gridTemplateColumns={gridTemplateColumns}
              expanded={focusedProductId === product.id}
              onAddCart={() => onAddCart(product)}
              limitedColumns={limitedColumns}
              onExpand={() => {
                onSetOverlay(true)
                onSetFocusedProductId(product.id)
              }}
              onMinimise={() => {
                onSetOverlay(false)
                onSetFocusedProductId(null)
              }}
            />
          ))}
        </ul>
      )}
      {loading && <Loader background='bg-shade-0 opacity-07' />}
    </Article>
  )
}

export default ProductList

import React, {useEffect, useContext, useState } from 'react'
import classNames from 'classnames'

import { TINY, SMALL, MEDIUM, BRAND, NEUTRAL, CLOSE, COLUMN } from 'components/constants'
import { CLICK_AND_COLLECT, HOLD, TRANSFER, SPECIAL_ORDER } from 'components/fulfillments/constants'

import Button from 'components/shared/Button' //mebbe remove
import Checkbox from 'components/shared/Checkbox'
import ButtonMenu from 'components/shared/ButtonMenu'
import CheckboxSelector from 'components/shared/CheckboxSelector'
import Icon from 'components/shared/Icon'
import Tag from 'components/shared/Tag'
import SearchInput from 'components/shared/SearchInput'

import getFriendlyName from './helpers/getFriendlyName'

import { FulFillmentsContext } from 'components/fulfillments/Provider'

const FulfillmentFilters = ({ className }) => {
  const [filterExpanded, setFilterExpanded] = useState(false)
  const { typeFilters, updateTypeFilters, clearFilters, keywordFilter, setKeywordFilter } = useContext(FulFillmentsContext)
  const checkboxClasses = "flex align-items-center ps-4 pe-3"
  
  useEffect(() => {
    setFilterExpanded(typeFilters.length > 0)
  }, [typeFilters])

  return (
    <ButtonMenu
      className={classNames({
        'gap-2': true,
        [className]: className,
      })}>
      <SearchInput
        id='filter'
        className='pe-2'
        defaultValue={keywordFilter}
        onChange={(value) => setKeywordFilter(value)}
        placeholder='Filter by keyword'
      />
      <CheckboxSelector
        id='order-type'
        label={typeFilters.length ? `Filters Applied: ${typeFilters.length}` : 'Filter by type'}
        theme={BRAND}
        isExpanded={filterExpanded}
        shadow={2}>
        <ButtonMenu direction={COLUMN}>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            size={TINY}
            onClick={() => clearFilters()}>
            <span className='text-left grow'>
              Reset filters
            </span>
            <Icon type={CLOSE} size={17} />
          </Button>
          <Checkbox 
            id={CLICK_AND_COLLECT}
            label={(<Tag text={getFriendlyName(CLICK_AND_COLLECT)} size={MEDIUM} theme={CLICK_AND_COLLECT}/>)} 
            onChange={(e) => {updateTypeFilters(CLICK_AND_COLLECT)}}
            checked={typeFilters.indexOf(CLICK_AND_COLLECT) !== -1}
            className={checkboxClasses}
          />
          <Checkbox 
            id={HOLD}
            label={(<Tag text={getFriendlyName(HOLD)} size={MEDIUM} theme={HOLD}/>)} 
            onChange={(e) => {updateTypeFilters(HOLD)}}
            checked={typeFilters.indexOf(HOLD) !== -1}
            className={checkboxClasses}
          />
          <Checkbox 
            id={TRANSFER}
            label={(<Tag text={getFriendlyName(TRANSFER)} size={MEDIUM} theme={TRANSFER}/>)} 
            onChange={(e) => {updateTypeFilters(TRANSFER)}}
            checked={typeFilters.indexOf(TRANSFER) !== -1}
            className={checkboxClasses}
          />
          <Checkbox 
            id={SPECIAL_ORDER}
            label={(<Tag text={getFriendlyName(SPECIAL_ORDER)} size={MEDIUM} theme={SPECIAL_ORDER}/>)} 
            onChange={(e) => {updateTypeFilters(SPECIAL_ORDER)}}
            checked={typeFilters.indexOf(SPECIAL_ORDER) !== -1}
            className={checkboxClasses}
          />
        </ButtonMenu>
      </CheckboxSelector>
    </ButtonMenu>
  )
}

export default FulfillmentFilters

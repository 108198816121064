import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LARGE, HUGE } from 'components/constants'
import { BRAND, COLUMN } from 'components/constants'
import { SLIDE_UP } from 'components/constants'
import { BANK, DOLLAR, NUMBER } from 'components/constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Input from 'components/shared/Input'
import FieldLabel from 'components/shared/FieldLabel'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const BankTransferForm = ({
  animation,
  drawerError = null,
  center = true,
  className,
  onSubmit,
  theme = BRAND,
  syncingOrder = false,
  owed,
}) => {
  const [amount, setAmount] = useState(null)
  const [refNum, setRefNum] = useState(null)

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('reference').focus()
  }, [])

  function submit() {
    onSubmit({
      amountTendered: amount || owed,
      paymentDetails: refNum,
      paymentType: BANK,
    })
  }

  return (
    <Form
      gridTemplateRows='1fr auto'
      animation={animation}
      className={classNames({'expand': true, [className]: className})}
      onSubmit={() => submit()}
    >
      <Container
        center={center}
        maxWidth={800}
        padding={5}
        gap={3}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon
            type={BANK}
            size={LARGE}
            theme={theme}
            shade={3}
          />
          Bank transfer
        </span>
        <Heading>
          Amount received
        </Heading>
        <div className='flex gap-2'>
          <span>
            <FieldLabel required={true} targetId='reference'>
              Reference number
            </FieldLabel>
            <Input
              id='reference'
              name='reference'
              className='border'
              required={true}
              icon={NUMBER}
              width={300}
              onChange={setRefNum}
            />
          </span>
          <span>
            <FieldLabel>
              Custom amount
            </FieldLabel>
            <Input
              name='amount'
              type='number'
              step='.01'
              inputMode='decimal'
              pattern='[0-9]*'
              className='border'
              width={300}
              icon={DOLLAR}
              min={owed}
              onChange={value => setAmount(parseFloat(value))}
            />
          </span>
        </div>
      </Container>
      {drawerError}
      <ButtonMenu animation={SLIDE_UP} direction={COLUMN}>
        <Button
          type='submit'
          disabled={!refNum || syncingOrder}
          theme={theme}
          shade={5}
          size={HUGE}>
          Accept bank transfer&nbsp;
          {getFormattedPrice(amount || owed)}
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default BankTransferForm

import { SET_ONLINE, SET_SYNC_IN_PROGRESS } from 'components/online/constants'

export default function (state = true, action) {
  switch (action.type) {
    case SET_ONLINE:
      return action.online
    default:
      return state
  }
}

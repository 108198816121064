import React from 'react'

import STORES from 'data/dummy/stores'
import PRODUCTS from 'data/dummy/products'

import { BASE, SMALL, NEUTRAL, EXPAND, CLOSE } from 'components/constants'

import Button from 'components/shared/Button'
import Container from 'components/styleguide/shared/Container'
import Headline from 'components/shared/Headline'
import Icon from 'components/shared/Icon'
import Stage from 'components/styleguide/shared/Stage'
import ProductCard from 'components/products/ProductCard'
import ProductGrid from 'components/products/ProductGrid'
import ProductList from 'components/products/ProductList'
import ProductSearch from 'components/products/ProductSearch'
import LineItem from 'components/shared/LineItem'

export default function ProductComponents() {
  return (
    <Container heading='7. Product components'>
      <Stage
        width={800}
        height={600}
        heading='Search'
        subheading='Path: products/ProductSearch.jsx'
      >
        <ProductSearch
          products={PRODUCTS}
          loading={false}
          onAddCart={() => null}
        />
      </Stage>
      <Stage
        heading='List'
        subheading='Path: products/ProductList.jsx'
      >
        <ProductList
          className='shadow-3'
          products={PRODUCTS.slice(0, 4)}
          onAddCart={() => null}
          onSetOverlay={() => null}
          onSetFocusedProductId={() => null}
        />
      </Stage>
      <Stage
        width={800}
        heading='Grid'
        subheading='Path: products/ProductGrid.jsx'
      >
        <ProductGrid
          className='shadow-3'
          outlet={STORES[0]}
          items={PRODUCTS.slice(0, 6)}
          products={PRODUCTS}
          onSelect={() => null}
        />
      </Stage>
      <Stage
        width={700}
        heading='Expandable Line Item'
        subheading='Path: products/ProductListItem.jsx'
      >
        {/* <ExpandableLineItem
          context='product'
          item={PRODUCTS[0]}
          first={true}
          focused={false}
          renderContent={(expanded, setExpanded) => renderProductContent(PRODUCTS[0], expanded, setExpanded)}
        /> */}
      </Stage>
      <Stage
        width={250}
        height={300}
        heading='Card'
        subheading='Path: products/ProductCard'
      >
        <ProductCard
          product={PRODUCTS[0]}
          onSelect={() => null}
          onFocus={() => null}
          onMinimise={() => null}
        />
      </Stage>
    </Container>
  )

  function renderProductContent(product, expanded, setExpanded) {
    return (
      <div className='relative grid' style={{ gridTemplateColumns: '1fr' }}>
        <button
          type='button'
          className='font-size-2'
          onClick={() => alert('Item clicked')}
        >
          <LineItem
            gridColumns={'1fr'}
            size={BASE}
          >
            <div className='flex row gap-1 pl-3 pr-2 pe-3'>
              <div className='flex col gap-1'>
                <Headline charLimit={100}>
                  {product.name}
                </Headline>
                <span className='color-shade-4 font-size-1 font-weight-2'>
                  {product.code}
                </span>
              </div>
            </div>
          </LineItem>
        </button>
        <span data-expand className='absolute right-0 y-0 flex p-1 justify-center align-products-center'>
          <Button
            className='hover:bg-brand-2'
            rounded={3}
            shade={0}
            size={SMALL}
            theme={NEUTRAL}
            style={{ width: 40 }}
            onClick={() => {
              setExpanded(!expanded)
              alert('Handle expand content')
            }}>
            {!expanded && <Icon type={EXPAND} size={20} />}
            {expanded && <Icon type={CLOSE} size={18} />}
          </Button>
        </span>
      </div>
    )
  }
}

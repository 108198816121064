import React from 'react'

import STORES_DATA from 'data/dummy/stores'
import USERS_DATA from 'data/dummy/users'

import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'
import PasscodeForm from 'components/users/PasscodeForm'
import LockTerminalFlow from 'components/users/LockTerminalFlow'

export default function UserComponents() {
  return (
    <Container heading='11. User components'>
      <Stage
        width={800}
        height={700}
        heading='Passcode'
        subheading='Path: users/PasscodeForm.jsx'
      >
        <PasscodeForm
          user={USERS_DATA[1]}
          outlet={STORES_DATA[0]}
          animation={null}
          onBack={() => null}
          onSuccess={() => null}
        />
      </Stage>
    </Container>
  )
}

import { UPDATE_DEVICES } from 'constants/settings'

export default function UpdatePrinter(devices) {
  return {
    type: UPDATE_DEVICES,
    devices,
  }
}



import React from 'react'
import classNames from 'classnames'

import { MEDIUM, XXLARGE } from 'components/constants'
import { LAYBY } from 'components/constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import LineItem from 'components/shared/LineItem'
import OrderPaymentsBlock from 'components/order/OrderPaymentsBlock'
import getCustomerDisplayName from 'components/customers/helpers/getCustomerDisplayName'

const LaybyModal = ({
  customer,
  payments = [],
  items = [],
  theme = LAYBY,
  size = XXLARGE,
  shade = 0,
  shadow = 3,
  rounded = 0,
  status,
  zIndex,
  onBack,
  onSubmit,
  maxWidth,
  animation,
}) => {

  function submit() {
    if (status === LAYBY) onBack()
    else onSubmit()
  }

  return (
    <Form
      id="layby-modal"
      theme={theme}
      shade={shade}
      shadow={shadow}
      rounded={rounded}
      animation={animation}
      maxWidth={maxWidth}
      className={classNames({ ['z-' + zIndex]: !!zIndex })}
      onSubmit={submit}
    >
      <ButtonMenu className='ps-3 pt-3'>
        <Button
          shade={0}
          rounded={4}
          padding={5}
          size={MEDIUM}
          theme={theme}
          onClick={onBack}>
          Back
        </Button>
      </ButtonMenu>
      <Container className='pe-4'>
        <Heading size={size}>
          Convert this order to layby?
        </Heading>
        {customer && (
          <ul className='flex col gap mb-5'>
            <li>
              <LineItem
                headline={items.length === 1 ? '1 item' : items.length + ' items'}
                fontSize={4}
              />
            </li>
            <li>
              <LineItem
                headline={getCustomerDisplayName(customer)}
                fontSize={4}
              />
            </li>
          </ul>
        )}
        {payments.length > 0 && (
          <OrderPaymentsBlock payments={payments}/>
        )}
      </Container>
      <Button
        type='submit'
        className='expand-x'
        shade={5}
        rounded={0}
        theme={theme}
        size={size}>
        Yes, convert to layby
      </Button>
    </Form>
  )
}

export default LaybyModal

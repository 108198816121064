export const SYDNEY_OUTLET = {
  id: 1,
  name: 'Sydney',
  current: true,
}

export const MELBOURNE_OUTLET = {
  id: 2,
  name: 'Melbourne',
}

export const NEW_YORK_OUTLET = {
  id: 3,
  name: 'New York City',
}

const OUTLETS = [
  SYDNEY_OUTLET,
  MELBOURNE_OUTLET,
  NEW_YORK_OUTLET,
]

export default OUTLETS

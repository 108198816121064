import { SET_VIEW_ANIMATION } from '../constants'

export default function (state = [], action) {
  switch (action.type) {
    case SET_VIEW_ANIMATION:
      setLocalStorage(action.id)
      return action.id
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('viewAnimation', JSON.stringify(payload))
}

import { USE_DUMMY_DATA } from 'constants/settings'

import authenticatedFetch from './authenticatedFetch'
import { log } from 'dev/log'

export function syncOrder(
  order = {},
  onSuccess,
  onError = () => alert('Error syncing order'),
  onOffline,
) {
  log('Syncing order...')

  if (USE_DUMMY_DATA) return onSuccess()

  if (navigator.onLine) {
    const userId = order.user ? order.user.id : null

    authenticatedFetch('orders',
      {
        method: 'POST',
        body: JSON.stringify({ ...order, userId: userId }),
      },
      onSuccess,
      onError,
      onOffline
    )
  }

  // Offline
  else onOffline()
}

import React from 'react'
import classNames from 'classnames'

import LineItem from 'components/shared/LineItem'

import getFormattedDate from 'components/helpers/getFormattedDate'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

const OrderPaymentsBlock = ({
  className,
  payments = [],
  showHeading = true,
  size = null,
}) => {
  return (
    <section className={classNames({
      'sc-receipt-payments': true,
      [className]: className,
    })}>
      {showHeading && (
        <header className='border-bottom flex gap align-items-baseline pt-4 pb-3 ps-4'>
          <h3 className='font-size-2 shade-5'>
            {payments.length} {payments.length === 1 ? 'Payment' : 'Payments'}
          </h3>
        </header>
      )}
      <ul className='flex col'>
        {payments.map((payment, index) => (
          <li
            className={classNames({
              'relative z-0': true,
              'border-top': index > 0
            })}
            key={index}>
            <LineItem
              fontSize={(() => {
                switch (size) {
                  default:
                    return 2
                }
              })()}
              gridTemplateColumns='1fr 1fr 1fr'
              className={classNames({
                'font-size-2 p-4 align-items-baseline': true,
              })}>
              <strong>
                {getFormattedDate(payment.created)}
              </strong>
              {payment.type && (
                <span className='capitalize' style={{ opacity: 0.7 }}>
                  {payment.type.toLowerCase()} Payment{payment.amount < 0 ? ' (Refund)' : ''}
                </span>
              )}
              <span className='text-right'>
                {getFormattedPrice(convertToAbsoluteAmountValue(payment.amount))}
              </span>
            </LineItem>
          </li>
        ))}
      </ul>
    </section>
  )
}

export default OrderPaymentsBlock

import {
  NEXT,
  BACK,
  SLIDE_NEXT,
  SCALE_IN,
  SLIDE_PREV,
  SLIDE_UP,
  SLIDE_DOWN,
  FADE,
} from 'constants/motion'

export default function getAnimation(id, delay = 0) {
  const transition = {
    type: 'spring',
    bounce: 0.5,
    duration: 0.4
  }

  switch (id) {
    case FADE:
      return {
        initial: { opacity: 0 },
        animate: { opacity: 1 },
        exit: { opacity: 0 },
        transition: { duration: 0.05 }
      }
    case SCALE_IN:
      return {
        initial: { opacity: 0, scale: 0.8 },
        animate: { opacity: 1, scale: 1 },
        exit: { opacity: 0, scale: 0.6 }
      }
    case SLIDE_NEXT:
      return {
        initial: { x: '10%' },
        animate: { x: 0 },
        exit: { x: '-10%' }
      }
    case SLIDE_PREV:
      return {
        initial: { x: '-10%' },
        animate: { x: 0 },
        exit: { x: '-10%' }
      }
    case SLIDE_UP:
      return {
        initial: { opacity: 0, y: '100%' },
        animate: { opacity: 1, y: 0 },
        transition: { delay }
      }
    case SLIDE_DOWN:
      return {
        initial: { opacity: 0, y: '-10%' },
        animate: { opacity: 1, y: 0 }
      }
    case NEXT:
      return {
        initial: {
          opacity: 1,
          borderRadius: 100,
          scale: 0.9,
          y: 0,
        },
        animate: {
          opacity: 1,
          borderRadius: 0,
          scale: 1,
          y: 0,
        },
        transition: {
          type: 'spring',
          duration: 0.3,
        }
      }
    case BACK:
      return {
        initial: { scale: 1.1 },
        animate: { scale: 1 },
        transition
      }
    default:
      return null
  }
}

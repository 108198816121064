import {
  MARK_SHIPMENT_READ,
  SET_SHIPMENT_STATUS,
  SET_SHIPMENTS,
} from '../constants'

export default function (state = {}, action) {
  switch (action.type) {
    case MARK_SHIPMENT_READ:
      return setLocalStorage(state.map((shipment) => {
        if (shipment.id === action.id) return { ...shipment, read: true }
        else return shipment
      }))
    case SET_SHIPMENT_STATUS:
      return setLocalStorage(state.map((shipment) => {
        if (shipment.id === action.id) return { ...shipment, status: action.status }
        else return shipment
      }))
    case SET_SHIPMENTS:
      return setLocalStorage(action.shipments.map((shipment) => {
        if (state.find(s => s.id === shipment.id)) return shipment
        else return { ...shipment, read: false }
      }))
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('shipments', JSON.stringify(payload))
  return payload
}

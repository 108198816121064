import { USE_DUMMY_DATA } from 'constants/settings'
import ORDERS from 'data/dummy/orders'

import { log, dir } from 'dev/log'
import authenticatedFetch from './authenticatedFetch'

export function fetchOrders(
  onSuccess,
  onError = () => alert('Error fetching orders'),
) {
  log('Fetching orders...')

  if (USE_DUMMY_DATA) return onSuccess(ORDERS)

  if (navigator.onLine) {
    authenticatedFetch('orders',
      { method: 'GET' },
      // Fetched orders
      ({ orders }) => {
        dir(orders)
        onSuccess(orders)
      },
      // Error
      () => onError()
    )
  }
}

import { SET_TAXES } from 'components/taxes/constants'

export default function (state = [], { type, taxes }) {
  switch (type) {
    case SET_TAXES:
      window.localStorage.setItem('taxes', JSON.stringify(taxes))
      return taxes
    default:
      return state
  }
}

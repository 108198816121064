import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import getAnimation from 'helpers/getAnimation'

const Fieldset = ({ animation, className, gap = 3, children, label, required }) => {
  return (
    <motion.fieldset
      {...getAnimation(animation)}
      required={required}
      className={classNames({
        'flex': true,
        ['gap-' + gap]: gap > 0,
        [className]: className,
      })}
    >
      {label && <div className='color-shade-5'>{label}</div>}
      {required === true && <div className='font-size-2 color-shade-4'>*Required</div>}
      {children}
    </motion.fieldset>
  )
}

export default Fieldset

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { MEDIUM, XXLARGE } from 'components/constants'
import { COLUMN } from 'components/constants'
import { CUSTOMER, LAYBY } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getCustomerFullName from 'components/customers/helpers/getCustomerFullName'

const CustomerMatchForm = ({
  animation,
  className,
  customers = [],
  innerAnimation = null,
  maxHeight,
  maxWidth,
  onSubmit,
  onBack,
  showBackButton = true,
  rounded = 0,
  padding = 3,
  shade = 0,
  size = XXLARGE,
  theme = CUSTOMER,
  zIndex,
}) => {
  const [selectedCustomer, setSelectedCustomer] = useState(customers[0])

  useEffect(() => {
    if (isTouchScreen()) return
    document.getElementById('add-customer-button').focus()
  }, [])

  return (
    <Form
      animation={animation}
      maxHeight={maxHeight}
      maxWidth={maxWidth}
      rounded={rounded}
      shade={shade}
      shadow={2}
      theme={theme}
      disabled={selectedCustomer === null}
      onSubmit={() => onSubmit(selectedCustomer)}
      className={classNames({
        ['z-' + zIndex]: zIndex !== undefined,
        [className]: className !== undefined,
      })}>
      {showBackButton && (
        <ButtonMenu className='ps-3 pt-3'>
          <Button
            rounded={3}
            padding={5}
            shade={shade}
            theme={theme}
            onClick={onBack}>
            Back
          </Button>
        </ButtonMenu>
      )}
      <Container
        animation={innerAnimation}
        scroll={true}
        padding={padding}
        className={'pe-' + (padding - 2)}>
        <Heading size={size}>
          We've found a matching customer
        </Heading>
        <ButtonMenu direction={COLUMN} className='gap-2'>
          {customers.map((customer, index) => (
            <Button
              shade={0}
              rounded={2}
              padding={4}
              size={MEDIUM}
              key={index}
              theme={theme}
              shadow={selectedCustomer.id === customer.id ? 1 : 0}
              onClick={() => setSelectedCustomer(customer)}
              className={classNames({
                'justify-start': true,
              })}>
              {getCustomerFullName(customer)}
            </Button>
          ))}
        </ButtonMenu>
      </Container>
      <Button
        id='add-customer-button'
        type='submit'
        shade={theme === LAYBY ? 3 : 4}
        rounded={0}
        theme={theme}
        size={size}
      >
        {theme === LAYBY && 'Initiate layby'}
        {theme !== LAYBY && 'Add ' + selectedCustomer.firstName}
      </Button>
    </Form>
  )
}

export default CustomerMatchForm

import { CASH, CARD, BANK, CHEQUE } from 'components/constants'

export default function getPaymentTotalsByType(orders = [], startCashBalance) {
  let totals = {
    CASH: startCashBalance || 0,
    CARD: 0,
    BANK: 0,
    CHEQUE: 0,
  }
  orders.forEach(o => o.payments.forEach(payment => totals[payment.type] += payment.amount))

  return totals
}

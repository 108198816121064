import { SET_PRINTABLE_LABELS } from '../constants'

export default function (state = null, action) {
  switch (action.type) {
    case SET_PRINTABLE_LABELS:
      window.localStorage.setItem('printableLabels', JSON.stringify(action.printableLabels))
      return action.printableLabels
    default:
      return state
  }
}



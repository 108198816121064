export default function shouldApplyCashRounding(
  store = {},
  cashRoundingData = {},
) {
  return (
    store.cash_rounding_unit !== null &&
    store.cash_rounding_rule !== null &&
    Object.keys(cashRoundingData).length > 0
  )
}

import React, { useState, useEffect } from 'react'

import { BRAND, COLUMN } from 'components/constants'

import Article from 'components/shared/Article'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import Topbar from 'components/navigation/Topbar'
import UserButtonLineItem from 'components/users/shared/UserButtonLineItem'

const ShiftAddUser = ({
  onSubmit,
  onBack,
  transition,
  shift = {},
  users = {},
  theme = BRAND,
  shade = 6,
}) => {
  const [filteredUsers, setFilteredUsers] = useState(getNonShiftUsers(users))

  useEffect(() => {
    setFilteredUsers(getNonShiftUsers(users))
  }, [users])

  function getNonShiftUsers(users) {
    return Object.keys(users)
      .filter((id) => !shift.users.find((userId) => userId === id))
      .map((id) => users[id])
  }

  return (
    <Article
      className='expand'
      gridTemplateRows='1fr'
      animation={transition}
      theme={theme}
      shade={shade}>
      <Topbar
        className='absolute x-0'
        transitional={true}
        theme={theme}
        shade={shade}
        onBack={onBack}
      />
      <Container center={true} maxWidth={680} gap={4}>
        <Heading theme={theme}>
          Add user to shift
        </Heading>
        {filteredUsers.length > 0 && (
          <ButtonMenu
            id='user-menu'
            className='overflow-y-auto'
            rounded={4}
            direction={COLUMN}
          >
            {filteredUsers.map((user, index) => (
              <UserButtonLineItem
                key={index}
                user={user}
                onClick={() => {
                  onSubmit(user)
                  onBack()
                }}
              />
            ))}
          </ButtonMenu>
        )}
        {filteredUsers.length === 0 && (
          <div className='font-weight-2 font-size-4' style={{ height: 200 }}>
            All available users have been added to this shift
          </div>
        )}
      </Container>
    </Article>
  )
}

export default ShiftAddUser

import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { USE_DUMMY_DATA } from 'constants/settings'
import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchProducts } from 'data/api/fetchProducts'
import { getIndexedDB } from 'data/indexedDB'

import getSyncTimeout from 'data/helpers/getSyncTimeout'
import SetOnline from '../components/online/actions/SetOnline'
import SetSyncInProgress from '../components/online/actions/SetSyncInProgress'

const useSyncProducts = ({ poll = false }) => {
  const dispatch = useDispatch()
  const syncTimeout = getSyncTimeout()
  const [products, setProducts] = useState([])
  const [reserved, setReserved] = useState([])
  const [syncing, setSyncing] = useState(false)
  const [loading, setLoading] = useState(false)

  let syncInterval = null

  useEffect(() => {
    setLoading(true)
    // On mount we load products from indexedDB
    getIndexedProducts((products, reserved) => {
      // Use stored products from indexedDB if there are any
      if (products.length > 0) {
        setProducts(products)
        setReserved(reserved)
        setLoading(false)

      // If there are no products in indexedDB on mount we fetch
      // them from the server, add them to indexedDB, and sync to
      // our local state for use.
      } else fetchAndSync(1, () => setLoading(false))
    })

    // While online we check periodically for product changes and
    // fetch and sync new or updated products from the server.
    if (poll && navigator.onLine & USE_DUMMY_DATA === false) {
      syncInterval = setInterval(() => {
        fetchCacheVersions('products',
          () => fetchAndSync(1, () => setLoading(false)),
          (online) => dispatch(SetOnline(online))
        )
      }, syncTimeout)
    }
    return () => clearInterval(syncInterval)
  }, [])

  // Updates our local product state after we have
  // finished fetching all products from the server.
  useEffect(() => {
    dispatch(SetSyncInProgress('products', syncing))
    if (syncing === true) return
    getIndexedProducts((products, reserved) => {
      setProducts(products)
      setReserved(reserved)
    })
  }, [syncing])

  // Fetch products from the server in chunks and index them
  // with indexedDB, before syncing to our local state for use.
  async function fetchAndSync(page = 1, onComplete) {
    setSyncing(true)

    fetchProducts(page, ({ products, reserved_products, pages }) => {
      // Index products by page with indexedDB
      products.forEach((product) => addIndexedDB(product))
      // Index reserved products on page 1
      if (page === 1) {
        setReserved(reserved_products)
        reserved_products.forEach(reserved => addIndexedDB(reserved, true))
      }
      // Continue fetching products from the server if there
      // are more pages (current page less than total pages)
      if (page < parseInt(pages)) fetchAndSync(page + 1, onComplete)
      // After all products have been indexed we stop fetching
      else {
        setSyncing(false)
        if (onComplete) onComplete()
      }
    })

    function addIndexedDB(product, reserved = false) {
      if (product) getIndexedDB().products.put({
        id: product.id,
        timestamp: product.timestamp,
        data: JSON.stringify({...product, reserved }),
      })
    }
  }

  async function getIndexedProducts(onSuccess) {
    const indexedProducts = await getIndexedDB().products.toArray()

    onSuccess(indexedProducts
      .map((product) => JSON.parse(product.data))
      .filter((product) => product.reserved !== true),
      indexedProducts
      .map((product) => JSON.parse(product.data))
      .filter((product) => product.reserved === true)
    )
  }

  return { loading, syncing, products, reserved, fetchAndSync }
}


export default useSyncProducts

import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

const Checkbox = ({ checked, label, onChange, dataTestId, id, className }) => {
  const [isChecked, setIsChecked] = useState(checked)

  useEffect(() => {
    setIsChecked(checked)
  }, [checked])

  function handleOnChange() {
    setIsChecked(!isChecked)
    if (onChange) {
      onChange(!isChecked)
    }
  }

  return (
    <label 
      className={classNames({
        [className]: className,
      })}
      htmlFor={id + "_checkbox"}>
      <input
        data-testid={dataTestId}
        name="checkbox"
        id={id + "_checkbox"}
        type="checkbox"
        checked={isChecked}
        onChange={handleOnChange}
      />
      {label && <span className="ml-2">{label}</span>}
    </label>
  )
}

export default Checkbox
export const USER_ANG = {
  id: 2,
  firstName: 'Ang',
  lastName: 'Lee',
  email: 'ang@storeconnect.com',
  discount_level: 'restricted',
}

export const USER_ANDY = {
  id: 1,
  firstName: 'Andy',
  lastName: 'Madison',
  email: 'andy@storeconnect.com',
  discount_level: 'unrestricted',
}

export const USER_BEN_D = {
  id: 3,
  firstName: 'Ben',
  lastName: 'Davies',
  email: 'ben@storeconnect.com',
  discount_level: 'restricted',
}

export const USER_BEN_C = {
  id: 4,
  firstName: 'Ben',
  lastName: 'Cartwright',
  email: 'benc@storeconnect.com',
  discount_level: 'none',
}

export default [
  USER_ANDY,
  USER_ANG,
  USER_BEN_D,
  USER_BEN_C,
]

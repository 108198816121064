import React from 'react'
import classNames from 'classnames'

import { SMALL, MEDIUM, LARGE } from 'constants/size'
import { NOTIFY } from 'components/constants'

import ProductImage from 'components/products/shared/ProductImage'
import Tag from 'components/shared/Tag'
import Count from 'components/shared/Count'

import getTruncatedText from 'components/helpers/getTruncatedText'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const CartLineItem = ({
  isReturnItem = false,
  imageHeight = 70,
  showImage = true,
  showPrice = true,
  cartItem = {},
  size = MEDIUM,
}) => {

  return (
    <div
      gap={3}
      className={classNames({
        'grid pe-1 gap-3 ps-2 justify-start align-items-center expand-x text-left': true,
        'color-notify-5': isReturnItem
      })}
      style={{
        gridTemplateColumns: showImage ? 'auto 1fr auto' : '1fr auto',
      }}
    >
      {showImage && (
        <ProductImage
          height={imageHeight}
          imageSrc={cartItem.image}
        />
      )}
      <span className='flex col gap-1'>
        <span className={classNames({
          'font-weight-3 flex gap-1': true,
          'font-size-2': size === MEDIUM,
          'font-size-3': size === LARGE,
        })}>
          {(cartItem.quantity < -1 || cartItem.quantity > 1) && (
            <Count
              floating={cartItem.image !== null}
              number={cartItem.quantity}
            />
          )}
          {getTruncatedText(cartItem.name, 80)}
        </span>
        {cartItem.code && (
          <span className='opacity-07 font-size-1 font-weight-2'>
            {cartItem.code}
          </span>
        )}
        {cartItem.requires_serial && (
          <em className='opacity-07 font-size-1 font-weight-2'>
            Requires serial
          </em>
        )}
      </span>
      {showPrice === true && (
        <span className='flex col align-items-end gap-1'>
          <div className='font-size-2 font-weight-3'>
            {getFormattedPrice((cartItem.price - cartItem.discount) * cartItem.quantity)}
          </div>
          {cartItem.discount > 0 && (
            <Tag
              theme={NOTIFY}
              size={SMALL}
              text={`${getFormattedPrice(cartItem.discount * cartItem.quantity)} discount`}
            />
          )}
          {cartItem.discount < 0 && (
            <Tag
              theme={NOTIFY}
              size={SMALL}
              text={`${getFormattedPrice((cartItem.discount * -1) * cartItem.quantity)} extra`}
            />
          )}
        </span>
      )}
    </div>
  )
}

export default CartLineItem

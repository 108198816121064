import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SetOnline from 'components/online/actions/SetOnline'
import { log } from 'dev/log'

export default function Online({ children }) {
  const dispatch = useDispatch()
  const online = useSelector((state) => state.online)

  function setOnline() {
    dispatch(SetOnline(true))
  }

  function setOffline() {
    dispatch(SetOnline(false))
  }

  useEffect(() => {
    window.addEventListener('online', setOnline)
    window.addEventListener('offline', setOffline)
    return () => {
      window.removeEventListener('online', setOnline)
      window.removeEventListener('offline', setOffline)
    }
  }, [])

  useEffect(() => {
    log(online ? 'Device is online' : 'Device is offline')
  }, [online])

  // We can probably put a presentation here that the device is offline if needed.
  return (
    <>
      {children}
    </>
  )
}

import React from 'react'
import classNames from 'classnames'

const FieldLabel = ({
  required = false,
  showRequiredLabel = true,
  targetId,
  children,
}) => {
  return (
    <label
      htmlFor={targetId}
      className={classNames({
        'text-left flex pe-2 align-items-baseline gap-1': true,
        'opacity-07': true,
      })}>
      <span className='font-size-3 font-weight-2'>
        {children}
      </span>
      {required === true && showRequiredLabel === true && (
        <span className='color-shade-5 font-size-1'>(Required)</span>
      )}
    </label>
  )
}

export default FieldLabel

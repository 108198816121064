import { USE_DUMMY_DATA } from 'constants/settings'
import CUSTOMERS from 'data/dummy/customers'

import { dir, log } from 'dev/log'
import authenticatedFetch from './authenticatedFetch'


export function fetchCustomers(
  onSuccess,
  onOffline,
  onError = () => alert('Error fetching customers'),
) {
  log('Fetching customers...')

  if (USE_DUMMY_DATA) return onSuccess(CUSTOMERS)

  if (navigator.onLine) {
    authenticatedFetch('customers',
      { method: 'GET' },
      // Success
      ({ customers }) => {
        dir(customers)
        onSuccess(customers)
      },
      // Error
      () => onError()
    )
  }
  // Offline
  else onOffline()
}

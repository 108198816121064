import Dexie from 'dexie';

export default function getDatabase() {
  const db = new Dexie('storeconnect_pos');

  db.version(1).stores({
    products: 'id,timestamp,data',
  })

  return db
}

import React from 'react'

import { SMALL } from 'components/constants'

import LineItem from 'components/shared/LineItem'
import Record from 'components/shared/Record'
import Tag from 'components/shared/Tag'
import Headline from 'components/shared/Headline'

import getOrderStatusText from 'components/orders/helpers/getOrderStatusText'
import getDateLocalTimezone from 'components/helpers/getDateLocalTimezone'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const CustomerOrdersBlock = ({ className, orders }) => {
  if (orders.length > 0) return (
    <div className={className}>
      <ul className='bg-shade-1 flex col gap-1px'>
        {orders.map((order, index) => (
          <li className='relative bg-white' key={index}>
            <LineItem
              gridTemplateColumns='1fr 1fr 1fr 1fr 1fr'
              className='ps-4 pe-2'>
              <Headline>
                {order.reference_number}
              </Headline>
              <span>
                <Tag text={getOrderStatusText(order)} />
              </span>
              <strong className='opacity-07'>
                {getDateLocalTimezone(order.created)}
              </strong>
              <span>
                <Record
                  label='Amount to pay'
                  showEmptyValue={true}
                  size={SMALL}>
                  {getFormattedPrice(getAmountOwed(order))}
                </Record>
              </span>
              <span>
                <Record
                  label='Total'
                  showEmptyValue={true}
                  size={SMALL}>
                    {getFormattedPrice(order.total)}
                </Record>
              </span>
            </LineItem>
          </li>
        ))}
      </ul>
    </div>
  )
  else return null
}

export default CustomerOrdersBlock

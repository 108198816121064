import { USE_DUMMY_DATA } from 'constants/settings'
import STORES from 'data/dummy/stores'

import authenticatedFetch from './authenticatedFetch'
import { dir, log } from 'dev/log'

export function fetchStores(
  onSuccess,
  onOffline,
  onError = () => alert('Error fetching stores'),
) {
  log('Fetching stores...')

  if (USE_DUMMY_DATA) onSuccess(STORES)

  if (navigator.onLine) {
  authenticatedFetch('stores',
      { method: 'GET' },
      // Success
      ({ stores }) => {
        log('Fetched stores:')
        dir(stores)
        onSuccess(stores)
      },
      // Error
      () => onError()
    )
  }
  else onOffline()
}

import { LAYBY, BRAND, CUSTOMER, NOTIFY } from 'constants/theme'
import { PARKED, INCOMPLETE } from 'components/orders/constants'

export default function getOrderTheme(orderType = null) {
  if (orderType === null) return BRAND
  if (orderType === INCOMPLETE) return NOTIFY
  if (orderType.toUpperCase() === PARKED) return CUSTOMER
  if (orderType.toUpperCase() === LAYBY) return LAYBY
  return BRAND
}

import React, { useEffect, useState } from 'react'

import { CLOSE, SMALL, TINY, NEUTRAL, BRAND, COLUMN } from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import ButtonSelector from 'components/shared/ButtonSelector'
import Container from 'components/shared/Container'
import StickyHeader from 'components/shared/StickyHeader'
import Record from 'components/shared/Record'
import ProductList from 'components/products/ProductList'
import Overlay from 'components/shared/Overlay'
import Icon from 'components/shared/Icon'
import Main from 'components/shared/Main'
import QuantityPicker from 'components/shared/QuantityPicker'

const PrintDetails = ({
  animation,
  labelTemplates,
  prepareLabels,
  previewBlob,
  selectedProducts,
  setSelectedLabelTemplate,
  selectedLabelTemplate = null,
  isPreparingPreview = false,
  printQuantity = 1,
  onQuantityChange,
  onPrint,
  onBack,
}) => {
  const [showFullLabelPreview, setShowFullLabelPreview] = useState(false)

  useEffect(() => {
    if (selectedLabelTemplate === null) return
    prepareLabels(selectedLabelTemplate, true)
  }, [])

  return (
    <Main scroll={true} theme={NEUTRAL} shade={0} animation={animation}>
      <Article gridRows='auto 1fr' theme={NEUTRAL} shade={0}>
        <StickyHeader>
          <ButtonMenu className='justify-space-between ps-3 pt-2'>
            <Button
              className='bg-white'
              shade={0}
              shadow={3}
              padding={5}
              theme={BRAND}
              onClick={onBack}
            >
              Back
            </Button>
            <Button
              className='bg-white'
              shade={5}
              shadow={2}
              padding={5}
              theme={BRAND}
              disabled={labelTemplates === null || labelTemplates.length === 0 || selectedLabelTemplate === null}
              onClick={onPrint}
            >
              Print
            </Button>
          </ButtonMenu>
        </StickyHeader>

        <Container className='pe-5' padding={5} gap={4}>
          <ButtonMenu className='justify-space-between'>
            <ButtonSelector
              id='label-template-dropdown'
              label='Label Template'
              theme={BRAND}
              selected={selectedLabelTemplate?.name}
              shadow={2}
            >
              <ButtonMenu direction={COLUMN}>
                <Button
                  className='justify-start'
                  rounded={0}
                  theme={NEUTRAL}
                  size={TINY}
                  onClick={() => setSelectedLabelTemplate(null)}
                >
                  <span className='text-left grow'>
                    Select Label Template
                  </span>
                </Button>
                {labelTemplates.map((template) => {
                  return (
                    <Button
                      key={template.id}
                      className='justify-start'
                      rounded={0}
                      theme={NEUTRAL}
                      size={TINY}
                      onClick={() => {
                        if (selectedLabelTemplate !== null && selectedLabelTemplate.id === template.id) return
                        setSelectedLabelTemplate(template)
                        prepareLabels(template)
                      }}>
                      <span className='text-left grow'>
                        {template.name}
                      </span>
                    </Button>
                  )
                })}
              </ButtonMenu>
            </ButtonSelector>
          </ButtonMenu>

          <section className='relative z-0 bg-shade-1 rounded-3 p-3'>
            {selectedLabelTemplate === null && (
              <div className='p-4 font-2 color-shade-3 font-weight-2'>
                No selected label template. Please select one.
              </div>
            )}

            {selectedLabelTemplate !== null && (
              <>
                <div className="flex row justify-space-between mt-2 mb-2 pl-4 pr-4">
                  <div className="flex col gap-2">
                    <span className="font-size-2">Print quantity</span>
                    <QuantityPicker
                      size={TINY}
                      theme={BRAND}
                      quantity={printQuantity}
                      disableDeductButton={printQuantity === 1}
                      onDecrement={() => {
                        onQuantityChange(printQuantity - 1)
                      }}
                      onIncrement={() => {
                        onQuantityChange(printQuantity + 1)
                      }}
                      skipOverZero={true}
                    />

                    <div className="mt-4 mb-4">
                      <div className='font-weight-2'>
                        {!isPreparingPreview && selectedLabelTemplate.name}
                        {isPreparingPreview && `${selectedLabelTemplate.name} (Preparing preview)`}
                      </div>

                      <div className='bg-shade-1 rounded-3 flex gap-5'>
                        <Record label='Size Unit'>{selectedLabelTemplate.size_unit}</Record>
                        <Record label='Width'>{selectedLabelTemplate.width}</Record>
                        <Record label='Height'>{selectedLabelTemplate.height}</Record>
                        <Record label='Print Density'>{selectedLabelTemplate.dpi} dpi</Record>
                      </div>
                    </div>
                  </div>

                  <div className="bg-shade-2 rounded-3" style={{ width: 200, height: 130 }}>
                    {!isPreparingPreview && previewBlob !== null && (
                      <img
                        className="shadow-4 rounded-3"
                        style={{ width: 200, cursor: 'pointer' }}
                        src={URL.createObjectURL(previewBlob)}
                        onClick={() => setShowFullLabelPreview(true)}
                      />
                    )}
                  </div>
                </div>
              </>
            )}

            <>
              <div className=" pl-4 pr-4 mb-4">
                <span className="font-size-2 color-shade-3 mt-4">
                  Selected Products ({selectedProducts.length})
                </span>
              </div>

              <div className="pl-2 pr-2">
                <ProductList
                  className='ps-3 pb-3'
                  outlets={[]}
                  outlet={null}
                  onAddCart={() => null}
                  keyword={''}
                  limitedColumns={true}
                  showOverlay={false}
                  selectedProducts={[]}
                  focusedProductId={null}
                  onSetOverlay={() => null}
                  onSetFocusedProductId={() => null}
                  products={selectedProducts}
                />
              </div>
            </>
          </section>
        </Container>
      </Article>

      {showFullLabelPreview && (
        <Overlay onClose={() => setShowFullLabelPreview(false)}>
          <div className="bg-white p-4 z-2 rounded-3 shadow-3">
            <div className="flex justify-end">
              <Button
                className='hover:bg-brand-2'
                rounded={3}
                shade={0}
                size={SMALL}
                theme={NEUTRAL}
                style={{ width: 40 }}
                onClick={() => setShowFullLabelPreview(false)}
              >
                <Icon type={CLOSE} size={18} />
              </Button>
            </div>
            <img
              className="border shadow-3"
              style={{ width: 400, cursor: 'pointer' }}
              src={URL.createObjectURL(previewBlob)}
            />
          </div>
        </Overlay>
      )}
    </Main>
  )
}

export default PrintDetails

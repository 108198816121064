import React, { useEffect, useState } from 'react'

import { NEUTRAL, DEBOUNCE_DELAY  } from 'components/constants'
import { BACK, NEXT } from 'components/constants'

import { fetchSuppliers } from 'data/api/fetchSuppliers'

import ButtonMenu from 'components/shared/ButtonMenu'
import Main from 'components/shared/Main'
import SearchInput from 'components/shared/SearchInput'
import Supplier from 'components/suppliers/Supplier'
import SupplierList from 'components/suppliers/SupplierList'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'

import useInputDebounce from 'hooks/useInputDebounce'
import usePrevious from 'hooks/usePrevious'

export default function Suppliers() {
  const [animation, setAnimation] = useState(null)
  const [keyword, setKeyword] = useState('')
  const [suppliers, setSuppliers] = useState([])
  const [loading, setLoading] = useState(false)
  const [supplier, setSupplier] = useState({})

  const debouncedKeyword = useInputDebounce(keyword, DEBOUNCE_DELAY)
  const previousDebouncedKeyword = usePrevious(debouncedKeyword)

  useEffect(() => fetch(keyword), [])

  useEffect(() => {
    if (debouncedKeyword !== previousDebouncedKeyword) {
      setLoading(true)
      fetch(debouncedKeyword)
    }
  }, [debouncedKeyword, previousDebouncedKeyword])

  function fetch(query) {
    setLoading(true)
    fetchSuppliers(query,
      // Success
      (suppliers) => {
        setSuppliers(suppliers)
        setLoading(false)
      },
      // Offline
      () => {
        setSuppliers([])
        setLoading(false)
      }
    )
  }

  if (Object.keys(supplier).length === 0) {
    return (
      <View
        className='bg-gradient-2'
        theme={NEUTRAL}
        animation={animation}
      >
        <Topbar className='border-bottom' />
        <Main className="expand-x">
          {Object.keys(supplier).length === 0 && (
            <ButtonMenu className='ps-3 pe-2'>
              <SearchInput
                id='search'
                className='grow'
                defaultValue={keyword}
                onChange={(value) => setKeyword(value)}
                placeholder='Search by name'
              />
            </ButtonMenu>
          )}
          <SupplierList
            animation={animation}
            loading={loading}
            query={keyword}
            suppliers={suppliers}
            searched={keyword.length > 0}
            onFilter={(value) => filterByKeyword(value)}
            onSupplierClick={(supplier) => {
              setAnimation(NEXT)
              setSupplier(supplier)
            }}
          />
        </Main>
      </View>
    )
  } else {
    return (
      <Supplier
        animation={animation}
        supplier={supplier}
        onBack={() => {
          setAnimation(BACK)
          setSupplier({})
        }}
      />
    )
  }
}

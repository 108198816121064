import { CREATE_CUSTOMER, SET_CUSTOMERS } from '../constants'

export default function (state = {}, action) {
  switch (action.type) {
    case CREATE_CUSTOMER:
      setLocalStorage({ ...state, [action.customer.id]: action.customer })
      return { ...state, [action.customer.id]: action.customer }

    case SET_CUSTOMERS:
      setLocalStorage(action.customers)
      return action.customers

    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('customers', JSON.stringify(payload))
}

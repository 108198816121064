import { USE_DUMMY_DATA } from 'constants/settings'
import { dir, log } from 'dev/log'
import authenticatedFetch from 'data/api/authenticatedFetch'

export function syncShift(
  register = {},
  shift = {},
  onSuccess,
  onError = () => alert('Error syncing shift'),
  onOffline
) {
  log('Syncing shift...')

  if (USE_DUMMY_DATA) return onSuccess()

  if (navigator.onLine) {
    authenticatedFetch('registers/' + register.id + '/shifts',
      {
        method: 'POST',
        body: JSON.stringify(shift),
      },
      // Success
      () => {
        dir(shift)
        onSuccess()
      },
      onError,
      onOffline
    )
  }
  // Offline
  else onOffline()
}

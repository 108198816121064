import {
  UPDATE_PRINTER,
  UPDATE_DRAWER,
  UPDATE_DEVICES,
  UPDATE_TYRO,
  CONNECT_DRAWER_TO_PRINTER,
  UPDATE_CONTEXT,
} from 'constants/settings'

import defaultState from 'data/settingsData'

let updatedState = {}

export default function (state = defaultState, action) {
  switch (action.type) {
    case CONNECT_DRAWER_TO_PRINTER:
      const printers = [...state.printers]
      const matchedPrinterIndex = printers.findIndex((printer) => printer.key === action.printer.key)
      printers[matchedPrinterIndex] = action.printer
      updatedState = {
        ...state,
        printers,
        drawer: {...state.drawer, printerKey: action.printer.key}
      }

      setLocalStorage(updatedState)
      return updatedState

    case UPDATE_DEVICES:
      updatedState = {
        ...state,
        devices: action.devices,
      }

      setLocalStorage(updatedState)
      return updatedState

    case UPDATE_PRINTER:
      const updatedArray = state.printers.map((printer) => {
        if (printer.key === action.printer.key) return action.printer
        return printer
      });
      updatedState = {
        ...state,
        printers: updatedArray,
      }
      
      setLocalStorage(updatedState)
      return updatedState

    // Will be completely handled in a separate ticket
    case UPDATE_DRAWER:
      updatedState = {
        ...state,
        drawer: action.drawer,
      }
 
      setLocalStorage(updatedState)
      return updatedState
    case UPDATE_CONTEXT:
      const updatedContexts = state.contexts.map((context) => {
        if (context.key === action.context.key) return action.context
        return context
      });
      updatedState = {
        ...state,
        contexts: updatedContexts,
      }
      setLocalStorage(updatedState)
      return updatedState

    case UPDATE_TYRO:
      updatedState = {
        ...state,
        tyro: {
          printTyroReceipt: action.printTyroReceipt,
          alwaysPrintMerchantReceipt: action.alwaysPrintMerchantReceipt
        }
      }
 
      setLocalStorage(updatedState)
      return updatedState
    default:
      setLocalStorage(state)
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('settings', JSON.stringify(payload))
}
import React from 'react'

import { BRAND, NEUTRAL, COLUMN } from 'components/constants'
import { SMALL, MEDIUM } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import ButtonSelector from 'components/shared/ButtonSelector'
import Tag from 'components/shared/Tag'

import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'
import Accordion from 'components/shared/Accordion'

export default function AccordionsDropdowns() {
  return (
    <Container heading='3. Accordions & Dropdowns'>
      <Stage heading='Dropdown'>
        <ButtonSelector
          id='dropdown-selector'
          label='Label text'
          theme={BRAND}
          size={MEDIUM}
          shadow={2}
        >
          <ButtonMenu direction={COLUMN}>
            <Button
              className='justify-start'
              rounded={0}
              theme={NEUTRAL}
              size={MEDIUM}
              onClick={() => onFilterType(null)}
            >
              <Tag
                text='Include all'
                size={SMALL}
              />
            </Button>
          </ButtonMenu>
        </ButtonSelector>
      </Stage>

      <Stage width={500} heading='Accordion'>
        <div className='expand-x flex col gap-3'>
          <Accordion
            headline='Headline text'
            className='expand-x shadow-2'
          >
            <div className='expand-x' style={{ height: 100 }}></div>
          </Accordion>
          <Accordion
            headline='Headline text'
            className='expand-x shadow-2'
            open={true}
          >
            <div className='expand-x' style={{ height: 100 }}></div>
          </Accordion>
        </div>
      </Stage>
    </Container>
  )
}

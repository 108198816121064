const STORE_A = {
  cash_rounding_rule: 'up',
  cash_rounding_unit: 0.05,
  currency: 'AUD',
  current: true,
  id: 1,
  name: 'Store A',
  orderReferencePrefix: 'SC',
  orderTypes: ['Layby'],
  require_serial_number: true,
}

const STORE_B = {
  cash_rounding_rule: null,
  cash_rounding_unit: null,
  currency: 'USD',
  current: false,
  id: 2,
  name: 'Store B',
  orderReferencePrefix: 'SC',
  orderTypes: ['Layby'],
  require_serial_number: false
}

const STORES = [STORE_A, STORE_B]

export default STORES

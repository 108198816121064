import React from 'react'

import { BRAND } from 'components/constants'
import { SMALL, LARGE, XLARGE } from 'components/constants'
import { CHEVRON } from 'components/constants'
import { REGISTER } from 'components/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import LineItem from 'components/shared/LineItem'

const RegisterLineItem = ({ register, theme = BRAND, rounded = 0, shade = 5, onClick }) => {
  return (
    <Button
      padding={5}
      size={XLARGE}
      rounded={rounded}
      theme={theme}
      shade={shade}
      onClick={onClick}>
      <LineItem
        className='font-size-6 font-weight-3'
        gridTemplateColumns='1fr auto'
        gap={3}>
        <strong>{register.name}</strong>
        <Icon type={CHEVRON} size={17} rotate={270} />
      </LineItem>
    </Button>
  )
}

export default RegisterLineItem

import { USE_DUMMY_DATA } from 'constants/settings'
import PRODUCTS, { CASH_ROUNDING } from 'data/dummy/products'

import authenticatedFetch from './authenticatedFetch'
import { log, dir } from 'dev/log'

export function fetchProducts(
  page = 1,
  onSuccess,
  onError = () => alert('Error fetching products'),
) {
  log('Fetching products...')
  log('PAGE ' + page)

  if (USE_DUMMY_DATA) return onSuccess({
    pages: 1,
    products: PRODUCTS,
    reserved_products: [CASH_ROUNDING],
  })

  if (navigator.onLine) {
    authenticatedFetch(
      'products?page=' + page,
      { method: 'GET' },
      // Success
      (json) => {
        dir(json)
        onSuccess(json)
      },
      // Error
      () => onError()
    )
  }
}

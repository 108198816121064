import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'
import { FADE } from 'components/constants'
import getAnimation from 'helpers/getAnimation'

const Loader = ({
  zIndex = 2,
  size = 40,
  color = 'var(--sc-shade-4)',
  message = '',
  background = 'bg-opacity-1',
}) => {
  return (
    <motion.div
      className={classNames({
        'color-shade-5': true,
        [background]: background,
      })}
      style={{
        position: 'absolute',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 10,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        zIndex,
        color,
      }}
      {...getAnimation(FADE)}
    >
      <svg
        style={{ width: size, height: size }}
        viewBox='0 0 50 50'
        xmlns='http://www.w3.org/2000/svg'
      >
        <circle
          style={{ animation: 'dash 1.5s ease-in-out infinite' }}
          cx='25'
          cy='25'
          r='20'
          fill={'none'}
          strokeLinecap='round'
          stroke='currentColor'
          strokeWidth='5'
        ></circle>
      </svg>
      {message}
    </motion.div>
  )
}

export default Loader

import { CASH_ROUNDING_RULE } from 'components/constants'
import { getCeilTotal, getMidCeilTotal, getFloorTotal, getMidFloorTotal } from 'components/orders/helpers/getRoundedTotals'

export default function getRoundedAmount(amount, store = {}) {
  const unit = parseFloat(store.cash_rounding_unit || 0.05)

  switch (store.cash_rounding_rule) {
    case CASH_ROUNDING_RULE.always_up:
      return getCeilTotal(unit, amount)
    case CASH_ROUNDING_RULE.always_down:
      return getFloorTotal(unit, amount)
    case CASH_ROUNDING_RULE.nearest_up:
      return getMidCeilTotal(unit, amount)
    case CASH_ROUNDING_RULE.nearest_down:
    default:
      return getMidFloorTotal(unit, amount)
  }
}

import React from 'react'
import classNames from 'classnames'

const CustomerAddressBlock = ({ className, address1, address2, city, state }) => {
  if (hasAddressInformation())
    return (
      <section className={classNames({ 'flex row': true, [className]: className })}>
        <span className='expand flex col gap-2 align-items-baseline'>
          <span className='font-size-2 color-shade-4'>Address</span>
          <address className='font-size-3 font-weight-2'>
            {(address1 || address2) && (
              <div>
                {address1}
                {address2 && <span>, {address2}</span>}
              </div>
            )}
            {city && <span>{city}</span>}
            {state && <span> {state}</span>}
          </address>
        </span>
      </section>
    )
  else return null

  function hasAddressInformation() {
    return address1 || address2 || city || state
  }
}

export default CustomerAddressBlock

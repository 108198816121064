import { SET_USER, RESET_USER } from '../constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_USER:
      window.localStorage.setItem('user', JSON.stringify(action.user))
      return action.user

    case RESET_USER:
      window.localStorage.setItem('user', JSON.stringify({}))
      return {}

    default:
      return state
  }
}

import React from 'react'
import classNames from 'classnames'

const OrderNotesBlock = ({ className, notes }) => {
  if (notes) return (
    <section className={classNames({
      'flex col gap-3 expand-x': true,
      'bg-shade-0 shadow-3 rounded-3 ps-5 pe-6': true,
      [className]: className
    })}>
      <h3 className='font-size-4 font-weight-3'>Order notes</h3>
      <div className='font-weight-2 font-size-2 flex col gap-3 color-shade-5'>
        {notes}
      </div>
    </section>
  )
  else return null
}

export default OrderNotesBlock

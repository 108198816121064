import { v4 as uuidv4 } from 'uuid'

export default function createShift(store, user, startBalance) {
  const id = uuidv4()

  return {
    id,
    startBalance,
    startedAt: new Date().toISOString(),
    endedAt: null,
    endBalance: null,
    sync: true,
    storeId: store.id,
    userId: user.id,
    users: [user.id],
  }
}

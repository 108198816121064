import { SET_SYNC_IN_PROGRESS } from 'components/online/constants'
import { CLEAR_SYNC_IN_PROGRESS } from '../constants'

export default function (state = true, action) {
  switch (action.type) {
    case SET_SYNC_IN_PROGRESS:
      return { ...state, [action.record]: action.syncing }
    case CLEAR_SYNC_IN_PROGRESS:
      return {}
    default:
      return state
  }
}

import React, { useState } from 'react'

import { BASE } from 'constants/size'
import { FADE, SLIDE_NEXT, SLIDE_PREV } from 'constants/motion'

import SequentialForm from 'components/shared/SequentialForm'
import ProductVariantSelector from './ProductVariantSelector'
import ProductPricingSelector from './ProductPricingSelector'

import hasVariablePricing from './helpers/hasVariablePricing'
import getVariantCount from './helpers/getVariantCount'
import getProductById from './helpers/getProductById'

const PRODUCT_PRICING_SELECTOR = 'product_pricing_selector'
const PRODUCT_VARIANT_SELECTOR = 'product_variant_selector'

const ProductConfigurationForm = ({
  initialAnimation = FADE,
  product = {},
  variants = [],
  size = BASE,
  maxWidth = null,
  maxHeight = null,
  className,
  onDefocus,
  onSubmit,
}) => {
  const [configuredProduct, setConfiguredProduct] = useState(product)
  const [activeFormIndex, setActiveFormIndex] = useState(0)
  const [animation, setAnimation] = useState(initialAnimation)

  function handleFormChange(event) {
    switch (event.target.name) {
      case PRODUCT_VARIANT_SELECTOR:
        return handleVariantSelection(event.target.value)
      case PRODUCT_PRICING_SELECTOR:
        return onSubmit({ ...configuredProduct, price: event.target.value })
    }
  }

  function handleVariantSelection(variantId) {
    const product = getProductById(variantId, variants)

    if (hasVariablePricing(product)) {
      setConfiguredProduct(product)
      setAnimation(SLIDE_NEXT)
      setActiveFormIndex(activeFormIndex + 1)
    } else onSubmit(product)
  }

  function handleBack() {
    if (variants.length === 0) return
    setAnimation(SLIDE_PREV)
    setActiveFormIndex(activeFormIndex - 1)
  }

  return (
    <SequentialForm
      id={'product-' + product.id}
      animation={animation}
      activeIndex={activeFormIndex}
      className={className}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      onChange={handleFormChange}
    >
      {variants.length > 0 && (
        <ProductVariantSelector
          legend={'Choose from ' + getVariantCount(variants)}
          name={PRODUCT_VARIANT_SELECTOR}
          variants={variants}
          size={size}
          onDefocus={onDefocus}
        />
      )}
      {hasVariablePricing(configuredProduct) && (
        <ProductPricingSelector
          legend='Select a variable price'
          name={PRODUCT_PRICING_SELECTOR}
          product={configuredProduct}
          size={size}
          onDefocus={onDefocus}
          onBack={handleBack}
        />
      )}
    </SequentialForm>
  )
}

export default ProductConfigurationForm

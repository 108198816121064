import { SET_REGISTER } from 'components/registers/constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_REGISTER:
      window.localStorage.setItem('register', JSON.stringify(action.register))
      return action.register
    default:
      return state
  }
}

import React, { useState, useEffect } from 'react'
import { v4 as uuidv4 } from 'uuid'
import classNames from 'classnames'

import { MEDIUM, XLARGE, XXLARGE } from 'constants/size'
import { BRAND, NOTIFY } from 'constants/theme'
import { CART_ITEM } from 'components/checkout/constants'
import { CUSTOMER, CUSTOMER_MATCH, CUSTOMER_NAME, CUSTOMER_CONTACT } from 'components/customers/constants'
import { LOADER_TIMEOUT } from 'constants/settings'
import { PARKED } from 'components/orders/constants'
import { SLIDE_NEXT, SLIDE_PREV } from 'components/constants'

import AddCustomerFlow from 'components/checkout/shared/AddCustomerFlow'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Loader from 'components/shared/Loader'
import ParkOrderForm from 'components/checkout/shared/ParkOrderForm'

import CartItem from './CartItem'
import CartList from './CartList'
import CartTotalsBlock from './CartTotalsBlock'

import getCartQuantity from 'components/checkout/helpers/getCartQuantity'
import getCustomerFullName from 'components/customers/helpers/getCustomerFullName'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import getTotalQuantity from 'components/helpers/getTotalQuantity'

export default function Cart({
  cartItems,
  className,
  customer = {},
  user = {},
  taxInclusive = false,
  onCheckout,
  onIncrement,
  onDecrement,
  onCreateCustomer,
  onSetCustomer,
  pushDiscount = null,
  onSetSerials,
  onSetPrice,
  onReset,
  onRemove,
  onPark,
  onClickDiscount,
}) {
  const cartCount = getTotalQuantity(cartItems)
  const [activeCartItem, setActiveCartItem] = useState({})
  const [openCartItemId, setOpenCartItemId] = useState(null)
  const [paymentData, setPaymentData] = useState(getPaymentData(cartItems, taxInclusive))
  const [prevCartItems, setCartItems] = useState(cartItems)
  const [isLoading, setLoading] = useState(true)
  const [viewId, setViewId] = useState(null)

  useEffect(() => {
    setLoading(false)
    setCartItems(cartItems)
    setPaymentData(getPaymentData(cartItems, taxInclusive))

    // Cart item change
    findNewOrUpdatedItem(cartItems, (newOrUpdatedItem) => {
      if (newOrUpdatedItem === undefined) return
      // Reset "active" cart item if a different cart items has changed
      if (activeCartItem.id !== newOrUpdatedItem.id) {
        setActiveCartItem({})
        setViewId(null)
        setLoading(true)
        setTimeout(() => setLoading(false), LOADER_TIMEOUT)
      }
      // Update state for "active" cart item
      else if (Object.keys(activeCartItem).length > 0) {
        setActiveCartItem(newOrUpdatedItem || {})
      }
      if (viewId && Object.keys(activeCartItem).length === 0) {
        setViewId(null)
        setLoading(true)
        setTimeout(() => setLoading(false), LOADER_TIMEOUT)
      }
      // Reset the "open" cart item if a different cart item has changed
      if (openCartItemId && openCartItemId !== newOrUpdatedItem.id) {
        setOpenCartItemId(null)
        setLoading(true)
        setTimeout(() => setLoading(false), LOADER_TIMEOUT)
      }
    })
  }, [cartItems])

  // Find changed cart item

  function findNewOrUpdatedItem(cartItems, callback) {
    // New cart item
    if (cartItems.length > prevCartItems.length) {
      callback(cartItems[cartItems.length - 1])
    }
    // Quantity change
    else if (getCartQuantity(cartItems) !== getCartQuantity(prevCartItems)) {
      callback(cartItems.find((item, index) => item !== prevCartItems[index]))
    }
  }

  // Create and set customer

  function createAndSetCustomer(customer) {
    const id = uuidv4()

    onCreateCustomer({ ...customer, id })
    onSetCustomer({ ...customer })
    setViewId(null)
  }

  function resetCustomer() {
    onSetCustomer({})
    setViewId(null)
  }

  return (
    <aside
      id='cart'
      className={classNames({
        'overflow-hidden bg-white shadow-3 expand relative z-foreground flex col': true,
        [className]: className,
      })}>
      <div className='pt-2 ps-2 flex col gap-2'>
        {viewId === null && (
          <ButtonMenu id='checkout-nav-menu' className='gap-2'>
            {Object.keys(customer).length > 0 && (
              <Button
                data-customer
                className='grow'
                shade={3}
                size={MEDIUM}
                theme={CUSTOMER}
                icon={CUSTOMER}
                onClick={() => setViewId(CUSTOMER_CONTACT)}
              >
                {getCustomerFullName(customer)}
              </Button>
            )}
            {Object.keys(customer).length === 0 && (
              <Button
                className='grow'
                shade={3}
                theme={CUSTOMER}
                size={MEDIUM}
                onClick={() => setViewId(CUSTOMER_CONTACT)}
              >
                Add customer
              </Button>
            )}
            <Button
              className='grow'
              shade={3}
              theme={BRAND}
              disabled={cartItems.length === 0}
              onClick={() => setViewId(PARKED)}
            >
              Park order
            </Button>
          </ButtonMenu>
        )}
        {viewId && (
          <Button
            shade={3}
            theme={BRAND}
            size={MEDIUM}
            onClick={() => {
              setViewId(null)
              setActiveCartItem({})
            }}>
            Back to cart
          </Button>
        )}
      </div>
      <div className='relative overflow-y-auto overflow-x-hidden grow'>
        {isLoading && <Loader background='bg-opacity-1' />}
        {views()}
      </div>
      {(viewId === null || viewId === CART_ITEM) && (
        <ButtonMenu className='border-top flex col'>
          {viewId === null && cartItems.length > 0 && (
            <CartTotalsBlock
              taxInclusive={taxInclusive}
              cartCount={cartCount}
              total={paymentData.total}
              discount={paymentData.discount}
              orderDiscount={paymentData.orderDiscount}
              subtotal={paymentData.subtotal}
              taxes={paymentData.taxes}
            />
          )}
          {viewId === null && cartItems.length > 2 && (
            <Button
              className='ms-3 mb-2'
              theme={NOTIFY}
              shade={3}
              disabled={cartItems.length === 0}
              onClick={() => onReset()}
            >
              Empty cart
            </Button>
          )}
          <Button
            rounded={0}
            shade={5}
            size={XXLARGE}
            onClick={onCheckout}
            disabled={cartItems.length === 0}
            theme={BRAND}>
            Checkout
          </Button>
        </ButtonMenu>
      )}
    </aside>
  )

  function views() {
    switch (viewId) {
      case CART_ITEM:
        return (
          <CartItem
            cartItem={activeCartItem}
            animation={SLIDE_NEXT}
            user={user}
            onDecrement={(id) => onDecrement(id)}
            onIncrement={(id) => onIncrement(id)}
            pushDiscount={pushDiscount && pushDiscount}
            onSetSerials={(id, serials) => onSetSerials({ id, serials })}
            onSetPrice={(amount) => onSetPrice({ id: activeCartItem.id, amount })}
            onClickDiscount={(payload) => onClickDiscount(payload)}
          />
        )
      case CUSTOMER_CONTACT:
      case CUSTOMER_NAME:
      case CUSTOMER_MATCH:
        return (
          <AddCustomerFlow
            className='expand'
            showBackButton={false}
            animation={SLIDE_NEXT}
            innerAnimation={SLIDE_NEXT}
            viewId={viewId}
            onClose={() => setViewId(null)}
            onCreateCustomer={createAndSetCustomer}
            onSetCustomer={(customer) => {
              onSetCustomer(customer)
              setViewId(null)
            }}
            onResetCustomer={() => {
              resetCustomer()
            }}
          />
        )
      case PARKED:
        return (
          <ParkOrderForm
            className='expand'
            size={XLARGE}
            innerAnimation={SLIDE_NEXT}
            customer={customer}
            taxInclusive={taxInclusive}
            total={paymentData.total}
            subtotal={paymentData.subtotal}
            discount={paymentData.discount}
            cartCount={cartCount}
            onSubmit={() => {
              onPark()
              setViewId(null)
              resetCustomer()
            }}
          />
        )
      default:
        return (
          <CartList
            transition={SLIDE_PREV}
            openCartItemId={openCartItemId}
            cartItems={cartItems}
            onRemove={(id) => onRemove(id)}
            onDecrement={(id) => onDecrement(id)}
            onIncrement={(id) => onIncrement(id)}
            onOpenCartItem={setOpenCartItemId}
            onMore={(cartItem) => {
              setViewId(CART_ITEM)
              setActiveCartItem(cartItem)
            }}
          />
        )
    }
  }
}

import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

const SIZE = '23px'

const Count = ({ number, floating = false, className, style }) => {
  return (
    <motion.span
      data-count
      className={classNames({
        'flex align-items-center justify-center no-shrink': true,
        'bg-brand-5 color-shade-0 shadow-3 font-size-05 font-weight-3 rounded-2': true,
        'bg-notify-5': number < 0,
        'absolute': floating === true,
        [className]: className
      })}
      style={{
        width: SIZE,
        height: SIZE,
        lineHeight: 1,
        top: 'var(--sc-spacing-2)',
        left: 'var(--sc-spacing-3)',
        marginLeft: floating ? 0 : 'calc(var(--sc-spacing-1) * -1)',
        ...style
      }}>
      {number}
    </motion.span>
  )
}

export default Count

import React, { useState } from 'react'

import { NEUTRAL } from 'components/constants'

import dummyData from 'data/dummy/fulfillments'
import dummyOrderData from 'data/dummy/orders'

import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Main from 'components/shared/Main'

import Fulfillment from 'components/fulfillment'
import FulfillmentFilters from './FulfillmentFilters'
import FulfillmentsList from './FulfillmentsList'

import { CLICK_AND_COLLECT, HOLD, TRANSFER, SPECIAL_ORDER } from './constants'
import { BACK, NEXT } from 'components/constants'

const Fulfillments = ({}) => {
  const fulfillmentCategories = [CLICK_AND_COLLECT, HOLD, TRANSFER, SPECIAL_ORDER];
  const [fulfillment, setFulfillment] = useState({})
  const [animation, setAnimation] = useState(NEXT)

  function clearSelectedFulfillment() {
    setFulfillment({})
  }

  function switchFulfillment(ffment) {
    setAnimation(BACK)
    setFulfillment({})
    setAnimation(NEXT)
    setTimeout(() => setFulfillment(ffment), 200)
  }
  
  const relatedFulfillments = dummyData.filter((item) => item.order_id == fulfillment.order_id && item.fulfillment_id !== fulfillment.fulfillment_id),
        relatedOrder = dummyOrderData.find((order) => order.reference_number == fulfillment.order_id),
        hasFulfillment = Object.keys(fulfillment).length > 0;
  return (
    <>
      {hasFulfillment && (
        <View shade={1} theme={NEUTRAL} animation={animation}>
          <Fulfillment   
            dummyData={fulfillment}
            relatedFulfillments={relatedFulfillments}
            relatedOrder={relatedOrder && relatedOrder}
            onBack={() => {
              setAnimation(BACK)
              clearSelectedFulfillment()
            }}
            onSwitchFulfillment={(ffment) => {
              switchFulfillment(ffment)
            }}
            onPrintPackingSlip={() => alert('PRINT ME')}
          />
        </View> 
      )}
      {hasFulfillment === false && (
        <View className='bg-gradient-2' theme={NEUTRAL}  animation={animation}>
          <Topbar className='border-bottom' />
          <Main className='expand-x overflow-x-scroll'>
            <FulfillmentFilters
              className='bg-shade-1 pe-2 ps-3'
              fulfillmentCategories={fulfillmentCategories}
            />
            <FulfillmentsList
              fulfillmentsData={dummyData}
              onSetFulfillment={(ffment) => {
                setAnimation(NEXT)
                setFulfillment(ffment)
              }}
            />
          </Main>
        </View>
      )}
    </>
  )
}

export default Fulfillments
import getLocalStorage from 'data/helpers/getLocalStorage'
import buildSettings from 'data/helpers/buildSettings'

const INITIAL_STATE = {
  cartItems: [],
  online: true,
  syncInProgress: {},
  forceSync: false,
  customer: getLocalStorage('customer', {}),
  error: false,
  fulfillments: getLocalStorage('fulfillments', []),
  locked: getLocalStorage('locked', false),
  online: navigator.onLine,
  order: getLocalStorage('order', {}),
  outlet: getLocalStorage('outlet', {}),
  outlets: getLocalStorage('outlets', []),
  printableLabels: getLocalStorage('printableLabels', []),
  register: getLocalStorage('register', {}),
  shift: getLocalStorage('shift', {}),
  shipment: getLocalStorage('shipment', {}),
  settings: buildSettings(),
  store: getLocalStorage('store', {}),
  taxes: getLocalStorage('taxes', []),
  user: getLocalStorage('user', {}),
  users: getLocalStorage('users', []),
  viewAnimation: getLocalStorage('viewAnimation', null),
  viewId: getLocalStorage('viewId', null),
  viewParents: getLocalStorage('viewParents', []),
}

export default INITIAL_STATE

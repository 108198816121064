import { LOCK, UNLOCK } from '../constants'

export default function (state = false, action) {
  switch (action.type) {
    case LOCK:
      window.localStorage.setItem('locked', true)
      return true
    case UNLOCK:
      window.localStorage.setItem('locked', false)
      return false
    default:
      return state
  }
}

import React from 'react'

import CartListItem from './CartListItem'
import ScrollableList from 'components/shared/ScrollableList'

const CartList = ({
  cartItems = [],
  onIncrement,
  onDecrement,
  onRemove,
  onMore,
  onOpenCartItem,
  openCartItemId,
  transition,
}) => {
  if (cartItems.length > 0)
    return (
      <ScrollableList
        focusedElemId={openCartItemId}
        transition={transition}
        onLoadScrollBtm>
        {cartItems.map((item, index) => (
          <CartListItem
            key={index}
            cartItem={item}
            openCartItemId={openCartItemId}
            open={item.id === openCartItemId}
            onOpen={onOpenCartItem}
            onRemove={onRemove}
            onMore={onMore}
            onIncrement={onIncrement}
            onDecrement={onDecrement}
          />
        ))}
      </ScrollableList>
    )
  else return <section></section>
}

export default CartList

import React from 'react'
import classNames from 'classnames'

import { NEUTRAL, BRAND, LAYBY } from 'components/constants'

import Record from 'components/shared/Record'
import getCustomerDisplayName from 'components/customers/helpers/getCustomerDisplayName'

const OrderCustomerBlock = ({
  className,
  customer,
  theme = NEUTRAL,
}) => {
  const minWidth = 240
  if (customer === null || customer.id === null) return null

  return (
    <div
      style={{ minWidth }}
      className={classNames({
        'sc-order-summary flex align-items-center gap-5 pe-4 ps-5': true,
        'rounded-3': true,
        'bg-layby-2 color-layby-7': theme === LAYBY,
        'bg-customer-2 color-customer-8': theme === BRAND,
        [className]: className,
      })}>
      <Record label='Customer'>
        {getCustomerDisplayName(customer)}
      </Record>
    </div>
  )
}

export default OrderCustomerBlock

import { CLICK_AND_COLLECT, HOLD, TRANSFER, SPECIAL_ORDER, COMPLETE, REQUESTED, PICKED, PACKED, DELIVERED, OUT_OF_STOCK, READY_FOR_PICKUP, ORDERED, SHIPPED, MISSING, RECEIVED } from "../constants"

export default function getFriendlyName(text) {
  switch(text) {
    case CLICK_AND_COLLECT:
      return "Click & Collect"
    case HOLD:
      return "Hold"
    case TRANSFER:
      return "Transfer"
    case SPECIAL_ORDER:
      return "Special Order"
    case COMPLETE:
      return "Complete"
    case REQUESTED:
      return "Requested"
    case PICKED:
      return "Picked"
    case PACKED:
      return "Packer"
    case DELIVERED:
      return "Delivered"
    case OUT_OF_STOCK:
      return "Out Of Stock"
    case READY_FOR_PICKUP:
      return "Ready For Pickup"
    case ORDERED:
      return "Ordered"
    case SHIPPED:
      return "Shipped"
    case MISSING:
      return "Missing"
    case RECEIVED:
      return "Received"
    default:
      return text
  }
}
import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import classNames from 'classnames'

import { MEDIUM, LARGE, XXLARGE } from 'components/constants'
import { LAYBY } from 'components/constants'
import { SLIDE_UP, SCALE_IN } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'
import Input from 'components/shared/Input'

const CustomerContactForm = ({
  animation = SCALE_IN,
  className,
  customer = {},
  innerAnimation,
  maxWidth,
  onBack,
  onSubmit,
  onResetCustomer,
  rounded = 0,
  shade = 0,
  padding = 6,
  size = XXLARGE,
  theme = CUSTOMER,
  zIndex,
}) => {
  const [value, setValue] = useState(getContact())

  function getContact() {
    if (Object.keys(customer).length === 0) return ''
    return customer.phone || customer.email || ''
  }

  if(Object.keys(customer).length) {
    return (
      <Container
        gap={3}
        padding={padding}
        animation={innerAnimation}
        className={'pe-' + (padding - 1)}
      >
        <p className="text-center font-size-3">
          Current customer: <br/>
          <strong className="font-size-5">{customer.firstName} {customer.lastName}</strong>
        </p>
        <Button
          theme={theme}
          shade={4}
          size={LARGE}
          rounded={3}
          className='expand-x'
          type='submit'
          onClick={() => onResetCustomer()}
        >
          Remove customer
        </Button>
      </Container>
    )
  } else {
    return (
      <Form
        id='customer-form'
        shadow={2}
        shade={shade}
        theme={theme}
        rounded={rounded}
        maxWidth={maxWidth}
        animation={animation}
        onSubmit={() => onSubmit(value)}
        className={classNames({
          ['z-' + zIndex]: !!zIndex,
          [className]: className,
        })}
      >
        {onBack && (
          <ButtonMenu className='ps-3 pt-3'>
            <Button
              rounded={4}
              padding={5}
              size={MEDIUM}
              shade={shade}
              theme={theme}
              onClick={onBack}
            >
              Back
            </Button>
          </ButtonMenu>
        )}
        <Container
          gap={3}
          padding={padding}
          animation={innerAnimation}
          className={'pe-' + (padding - 1)}
        >
          {theme === LAYBY && (
            <Heading size={size}>
              Enter customer phone, email, or name to create layby
            </Heading>
          )}
          {theme !== LAYBY && (
            <Heading size={size}>Enter customer phone, email, or name</Heading>
          )}
          <Input
            className='border'
            id='customer'
            name='email'
            shade={0}
            shadow={1}
            required={true}
            defaultValue={value}
            onChange={(value) => setValue(value)}
          />
        </Container>
        <Button
          type='submit'
          shade={theme === LAYBY ? 5 : 3}
          rounded={0}
          animation={SLIDE_UP}
          size={XXLARGE}
          theme={theme}
          onBlur={onBack}
        >
          Continue
        </Button>
      </Form>
    )
  }
}

export default CustomerContactForm

import { USE_DUMMY_DATA } from 'constants/settings'
import PRINTABLE_LABELS from 'data/dummy/printableLabels'

import authenticatedFetch from './authenticatedFetch'

export function fetchPrintableLabels(
  onSuccess,
  onError = () => alert('Error fetching printable labels'),
) {
  if (USE_DUMMY_DATA) return onSuccess(PRINTABLE_LABELS)

  if (navigator.onLine) {
    authenticatedFetch(
      'printable_labels',
      { method: 'GET' },
      // Success
      ({ printable_labels }) => onSuccess(printable_labels),
      // Error
      () => onError()
    )
  }
}

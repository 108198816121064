import React, { useState, createContext } from 'react'

export const FulFillmentsContext = createContext();

const FulfillmentsProvider = ({ children }) => {
  const [typeFilters, setTypeFilters] = useState([])
  const [keywordFilter, setKeywordFilter] = useState('')

  function updateTypeFilters(typeFilter) {
    if (typeFilters.includes(typeFilter)) {
      setTypeFilters(typeFilters.filter((_typeFilter) => _typeFilter !== typeFilter))
    } else {
      setTypeFilters([...typeFilters, typeFilter])
    }
  }

  function clearFilters() {
    setTypeFilters([])
    setKeywordFilter('')
  }

  return (
    <FulFillmentsContext.Provider value={{ typeFilters, updateTypeFilters, clearFilters, keywordFilter, setKeywordFilter }}>
      {children}
    </FulFillmentsContext.Provider>
  )
}

export default FulfillmentsProvider
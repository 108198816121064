import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import {
  CLOSE,
  NEUTRAL,
  SCALE_IN,
  SMALL,
  BRAND,
  LARGE,
} from 'components/constants'
import { REGULAR_PRODUCT } from 'components/constants'

import Article from 'components/shared/Article'
import Container from 'components/shared/Container'
import Record from 'components/shared/Record'
import CheckoutPrice from 'components/checkout/shared/CheckoutPrice'
import ButtonMenu from 'components/shared/ButtonMenu'
import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'
import ProductImage from 'components/products/shared/ProductImage'

import getFormattedPrice from 'components/helpers/getFormattedPrice'
import QuantityPicker from 'components/shared/QuantityPicker'
import createCartItem from 'components/checkout/helpers/createCartItem'
import calculateTaxes from 'components/orders/helpers/calculateItemTaxes'

const OrderItemAdjustmentModal = ({
  selectedItem = null,
  taxes = [],
  store = {},
  orderItems = [],
  products,
  theme = BRAND,
  onCreate,
  onClose,
}) => {
  const [adjustmentItem, setAdjustmentItem] = useState(null)
  const [currentQuantity, setCurrentQuantity] = useState(0)
  const [newQuantity, setNewQuantity] = useState(computeCurrentQuantity()) // Set new quantity using current quantity

  useEffect(() => {
    let adjustmentItem = {}

    // If we are editing a current adjustment item
    if (selectedItem.newAdjustment) {
      adjustmentItem = { ...selectedItem }
    } else {
      adjustmentItem = {
        ...selectedItem,
        id: uuidv4(),
        originalItemId: selectedItem.id, // Might be useful in the future if we want to save this as well in DB
        quantity: 0,
        type: REGULAR_PRODUCT,
      }
    }

    const currentQuantity = computeCurrentQuantity()

    // We need to set the new quantity if we are adjusting and adjustment item
    if (selectedItem.newAdjustment) {
      setNewQuantity(currentQuantity + selectedItem.quantity)
    }

    setAdjustmentItem(adjustmentItem)
    setCurrentQuantity(currentQuantity)
  }, [])

  function computeCurrentQuantity() {
    const productGroup = orderItems.filter((item) => item.productId === selectedItem.productId)
    return productGroup.reduce((quantity, item) => {
      return item.quantity + quantity
    }, 0)
  }

  function increment() {
    setNewQuantity(newQuantity + 1)
  }

  function decrement() {
    setNewQuantity(newQuantity - 1)
  }

  function onSave() {
    let updatedItem = {}

    const product = products.find((product) => product.id === adjustmentItem.productId)
    const finalQuantity = newQuantity - currentQuantity

    updatedItem = createCartItem({
      id: adjustmentItem.id,
      product: product,
      taxes: taxes,
      quantity: finalQuantity,
      taxInclusive: store.taxInclusive,
      type: REGULAR_PRODUCT,
    })

    // We should get the price of the latest product item added or adjusted for item deduction
    if (finalQuantity <= 0) {
      let productItems = orderItems.filter((item) => item.productId === selectedItem.productId)
      productItems = productItems.sort((itemA, itemB) => itemB.created - itemA.created);
      const latestItemAddition = productItems[0]
      updatedItem = ({ ...updatedItem, price: latestItemAddition.price })
    }

    updatedItem = ({
      ...adjustmentItem,
      ...updatedItem,
      taxes: calculateTaxes(updatedItem)
    })

    onCreate({ ...updatedItem, newAdjustment: true })
  }

  return (
    <Article
      id="adjustment-modal"
      theme={NEUTRAL}
      shade={0}
      shadow={3}
      rounded={3}
      animation={SCALE_IN}
      minWidth={600}
      maxWidth={800}
      className='z-2'
    >
      <Container gap={0} className='' withoutPadding={true}>
        <button
          className='absolute translate-y--50 right-0 mt-6 mr-6 flex align-items-center justify-center'
          onClick={onClose}
        >
          <Icon type={CLOSE} size={18} />
        </button>
        <div className="ps-6 mt-6 pt-4 mb-4">
          <div className="flex row gap-2">
            <span>
              <ProductImage imageSrc={adjustmentItem?.image} />
            </span>
            <div className="flex col align-items-center justify-center pr-6">
              <Record label='Product'>
                {adjustmentItem?.name}
              </Record>
            </div>
          </div>
          <div className="flex row mb-4 gap-6 align-items-center justify-center">
            <Record label='Current quantity'>
              {currentQuantity}
            </Record>
            <Record label='New quantity'>
              {newQuantity}
            </Record>
            <Record label='Current price'>
              {getFormattedPrice(adjustmentItem?.price)}
            </Record>
            <Record label='Total'>
              <CheckoutPrice
                price={adjustmentItem?.price}
                discount={0}
                quantity={newQuantity}
              />
            </Record>
          </div>
          <div className="mt-4 ps-8">
            <QuantityPicker
              size={SMALL}
              theme={theme}
              quantity={newQuantity}
              disableDeductButton={newQuantity === 0}
              onDecrement={decrement}
              onIncrement={increment}
            />
          </div>
        </div>

        <ButtonMenu
          id='footer'
          className='gap-1'
          shade={6}
          theme={theme}
        >
          <Button
            className='flex-basis grow gap'
            rounded={0}
            shade={5}
            size={LARGE}
            theme={theme}
            onClick={onSave}
          >
            Save
          </Button>
        </ButtonMenu>
      </Container>
    </Article>
  )
}

export default OrderItemAdjustmentModal
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { LARGE, HUGE } from 'components/constants'
import { COLUMN } from 'components/constants'
import { BRAND, NEUTRAL } from 'components/constants'
import { SLIDE_UP } from 'components/constants'
import { CARD, DOLLAR } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'
import Icon from 'components/shared/Icon'

import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

export default function CardPaymentForm({
  animation,
  className,
  drawerError = null,
  isRefund = false,
  center = true,
  layby = false,
  syncingOrder = false,
  theme = BRAND,
  onSubmit,
  owed = 0,
  payments = [],
}) {
  const [amount, setAmount] = useState()
  const [refNum, setRefNum] = useState(null)

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('ref').focus()
  }, [])

  function submit(amount) {
    onSubmit({
      amountTendered: amount,
      paymentDetails: refNum,
      paymentType: CARD,
    })
  }

  function headline() {
    if (layby === true) return 'Payment amount'
    if (payments.length > 0) return 'Amount charged'
    else return 'Amount received'
  }

  return (
    <Form
      gridTemplateRows='1fr auto'
      shade={0}
      theme={NEUTRAL}
      animation={animation}
      className={classNames({
        'expand': true,
        [className]: className,
      })}
    >
      <Container maxWidth={800} center={center}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon shade={3} type={CARD} size={LARGE} theme={theme} />
          Card
        </span>
        <Heading>{headline()}</Heading>
        <div
          className={classNames({
            'flex gap-2': true,
            'row-reverse justify-end': layby === true,
          })}>
          <span>
            <Field
              valid={true}
              width={340}
              id='ref'
              border={true}
              name='fef'
              label='Reference number'
              onChange={(value) => setRefNum(value)}
            />
          </span>
          <span>
            <Field
              id='amount'
              name='amount'
              label='Custom amount'
              width={340}
              border={true}
              icon={DOLLAR}
              type='number'
              onChange={(value) => {
                setAmount(value ? parseFloat(value) : null)
              }}
            />
          </span>
        </div>
      </Container>
      {drawerError}
      <ButtonMenu
        direction={COLUMN}
        animation={SLIDE_UP}
        delay={{ delay: 0.2 }}>
        {!amount && (
          <Button
            shade={5}
            size={HUGE}
            theme={theme}
            disabled={layby || syncingOrder}
            onClick={() => submit(owed)}
          >
            {isRefund === true && <>Amount to refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(owed))}</>}
            {isRefund === false && <>{layby ? 'Amount remaining' : 'Amount to pay'}&nbsp;{getFormattedPrice(owed)}</>}
          </Button>
        )}
        {amount && amount !== 0 && (
          <Button
            shade={5}
            size={HUGE}
            theme={theme}
            disabled={syncingOrder}
            onClick={() => submit(amount)}
          >
            {isRefund === true && <>Submit refund&nbsp;{getFormattedPrice(convertToAbsoluteAmountValue(amount))}</>}
            {isRefund === false && <>Submit payment&nbsp;{getFormattedPrice(amount)}</>}
          </Button>
        )}
      </ButtonMenu>
    </Form>
  )
}

import React, { useState } from 'react'

import ORDERS from 'data/dummy/orders'

import Container from './shared/Container'
import Stage from './shared/Stage'

import Order from 'components/order'
import OrderSummaryBlock from 'components/order/OrderSummaryBlock'
import OrderActionsModal from 'components/order/OrderActionsModal'
import OrderNotesBlock from 'components/order/OrderNotesBlock'
import OrderPaymentsBlock from 'components/order/OrderPaymentsBlock'
import OrderItemsBlock from 'components/order/OrderItemsBlock'
import OrderCustomerBlock from 'components/order/OrderCustomerBlock'

export default function OrderComponents() {
  const [actionId, setActionId] = useState(null)

  return (
    <Container heading='5. Order'>
      <section className='flex col gap-5'>
        <Stage
          height={700}
          heading='Completed order'
          subheading='Path: order/index.jsx'
        >
          <Order
            className='shadow-3 rounded-3 overflow-hidden'
            order={ORDERS[0]}
            onAbandon={() => null}
            onCheckout={() => null}
            onBack={() => null}
            onEmail={() => null}
          />
        </Stage>
        <Stage
          height={700}
          heading='Layby'
          subheading='Path: order/index.jsx'
        >
          <Order
            className='shadow-3 rounded-3 overflow-hidden'
            order={ORDERS[1]}
            onAbandon={() => null}
            onCheckout={() => null}
            onBack={() => null}
            onEmail={() => null}
          />
        </Stage>
      </section>
      <section className='flex col gap-4'>
        <h2>
          Order components
        </h2>
        <Stage
          heading='Summary'
          subheading='OrderSummaryBlock.jsx'
        >
          <OrderSummaryBlock
            owed={100}
            paid={60}
            total={ORDERS[1].total}
            type={ORDERS[1].orderType}
          />
        </Stage>
        <Stage
          heading='Customer'
          subheading='OrderCustomerBlock.jsx'
        >
          <OrderCustomerBlock
            customer={ORDERS[0].customer}
          />
        </Stage>
        <Stage
          heading='Order items'
          subheading='OrderItemsBlock.jsx'
        >
          <OrderItemsBlock
            className='expand-x bg-shade-0 shadow-3 rounded-3'
            items={ORDERS[0].items}
          />
        </Stage>
        <Stage
          heading='Payments'
          subheading='OrderPaymentsBlock.jsx'
        >
          <OrderPaymentsBlock
            className='bg-shade-0 shadow-3 rounded-3 expand-x'
            payments={ORDERS[0].payments}
          />
        </Stage>
        <Stage
          heading='Notes'
          subheading='OrderNotesBlock.jsx'
        >
          <OrderNotesBlock notes='Proin nec lacus ac sem accumsan porta. Aliquam feugiat posuere lectus ac aliquet. Nam vestibulum rutrum posuere. Nullam elementum dictum massa quis dapibus. Quisque id magna lobortis, semper massa nec, pretium felis. Cras iaculis est non magna dapibus, vel dapibus arcu eleifend. Suspendisse egestas quam at dolor venenatis blandit. Integer auctor ornare diam ut lacinia. Vivamus in metus orci. Integer efficitur nulla at nibh sollicitudin, at faucibus mauris commodo. Integer quam nisi, bibendum eget enim in, pellentesque luctus ligula.' />
        </Stage>
        <Stage
          heading='Order actions'
          subheading='OrderActionsModal.jsx'
        >
          <OrderActionsModal
            id={actionId}
            onPrint={() => null}
            onEmail={() => null}
            onBack={() => setActionId(null)}
            onSetId={(id) => setActionId(id)}
          />
        </Stage>
      </section>
    </Container>
  )
}

import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { BRAND } from 'components/constants'
import { DASHBOARD, DISCONNECT_REGISTER } from 'constants/views'
import { XXLARGE } from 'components/constants'
import { BACK, NEXT } from 'components/constants'
import { PASSCODE } from 'components/users/constants'
import { START_BALANCE, SHIFT_USER } from 'components/shifts/constants'

import { fetchUsers } from 'data/api/fetchUsers'
import { fetchTaxes } from 'data/api/fetchTaxes'
import { syncShift } from 'data/api/syncShift'

import Button from 'components/shared/Button'
import Loader from 'components/shared/Loader'
import PasscodeForm from 'components/users/PasscodeForm'
import Topbar from 'components/navigation/Topbar'
import ShiftUser from './ShiftUser'
import ShiftStartBalance from './ShiftStartBalance'
import View from 'components/shared/View'
import Main from 'components/shared/Main'

import ResetUser from 'components/users/actions/ResetUser'
import ResetViewParents from 'components/navigation/actions/ResetViewParents'
import SetShift from 'components/shifts/actions/SetShift'
import SetUser from 'components/users/actions/SetUser'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetUsers from 'components/users/actions/SetUsers'
import SetTaxes from 'components/taxes/actions/SetTaxes'

import createShift from 'components/shifts/helpers/createShift'

// Fetches users, outlets and taxes

const StartShiftFlow = ({
  theme = BRAND,
  shade = 5,
}) => {
  const dispatch = useDispatch()
  const register = useSelector((state) => state.register)
  const users = useSelector((state) => state.users)
  const user = useSelector((state) => state.user)
  const store = useSelector((state) => state.store)
  const outlet = useSelector((state) => state.outlet)

  const [viewId, setViewId] = useState(null)
  const [animation, setAnimation] = useState(NEXT)
  const [syncing, setSyncing] = useState(true)

  useEffect(() => {
    // When component mounts fetch users and taxes
    // from server and set them to client state. We do not
    // poll for new server-side users, outlets or taxes as
    // we assume these will not change during a session.
    fetchTaxes((taxes) => dispatch(SetTaxes(taxes)))
    fetchUsers((users) => {
      setSyncing(false)
      dispatch(SetUsers(users))
    })
  }, [])

  function updateState(shift, synced) {
    dispatch(SetShift({ ...shift, sync: !!synced }))
    dispatch(SetUser(user))
    dispatch(ResetViewParents())
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewId(DASHBOARD))
  }

  function start(startBalance) {
    const shift = createShift(store, user, startBalance)

    syncShift(register, shift,
      () => updateState(shift, true),
      () => updateState(shift, false),
      () => updateState(shift, false)
    )
  }

  if (syncing === true) return <Loader />

  switch (viewId) {
    case SHIFT_USER:
      return (
        <ShiftUser
          theme={theme}
          shade={shade}
          users={users}
          animation={animation}
          onSetUser={(user) => {
            dispatch(SetUser(user))
            setAnimation(NEXT)
            setViewId(PASSCODE)
          }}
        />
      )
    case PASSCODE:
      return (
        <PasscodeForm
          animation={animation}
          user={user}
          onSuccess={() => setViewId(START_BALANCE)}
          onBack={() => {
            dispatch(ResetUser())
            setViewId(SHIFT_USER)
            setAnimation(BACK)
          }}
        />
      )
    case START_BALANCE:
      return (
        <ShiftStartBalance
          user={user}
          outlet={outlet}
          onSubmit={(amount) => start(amount)}
          onBack={() => {
            setAnimation(BACK)
            setViewId(SHIFT_USER)
          }}
        />
      )
    default:
      return (
        <View>
          <Topbar padding={3} transitional={true} className='ps-4' />
          <Main className='flex col justify-center expand'>
            <div className='flex justify-center'>
              <Button
                className='hover:scale-zoom mb-3'
                tabIndex={1}
                shadow={3}
                rounded={6}
                size={XXLARGE}
                theme={theme}
                shade={5}
                style={{ width: 500 }}
                onClick={() => {
                  setAnimation(NEXT)
                  setViewId(SHIFT_USER)
                }}
              >
                Start a shift
              </Button>
            </div>
          </Main>
          <Button
            tabIndex='-1'
            className='justify-end text-left font-weight-3'
            padding={4}
            rounded={3}
            onClick={() => {
              dispatch(SetViewId(DISCONNECT_REGISTER))
            }}
            theme={BRAND}
          >
            Disconnect Register
          </Button>
        </View>
      )
  }
}

export default StartShiftFlow

import { SIZE_UNITS, MILLIMETERS_IN_AN_INCH } from "components/printableLabels/constants";

export default function convertSizeUnit(size, unit) {
  const _unit = unit.toLowerCase()

  if (_unit === SIZE_UNITS.MILLIMETERS || _unit === SIZE_UNITS.MILLIMETERS_SHORT) {
    return (size / MILLIMETERS_IN_AN_INCH).toFixed(2)
  }

  return size
}
import React, { useRef, useEffect, useState, forwardRef } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { LEFT, RIGHT } from 'components/constants'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const DURATION = 0.1

const Expand = forwardRef((
  {
  children,
  className,
  theme,
  shade = 2,
  origin, // HTML element
  orientation,
  targetWidth = '100%',
  targetHeight,
  zIndex = 3,
  },
  ref
) => {
  const element = useRef(null)
  const transformOrigin = getTransformOrigin()
  const transition = { duration: DURATION }
  const [height, setHeight] = useState(targetHeight)

  useEffect(() => {
    if (element.current.clientHeight < origin.clientHeight) {
      setHeight(origin.clientHeight)
    } else {
      setHeight(element.current.clientHeight)
    }
  }, [])

  return (
    <motion.div
      data-expand
      ref={ref}
      style={{
        width: targetWidth,
        maxHeight: targetHeight,
      }}
      variants={containerVisualStates()}
      className={classNames({
        'absolute top-0 bg-white shadow-3 rounded-3': true,
        ['z-' + zIndex]: true,
        [getOrientation()]: orientation,
        [getThemeStyleClass(theme, shade)]: theme,
        [className]: className,
      })}
      initial='hidden'
      animate='visible'
      exit='hidden'
    >
      <motion.div
        ref={element}
        variants={contentVisualStates()}
        initial='hidden'
        animate='visible'
        exit='hidden'
      >
        {children}
      </motion.div>
    </motion.div>
  )

  function containerVisualStates() {
    return {
      hidden: {
        opacity: 0,
        ...getInitialScale(),
        transition: { ...transition, delay: DURATION },
      },
      visible: {
        opacity: 1,
        scaleX: 1,
        scaleY: 1,
        transition,
      },
    }
  }

  function contentVisualStates() {
    return {
      hidden: {
        opacity: 0,
      },
      visible: {
        opacity: 1,
        transition: { ...transition, delay: DURATION },
      },
    }
  }

  function getInitialScale() {
    return {
      scaleX: 1 / (targetWidth / origin.clientWidth),
      scaleY: 1 / (height / origin.clientHeight),
    }
  }

  function getOrientation() {
    switch (orientation) {
      case RIGHT:
        return 'right-0'
      case LEFT:
        return 'left-0'
      default:
        return null
    }
  }

  function getTransformOrigin() {
    switch (orientation) {
      case RIGHT:
        return 'top right'
      case LEFT:
        return 'top left'
      default:
        return 'top center'
    }
  }
})

export default Expand

import { LOCALE } from 'constants/settings'
import getCurrencySymbol from './getCurrencySymbol'

export default function getCompactPrice(amount = null) {
  if (amount === null) return null

  return new Intl.NumberFormat(LOCALE, {
    style: 'currency',
    notation: 'compact',
    currency: getCurrencySymbol(),
    maximumFractionDigits: 2,
    trailingZeroDisplay: 'stripIfInteger'
  }).format(amount)
}


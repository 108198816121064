import { v4 as uuidv4 } from 'uuid'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import createReferenceNumber from 'components/orders/helpers/createReferenceNumber'

export default function scaffoldOrder({
  store = {},
  orderType = null,
  cartItems = [],
  customer = {},
  shift = null,
  sync = true,
  user = {},
}) {

  const date = Date.now()
  const ref = createReferenceNumber(store.orderReferencePrefix, date)
  const payment = getPaymentData(cartItems, store.taxInclusive)
  return {
    created: date,
    customer,
    orderType,
    sync,
    discount: payment.discount,
    orderDiscount: payment.orderDiscount,
    fulfillments: [],
    id: uuidv4(),
    items: cartItems,
    payments: [],
    reference_number: ref,
    shiftId: shift.id,
    storeId: store.id,
    taxInclusive: store.taxInclusive,
    total: payment.total,
    updated: date,
    user,
  }
}

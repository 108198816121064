import React from 'react'
import classNames from 'classnames'

import { SUCCESS, BRAND, TINY } from 'components/constants'
import { READY, ACCEPTED, OUTGOING, INCOMING, REQUEST } from './constants'

import ButtonLineItem from 'components/shared/ButtonLineItem'
import Tag from 'components/shared/Tag'
import OutletIcon from 'components/outlets/OutletIcon'

import getFormattedDate from 'components/helpers/getFormattedDate'
import getTruncatedText from 'components/helpers/getTruncatedText'

/*
 * Shipment:
 * id
 * created
 * origin        {} [OUTLET]
 * destination   {} [OUTLET]
 * product       {} [PRODUCT]
 * qty
 */

const ShipmentLineItem = ({
  onClick,
  view = null,
  padding = 3,
  theme,
  active = false,
  shipment = {},
}) => {
  const outlet = getOutlet(shipment)

  return (
    <ButtonLineItem
      gridTemplateColumns='auto 1fr'
      className='align-items-center line-height-compact'
      active={active}
      padding={padding}
      onClick={() => onClick()}
    >
      <OutletIcon outlet={outlet} />
      <span className='flex col gap-3px'>
        <div className='flex gap-2 align-items-center'>
          <span className={classNames({
            'grow truncate': true,
            'font-weight-1 color-shade-5': shipment.read,
            'font-weight-3': shipment.read === false,
          })}>
            {outlet.name}
          </span>
          {showStatus() && (
            <Tag
              text={shipment.status}
              theme={getStatusTheme()}
              size={TINY}
            />
          )}
          <span className='font-size-1 color-shade-4 no-shrink'>
            {getFormattedDate(shipment.created)}
          </span>
        </div>
        <div className={classNames({
          'font-size-1 flex align-items-center gap-1': true,
          'font-weight-3': shipment.read === false,
          'font-weight-1 color-shade-5': shipment.read,
        })}>
          <span>{shipment.qty} {shipment.qty === 1 ? 'unit' : 'units'} {shipment.product.name}</span>
        </div>
        <div className='font-size-1 color-shade-4'>
          {getTruncatedText(shipment.notes, 38)}
        </div>
      </span>
    </ButtonLineItem>
  )

  function getOutlet(shipment) {
    switch (view) {
      case OUTGOING:
      case REQUEST:
        return shipment.destination
      case INCOMING:
        return shipment.origin
    }
  }

  function showStatus() {
    return shipment.status === REQUEST || shipment.status === READY
  }

  function getStatusTheme() {
    if (theme) return theme
    switch (shipment.status) {
      case READY:
      case ACCEPTED:
        return SUCCESS
      default:
        return BRAND
    }
  }
}

export default ShipmentLineItem

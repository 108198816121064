export default {
  1: {
    id: 1,
    firstName: 'Jacob',
    lastName: 'Thomas',
    phone: '(415) 309-3926',
    address1: '212 w 17th st',
    address2: '5A',
    city: 'New York',
    state: 'NY',
    email: 'jacob@gmail.com',
  },
  2: {
    id: 2,
    firstName: 'Jane',
    lastName: 'Thomas',
    phone: '(415) 309-3926',
    email: 'jane@gmail.com',
  },
  3: {
    id: 3,
    firstName: 'Andy',
    lastName: 'Brown',
    email: 'andy@gmail.com',
    phone: '(394) 389-2234',
    address1: '86 Bourke st',
    city: 'Sydney',
  state: 'NSW',
  },
  4: {
    id: 4,
    firstName: 'Jenny',
    lastName: 'Anderson',
    email: 'jenny@gmail.com',
    phone: '(478) 938-209',
    address1: '24 Victoria st',
    city: 'Melbourne',
    state: 'VIC',
  },
  5: {
    id: 5,
    firstName: 'Jake',
    lastName: 'McAdams',
    email: 'jake@gmail.com',
  },
  6: {
    id: 6,
    firstName: 'Michael',
    lastName: 'Peters',
    email: 'michael@gmail.com',
  },
  7: {
    id: 7,
    firstName: 'Stuart',
    lastName: 'Wiseman',
    email: 'stuart@gmail.com',
  },
}

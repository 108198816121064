import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import { NEUTRAL, DASHBOARD, LINE_ITEM } from 'components/constants'
import { PARKED } from 'components/orders/constants'
import { CHECKOUT } from 'components/checkout/constants'
import { NEXT } from 'constants/motion'

import Cart from 'components/cart'
import CartDiscountModal from 'components/cart/CartDiscountModal'
import Main from 'components/shared/Main'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Products from 'components/products'

import CreateCustomer from 'components/customers/actions/CreateCustomer'
import DecrementCartItem from 'components/checkout/actions/DecrementCartItem'
import DiscountCartItem from 'components/checkout/actions/DiscountCartItem'
import IncrementCartItem from 'components/checkout/actions/IncrementCartItem'
import RemoveCartItem from 'components/checkout/actions/RemoveCartItem'
import ResetCartItems from 'components/checkout/actions/ResetCartItems'
import SetCartItemSerials from 'components/checkout/actions/SetCartItemSerials'
import SetCartItemPrice from 'components/checkout/actions/SetCartItemPrice'
import SetCustomer from 'components/checkout/actions/SetCustomer'
import SetOrder from 'components/orders/actions/SetOrder'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetViewParents from 'components/navigation/actions/SetViewParents'

import scaffoldOrder from 'components/orders/helpers/scaffoldOrder'
import useSyncProducts from 'hooks/useSyncProducts'
import useSyncOrders from 'hooks/useSyncOrders'
import SetCartItems from 'components/checkout/actions/SetCartItems'
import setCartItemsImagesFromProducts from 'components/order/helpers/setCartItemsImagesFromProducts'

export default function () {
  const dispatch = useDispatch()
  const { createOrder } = useSyncOrders({ poll: false })
  const { loading, products } = useSyncProducts({ poll: true })

  const order = useSelector((state) => state.order)
  const cartItems = useSelector((state) => state.cartItems)
  const customer = useSelector((state) => state.customer)
  const shift = useSelector((state) => state.shift)
  const store = useSelector((state) => state.store)
  const user = useSelector((state) => state.user)

  const [discountModal, setDiscountModal] = useState({})
  const [pushDiscount, setPushDiscount] = useState(null)

  // Continue orders will only available for parked orders
  useEffect(() => {
    if(order && order.items && order.orderType === PARKED) {
      dispatch(SetCartItems(order.items))
      dispatch(SetOrder({}))
    }
  }, [order])

  // The introduction of "returns" is a primary factor in scenarios
  // where the cart contains items prior to loading the dashboard.
  // This scenario can only happen now when a return process is initiated
  // on an order summary page. We want to set the image of the cart item since
  // items are coming from the order items and we do not store image in it.
  // Also, this makes sure that it's always a fresh order when Dashboard mounts.
  useEffect(() => {
    dispatch(SetOrder({}))

    if (cartItems.length > 0) {
      const items = setCartItemsImagesFromProducts(cartItems, products)
      dispatch(SetCartItems(items))
    }
  }, [])

  function checkout() {
    dispatch(SetViewAnimation(NEXT))
    dispatch(SetViewParents([DASHBOARD]))
    dispatch(SetViewId(CHECKOUT))
  }

  function park() {
    dispatch(ResetCartItems())
    createOrder(scaffoldOrder({
      cartItems,
      customer,
      orderType: PARKED,
      sync: false,
      shift,
      store,
      user,
    }))
  }

  function handleSetDiscount(price, cartItem) {
    const object = {id: cartItem.id, amount: cartItem.price - price}
    setPushDiscount(object)
    dispatch(DiscountCartItem(object))
  }

  return (
    <View theme={NEUTRAL} shade={1}>
      {discountModal.cartItem && (
        <CartDiscountModal 
          data={discountModal}
          modalContext={LINE_ITEM}
          pushDiscount={pushDiscount && pushDiscount}
          onCloseOverlay={() => setDiscountModal({})}
          onSetDiscount={(price, cartItem) => { handleSetDiscount(price, cartItem) }}
        />
      )}
      <Topbar
        className='border-bottom'
        shade={0}
        theme={NEUTRAL}
      />
      <Main gridTemplateColumns='1fr 440px'>
        <Products loading={loading} />
        <Cart
          customer={customer}
          cartItems={cartItems}
          user={user}
          taxInclusive={store.taxInclusive}
          onPark={park}
          onCheckout={checkout}
          onReset={() => dispatch(ResetCartItems())}
          onRemove={(id) => dispatch(RemoveCartItem(id))}
          onDecrement={(id) => dispatch(DecrementCartItem(id))}
          onIncrement={(id) => dispatch(IncrementCartItem(id))}
          onCreateCustomer={(customer) => dispatch(CreateCustomer(customer))}
          onSetCustomer={(customer) => dispatch(SetCustomer(customer))}
          pushDiscount={pushDiscount && pushDiscount}
          onSetSerials={(payload) => dispatch(SetCartItemSerials(payload))}
          onSetPrice={(payload) => dispatch(SetCartItemPrice(payload))}
          onClickDiscount={(payload) => {setDiscountModal(payload)}}
        />
      </Main>
    </View>
  )
}

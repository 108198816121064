const convertNewLinesToPTags = (text) => {
  const lines = text.split('\n');
  let wrappedLines = [];

  for (var i = 0; i < lines.length; i++) {
    if (lines[i].trim() !== '') {
      // Wrap the line with <p> tags
      wrappedLines.push('<p>' + lines[i] + '</p>');
    }
  }

  return wrappedLines.join('');
}

export default convertNewLinesToPTags
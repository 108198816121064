import { configureStore } from '@reduxjs/toolkit'
import { combineReducers } from 'redux'

import cartItems from 'components/checkout/reducers/cartItems'
import online from 'components/online/reducers/online'
import customer from 'components/checkout/reducers/customer'
import customers from 'components/customers/reducers/customers'
import error from 'components/system/reducers/error'
import forceSync from 'components/system/reducers/forceSync'
import locked from 'components/navigation/reducers/locked'
import order from 'components/orders/reducers/order'
import outlet from 'components/outlets/reducers/outlet'
import outlets from 'components/outlets/reducers/outlets'
import printableLabels from 'components/printableLabels/reducers/printableLabels'
import register from 'components/registers/reducers/register'
import shiftOrders from 'components/shifts/reducers/shiftOrders'
import shift from 'components/shifts/reducers/shift'
import shipment from 'components/shipments/reducers/shipment'
import fulfillments from 'components/shipments/reducers/shipments'
import sidebar from 'components/navigation/reducers/sidebar'
import store from 'components/stores/reducers/store'
import syncInProgress from 'components/online/reducers/syncInProgress'
import taxes from 'components/taxes/reducers/taxes'
import settings from 'components/settings/reducers/settings'
import user from 'components/users/reducers/user'
import users from 'components/users/reducers/users'
import viewAnimation from 'components/navigation/reducers/viewAnimation'
import viewId from 'components/navigation/reducers/viewId'
import viewParents from 'components/navigation/reducers/viewParents'

const reducer = combineReducers({
  cartItems,
  customer,
  customers,
  error,
  fulfillments,
  forceSync,
  locked,
  online,
  order,
  outlet,
  outlets,
  printableLabels,
  register,
  shift,
  shiftOrders,
  shipment,
  sidebar,
  settings,
  store,
  syncInProgress,
  taxes,
  user,
  users,
  viewAnimation,
  viewId,
  viewParents,
})

export default function (state) {
  return configureStore({ reducer, preloadedState: state })
}

import React from 'react'

import { BRAND, COLUMN } from 'components/constants'
import { NUMBER, DOLLAR, SEARCH } from 'components/constants'
import { SMALL, MEDIUM, LARGE, XLARGE, XXLARGE, HUGE } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Field from 'components/shared/Field'
import Input from 'components/shared/Input'
import QuantityPicker from 'components/shared/QuantityPicker'

import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'

export default function ButtonsAndInputs() {
  return (
    <Container heading='2. Buttons & Inputs'>

      <Stage heading='Small'>
        <div className='flex gap-3'>
          <Input
            placeholder='Placeholder'
            shadow={2}
            width={200}
            size={SMALL}
            icon={DOLLAR}
          />
          <Button
            shade={5}
            shadow={2}
            size={SMALL}
            theme={BRAND}
          >
            Button text
          </Button>
        </div>
      </Stage>

      <Stage heading='Medium'>
        <div className='flex gap-3'>
          <Input
            placeholder='Placeholder'
            shadow={2}
            width={250}
            size={MEDIUM}
            icon={DOLLAR}
          />
          <Button
            shadow={3}
            shade={5}
            size={MEDIUM}
            theme={BRAND}
          >
            Button text
          </Button>
        </div>
      </Stage>

      <Stage heading='Large'>
        <div className='flex gap-4'>
          <Input
            placeholder='Placeholder'
            shadow={2}
            width={300}
            size={LARGE}
            icon={DOLLAR}
          />
          <Button
            shadow={2}
            shade={5}
            size={LARGE}
            theme={BRAND}
          >
            Button text
          </Button>
        </div>
      </Stage>


      <Stage heading='Field with accoutrement'>
        <Field
          required={true}
          width={300}
          icon={DOLLAR}
          placeholder='Placeholder'
          label='Label text'
        />
      </Stage>

      <Stage heading='Field with error message'>
        <Field
          required={true}
          valid={false}
          width={300}
          max={80}
          defaultValue={100}
          icon={DOLLAR}
          type='number'
          errorMsg='Error: Exceeds maximum'
          label='Amount'
        />
      </Stage>

      <Stage width={300} heading='Quantity picker'>
        <QuantityPicker
          quantity={1}
          onIncrement={() => null}
          onDecrement={() => null}
          skipOverZero={true}
        />
      </Stage>

      <Stage heading='Inputs with icons'>
        <div className='flex col gap-4'>
          <Input
            width={300}
            defaultValue={200}
            icon={DOLLAR}
            type='number'
          />
          <Input
            width={300}
            defaultValue='A000001'
            icon={NUMBER}
            type='text'
          />
          <Input
            placeholder='Find by ID'
            type='search'
            shade={0}
            shadow={2}
            width={300}
            icon={SEARCH}
          />
        </div>
      </Stage>

      <Stage width={480} heading='Vertical button menu'>
        <ButtonMenu
          className='overflow-hidden'
          rounded={3}
          direction={COLUMN}
        >
          <Button
            className='justify-start'
            rounded={0}
            padding={5}
            shade={5}
            theme={BRAND}
            size={XLARGE}
          >
            Email receipt
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            padding={5}
            shade={5}
            theme={BRAND}
            size={XLARGE}
          >
            Print receipt
          </Button>
          <Button
            className='justify-start'
            rounded={0}
            padding={5}
            shade={5}
            theme={BRAND}
            size={XLARGE}
          >
            Convert to layby
          </Button>
        </ButtonMenu>
      </Stage>

      <Stage heading='Oversized buttons'>
        <div>
          <Button
            shade={5}
            theme={BRAND}
            size={XLARGE}
            className='mb-5'
          >
            XLarge button
          </Button>
          <Button
            shade={5}
            rounded={5}
            padding={8}
            theme={BRAND}
            size={XXLARGE}
          >
            XXLarge button
          </Button>
        </div>
      </Stage>

      <Stage heading='Primary button'>
        <Button
          className='expand-x'
          theme={BRAND}
          size={HUGE}
          rounded={0}
          shade={5}
        >
          Confirm payment
        </Button>
      </Stage>

      <Stage heading='Primary button menu'>
        <ButtonMenu
          className='expand-x gap-1'
          theme={BRAND}
          shade={7}
        >
          <Button
            shade={6}
            rounded={0}
            theme={BRAND}
            size={HUGE}
            className='half'
          >
            Print receipt
          </Button>
          <Button
            shade={6}
            rounded={0}
            theme={BRAND}
            size={HUGE}
            className='half'
          >
            Email receipt
          </Button>
        </ButtonMenu>
      </Stage>
    </Container>
  )
}

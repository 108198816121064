export const ADD_CART_ITEM = 'ADD_CART_ITEM'
export const ADD_TO_CART = 'ADD_TO_CART'
export const CARD = 'Card'
export const CART = 'CART'
export const CART_ITEM = 'CART_ITEM'
export const CASH = 'Cash'
export const CHECKOUT = 'CHECKOUT'
export const CHEQUE = 'Cheque'
export const DECREMENT_CART_ITEM = 'DECREMENT_CART_ITEM'
export const DISCOUNT_CART_ITEM = 'DISCOUNT_CART_ITEM'
export const INCREMENT_CART_ITEM = 'INCREMENT_CART_ITEM'
export const PAYMENT = 'PAYMENT'
export const RECEIPT = 'RECEIPT'
export const REMOVE_CART_ITEM = 'REMOVE_CART_ITEM'
export const RESET_CART_ITEMS = 'RESET_CART_ITEMS'
export const RESET_CHECKOUT_PAYMENTS = 'RESET_CHECKOUT_PAYMENTS'
export const RESET_CUSTOMER = 'RESET_CUSTOMER'
export const SET_CART_ITEM_SERIALS = 'SET_CART_ITEM_SERIALS'
export const SET_CART_DISCOUNTS = 'SET_CART_DISCOUNTS'
export const SET_CART_ITEMS = 'SET_CART_ITEMS'
export const SET_CHECKOUT_PAYMENTS = 'SET_CHECKOUT_PAYMENTS'
export const SET_CART_ITEM_PRICE = 'SET_CART_ITEM_PRICE'
export const SET_CUSTOMER = 'SET_CUSTOMER'
export const TRANSFER = 'Bank transfer'
export const VOUCHER_STATUSES = {
  not_found: 'Not Found',
  expired: 'Expired',
  no_balance: 'No Balance',
  valid: 'Valid'
}

export const TYRO_STATUSES = {
  pending: 'PENDING',
  approved: 'APPROVED',
  declined: 'DECLINED',
  cancelled: 'CANCELLED',
  error: 'SYSTEM ERROR',
  reversed: 'REVERSED'
}

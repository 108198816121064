import React from 'react'
import { useSelector, useDispatch } from 'react-redux'

import ProductScanner from 'components/products/ProductScanner'
import ProductSearch from 'components/products/ProductSearch'
import AddToCart from 'components/checkout/actions/AddToCart'
import Loader from 'components/shared/Loader'

import useSyncProducts from 'hooks/useSyncProducts'

const Products = ({ loading = false }) => {
  const dispatch = useDispatch()
  const store = useSelector((state) => state.store)
  const taxes = useSelector((state) => state.taxes)
  const outlets = useSelector((state) => state.outlets)

  const { products } = useSyncProducts({ poll: true })

  function addProductToCart(product) {
    dispatch(AddToCart(product, taxes, store.taxInclusive))
  }

  return (
    <>
      {loading && <Loader message='Loading Products' />}
      <ProductScanner
        products={products}
        onSelect={(product) => addProductToCart(product)}
      />
      <ProductSearch
        outlets={outlets}
        products={products}
        currentOutlet={outlets.find((outlet) => !!outlet.current)}
        onAddToCart={(product) => addProductToCart(product)}
      />
    </>
  )
}

export default Products

import { SET_SHIFT, RESET_SHIFT } from 'components/shifts/constants'
import setLocalStorage from 'data/helpers/setLocalStorage'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_SHIFT:
      return setLocalStorage('shift', action.shift)

    case RESET_SHIFT:
      return setLocalStorage('shift', {})

    default:
      return state
  }
}

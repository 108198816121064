import React, { useState, useRef, useEffect } from 'react'
import classNames from 'classnames'

import { EXPAND } from 'components/constants'
import { LEFT, RIGHT, CENTER } from 'components/constants'
import { NEUTRAL } from 'components/constants'

import Button from 'components/shared/Button'
import Expand from 'components/shared/Expand'
import Icon from 'components/shared/Icon'

const DropdownSelector = ({
  id,
  height,
  label,
  onExpand,
  onMinimize,
  onSelect,
  selectedOption = {},
  options = [],
  parent = null,
  autoFocus = false,
  relative = true,
  style,
  theme,
  width = '100%',
}) => {
  const containerRef = useRef(null)
  const buttonRef = useRef(null)
  const [expanded, setExpanded] = useState(false)
  const [selected, setSelected] = useState(selectedOption)

  useEffect(() => {
    document.addEventListener('click', handleClose)
    return () => document.removeEventListener('click', handleClose)
  }, [])

  function select(option) {
    setExpanded(false)
    setSelected(option)
    if (onSelect) onSelect(option.value)
    if (onMinimize) onMinimize()
  }

  function handleClose({ target }) {
    if (buttonRef.current && !buttonRef.current.contains(target)) {
      setExpanded(false)
      if (onMinimize) onMinimize()
    }
  }

  return (
    <div
      data-dropdown-selector
      style={style}
      id={id}
      ref={containerRef}
      className={classNames({
        'grow': true,
        'relative': relative === true,
      })}
    >
      <button
        data-dropdown-selector-init
        ref={buttonRef}
        className={classNames({
          'expand-x flex align-items-center text-left shadow-2 border rounded-2': true,
        })}
        onClick={() => {
          setExpanded(true)
          if (onExpand) onExpand()
        }}
      >
        <span className='flex col grow p-3 gap-1'>
          <div className='font-size-1 color-shade-5'>
            {label}
          </div>
          <div style={{ height: 22 }} className='font-weight-3'>
            {selected.label}
          </div>
        </span>
        <span className='flex no-shrink justify-center border-left me-3 ps-4'>
          <Icon type={EXPAND} size={20} />
        </span>
      </button>

      {expanded === true && (
        <Expand
          targetWidth={width}
          targetHeight={height}
          origin={containerRef.current}
          theme={theme}
          shade={0}
          orientation={getOrientation()}
          className='z-4 shadow-brand'
        >
          {options.map((option, index) => (
            <Button
              key={option.value}
              theme={NEUTRAL}
              rounded={0}
              padding={4}
              shade={0}
              autoFocus={index === 0}
              onClick={() => select(option)}
              className={classNames({
                'expand-x justify-start': true,
                'rounded-top-2': index === 0,
                'rounded-bottom-2': index === options.length - 1,
              })}
            >
              <input value={option.value} type='radio' className='hide' />
              {option.label}
            </Button>
          ))}
        </Expand>
      )}
    </div>
  )

  function getOrientation() {
    const leftPos = containerRef.current.offsetLeft
    const centerX = getContainer() / 2
    const quarter = centerX / 4

    if (leftPos > quarter && leftPos < 3 * quarter) return CENTER
    else if (leftPos > centerX) return RIGHT
    else return LEFT
  }

  function getContainer() {
    return parent ? parent : window
  }
}

export default DropdownSelector

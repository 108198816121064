import React, { useState } from 'react'
import classNames from 'classnames'

import { BRAND } from 'components/constants'
import { LARGE, HUGE } from 'components/constants'
import { SLIDE_UP } from 'components/constants'
import { DOLLAR } from 'components/constants'

import Button from 'components/shared/Button'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'
import Topbar from 'components/navigation/Topbar'

const ShiftStartBalance = ({
  theme = BRAND,
  shade = 5,
  className,
  onBack,
  onSubmit,
  animation,
}) => {
  const [amount, setAmount] = useState(null)

  return (
    <Form
      gridTemplateRows='auto 1fr auto'
      theme={theme}
      shade={shade}
      animation={animation}
      onSubmit={() => onSubmit(amount)}
      className={classNames({
        'expand': true,
        [className]: className,
      })}
    >
      <Topbar
        transitional={true}
        theme={theme}
        shade={shade}
        onBack={() => onBack()}
      />
      <Container
        gap={4}
        center={true}
        maxWidth={600}
        animation={animation}>
        <Heading theme={theme}>
          Set your opening float
        </Heading>
        <Field
          type='number'
          step='.01'
          id='startBalance'
          shade={2}
          shadow={3}
          required={true}
          size={LARGE}
          showRequiredLabel={false}
          icon={DOLLAR}
          defaultValue={amount}
          theme={theme}
          onChange={(value) => setAmount(parseFloat(value || 0))}
        />
      </Container>
      <Button
        shade={6}
        theme={theme}
        size={HUGE}
        animation={SLIDE_UP}
        className='expand-x'
        type='submit'>
        Start shift
      </Button>
    </Form>
  )
}

export default ShiftStartBalance

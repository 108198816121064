export default function isCustomerMatch(value, customer) {
  if (customer === null || customer === undefined || Object.keys(customer).length === 0) return false

  const strings = value.toLowerCase().split(/\s+/)
  const firstName = customer.firstName.toLowerCase()
  const lastName = customer.lastName.toLowerCase()
  const email = customer.email ? customer.email.toLowerCase() : ''
  const phone = customer.phone ? customer.phone.replace(/[^0-9]/g, '') : ''
  const fullText = [firstName + lastName, email, phone].join(',')

  return strings.every(string => fullText.includes(string))
}

import React from 'react'
import classNames from 'classnames'

import { COLUMN } from 'components/constants'
import { INCOMING, OUTGOING, REQUEST } from './constants'

import Icon from 'components/shared/Icon'
import Count from 'components/shared/Count'
import ButtonMenu from 'components/shared/ButtonMenu'

const ShipmentsFilterMenu = ({
  className,
  stockRequestCount = 0,
  onClick,
}) => {
  return (
    <ButtonMenu
      direction={COLUMN}
      className={classNames({ 'gap-2 pe-4': true, [className]: className })}
    >
      <button
        className='relative'
        onClick={() => onClick(REQUEST)}
      >
        <Icon size={44} />
        <Count
          className='absolute'
          style={{ top: 2, right: 8 }}
          number={stockRequestCount}
        />
      </button>
      <button onClick={() => onClick(INCOMING)}>
        <Icon size={44} />
      </button>
      <button onClick={() => onClick(OUTGOING)}>
        <Icon size={44} />
      </button>
    </ButtonMenu>
  )
}

export default ShipmentsFilterMenu

import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Form = ({
  animation,
  children,
  className,
  id,
  gridTemplateRows = '1fr auto',
  rounded = 0,
  maxWidth,
  maxHeight,
  shade,
  shadow,
  theme,
  onSubmit,
}) => {
  return (
    <motion.form {...getAnimation(animation)}
      id={id}
      onSubmit={(event) => {
        event.preventDefault()
        onSubmit(event)
      }}
      className={classNames({
        'relative grid': true,
        'overflow-hidden': rounded > 0,
        ['rounded-' + rounded]: rounded > 0,
        ['shadow-' + shadow]: !!shadow,
        [className]: className,
        [getThemeStyleClass(theme, shade)]: theme,
      })}
      style={{
        maxWidth,
        maxHeight,
        gridTemplateColumns: '1fr',
        gridTemplateRows,
      }}>
      {children}
    </motion.form>
  )
}

export default Form

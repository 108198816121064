import { USE_DUMMY_DATA } from 'constants/settings'
import ORDERS from 'data/dummy/orders'

import authenticatedFetch from './authenticatedFetch'

export function queryOrders(
  params = {},
  onSuccess,
  onOffline,
  onError = () => alert('Error searching orders'),
) {
  if (USE_DUMMY_DATA) return onSuccess(ORDERS)

  if (navigator.onLine) {
    params = { ...params,
      timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }

    authenticatedFetch('order_searches',
      {
        method: 'POST',
        body: JSON.stringify(params),
      },
      // Success
      ({ orders }) => onSuccess(orders),
      // Error
      () => onError()
    )
  } else onOffline()
}
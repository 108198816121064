import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { BRAND, RESERVED_PRODUCTS, SCALE_IN, SLIDE_PREV } from 'components/constants'
import { BACK, NEXT } from 'constants/motion'
import { CUSTOMER, CUSTOMER_CONTACT } from 'components/customers/constants'
import { SERIALS, PARKED, LAYBY, COMPLETE, INCOMPLETE } from 'components/orders/constants'
import { RECEIPT, PAYMENT } from './constants'

import useSyncOrders from 'hooks/useSyncOrders'
import useSyncProducts from 'hooks/useSyncProducts'

import Article from 'components/shared/Article'
import Overlay from 'components/shared/Overlay'
import Order from 'components/order'
import Container from 'components/shared/Container'

import CheckoutActionsModal from './CheckoutActionsModal'
import CheckoutNavMenu from './CheckoutNavMenu'
import CheckoutSummaryBlock from './CheckoutSummaryBlock'
import CheckoutSummaryModal from './CheckoutSummaryModal'
import CheckoutPaymentMenu from './CheckoutPaymentMenu'
import CheckoutPaymentForm from './CheckoutPaymentForm'
import CheckoutPaymentHeadline from './CheckoutPaymentHeadline'

import AddCartItem from 'components/checkout/actions/AddCartItem'
import CreateCustomer from 'components/customers/actions/CreateCustomer'
import ResetCartItems from './actions/ResetCartItems'
import ResetCustomer from './actions/ResetCustomer'
import ResetOrder from 'components/orders/actions/ResetOrder'
import SetCustomer from './actions/SetCustomer'
import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'
import SetCartItems from './actions/SetCartItems'

import canAbandonOrder from 'components/orders/helpers/canAbandonOrder'
import isFullySettled from 'components/orders/helpers/isFullySettled'
import canLayby from 'components/orders/helpers/canLayby'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import getRequiredSerials from 'components/checkout/helpers/getRequiredSerials'
import getTotalQuantity from 'components/helpers/getTotalQuantity'
import hasIncompletePayment from 'components/orders/helpers/hasIncompletePayment'
import scaffoldOrder from 'components/orders/helpers/scaffoldOrder'
import shouldApplyCashRounding from 'components/stores/helpers/shouldApplyCashRounding'
import hasRefund from 'components/order/helpers/hasRefund'
import shouldSyncOrder from 'components/orders/helpers/shouldSyncOrder'
import getUpdatedPayments from 'components/orders/helpers/getUpdatedPayments'
import getTotalRefunded from 'components/orders/helpers/getTotalRefunded'
import getRemainingRefundAmount from 'components/orders/helpers/getRemainingRefundAmount'
import getRefundAmount from 'components/orders/helpers/getRefundAmount'
import getProductById from 'components/products/helpers/getProductById'

const Checkout = ({ initialAnimation = NEXT }) => {
  const dispatch = useDispatch()
  const { abandonParkedOrder, createOrUpdateOrder, syncingOrder } = useSyncOrders({ poll: false })
  const { reserved, products } = useSyncProducts({ poll: false })

  const order = useSelector((state) => state.order)
  const cartItems = order.items || useSelector((state) => state.cartItems)
  const customer = useSelector((state) => state.customer)
  const shift = useSelector((state) => state.shift)
  const store = useSelector((state) => state.store)
  const taxes = useSelector((state) => state.taxes)
  const user = useSelector((state) => state.user)
  const viewParents = useSelector((state) => state.viewParents)
  const viewId = useSelector((state) => state.viewId)

  const hasRequiredSerials = getRequiredSerials(cartItems).length > 0
  const cashRounding = reserved.find((item) => item.key === RESERVED_PRODUCTS.cash_rounding)
  const applyCashRounding = shouldApplyCashRounding(store, cashRounding)
  const tippingProduct = reserved.find((item) => item.key === RESERVED_PRODUCTS.tipping)
  const tyroSurchargeProduct = getProductById(store.tyroSurchargeProductId, products)

  const [view, setView] = useState(PAYMENT)
  const [theme, setTheme] = useState(getInitialTheme())
  const [actionId, setActionId] = useState(hasRequiredSerials ? SERIALS : null)
  const [paymentType, setPaymentType] = useState(null)
  const [actionsAnimation, setActionsAnimation] = useState()
  const [innerAnimation, setInnerAnimation] = useState()
  const [loading, setLoading] = useState(false)
  const [submitFailed, setSubmitFailed] = useState(false)
  const [showActions, setShowActions] = useState(hasRequiredSerials && !!actionId)
  const [showSummary, setShowSummary] = useState(false)

  useEffect(() => { setSubmitFailed(false) }, [paymentType])

  // If we're checking out an existing order we use this order
  // as a starting point for our component's order state. Otherwise
  // we start with boilerplate order details. We maintain this local
  // order state throughout the checkout process and sync it to our
  // redux state and backend once checkout is complete.
  const [checkout, setCheckout] = useState(getInitialCheckoutState())

  const isRefund = hasRefund(checkout)
  const refundAmount = getRemainingRefundAmount(checkout)
  
  function getInitialCheckoutState() {
    if (Object.keys(order).length > 0)
      return order
    else return scaffoldOrder({
      cartItems,
      customer,
      shift,
      store,
      user,
    })
  }
  useEffect(() => { cartItems.length !== 0 && setCheckout(getInitialCheckoutState())}, [cartItems])

  function submitPayment(
    payment = {},
    adjustmentLineItems,
  ) {
    const newOrderTotal = checkout.total + adjustmentLineItems.reduce((num, item) => num + item.price, 0)

    let order = { ...checkout,
      customer,
      payments: getUpdatedPayments(payment, checkout),
      sync: shouldSyncOrder(checkout),
      total: newOrderTotal,
      items: [...checkout.items, ...adjustmentLineItems]
    }

    const isSettled = isFullySettled(order)

    // We don't want a layby order change status or type unless payment/order completed
    let orderType = isSettled ? COMPLETE : INCOMPLETE
    if (orderType !== COMPLETE && order.orderType === LAYBY) {
      orderType = LAYBY
    }

    order.orderType = orderType

    // We issue a receipt if the order is paid in full
    // or the order is a layby.
    if (order.orderType === COMPLETE || order.orderType === LAYBY)
      createOrUpdateOrder(order,
        () => {
          setCheckout(order)
          setView(RECEIPT)
          dispatch(ResetCartItems())
          dispatch(ResetCustomer())
        },
        (response) => {
          // 422 indicates voucher payment failed,
          // other errors are ignored for now
          if (response.status === 422) {
            setSubmitFailed(true)
          } else {
            setCheckout(order)
            setView(RECEIPT)
            dispatch(ResetCartItems())
            dispatch(ResetCustomer())
          }
        }
      )
    // Split payment
    else createOrUpdateOrder({ ...order, orderType: INCOMPLETE },
      () => {
        setCheckout(order)
        setPaymentType(null)
      },
      (response) => {
        // 422 indicates voucher payment failed,
        // other errors are ignored for now
        if (response.status === 422) {
          setSubmitFailed(true)
        } else {
          setCheckout(order)
          setPaymentType(null)
        }
      }
    )
  }

  function email(address) {
    createOrUpdateOrder({ ...checkout,
      checkout_email: address,
      send_email: true,
      sync: true,
    })
  }

  function park() {
    createOrUpdateOrder({ ...checkout, orderType: PARKED, sync: false })
    back()
    reset()
  }

  function back() {
    dispatch(SetViewAnimation(BACK))
    dispatch(SetViewId(viewParents[viewParents.length - 1]))
  }

  function reset() {
    dispatch(ResetCustomer())
    dispatch(ResetCartItems())
    dispatch(ResetOrder())
  }

  switch (view) {
    case PAYMENT:
      return (
        <Article
          id='payment'
          className='expand'
          gridTemplateRows='auto 1fr auto'
          animation={initialAnimation}
        >
          <CheckoutNavMenu
            user={user}
            customer={customer}
            view={view}
            theme={theme}
            backDisabled={loading || (paymentType === null && hasIncompletePayment(checkout))}
            onBack={() => {
              if (paymentType) {
                setInnerAnimation(SLIDE_PREV)
                setPaymentType(null)
              } else back()
            }}
            onShowOrderActions={() => setShowActions(true)}
            onAddCustomer={() => {
              setShowActions(true)
              setActionsAnimation(SCALE_IN)
              setActionId(CUSTOMER_CONTACT)
            }}
            onShowCustomer={() => {
              setShowActions(true)
              setActionsAnimation(SCALE_IN)
              setActionId(CUSTOMER)
            }}
          />
          {paymentType === null && (
            <Container maxWidth={760} center={true} gap={2}>
              <CheckoutPaymentHeadline
                order={checkout}
                owed={getAmountOwed(checkout)}
                animation={innerAnimation}
                refundAmount={refundAmount}
                isRefund={isRefund}
              />
              <CheckoutPaymentMenu
                isRefund={hasRefund(checkout)}
                animation={innerAnimation}
                theme={checkout.orderType === LAYBY ? LAYBY : BRAND}
                onClick={(paymentType) => setPaymentType(paymentType)}
              />
            </Container>
          )}
          {paymentType && (
            <CheckoutPaymentForm
              tyroSurchargeProduct={tyroSurchargeProduct}
              cashRounding={cashRounding}
              tippingProduct={tippingProduct}
              orderType={checkout.orderType}
              applyCashRounding={applyCashRounding}
              owed={checkout.total - getAmountPaid(checkout)}
              refundAmount={refundAmount}
              type={paymentType}
              payments={checkout.payments}
              isRefund={isRefund}
              store={store}
              order={order}
              theme={checkout.orderType === LAYBY ? LAYBY : BRAND}
              onCreateAdjustmentItem={(adjustmentItem) => dispatch(AddCartItem(adjustmentItem))}
              onSubmit={submitPayment}
              submitFailed={submitFailed}
              submitting={loading}
            />
          )}
          {paymentType === null && Object.keys(checkout).length > 0 && (
            <footer className='pb-3 ps-3'>
              <CheckoutSummaryBlock
                withRefund={hasRefund(checkout)}
                className='rounded-3 pe-4 pl-5 pr-6'
                theme={theme}
                total={checkout.total}
                type={checkout.orderType}
                paid={getAmountPaid(checkout)}
                refundAmount={getRefundAmount(checkout)}
                refunded={getTotalRefunded(checkout)}
                cartCount={getTotalQuantity(cartItems)}
                onClick={() => setShowSummary(true)}
              />
            </footer>
          )}
          {showSummary && (
            <Overlay onClose={() => setShowSummary(false)}>
              <CheckoutSummaryModal
                withRefund={isRefund}
                refunded={getTotalRefunded(checkout)}
                maxWidth={600}
                order={checkout}
                products={products}
                theme={theme}
                onClose={() => setShowSummary(false)}
              />
            </Overlay>
          )}

          {showActions === true && (
            <Overlay
              disabled={hasRequiredSerials}
              onClose={() => {
                setActionId(null)
                setActionsAnimation(SCALE_IN)
                setShowActions(false)
                if (order.orderType === LAYBY && Object.keys(customer).length === 0) {
                  setCheckout({ ...checkout, orderType: null })
                  setTheme(BRAND)
                }
              }}
            >
              <CheckoutActionsModal
                initialAnimation={actionsAnimation}
                customer={customer}
                cartItems={checkout.items}
                payments={checkout.payments}
                notes={checkout.notes}
                orderType={checkout.orderType}
                id={actionId}
                theme={theme}
                total={checkout.total}
                user={user}

                canPark={order.orderType !== PARKED && checkout.payments.length === 0}
                canAbandon={canAbandonOrder(checkout)}
                canClose={hasRequiredSerials === false}
                canLayby={canLayby(checkout)}
                canEmail={view === RECEIPT}
                canSetSerials={true}
                showBackButton={true}
                onCreateCustomer={(customer) => dispatch(CreateCustomer(customer))}
                onSetCustomer={(customer) => dispatch(SetCustomer(customer))}
                onSetId={(id) => setActionId(id)}
                onSetOrderNotes={(notes) => setCheckout({ ...checkout, notes })}
                onEmail={(address) => email(address)}
                onSetSerials={(id, serials) => {
                  setCheckout({
                    ...checkout,
                    items: checkout.items.map((item) => {
                      if (item.id === id) return { ...item, serial_numbers: serials }
                      else return item
                    })
                  })
                }}
                onSetDiscount={(cart) => {
                  dispatch(SetCartItems(cart))            
                  setActionId(null)
                  setShowActions(false)
                }}
                onExit={() => {
                  back()
                  reset()
                }}
                onPark={() => {
                  setActionId(null)
                  setShowActions(false)
                  park()
                }}
                onBack={() => {
                  if (view === RECEIPT) setShowActions(false)
                  setActionId(null)
                }}
                onClose={() => {
                  setActionId(null)
                  setShowActions(false)
                }}
                onAbandon={() => {
                  abandonParkedOrder(checkout.id)
                  dispatch(ResetCartItems())
                }}
                onResetLayby={() => {
                  setTheme(BRAND)
                  setCheckout({ ...checkout, orderType: undefined })
                }}
                onSetLayby={() => {
                  setTheme(LAYBY)
                  setCheckout({ ...checkout, orderType: LAYBY })
                }}
                onSetOrder={(params) => {
                  setCheckout({ ...checkout, ...params })
                  setActionId(null)
                  setShowActions(false)
                  setActionsAnimation(SCALE_IN)
                }}
              />
            </Overlay>
          )}
        </Article>
      )

    case RECEIPT:
      return (
        <Order
          order={checkout}
          store={store}
          taxes={taxes}
          products={products}
          viewId={viewId}
          showConfirmationMsg={true}
          animation={initialAnimation}
          syncingOrder={syncingOrder}
          onEmail={(address) => email(address)}
          onApplyAdjustment={(order, successCallback) => {
            let updatedOrder = {...order}
            // We want to mark the order as complete when sum of existing payments
            // equals the new adjusted ordel total
            if (getAmountPaid(order) === order.total) {
              updatedOrder = {...updatedOrder, orderType: COMPLETE}
            }

            createOrUpdateOrder(updatedOrder,
              () => {
                setCheckout(updatedOrder)
                successCallback()
              },
            )
         }}
          onMakePayment={() => {
            setView(PAYMENT)
            setPaymentType(null)
          }}
          onBack={() => {
            back()
            reset()
          }}
        />
      )
  }

  function getInitialTheme() {
    if (Object.keys(order).length > 0) {
      if (!order.orderType) return BRAND
      if (order.orderType.toUpperCase() === LAYBY.toUpperCase()) {
        return LAYBY
      } else return BRAND
    } else return BRAND
  }
}

export default Checkout

import { SET_PRINTABLE_LABELS } from 'components/printableLabels/constants'

export default function SetPrintableLabels(printableLabels) {
  return {
    type: SET_PRINTABLE_LABELS,
    printableLabels
  }
}



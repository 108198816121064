import { COUNTRY_CODE } from 'components/constants'

export default function getDateLocalTimezone(utc) {
  const browserTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone

  return new Date(utc).toLocaleDateString(COUNTRY_CODE, {
    timeZone: browserTimeZone,
    year: 'numeric',
    month: 'short',
    day: 'numeric'
  })
}

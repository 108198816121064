import { SET_OUTLET } from 'components/outlets/constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_OUTLET:
      window.localStorage.setItem('outlet', JSON.stringify(action.outlet))
      return action.outlet
    default:
      return state
  }
}

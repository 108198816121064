import React from 'react'
import classNames from 'classnames'

import StoreConnectLogo from 'components/shared/StoreConnectLogo'
import getTruncatedText from 'components/helpers/getTruncatedText'

const StoreCard = ({
  store = {},
  outlet = {}
}) => {
  return (
    <span className={classNames({
      'flex align-items-center gap-3': true,
    })}>
      <StoreConnectLogo
        width={24}
        gradient={true}
      />
      <span className='flex col capitalize'>
        <p className="flex wrap align-items-center font-size-3 font-weight-3" style={{maxWidth: "600px"}}>
          <span className="no-wrap mr-1">{getTruncatedText(store.name, 60)}</span>
          {outlet.name !== undefined && ( <span className="font-weight-1 font-size-2 color-shade-4 no-wrap">{getTruncatedText(outlet.name, 70)}</span>)}
        </p>
      </span>
    </span>
  )
}

export default StoreCard

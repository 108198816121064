import React from 'react'
import classNames from 'classnames'


import Article from 'components/shared/Article'
import ContactLineItem from './ContactLineItem'
import Empty from 'components/shared/Empty'

const ContactList = ({
  contacts,
  className
}) => {
  return (
    <Article
      id='contact-list'
      rounded={2}
      className={classNames({ [className]: className })}
    >
      {contacts.length > 0 && (
        <ul className='flex col'>
          {contacts.map((contact, index) => {
            return (
              <ContactLineItem
                contact={contact}
                onFocus={() => setExpanded(true)}
                onMinimise={() => setExpanded(false)}
                last={index === contacts.length - 1}
              />
            )
          })}
        </ul>
      )}
      {contacts.length === 0 && (
        <Empty message='No contacts found' />
      )}
    </Article>
  )
}

export default ContactList
import { MIDWAY_ROUNDING_UNIT } from "../../constants"

export default function (total) {
  const MAX = 3

  const roundedTotals = [
    getCeilTotal(10, total),
    getCeilTotal(20, total),
    getCeilTotal(100, total),
    total > 1000 ? getCeilTotal(1000, total) : null,
  ]

  return roundedTotals
    .filter((v, i, a) => {
      return a.indexOf(v) === i && v !== null
    })
    .slice(0, MAX - 1)
}

export function getCeilTotal(unit, total) {
  return Math.ceil(total / unit) * unit
}

export function getFloorTotal(unit, total) {
  return Math.floor(total / unit + unit) * unit
}

// We use this rounding nearest up rule
export function getMidCeilTotal(unit, total) {
  const _total = total - (unit / MIDWAY_ROUNDING_UNIT)
  return Math.ceil(_total / unit) * unit
}

// We use this rounding nearest down rule
export function getMidFloorTotal(unit, total) {
  const _total = total + (unit / MIDWAY_ROUNDING_UNIT)
  return Math.floor(_total / unit) * unit
}
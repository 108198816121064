import React from 'react'
import classNames from 'classnames'

import Count from 'components/shared/Count'

const ProductImage = ({
  className = null,
  imageSrc,
  rounded = 2,
  height = 180,
  style = null,
}) => {
  if (imageSrc) return (
    <figure
      style={{ ...style, height, marginLeft: 'calc(var(--sc-spacing-2) * -1)' }}
      className={classNames({
        'relative overflow-hidden font-size-0 flex align-items-center justify-center no-shrink pointer-events-x': true,
        ['rounded-' + rounded]: true,
        [className]: className !== null,
      })}>
      {imageSrc && (
        <img
          src={imageSrc}
          style={{
            maxWidth: '90%',
            maxHeight: (height * 0.9) + 'px',
          }}
        />
      )}
    </figure>
  )
  else return null
}

export default ProductImage

function Ripple(event) {
  const THRESHOLD = 4
  const target = event.currentTarget
  const diameter = Math.max(target.clientWidth, target.clientHeight)
  const ripple = target.getElementsByClassName('ripple')[0]
  const elem = document.createElement('span')
  const elemWidth = diameter / THRESHOLD
  const elemHeight = diameter / THRESHOLD
  const viewportOffset = target.getBoundingClientRect()
  const x = event.pageX - (viewportOffset.left + elemWidth / 2)
  const y = event.pageY - (viewportOffset.top + elemHeight / 2)

  if (ripple) ripple.remove()

  elem.classList.add('ripple')
  elem.style.width = elemWidth + 'px'
  elem.style.height = elemHeight + 'px'
  elem.style.top = y + 'px'
  elem.style.left = x + 'px'

  target.appendChild(elem)
}

export default Ripple

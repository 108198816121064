export const FULFILLMENTS = 'FULFILLMENTS'

export const CLICK_AND_COLLECT = 'CLICK_AND_COLLECT'
export const HOLD = 'HOLD'
export const TRANSFER = 'TRANSFER'
export const SPECIAL_ORDER = 'SPECIAL_ORDER'

export const COMPLETE = "COMPLETE"
export const REQUESTED = "REQUESTED"
export const PICKED = "PICKED"
export const PACKED = "PACKED"
export const DELIVERED = "DELIVERED"
export const OUT_OF_STOCK = "OUT_OF_STOCK"
export const READY_FOR_PICKUP = "READY_FOR_PICKUP"
export const ORDERED = "ORDERED"
export const SHIPPED = "SHIPPED"
export const MISSING = "MISSING"
export const RECEIVED = "RECEIVED"

export const columns = {
  CLICK_AND_COLLECT: {
    REQUESTED: ["Product Name", "Qty Requested", "Qty Picked", "Qty Remaining"],
    PICKED: ["Product Name", "Qty Picked", "Qty Packed", "Qty Remaining"],
    READY_FOR_PICKUP: ["Product Name", "Qty Expected", "Qty Released", "Qty Missing"],
    COMPLETE: ["Product Name", "Total Expected", "Qty Released", "Qty Missing"],
    MISSING: ["Product Name", "Qty Expected", "Qty Packed", "Qty Missing"],
  },
  HOLD: {
    REQUESTED: ["Product Name", "Qty Requested", "Qty Picked", "Qty Remaining"],
    PICKED: ["Product Name", "Qty Picked", "Qty Packed", "Qty Remaining"],
    READY_FOR_PICKUP: ["Product Name", "Qty Expected", "Qty Released", "Qty Missing"],
    COMPLETE: ["Product Name", "Total Expected", "Qty Released", "Qty Missing"],
    MISSING: ["Product Name", "Qty Expected", "Qty Packed", "Qty Missing"],
  },
  TRANSFER: {
    REQUESTED: ["Product Name", "Qty Requested", "Qty Picked", "Qty Remaining"],
    PICKED: ["Product Name", "Qty Picked", "Qty Packed", "Qty Remaining"],
    READY_FOR_PICKUP: ["Product Name", "Qty Expected", "Qty Released", "Qty Missing"],
    COMPLETE: ["Product Name", "Total Expected", "Qty Released", "Qty Missing"],
    MISSING: ["Product Name", "Qty Expected", "Qty Packed", "Qty Missing"],
  },
  SPECIAL_ORDER: {
    REQUESTED: ["Product Name", "Qty Requested", "Qty Picked", "Qty Remaining"],
    PICKED: ["Product Name", "Qty Picked", "Qty Packed", "Qty Remaining"],
    READY_FOR_PICKUP: ["Product Name", "Qty Expected", "Qty Released", "Qty Missing"],
    COMPLETE: ["Product Name", "Total Expected", "Qty Released", "Qty Missing"],
    MISSING: ["Product Name", "Qty Expected", "Qty Packed", "Qty Missing"],
  }
}

export const modalCopy = {
  REQUESTED: {
    allFilled: "You have indicated that the following items have been picked:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br><strong class='font-weight-3'>You cannot undo this</strong>.<br>Please check the box next to each missing item to confirm:"
  },
  PICKED: {
    allFilled: "You have indicated that the following items have been packed:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  },
  READY_FOR_PICKUP: {
    allFilled: "You have indicated that the following items have been released to the customer:",
    inDeficit: "You have indicated that some items are not available. Missing items will be removed from the order and the customer will be notified.<br/><strong class='font-weight-3'>You cannot undo this<strong>.<br>Please check the box next to each missing item to confirm:"
  }
}

export const objectStatusProperies = {
  REQUESTED: 'quantityPicked', 
  PICKED: 'quantityPacked',
  READY_FOR_PICKUP: 'quantityReleased'
}
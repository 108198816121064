import React, { useState, useEffect } from 'react'

import { LARGE } from 'constants/size'
import { LIST, GRID, PREFERRED_PRODUCT_LAYOUT } from 'components/constants'

import Overlay from 'components/shared/Overlay'
import Empty from 'components/shared/Empty'
import ProductGrid from './ProductGrid'
import ProductList from './ProductList'
import ProductSearchNav from './ProductSearchNav'
import ProductConfigurationForm from './ProductConfigurationForm'

import getFilteredProducts from 'components/products/helpers/getFilteredProducts'
import getMasterProducts from 'components/products/helpers/getMasterProducts'
import hasVariablePricing from './helpers/hasVariablePricing'
import getProductVariants from './helpers/getProductVariants'

const ProductSearch = ({
  batchSelectable = false,
  batchSelectedAction,
  currentOutlet,
  onAddToCart,
  products = [],
  outlets = [],
}) => {
  const [filteredProducts, setFilteredProducts] = useState([])
  const [focusedProductId, setFocusedProductId] = useState(null)
  const [selectedProduct, setSelectedProduct] = useState({})
  const [showOverlay, setShowOverlay] = useState(false)
  const [layout, setLayout] = useState(PREFERRED_PRODUCT_LAYOUT)
  const [keyword, setKeyword] = useState('')
  const [previousLayout, setpreviousLayout] = useState(PREFERRED_PRODUCT_LAYOUT)
  const [showProductConfig, setShowProductConfig] = useState(false)

  useEffect(() => {
    setFilteredProducts(getFilteredProducts(products, keyword))
  }, [products])

  function filter(keyword) {
    setKeyword(keyword)
    if (keyword.length > 1 || batchSelectable) {
      setLayout(LIST)
      setFilteredProducts(getFilteredProducts(products, keyword))
    } else {
      previousLayout && setLayout(previousLayout);
      setFilteredProducts(getFilteredProducts(products))
    }
  }

  function toggle() {
    setLayout((currentLayout) => {
      const layout = currentLayout === GRID ? LIST : GRID
      setpreviousLayout(layout);
      return layout
    })
  }

  function onSelect(product) {
    if (onAddToCart !== undefined) {
      onAddToCart(product)
    }
  }

  return (
    <>
      <section
        id='ProductSearch'
        style={{ gridTemplateRows: 'auto 1fr' }}
        className='grid relative z-0 overflow-hidden'
      >
        <ProductSearchNav
          batchSelectable={batchSelectable}
          batchSelectedAction={batchSelectedAction}
          keyword={keyword}
          layout={layout}
          toggle={toggle}
          filter={filter}
        />
        {layout === LIST && filteredProducts.length > 0 && (
          <ProductList
            className='ps-3 pb-3'
            outlets={outlets}
            outlet={currentOutlet}
            onAddCart={(product) => {
              if (hasVariablePricing(product)) {
                setSelectedProduct(product)
                setShowProductConfig(true)
              } else onSelect(product)
            }}
            keyword={keyword}
            showOverlay={showOverlay}
            focusedProductId={focusedProductId}
            products={filteredProducts}
            onSetOverlay={setShowOverlay}
            onSetFocusedProductId={setFocusedProductId}
          />
        )}
        {layout === GRID && (
          <ProductGrid
            items={getMasterProducts(products)}
            products={products}
            outlet={currentOutlet}
            scroll={true}
            showOverlay={showOverlay}
            onShowVariants={() => setShowOverlay(true)}
            onHideVariants={() => setShowOverlay(false)}
            onSelect={(product) => {
              setShowOverlay(false)
              onSelect(product)
            }}
          />
        )}
        {filteredProducts.length === 0 && keyword.length > 0 && (
          <Empty message={`No results found for "${keyword}"`} />
        )}
        {showOverlay === true && (
          <Overlay
            zIndex={2}
            fixed={true}
            active={showOverlay}
            onClose={() => {
              setFocusedProductId(null)
              setShowOverlay(false)
            }}
          />
        )}
      </section>

      {showProductConfig && (
        <Overlay>
          <ProductConfigurationForm
            className='bg-white rounded-3 shadow-3 relative z-2'
            maxWidth={500}
            maxHeight={400}
            size={LARGE}
            product={selectedProduct}
            variants={getProductVariants(selectedProduct, products)}
            onSubmit={(configuredProduct) => {
              onSelect(configuredProduct)
              setSelectedProduct({})
              setShowProductConfig(false)
            }}
          />
        </Overlay>
      )}
    </>
  )
}

export default ProductSearch

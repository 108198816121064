import { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'

import SHIFTS from 'data/dummy/shifts'
import { USE_DUMMY_DATA } from 'constants/settings'

import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchShifts } from 'data/api/fetchShifts'
import { syncShift } from 'data/api/syncShift'

import hasVersionDiff from 'data/helpers/hasVersionDiff'
import getLocalStorage from 'data/helpers/getLocalStorage'
import getSyncTimeout from 'data/helpers/getSyncTimeout'
import setLocalStorage from 'data/helpers/setLocalStorage'
import SetSyncInProgress from '../components/online/actions/SetSyncInProgress'

const useSyncShifts = ({ poll = false }) => {
  const syncTimeout = getSyncTimeout()
  const dispatch = useDispatch()
  const register = useSelector((state) => state.register)
  const [shifts, setShifts] = useState(getLocalStorage('shifts', []))
  const [fetchingShifts, setFetchingShifts] = useState(false)

  let syncInterval = null

  useEffect(() => {
    if (USE_DUMMY_DATA) return setShifts(SHIFTS)

    if (navigator.onLine) {
      // Fetch shifts from server and update client. 
      // Note we always fetch because the client will
      // always have at least one shift (started on the device)
      setFetchingShifts(true)
      fetchShifts(register, (shifts) => setShifts(shifts))

      if (poll = false) return
      // While online we check periodically for shift and order
      // changes on the server and sync them to the client. In
      // most cases where we are just accessing or setting the
      // client state this is not necessary.
      syncInterval = setInterval(() => {
        fetchCacheVersions('shifts', () => {
          setFetchingShifts(true)
          fetchShifts(register, (shifts) => setShifts(shifts))
        })
      }, syncTimeout)
    }
    return () => clearInterval(syncInterval)
  }, [])

  // Update fetching state and local storage when shifts change
  useEffect(() =>{
    setLocalStorage('shifts', shifts)
    setFetchingShifts(false)
  }, [shifts])

  useEffect(() => {
    dispatch(SetSyncInProgress('shifts', fetchingShifts))
  }, [fetchingShifts])

  /**
   * Methods
   */

  function createShift(shift, onSuccess) {
    syncShift(register, shift,
      // Success
      () => {
        setShifts([...shifts, { ...shift, sync: false }])
        onSuccess()
      },
      // Offline
      () => {
        setShifts([...shifts, { ...shift, sync: true }])
        onSuccess()
      }
    )
  }

  return {
    shifts,
    fetchingShifts,
    setShifts,
    createShift,
  }
}

export default useSyncShifts

import React from 'react'

import getTaxSubtotal from 'components/orders/helpers/getTaxSubtotal'
import getTaxTypes from 'components/orders/helpers/getTaxTypes'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const OrderTaxesBlock = ({
  taxInclusive = false,
  taxes = [],
}) => {
  return (
    <ul data-order-taxes>
      {getTaxTypes(taxes).map((taxType, index) => {
        const taxSubtotal = getTaxSubtotal(taxes, taxType)

        return (
          <div
            className='flex row align-items-baseline justify-space-between'
            key={index}
            data-total-tax
          >
            <span>{taxType} {taxInclusive && '(Included)'}</span>
            {getFormattedPrice(taxSubtotal)}
          </div>
        )
      })}
    </ul>
  )
}

export default OrderTaxesBlock

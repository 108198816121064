import React from 'react'

const Logo = ({
  width = 50,
  gradient = true,
  fill = 'var(--sc-shade-3est)'
}) => {
  return (
    <svg width={width} viewBox='0 0 408 519'>
      <title>StoreConnect Logo</title>
      {gradient && (
        <defs>
          <linearGradient
            x1='100%'
            y1='50%'
            x2='0%'
            y2='50%'
            id='top-shadow-gradient'>
            <stop stopColor='var(sc-logo-1)' stopOpacity='0' offset='0%'></stop>
            <stop stopColor='var(sc-logo-1)' stopOpacity='0.5' offset='100%'></stop>
          </linearGradient>
          <linearGradient
            x1='70%'
            y1='60%'
            x2='30%'
            y2='40%'
            id='bottom-shadow-gradient'>
            <stop stopColor='var(--sc-logo-1)' stopOpacity='0' offset='0%'></stop>
            <stop stopColor='var(--sc-logo-1)' stopOpacity='0.5' offset='100%'></stop>
          </linearGradient>
          <linearGradient
            x1='0%'
            y1='100%'
            x2='80%'
            y2='0%'
            id='foreground-curve'>
            <stop stopColor='var(--sc-logo-2)' offset='50%'></stop>
            <stop stopColor='var(--sc-logo-3)' offset='90%'></stop>
          </linearGradient>
        </defs>
      )}

      <g transform='translate(0.002847, 0.000485)'>
        {/* Background curve (bottom right) */}
        <path
          fill='var(--sc-logo-1)'
          d='M231.147153,205.999515 L230.987153,206.269515 C255.376785,221.254788 272.078163,246.071558 276.776683,274.308705 C281.475203,302.545852 273.710011,331.433737 255.487153,353.509515 L283.277153,349.119515 L214.597153,460.539515 L59.2471529,485.109515 C166.507153,551.169515 307.527153,517.649515 373.587153,410.379515 C417.767153,338.649515 417.407153,251.819515 380.237153,182.379515 L231.147153,205.999515 Z'
        />

        {/* Top shadow */}
        <path
          d='M298.867153,95.9995153 L231.147153,205.999515 L380.237153,182.419515 C361.258755,146.905751 333.175278,117.079353 298.867153,95.9995153 L298.867153,95.9995153 Z'
          fill='var(--sc-logo-1)'
        />
        <path
          d='M298.867153,95.9995153 L231.147153,205.999515 L380.237153,182.419515 C361.258755,146.905751 333.175278,117.079353 298.867153,95.9995153 L298.867153,95.9995153 Z'
          fill='url(#top-shadow-gradient)'
          style={{ mixBlendMode: 'multiply' }}
        />

        {/* Bottom shadow */}
        <path
          fill='var(--sc-logo-1)'
          d='M283.277153,349.109515 L255.487153,353.499515 C224.026896,391.61832 169.298857,400.749611 127.167153,374.909515 L59.2471529,485.109515 L214.597153,460.539515 L283.277153,349.109515 Z'
        />
        <path
          fill='url(#bottom-shadow-gradient)'
          d='M283.277153,349.109515 L255.487153,353.499515 C224.026896,391.61832 169.298857,400.749611 127.167153,374.909515 L59.2471529,485.109515 L214.597153,460.539515 L283.277153,349.109515 Z'
          style={{ mixBlendMode: 'multiply' }}></path>

        {/* Top fold */}
        <polyline
          fill='var(--sc-logo-1)'
          points='348.217153 33.8795153 280.337153 144.099515 230.987153 206.259515 298.867153 96.0395153'></polyline>

        {/* Bottom fold */}
        <polyline
          fill='var(--sc-logo-1)'
          points='59.2471529 485.109515 127.127153 374.889515 176.487153 312.729515 108.597153 422.949515'></polyline>

        {/* Foreground curve (top left)  */}
        <path
          fill='url(#foreground-curve)'
          d='M108.597153,422.999515 C1.33715292,356.879515 -32.1828471,215.869515 33.8771529,108.599515 C99.9371529,1.32951529 240.997153,-32.1804847 348.217153,33.8795153 L280.337153,144.099515 C233.771264,115.42213 172.774539,129.923627 144.097153,176.489515 C115.419767,223.055404 129.921264,284.05213 176.487153,312.729515 L108.597153,422.999515 Z'
        ></path>
      </g>
    </svg>
  )
}

export default Logo

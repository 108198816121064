import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'

import { SLIDE_UP, SLIDE_DOWN } from 'constants/motion'
import {
  CLOSE,
  COLUMN,
  NEUTRAL,
  BASE,
  LARGE,
  NOTIFY,
  BRAND,
  CHEVRON,
  TINY
} from 'components/constants'
import { PRINTER_TYPES, PRODUCT_LABEL_CONTEXT, PACKING_SLIP_CONTEXT } from 'constants/settings'

import UpdateContext from 'components/settings/actions/UpdateContext'
import useHandlePrinting from 'hooks/useHandlePrinting'

import Checkbox from 'components/shared/Checkbox'
import Banner from 'components/shared/Banner'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import ButtonSelector from 'components/shared/ButtonSelector'
import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Main from 'components/shared/Main'
import Heading from 'components/shared/Heading'
import LineItem from 'components/shared/LineItem'
import Icon from 'components/shared/Icon'
import Slide from 'components/shared/Slide'

import UpdatePrinter from 'components/settings/actions/UpdatePrinter'
import OnlineStatus from 'components/shared/OnlineStatus'
import UpdateDrawer from 'components/settings/actions/UpdateDrawer'
import ConnectDrawerToPrinter from 'components/settings/actions/ConnectDrawerToPrinter'
import UpdateTyro from './actions/UpdateTyro'

const Settings = ({ initialAnimation }) => {
  const dispatch = useDispatch()
  const tyroSettings = useSelector((state) => state.settings.tyro)

  const [showDrawerSettings, setShowDrawerSettings] = useState(false)
  const [printTyroReceipt, setPrintTyroReceipt] = useState(tyroSettings.printTyroReceipt)
  const [alwaysPrintMerchantReceipt, setAlwaysPrintMerchantReceipt] = useState(tyroSettings.alwaysPrintMerchantReceipt)

  const {
    deviceFailed,
    deviceError,
    drawer,
    printers,
    printingContexts,
    printingTemplates,
    deviceFingerPrint,
    requestDevices,
    isDeviceAvailable,
    interpolateDeviceLabel
  } = useHandlePrinting()

  function setPrinter(deviceDesignation, device) {
    const printer = { ...deviceDesignation, device: deviceFingerPrint(device) }
    dispatch(UpdatePrinter(printer))
  }

  function printerOnClick(deviceDesignation) {
    requestDevices((device) => {
      setPrinter(deviceDesignation, device)
    })
  }

  function disconnectPrinter(printer) {
    let printerValues = { ...printer, device: null }
    if (printer.key === PRINTER_TYPES.RECEIPT) {
      printerValues = { ...printerValues, drawerPresent: false }
    }
    dispatch(UpdatePrinter(printerValues))
    setShowDrawerSettings(false)
    resetDrawerSettings()
  }

  function resetDrawerSettings() {
    const rules = drawer.openDrawerRules.map((rule) => { return { ...rule, openDrawer: false } })
    dispatch(UpdateDrawer({ ...drawer, openDrawerRules: rules }))
  }

  function renderPrintingTemplates(context, printerType = null) {
    let templates = printingTemplates

    if (printerType !== null) {
      templates = printingTemplates.filter((template) => template.printer_type.toLowerCase() === printerType.toLowerCase())
    }

    return (
      <ButtonSelector
        id='printing-template-dropdown'
        label='Selected template'
        theme={BRAND}
        selected={context.template !== null ? context.label : ''}
        shadow={2}
      >
        <ButtonMenu direction={COLUMN}>
          <Button
            className='justify-start'
            rounded={0}
            theme={NEUTRAL}
            size={TINY}
            onClick={() => {
              dispatch(UpdateContext({ ...context, templateId: null, template: null }))
            }}
          >
            <span className='text-left grow'>
              Select Template
            </span>
          </Button>
          {templates.map((template) => {
            return (
              <Button
                key={template.id}
                className='justify-start'
                rounded={0}
                theme={NEUTRAL}
                size={TINY}
                onClick={() => {
                  dispatch(UpdateContext({
                    ...context,
                    templateId: template.id,
                    template: template.label_template,
                    label: template.name, template
                  }))
                }}>
                <span className='text-left grow'>
                  {template.name}
                </span>
              </Button>
            )
          })}
        </ButtonMenu>
      </ButtonSelector>
    )
  }

  return (
    <View theme={NEUTRAL} shade={1} animation={initialAnimation}>
      <Topbar
        className='border-bottom'
        shade={0}
        theme={NEUTRAL}
      />
      <Main className="bg-shade-0" scroll={true}>
        <section className='p-6'>
          <div className="flex row justify-space-between">
            <Heading size={LARGE}>
              Printers
            </Heading>
          </div>

          <div className='bg-shade-1 p-4 mt-4 rounded-3 border-ends flex col'>
            {printers.map((printer) => {
              const printerConnected = printer.device !== null
              const drawerConnected = printer.drawerPresent
              return (
                <div key={printer.key} className="flex col">
                  <div className="flex row">
                    <Button
                      padding={4}
                      rounded={0}
                      shade={0}
                      shadow={3}
                      theme={NEUTRAL}
                      style={{ width: printerConnected ? '80%' : '100%' }}
                      size={LARGE}
                      onClick={() => {
                        printerOnClick(printer)
                      }}
                      className='hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow'
                    >
                      <LineItem gridTemplateColumns='200px 1fr auto' className='gap-2 font-size-3' style={{ width: 100 }}>
                        <span>
                          {printer.label}
                        </span>
                        <span>
                          {printer.device !== null ? interpolateDeviceLabel(JSON.parse(printer.device)) : 'No device selected'}
                        </span>
                        <OnlineStatus online={isDeviceAvailable(printer.key)} hideLabel={true} />
                      </LineItem>
                    </Button>
                    {printerConnected && (
                      <Button
                        padding={4}
                        rounded={0}
                        shade={3}
                        shadow={3}
                        theme={NOTIFY}
                        size={LARGE}
                        style={{ width: '20%' }}
                        center={false}
                        onClick={() => disconnectPrinter(printer)}
                        className='hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow flex justify-center'
                      >
                        <span className='font-size-3'>Disconnect</span>
                      </Button>
                    )}
                  </div>

                  {printer.device !== null && printer.shouldInquireAboutDrawerPresence && (
                    <div className="flex row bg-white p-4">
                      <Button
                        padding={4}
                        rounded={0}
                        shade={0}
                        shadow={3}
                        theme={NEUTRAL}
                        size={LARGE}
                        style={{ width: drawerConnected ? '90%' : '100%' }}
                        center={false}
                        onClick={() => {
                          dispatch(ConnectDrawerToPrinter({ ...printer, drawerPresent: !printer.drawerPresent }))
                          setShowDrawerSettings(!printer.drawerPresent)
                          if (!!printer.drawerPresent) resetDrawerSettings()
                        }}
                        className='hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow'
                      >
                        <Checkbox
                          checked={printer.drawerPresent}
                        />
                        <span className='font-size-2 pl-2'>This printer is connected to a drawer?</span>
                      </Button>
                      {drawerConnected && (
                        <Button
                          padding={4}
                          rounded={0}
                          shade={5}
                          shadow={3}
                          theme={BRAND}
                          size={LARGE}
                          style={{ width: '10%', minWidth: 50 }}
                          center={false}
                          onClick={() => {
                            setShowDrawerSettings(!showDrawerSettings)
                          }}
                          className='hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow flex justify-center'
                        >
                          <Icon type={showDrawerSettings ? CLOSE : CHEVRON} size={TINY} shade={3} />
                        </Button>
                      )}
                    </div>
                  )}

                  {drawer.printerKey === printer.key && printer.drawerPresent && showDrawerSettings && (
                    <Slide animation={!showDrawerSettings ? SLIDE_UP : SLIDE_DOWN}>
                      <div className="font-size-2 pb-4 ps-6 pt-4 bg-white">
                        Please verify and check payment methods that will trigger the cash drawer to open.
                      </div>
                      {drawer.openDrawerRules.map((rule) => {
                        return (
                          <Button
                            key={rule.paymentMethod}
                            padding={4}
                            rounded={0}
                            shade={0}
                            shadow={3}
                            theme={NEUTRAL}
                            size={LARGE}
                            style={{ width: '100%' }}
                            center={false}
                            onClick={() => {
                              const rules = [...drawer.openDrawerRules]
                              const matchedRuleIndex = rules.findIndex((_rule) => _rule.paymentMethod === rule.paymentMethod)
                              rules[matchedRuleIndex] = { ...rules[matchedRuleIndex], openDrawer: !rule.openDrawer }
                              dispatch(UpdateDrawer({ ...drawer, openDrawerRules: rules }))
                            }}
                            className='ps-6 hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow'
                          >
                            <Checkbox
                              checked={rule.openDrawer}
                            />
                            <span
                              className={classNames({
                                'font-size-2 pl-2': true,
                                'font-weight-2': !rule.openDrawer,
                                'font-weight-3': rule.openDrawer,
                              })}
                            >
                              {rule.paymentMethod}
                            </span>
                          </Button>
                        )
                      })}
                    </Slide>
                  )}
                </div>
              )
            })}
          </div>
        </section>
        <section className='pb-6 pl-6 pr-6'>
          <div className="flex row justify-space-between">
            <Heading size={LARGE}>
              Default Templates
            </Heading>
          </div>

          <div className='bg-shade-1 p-4 mt-4 rounded-3 border-ends flex col'>
            {printingContexts.map((context) => {
              // For now we are hiding these two until such time we need them
              if (context.key === PRODUCT_LABEL_CONTEXT || context.key === PACKING_SLIP_CONTEXT) return null
              return (
                <div key={context.key} className="flex col">
                  <div className="flex row p-4 bg-white shadow-3">
                    <LineItem className='flex justify-space-between pr-4 font-size-3' style={{ width: 100 }}>
                      <span>
                        {context.label}
                      </span>
                      <span>
                        {renderPrintingTemplates(context, context.printerType)}
                      </span>
                    </LineItem>
                  </div>
                </div>
              )
            })}
          </div>
        </section>
        {window.tyroClient &&
          <section className='p-6'>
            <div className="flex row justify-space-between">
              <Heading size={LARGE}>
                EFTPOS Settings
              </Heading>
            </div>
            <iframe id="iframe" style={{ display: 'none', width: 600, height: 400 }}></iframe>
            <div className='bg-shade-1 p-4 mt-4 rounded-3 border-ends flex col'>
              <div key={'eftpos'} className="">
                <div className="flex row">
                  <Button
                    padding={4}
                    rounded={0}
                    shade={0}
                    shadow={3}
                    theme={NEUTRAL}
                    size={LARGE}
                    onClick={() => {
                      const iframe = document.getElementById("iframe");
                      iframe.src = `${tyroBaseUrl}/configuration.html`;
                      iframe.style.display = "block";
                    }}
                    className='hover:scale-zoom hover:rounded-2 hover:shadow-3 transition-scale-shadow'
                  >
                    <LineItem gridTemplateColumns='200px 1fr auto' className='gap-2 font-size-3' style={{ width: 100 }}>
                      <span>
                        Connect
                      </span>
                    </LineItem>
                  </Button>
                </div>
                <div className="flex row bg-white p-4">
                  <Checkbox
                    id='tyroPrintReceipt'
                    checked={printTyroReceipt}
                    label={"Print Tyro receipts using StoreConnect printer"}
                    onChange={(enabled) => {
                      setPrintTyroReceipt(enabled)
                      dispatch(UpdateTyro(enabled, alwaysPrintMerchantReceipt))
                    }}
                  />
                  <span className='font-size-2 pl-2'></span>
                </div>
                <div className="flex row bg-white p-4">
                  <Checkbox
                    id="tyroPrintMerchantReceipt"
                    checked={alwaysPrintMerchantReceipt}
                    label={"Always Print Tyro Merchant Receipt"}
                    onChange={(enabled) => {
                      setAlwaysPrintMerchantReceipt(enabled)
                      dispatch(UpdateTyro(printTyroReceipt, enabled))
                    }}
                  />
                  <span className='font-size-2'></span>
                </div>
              </div>
            </div>
          </section>
        }
      </Main>
      {
        deviceFailed === true && (
          <Banner
            direction={COLUMN}
            animation={SLIDE_UP}
            theme={NOTIFY}
            shade={3}
            message={deviceError}
          />
        )
      }
    </View >
  )
}

export default Settings
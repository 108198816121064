import React from 'react'

import { LOCK } from 'components/constants'
import {
  AUTHENTICATION,
  DASHBOARD,
  DISCONNECT_REGISTER,
  REGISTER,
  STYLE_GUIDE
} from 'constants/views'

import { CUSTOMERS } from 'components/customers/constants'
import { CHECKOUT } from 'components/checkout/constants'
import { ORDERS } from 'components/orders/constants'
import { SUPPLIERS } from 'components/suppliers/constants'
import { START_SHIFT, END_SHIFT } from 'components/shifts/constants'
import { SHIPMENTS } from 'components/shipments/constants'
import { FULFILLMENTS } from 'components/fulfillments/constants'
import { PRINTABLE_LABELS } from 'components/printableLabels/constants'
import { SETTINGS } from 'constants/settings'

import Authentication from 'components/authentication'
import DisconnectRegister from 'components/disconnectRegister'
import Checkout from 'components/checkout'
import Register from 'components/registers'
import Customers from 'components/customers'
import Dashboard from 'components/dashboard/Dashboard'
import EndShiftFlow from 'components/shifts/EndShiftFlow'
import LockTerminalFlow from 'components/users/LockTerminalFlow'
import Orders from 'components/orders'
import Fulfillments from 'components/fulfillments'
import PrintableLabels from 'components/printableLabels'
import Shipments from 'components/shipments'
import Session from 'components/shifts/StartShiftFlow'
import StyleGuide from 'components/styleguide'
import Suppliers from 'components/suppliers'
import Provider from 'components/fulfillments/Provider'

import Settings from 'components/settings'

const Views = ({ viewId }) => {
  switch (viewId) {
    case AUTHENTICATION:
      // Authenticates user and stores token in client state
      return <Authentication />
    case REGISTER:
      // Fetches registers and stores
      return <Register />
    case START_SHIFT:
      // Fetches users, outlets and taxes
      return <Session />
    case DISCONNECT_REGISTER:
      return <DisconnectRegister />
    case DASHBOARD:
      // Fetches products
      return <Dashboard />
    case ORDERS:
      // Fetches orders and shifts
      return <Orders />
    case FULFILLMENTS:
      // Fetches holds, transfers, click & collects and special orders
      return <Provider><Fulfillments /></Provider>
    case CUSTOMERS:
      // Fetches customers
      return <Customers />
    case PRINTABLE_LABELS:
      // Fetches printable labels
      return <PrintableLabels />
    case CHECKOUT:
      return <Checkout />
    case SHIPMENTS:
      return <Shipments />
    case END_SHIFT:
      return <EndShiftFlow />
    case LOCK:
      return <LockTerminalFlow />
    case SUPPLIERS:
      return <Suppliers />
    case SETTINGS:
      return <Settings />
    case STYLE_GUIDE:
      return <StyleGuide />
  }
}

export default Views

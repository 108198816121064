import React from 'react'
import classNames from 'classnames'

import { LAYBY } from 'components/constants'

import Record from 'components/shared/Record'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'

const OrderSummaryBlock = ({
  className,
  owed,
  paid,
  total,
  type,
  refunded = 0,
  refundAmount,
  withRefund = false,
  orderNumber = null,
}) => {
  const minWidth = 400

  return (
    <div
      style={{ minWidth }}
      className={classNames({
        'sc-order-summary flex align-items-center gap-6 pe-4 ps-5': true,
        'bg-shade-1 rounded-3': true,
        [className]: className,
      })}>
      {orderNumber && (
        <Record
          icon={false}
          label="Order Number"
          data-owed>
          {orderNumber}
        </Record>
      )}
      {owed > 0 && (
        <span className='flex gap-5'>
          <Record
            icon={false}
            label={type === LAYBY ? 'Amount remaining' : 'Amount to pay'}
            data-owed>
            {getFormattedPrice(convertToAbsoluteAmountValue(owed))}
          </Record>
        </span>
      )}
      <Record label='Amount paid' data-paid>
        {getFormattedPrice(convertToAbsoluteAmountValue(paid))}
      </Record>

      {withRefund === true && (
        <Record label='Amount to refund' data-paid>
          {getFormattedPrice(convertToAbsoluteAmountValue(refundAmount))}
        </Record>
      )}

      {withRefund === true && (
        <Record label='Amount refunded' data-paid>
          {getFormattedPrice(convertToAbsoluteAmountValue(refunded))}
        </Record>
      )}
      <Record label={'Order total'} data-owed>
        {getFormattedPrice(convertToAbsoluteAmountValue(total))}
      </Record>
    </div>
  )
}

export default OrderSummaryBlock

import React from 'react'

import Tag from 'components/shared/Tag'
import Icon from 'components/shared/Icon'
import ButtonLineItem from 'components/shared/ButtonLineItem'

import getFriendlyName from '../helpers/getFriendlyName'

import { BRAND, CHEVRON, SUCCESS } from 'components/constants'
import { READY_FOR_PICKUP, COMPLETE } from '../constants'

const FulfillmentLineItem = ({
  fulfillment,
  gridTemplateColumns = '200px 100px 1fr 2fr auto',
  onSetFulfillment
}) => {
  return (
    <div className="border-ends flex col gap-1px bg-shade-1">
      <ButtonLineItem
        padding={4}
        gridTemplateColumns={gridTemplateColumns}
        onClick={() => onSetFulfillment()}
      >
        <div>
          <div className="font-size-1 color-shade-4">Order #</div>
          <div className="font-weight-3">{fulfillment.order_id}</div>
        </div>
        <div>
          <div className="font-size-1 color-shade-4">ID #</div>
          <div className="font-weight-3">{fulfillment.fulfillment_id}</div>
        </div>
        <div>
          <div className="font-weight-3"><Tag text={getFriendlyName(fulfillment.type)} size={'SMALL'} theme={fulfillment.type}/></div>
        </div>
        <div>
          <div className="font-weight-3"><Tag text={getFriendlyName(fulfillment.status)} size={'SMALL'} theme={fulfillment.status == READY_FOR_PICKUP || fulfillment.status == COMPLETE ? SUCCESS : BRAND}/></div>
        </div>
        <Icon className='opacity-06' type={CHEVRON} size={14} rotate={270} />
      </ButtonLineItem>
    </div>
  )
}

export default FulfillmentLineItem
import React from 'react'
import classNames from 'classnames'

import { XLARGE, XXLARGE, HUGE } from 'components/constants'
import { BRAND, LAYBY } from 'components/constants'
import { CUSTOMER } from 'components/customers/constants'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Heading from 'components/shared/Heading'

const CustomerDetailsForm = ({
  animation,
  className,
  customer = {},
  innerAnimation = null,
  maxWidth,
  onBack,
  onSetCustomer,
  onSubmit,
  showBackButton,
  rounded = 0,
  padding = 3,
  shade = 0,
  size = XXLARGE,
  theme = CUSTOMER,
  zIndex,
}) => {
  return (
    <Form
      id='customer-form'
      animation={animation}
      maxWidth={maxWidth}
      rounded={rounded}
      theme={theme}
      shade={shade}
      onSubmit={() => onSubmit(customer)}
      className={classNames({
        ['z-' + zIndex]: !!zIndex,
        [className]: className,
      })}
    >
      {showBackButton && (
        <ButtonMenu className='ps-3 pt-3'>
          <Button
            rounded={4}
            padding={5}
            shade={shade}
            theme={theme}
            onClick={onBack}
          >
            Back
          </Button>
        </ButtonMenu>
      )}
      <Container
        className={`pe-${padding - 2} overflow-y-auto`}
        padding={padding}
        animation={innerAnimation}
        style={{ maxHeight: 500 }}
      >
        <Heading size={size}>Enter customer details</Heading>
        <fieldset className='flex gap-3 col'>
          <Field
            label='First name'
            type='text'
            id='fname'
            name='fname'
            defaultValue={customer.firstName}
            border={true}
            required={true}
            shade={0}
            shadow={1}
            theme={theme === LAYBY ? LAYBY : BRAND}
            onChange={(firstName) => onSetCustomer({ ...customer, firstName })}
          />
          <Field
            label='Last name'
            type='text'
            id='lname'
            name='lname'
            defaultValue={customer.lastName}
            border={true}
            required={true}
            shade={0}
            shadow={1}
            theme={theme === LAYBY ? LAYBY : BRAND}
            onChange={(lastName) => onSetCustomer({ ...customer, lastName })}
          />
          <Field
            label='Phone'
            type='phone'
            id='phone'
            name='phone'
            defaultValue={customer.phone}
            border={true}
            shade={0}
            shadow={1}
            theme={theme === LAYBY ? LAYBY : BRAND}
            onChange={(phone) => onSetCustomer({ ...customer, phone })}
          />
          <Field
            label='Email'
            type='email'
            id='email'
            name='email'
            defaultValue={customer.email}
            border={true}
            shade={0}
            shadow={1}
            theme={theme === LAYBY ? LAYBY : BRAND}
            onChange={(email) => onSetCustomer({ ...customer, email })}
          />
        </fieldset>
      </Container>
      <Button
        theme={theme}
        shade={4}
        size={XXLARGE}
        rounded={0}
        disabled={!customer.firstName || !customer.lastName}
        className='expand-x'
        type='submit'
      >
        Add customer
      </Button>
    </Form>
  )
}

export default CustomerDetailsForm

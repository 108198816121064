import React from 'react'
import classNames from 'classnames'
import htmlParser from 'html-react-parser'

import { SMALL, BRAND, LARGE, NEUTRAL } from 'components/constants'

import convertNewLinesToPTags from 'components/helpers/convertNewLinesToParagraphInText'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import ContactList from 'components/suppliers/ContactList'
import StickyHeader from 'components/shared/StickyHeader'
import ListHeader from 'components/shared/ListHeader'
import Main from 'components/shared/Main'
import Record from 'components/shared/Record'
import Heading from 'components/shared/Heading'

const Supplier = ({
  animation,
  supplier,
  onBack,
}) => {
  const supplierDetailsAvailable = supplier.phone !== null || supplier.notes !== null

  return (
    <Main scroll={true} theme={NEUTRAL} shade={0} animation={animation}>
      <Article gridTemplateRows='auto 1fr' theme={NEUTRAL} shade={0}>
        <StickyHeader>
          <ButtonMenu className='justify-space-between ps-3 pt-2'>
            <Button
              className='bg-white'
              shade={0}
              rounded={3}
              shadow={3}
              padding={5}
              theme={BRAND}
              onClick={onBack}
            >
              Back
            </Button>
          </ButtonMenu>
        </StickyHeader>
        <Container className='pe-5' padding={5} gap={4}>
          <Heading size={5}>
            {supplier.name}
          </Heading>
          <section className='bg-shade-1 flex col rounded-3 p-4'>
            {supplierDetailsAvailable && (
              <>
                {supplier.phone && (
                  <Record label='Phone'>{supplier.phone}</Record>
                )}
                {supplier.notes && (
                  <Record label='Notes' size={SMALL} hasLongValue={true}>
                    <span>
                      {htmlParser(convertNewLinesToPTags(supplier.notes))}
                    </span>
                  </Record>
                )}
              </>
            )}

            {!supplierDetailsAvailable && (
              <div className='p-4 font-2 font-weight-2'>
                No supplier details to show
              </div>
            )}
          </section>

          <Heading size={LARGE}>
            Contacts
          </Heading>

          <section className='relative z-0 bg-shade-1 rounded-3 p-3'>
            <div
              className={classNames({
                'pt-2 ps-3': true,
              })}>
              <ListHeader
                gridTemplateColumns='300px 200px 1fr 1fr 1fr'
                gap={0}
              >
                <span className='pt-3'>Name</span>
                <span className='pt-3'>Email</span>
                <span className='pt-3'>Mobile Phone</span>
                <span className='pt-3'>Phone</span>
                <span className='pt-3'>Department</span>
                <span className='pt-3'></span>
              </ListHeader>
            </div>
            {supplier.contacts && (
              <ContactList
                contacts={supplier.contacts}
                className='ps-3 pb-3'
              />
            )}
          </section>
        </Container>
      </Article>
    </Main>
  )
}

export default Supplier

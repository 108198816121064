import React, { Children, useEffect, useRef } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { BASE, LARGE } from 'constants/size'
import getAnimation from 'helpers/getAnimation'


/**
 * This component presents its children as a series of
 * fieldsets with shared visual characteristics that
 * facilitate a step-by-step progression through the form.
 */

const SequentialForm = ({
  id,
  children,
  animation = null,
  activeIndex = 0,
  maxWidth = null,
  maxHeight = null,
  onSubmit,
  onBack,
  onChange,
  className,
}) => {
  const formRef = useRef(null)
  const sections = getValidChildNodes(children)

  useEffect(() => {
    getFirstInputOfVisibleFieldset(formRef.current).focus()
    formRef.current.querySelector('fieldset:not([aria-hiden]) input:not([type=hidden])').focus()
    formRef.current.addEventListener('keyup', handleInput)
    formRef.current.addEventListener('change', handleChange)

    return () => {
      if (formRef.current === null) return
      formRef.current.removeEventListener('keyup', handleInput)
      formRef.current.removeEventListener('change', handleChange)
    }
  }, [activeIndex])

  function handleInput(event) {
    switch (event.key) {
      case 'Enter':
        return handleSubmit(event)
      case 'Esc':
        return onBack()
    }
  }

  function handleChange(event) {
    if (onChange) onChange(event)
  }

  function handleSubmit(event) {
    if (onSubmit) onSubmit(new FormData(event.target.form))
  }

  const Fieldset = ({ child, active, index, size }) => {
    return (
      <motion.fieldset
        { ...getAnimation(animation) }
        aria-hidden={active === false}
        initial={index > 0 && { opacity: 0, x: '10%' }}
        animate={index > 0 && { opacity: 1, x: '0%' }}
        className={classNames({
          'pb-4': true,
          'pointer-events-x hide': active === false,
        })}
      >
        {child.props.legend && (
          <legend className={classNames({
            'font-weight-2 color-shade-5 expand-x': true,
            'font-size-3 ps-5 pt-4 pb-2': size === LARGE,
            'font-size-2 ps-4 pt-4 pb-2': size === BASE,
          })}>{child.props.legend}</legend>
        )}
        {child}
      </motion.fieldset>
    )
  }

  return (
    <form
      ref={formRef}
      data-form={id}
      style={{ maxWidth, maxHeight }}
      onSubmit={handleSubmit}
      onChange={handleChange}
      className={classNames({ 'expand-x': true, [className]: className })}
    >
      {sections.map((child, index) => (
        <Fieldset
          child={child}
          index={index}
          size={child.props.size}
          active={index === activeIndex}
          key={child.props.name}
        />
      ))}
    </form>
  )

  function getFirstInputOfVisibleFieldset(form) {
    return form.querySelector(
      'fieldset[aria-hidden=false] input:not([type=hidden])'
    )
  }

  function getValidChildNodes(children) {
    return Children.toArray(children).filter(child => !!child)
  }
}

export default SequentialForm

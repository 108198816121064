import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import getAnimation from 'helpers/getAnimation'

const Container = ({
  animation,
  center = false,
  children,
  className,
  gap = 1,
  maxWidth,
  padding = 7,
  style,
  id = null,
  scroll = false,
  withoutPadding = false,
}) => {
  return (
    <motion.div
      {...getAnimation(animation)}
      style={{ ...style,
        paddingLeft: getSidesPaddingFromMaxWidth(),
        paddingRight: getSidesPaddingFromMaxWidth(),
      }}
      className={classNames({
        'sc-container flex col': true,
        'overflow-y-auto overflow-x-hidden': scroll === true,
        'justify-center': center === true,
        ['ps-' + padding]: !withoutPadding && maxWidth === undefined,
        ['gap-' + gap]: gap > 0,
        [className]: className,
      })}
      id={id !== null ? id : ""}>
      {children}
    </motion.div>
  )

  function getSidesPaddingFromMaxWidth() {
    if (maxWidth === undefined) return null
    return `calc((100% - ${maxWidth}px) / 2 + ${getSpacing(padding)})`
  }

  function getSpacing(num) {
    return 'var(--sc-spacing-' + num + ')'
  }
}

export default Container

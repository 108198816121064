import CUSTOMERS from 'data/dummy/customers'
import { USE_DUMMY_DATA } from 'constants/settings'

import authenticatedFetch from './authenticatedFetch'

export function queryCustomers(
  query,
  onSuccess,
  onOffline,
  onError = () => alert('Error fetching customers'),
) {
  if (USE_DUMMY_DATA) return onSuccess(CUSTOMERS)

  if (navigator.onLine) {
    authenticatedFetch('customer_searches',
      {
        method: 'POST',
        body: JSON.stringify({ query }),
      },
      // Success
      ({ customers }) => onSuccess(customers),
      // Error
      () => onError()
    )
  } else onOffline()
}

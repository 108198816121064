import { SET_OUTLETS } from 'components/outlets/constants'

export default function (state = [], { type, outlets }) {
  switch (type) {
    case SET_OUTLETS:
      window.localStorage.setItem('outlets', JSON.stringify(outlets))
      return outlets
    default:
      return state
  }
}

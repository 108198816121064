import { ADD_SHIFT_ORDER, RESET_SHIFT_ORDERS } from '../constants'

export default function (state = [], action) {
  switch (action.type) {
    case ADD_SHIFT_ORDER:
      setLocalStorage([...state, action.id])
      return [...state, action.id]

    case RESET_SHIFT_ORDERS:
      setLocalStorage([])
      return []

    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('shiftOrders', JSON.stringify(payload))
}

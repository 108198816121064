import React from 'react'
import classNames from 'classnames'

import { MEDIUM, XXLARGE } from 'components/constants'
import { BRAND, NEUTRAL } from 'components/constants'
import { SLIDE_UP } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Form from 'components/shared/Form'
import Heading from 'components/shared/Heading'
import CheckoutSummaryBlock from 'components/checkout/CheckoutSummaryBlock'

import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'

const ParkOrderForm = ({
  animation,
  className,
  cartCount = 0,
  customer = {},
  expand,
  theme = BRAND,
  size = XXLARGE,
  shade = 0,
  shadow = 0,
  rounded = 0,
  innerAnimation,
  total,
  maxWidth,
  padding = 6,
  zIndex = 2,
  onBack,
  onSubmit,
}) => {

  return (
    <Form
      shade={shade}
      theme={theme}
      shadow={shadow}
      rounded={rounded}
      animation={animation}
      innerAnimation={innerAnimation}
      maxWidth={maxWidth}
      expand={expand}
      onSubmit={() => onSubmit()}
      className={classNames({
        ['z-' + zIndex]: !!zIndex,
        [className]: className
      })}
    >
      {onBack && (
        <ButtonMenu className='ps-3 pt-3'>
          <Button
            shade={0}
            rounded={4}
            padding={5}
            size={MEDIUM}
            theme={theme}
            onClick={onBack}
          >
            Back
          </Button>
        </ButtonMenu>
      )}
      <Container
        gap={5}
        padding={padding}
        animation={innerAnimation}
        className={'pe-' + (padding - 1)}
      >
        <Heading size={size}>
          Are you sure you want to park this order?
        </Heading>
        <CheckoutSummaryBlock
          theme={NEUTRAL}
          shade={1}
          paid={null}
          total={total}
          cartCount={cartCount}
          customer={customer}
        />
      </Container>
      <Button
        onClick={onSubmit}
        size={XXLARGE}
        theme={BRAND}
        shade={5}
        animation={SLIDE_UP}
        rounded={0}
        type='submit'
      >Park order</Button>
    </Form>
  )
}

export default ParkOrderForm

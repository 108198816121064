import React, { useState, useEffect } from 'react'

import { MEDIUM, XXLARGE } from 'components/constants'
import { BRAND, NEUTRAL } from 'components/constants'
import { SLIDE_NEXT } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Form from 'components/shared/Form'
import Input from 'components/shared/Input'
import Heading from 'components/shared/Heading'

import isTouchScreen from 'components/helpers/isTouchScreen'

const CustomerEmailForm = ({
  className,
  customer = {},
  onBack,
  onSubmit,
  layby = false,
  rounded = 4,
  size = XXLARGE,
  theme = BRAND,
  width = 620,
}) => {
  const [email, setEmail] = useState(
    Object.keys(customer).length > 0 ? customer.email : null
  )

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('email').focus()
  }, [])

  return (
    <Form
      maxWidth={width}
      shade={0}
      shadow={4}
      rounded={rounded}
      theme={theme}
      className={className}
      onSubmit={() => onSubmit(email)}
    >
      <ButtonMenu className='ps-3 pe-3'>
        <Button
          shade={0}
          rounded={4}
          theme={theme}
          onClick={onBack}
          size={MEDIUM}
        >
          Back
        </Button>
      </ButtonMenu>
      <Container
        gap={4}
        animation={SLIDE_NEXT}
        className='pb-5'
      >
        <Heading size={size}>
          Where should we email <br/>the receipt?
        </Heading>
        <Input
          className='border'
          type='email'
          id='email'
          shadow={2}
          shade={1}
          theme={NEUTRAL}
          defaultValue={email}
          onBlur={email === null ? onBack : null}
          onChange={(value) => setEmail(value || null)}
        />
      </Container>
      <Button
        className='expand-x'
        type='submit'
        shade={layby ? 3 : 5}
        rounded={0}
        onBlur={onBack}
        size={size}
        theme={theme}
        disabled={email === null}
      >
        Send email
      </Button>
    </Form>
  )
}

export default CustomerEmailForm

import React from 'react'
import classNames from 'classnames'

const Container = ({ children, className, heading, height, width }) => {
  return (
    <div
      className={classNames({
        'relative flex col z-0 pe-5': true,
        [className]: className,
      })}>
      {heading && (
        <header>
          <h1 className='font-weight-3 ps-4 mb-3'>
            {heading}
          </h1>
          <div></div>
        </header>
      )}
      <div
        style={{ width, height, maxWidth: '1200px' }}
        className={classNames({
          'relative flex col gap-7 ps-4 z-1': true,
          [className]: className,
        })}>
        {children}
      </div>
    </div>
  )
}

export default Container

import React from 'react'
import classNames from 'classnames'

import { MEDIUM } from 'components/constants'

import Accordion from 'components/shared/Accordion'
import OrderLineItem from 'components/orders/shared/OrderLineItem'

import getFormattedDate from 'components/helpers/getFormattedDate'
import getUserFullName from 'components/users/helpers/getUserFullName'
import getUserById from 'components/users/helpers/getUserById'
import getShiftOrders from 'components/orders/helpers/getShiftOrders'
import getShiftById from 'components/shifts/helpers/getShiftById'

const OrderShifts = ({
  className,
  shiftIds = [],
  shifts = [],
  orders = [],
  size = MEDIUM,
  users = [],
  onSetOrder,
}) => {
  return (
    <ul className='expand overflow-y-scroll relative z-1 ps-3 pb-3 flex col gap-2'>
      {shiftIds.length === 0 && (
        <span className='bg-white color-shade-3 p-4'>
          No recent orders
        </span>
      )}
      {shiftIds.length > 0 && shiftIds.map((shiftId) => {
        const shiftOrders = getShiftOrders(shiftId, orders)
        const shift = getShiftById(shiftId, shifts)

        if (shift) return (
          <Accordion
            open={true}
            gap={1}
            shadow={2}
            background={2}
            key={shiftId}
            size={size}
            className={className}
            data-shift={shift.id}
            headline={getShiftHeading(shift)}
          >
            <div className='border-ends flex col gap-1px bg-shade-1'>
              {shiftOrders.map((order) => (
                <OrderLineItem
                  key={order.id}
                  order={order}
                  customer={order.customer}
                  onClick={() => onSetOrder(order)}
                />
              ))}
              {shiftOrders.length === 0 && (
                <span className='bg-white color-shade-3 p-4'>
                  No recent orders
                </span>
              )}
            </div>
            <div className={classNames({
              'overflow-hidden gap-3 flex justify-space-between pe-3 ps-4': true,
              'font-size-1 bg-shade-0 color-shade-4 rounded-bottom-2': true,
            })}>
              {shift && shift.userId && (
                <span>
                  Manager: {getUserFullName(getUserById(shift.userId, users))}
                </span>
              )}
              <span className='color-shade-3'>
                ID {shift.id || 'undefined'}
              </span>
            </div>
          </Accordion>
        )
      })}
    </ul>
  )

  function getShiftHeading(shift) {
    if (shift.startedAt === undefined && shift.endedAt === undefined)
      return 'Unknown shift'
    if (shift.endedAt === undefined || shift.endedAt === null)
      return 'Current shift'
    if (getFormattedDate(shift.startedAt) === getFormattedDate(shift.endedAt))
      return getFormattedDate(shift.startedAt)
    else return getFormattedDate(shift.startedAt) + ' - ' + getFormattedDate(shift.endedAt)
  }
}

export default OrderShifts

import React from 'react'

import { XLARGE } from 'components/constants'
import { BRAND, CUSTOMER } from 'components/constants'
import { CHEVRON } from 'components/constants'

import Button from 'components/shared/Button'
import Headline from 'components/shared/Headline'
import Icon from 'components/shared/Icon'
import LineItem from 'components/shared/LineItem'
import getUserFullName from 'components/users/helpers/getUserFullName'

const UserButtonLineItem = ({
  shade = 6,
  rounded = 0,
  size = XLARGE,
  theme = BRAND,
  user = {},
  onClick,
}) => {
  return (
    <Button
      className='capitalize'
      padding={4}
      rounded={rounded}
      shade={shade}
      size={size}
      theme={theme}
      onClick={onClick}
    >
      <LineItem
        size={size}
        icon={CUSTOMER}
        gap={3}
        gridTemplateColumns='auto 1fr auto'
      >
        <Icon size={XLARGE} type={CUSTOMER} theme={BRAND} shade={3} />
        <Headline size={5}>{getUserFullName(user)}</Headline>
        <Icon
          type={CHEVRON}
          theme={theme}
          size={20}
          rotate={270}
          shade={3}
        />
      </LineItem>
    </Button>
  )
}

export default UserButtonLineItem

import React from 'react'
import classNames from 'classnames'
import { parsePhoneNumber } from 'libphonenumber-js'

import { COUNTRY_CODE } from 'components/constants'

import ButtonLineItem from 'components/shared/ButtonLineItem'
import Icon from 'components/shared/Icon'

const SupplierLineItem = ({
  supplier,
  className,
  children,
  gridTemplateColumns = '1fr 1fr 1fr auto',
  first = false,
  last = false,
  icon,
  onClick,
}) => {
  return (
    <ButtonLineItem
      padding={4}
      gridTemplateColumns={gridTemplateColumns}
      onClick={() => onClick(supplier)}
      className={classNames({
        'bg-shade-0 align-items-center': true,
        'rounded-top-2': first === true,
        'rounded-bottom-2': last === true,
        [className]: className,
      })}
    >
      <span className='font-size-3 font-weight-3'>
        {supplier.name}
      </span>
      {supplier.phone && (
        <span className='font-size-3 font-weight-2 opacity-07'>
          {parsePhoneNumber(supplier.phone, COUNTRY_CODE).formatNational()}
        </span>
      )}
      {children}
      {icon && <Icon size={24} type={icon} />}
    </ButtonLineItem>
  )
}

export default SupplierLineItem
import React, { useEffect, useState, useRef } from 'react'

import { LARGE } from 'constants/size'
import { FADE } from 'components/constants'
import { SCALE_IN } from 'constants/motion'

import Beep from 'sounds/beep'
import Error from 'sounds/error'

import Overlay from 'components/shared/Overlay'
import ProductConfigurationForm from 'components/products/ProductConfigurationForm'

import isAlphaNumeric from 'components/helpers/isAlphaNumeric'
import getProductByBarcode from './helpers/getProductByBarcode'
import hasVariablePricing from './helpers/hasVariablePricing'
import getProductVariants from './helpers/getProductVariants'

const MAX_LENGTH = 20
const ENTER_KEY = 'Enter'
const ESC_KEY = 'Escape'

const ProductScanner = ({ products, onSelect }) => {
  const [scanning, setScanning] = useState(false)
  const [scannerVisible, setScannerVisible] = useState(false);
  const [hasError, setHasError] = useState(false)
  const [inputValue, setInputValue] = useState(null)
  const [scannedProduct, setScannedProduct] = useState({})
  const [showProductConfig, setShowProductConfig] = useState(false)
  const visibleTimer = useRef(); 

  useEffect(() => {
    window.addEventListener('keyup', init)
    return () => {
      if (window) window.removeEventListener('keyup', init)
    }
  }, [])
  useEffect(() => {
    if (hasError === true) {
      Error.play()
      if(scannerVisible === false) {
        setScannerVisible(true)
      }
      const timer = setTimeout(resetError, 5000)
      return () => clearTimeout(timer)
    }
  }, [hasError])

  function init({ key }) {
    if (activeElementisAnInput() || isAlphaNumeric(key) === false) return
    if (scanning === false) {
      setScanning(true)
      setInputValue(key)
      if (scannerVisible === false) {
        visibleTimer.current = setTimeout(() => { setScannerVisible(true) }, 500);
        return () => clearTimeout(visibleTimer.current)
      }
        
    }
  }

  function handleInput({ key, target }) {
    switch (key) {
      case ESC_KEY:
        setScanning(false)
        setScannerVisible(false);
        setShowProductConfig(false)
        setInputValue("")
        setScannedProduct({})
        return
      case ENTER_KEY:
        getProductByBarcode(
          target.value,
          products,
          select,
          () => setHasError(true)
        )
        return
    }
    if(hasError === true) {
      if (activeElementisAnInput() || isAlphaNumeric(key) === false) {
        setInputValue(key)
        target.value = key
      }
      setHasError(false)
    }
  }
  function select(scannedProduct) {
    Beep.play()
    if (hasVariablePricing(scannedProduct)) {
      setScannedProduct(scannedProduct)
      setShowProductConfig(true)
    } else {
      onSelect(scannedProduct)
      setShowProductConfig(false)
    }
    setInputValue("")
    setScanning(false)
    clearTimeout(visibleTimer.current)
    setScannerVisible(false)
  }

  function resetError() {
    setShowProductConfig(false)
    setScanning(false)
    setScannerVisible(false)
    setHasError(false)
  }

  function activeElementisAnInput() {
    return document.activeElement.tagName === 'INPUT'
  }
  
  if (scanning) return (
    <Overlay
      shade={1}
      animation={FADE}
      className={`${scannerVisible === false && 'force-opacity-0'} ${hasError === true && 'theme-notify-3'}`}
      onClose={() => {
        setScanning(false)
        setScannerVisible(false)
        setShowProductConfig(false)
        setScannedProduct({})
        setInputValue(null)
      }}
    >
      {showProductConfig === false && (
        <div className='relative z-3 grow flex col gap align-items-center justify-center'>
          <strong className='font-size-5'>
            {hasError ? 'Product not found' : 'Barcode Entry'}
          </strong>
          <input
            className='input-height-5 font-weight-3 text-center upcase'
            pattern='[a-zA-Z0-9]+'
            type='text'
            id='barcodeField'
            autoFocus={true}
            maxLength={MAX_LENGTH}
            defaultValue={inputValue}
            onKeyUp={(event) => handleInput(event)}
            style={{ fontSize: 80 }}
          />
        </div>
      )}
      {showProductConfig === true && (
        <ProductConfigurationForm
          className='relative overflow-hidden z-2 bg-white rounded-3 shadow-3'
          maxWidth={500}
          maxHeight={400}
          size={LARGE}
          animation={SCALE_IN}
          product={scannedProduct}
          variants={getProductVariants(scannedProduct, products)}
          onDefocus={() => {
            setShowProductConfig(false)
            setScanning(false)
            setScannerVisible(false)
          }}
          onSubmit={(configuredProduct) => {
            onSelect(configuredProduct)
            setScanning(false)
            setScannerVisible(false)
            setShowProductConfig(false)
          }}
        />
      )}
    </Overlay>
  )
  else return null
}

export default ProductScanner

import React from 'react'
import classNames from 'classnames'

const ListHeader = ({
  children,
  gap = 3,
  gridTemplateColumns,
  padding = 4,
  top = 0,
  zIndex = 1,
}) => {
  return (
    <header
      className={classNames({
        'sticky top-0 grid align-items-center pt-3 pb-2': true,
        'bg-shade-0 rounded-top-2 color-shade-4 border-bottom': true,
        'font-size-1 font-weight-2': true,
        ['gap-' + gap]: gap > 0,
        ['z-' + zIndex]: zIndex > 0,
        ['ps-' + padding]: padding > 0,
      })}
      style={{
        gridTemplateColumns,
        top,
      }}
    >
    {children}
    </header>
  )
}

export default ListHeader

import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { NEUTRAL } from 'components/constants'
import { FADE } from 'components/constants'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'
import getAnimation from 'helpers/getAnimation'

const Overlay = ({
  animation = FADE,
  fixed = false,
  disabled,
  theme = NEUTRAL,
  shade = 1,
  opacity = 0.9,
  children,
  background = false,
  zIndex = 2,
  onClose,
  className
}) => {
  return (
    <motion.div
      data-overlay
      type='button'
      {...getAnimation(animation)}
      className={classNames({
        'x-0 y-0 flex align-items-center justify-center': true,
        'absolute': fixed === false,
        'fixed': fixed === true,
        ['z-' + zIndex]: zIndex > 0,
        [className]: className,
      })}>
      {children}
      <button
        type='button'
        onClick={onClose}
        disabled={disabled}
        style={{ opacity }}
        className={classNames({
          'absolute x-0 y-0 expand': true,
          [getThemeStyleClass(theme, shade)]: true,
        })}>
      </button>
    </motion.div>
  )
}

export default Overlay

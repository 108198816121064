import React from 'react'
import classNames from 'classnames'

import Record from 'components/shared/Record'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const CartItemPriceBreakdown = ({
  className,
  costPrice,
  minSellPrice,
  price,
  quantity,
}) => {
  return (
    <div
      className={classNames({ 'flex col gap': true, [className]: className })}
      data-item-sell-breakdown>
      <div
        className='grid wrap row gap-3'
        style={{ gridTemplateColumns: '1fr 1fr' }}>
        {costPrice && (
          <Record label='Cost per unit' data-unit-cost-price>
            {getFormattedPrice(costPrice)}
          </Record>
        )}
        {quantity > 1 && (
          <Record label='Cost per unit' data-unit-price>
            {getFormattedPrice(price)}
          </Record>
        )}
        {minSellPrice && (
          <Record label='Min. sell total' data-total-min-sell>
            {getFormattedPrice(minSellPrice ? minSellPrice * quantity : null)}
          </Record>
        )}
        {quantity > 1 && minSellPrice && (
          <Record label='Min. sell per unit' data-unit-min-sell>
            {getFormattedPrice(minSellPrice)}
          </Record>
        )}
      </div>
    </div>
  )
}

export default CartItemPriceBreakdown

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import {
  DEBOUNCE_DELAY,
  LARGE,
  HUGE,
  BRAND,
  COLUMN,
  VOUCHER,
  DOLLAR,
  SLIDE_UP,
  NOTIFY,
} from 'components/constants'
import { VOUCHER_STATUSES } from '../constants'

import { queryVoucher } from 'data/api/queryVoucher'

import Form from 'components/shared/Form'
import Button from 'components/shared/Button'
import Banner from 'components/shared/Banner'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Field from 'components/shared/Field'
import Heading from 'components/shared/Heading'
import Record from 'components/shared/Record'
import Icon from 'components/shared/Icon'
import Loader from 'components/shared/Loader'

import useInputDebounce from 'hooks/useInputDebounce'
import usePrevious from 'hooks/usePrevious'
import isTouchScreen from 'components/helpers/isTouchScreen'
import getFormattedDate from 'components/helpers/getFormattedDate'
import getFormattedPrice from 'components/helpers/getFormattedPrice'

const VoucherPaymentForm = ({
  animation,
  className,
  drawerError = null,
  center = true,
  submitFailed = false,
  submitting = false,
  syncingOrder = false,
  onSubmit,
  theme = BRAND,
  owed,
}) => {
  const [amount, setAmount] = useState(null)
  const [amountInvalid, setAmountInvalid] = useState(false)
  const [validating, setValidating] = useState(false)
  const [validateFailed, setValidateFailed] = useState(false)
  const [code, setCode] = useState('')
  const [voucher, setVoucher] = useState(null)
  const [voucherNotFound, setVoucherNotFound] = useState(false)
  const [voucherInvalid, setVoucherInvalid] = useState(false)

  const debouncedCode = useInputDebounce(code, DEBOUNCE_DELAY)
  const previousDebouncedCode = usePrevious(debouncedCode)

  useEffect(() => {
    if (isTouchScreen() === false) {
      document.getElementById('code').focus()
    }
  }, [])

  useEffect(() => {
    if (debouncedCode.length === 0) return
    if (debouncedCode !== previousDebouncedCode) {
      validate(debouncedCode)
    }
  }, [debouncedCode, previousDebouncedCode])

  // This will handle in the middle of the transaction connection changes
  useEffect(() => {
    if (navigator.onLine === false) {
      setVoucher(null)
    } else {
      if (code !== null && code !== '') {
        validate(code)
      }
    }
  }, [navigator.onLine])

  function submit() {
    onSubmit({
      amountTendered: amount || owed,
      paymentDetails: code,
      paymentType: VOUCHER,
    })
  }

  function validateByCode(code) {
    if (code.length === 0) {
      setCode('')
      setVoucher(null)
      setVoucherNotFound(false)
      setVoucherInvalid(false)
    } else {
      setCode(code)
    }
  }

  function validate(code) {
    setVoucher(null)
    setValidating(true)
    queryVoucher(code,
      // Success
      (voucher) => {
        if (voucher.id) {
          setVoucher(voucher)
          setVoucherInvalid(false)
        }

        switch (voucher.status_key) {
          case 'not_found':
            setVoucherNotFound(true)
            break
          case 'expired':
          case 'no_balance':
            setVoucherInvalid(true)
            break
          default:
            setVoucherNotFound(false)
            setVoucherInvalid(false)
        }

        setValidateFailed(false)
        setValidating(false)
      },
      // Offline
      () => {
        setValidating(false)
        setValidateFailed(true)
      },
      // Error
      (_status) => {
        setValidating(false)
        setValidateFailed(true)
      }
    )
  }

  function submitButtonDisabled() {
    return (
      navigator.onLine === false ||
      syncingOrder ||
      submitting ||
      voucherInvalid ||
      amountInvalid ||
      voucher === null ||
      voucherNotFound ||
      voucher.balance < owed
    )
  }

  function maxAmount() {
    if (voucher !== null) {
      if (voucherInvalid) return 0

      // We only allow max owed if voucher balance is greater than
      // so that change will not be applied or computed
      const maxOwed = owed.toFixed(2)
      return Math.min(...[maxOwed, voucher.balance])
    }

    return null
  }

  return (
    <Form
      gridRows='1fr auto'
      animation={animation}
      className={classNames({
        expand: true,
        [className]: className,
      })}
    >
      <Container center={center} maxWidth={800} gap={3}>
        <span className='flex gap-2 align-items-center font-size-5 font-weight-2'>
          <Icon type={VOUCHER} size={LARGE} theme={theme} shade={3} />
          Voucher
        </span>
        <div className='flex gap-2'>
          <span>
            <Field
              valid={true}
              width={340}
              id='code'
              border={true}
              name='code'
              label='Code'
              onChange={(value) => validateByCode(value)}
            />
          </span>
          <span>
            <Field
              id='amount'
              name='amount'
              label='Amount'
              width={340}
              min={1}
              max={maxAmount()}
              disabled={voucher === null}
              border={true}
              icon={DOLLAR}
              type='number'
              onValidationCallback={(value) => setAmountInvalid(!value)}
              onChange={(value) => {
                setAmount(value ? parseFloat(value) : null)
              }}
              errorMsg={amountInvalid ? 'Invalid amount' : null}
            />
          </span>
        </div>

        <section
          className={classNames({
            'relative flex col bg-shade-1 rounded-3 p-4 mt-4': true,
            'color-notify-5': voucherInvalid || voucherNotFound,
          })}
          style={{ minHeight: 150 }}
        >
          {navigator.onLine === false && (
            <span className='color-notify-5 font-size-3'>
              Cannot continue because the device is offline
            </span>
          )}
          {validating && (
            <div className=''>
              <Loader />
            </div>
          )}
          {!validating && voucher !== null && (
            <>
              <Heading
                size={LARGE}
                className={classNames({ 'color-notify-5': voucherInvalid })}
              >
                {voucher.name}
              </Heading>
              <div className='flex row gap-6 mt-4'>
                <Record label='Status'>
                  {VOUCHER_STATUSES[voucher.status_key]}
                </Record>
                <Record label='Current Balance'>
                  {getFormattedPrice(voucher.balance)}
                </Record>
                <Record label='Expiration'>
                  {voucher.expires_at !== null && getFormattedDate(voucher.expires_at)}
                  {voucher.expires_at === null && (
                    <>No expiration</>
                  )}
                </Record>
              </div>
            </>
          )}
          {navigator.onLine &&
            !validating &&
            voucher === null &&
            !voucherNotFound && <span>Enter voucher code for validation</span>}
          {!validating && voucher === null && voucherNotFound && (
            <span>Voucher not found. Please check code and try again</span>
          )}
        </section>
      </Container>

      {(submitFailed || validateFailed) && (
        <Banner
          direction={COLUMN}
          animation={SLIDE_UP}
          theme={NOTIFY}
          shade={3}
          message='Oops! Something went wrong. Please check your connection and voucher details.'
        />
      )}

      {drawerError}
      <ButtonMenu direction={COLUMN} animation={SLIDE_UP} theme={theme}>
        <Button
          id='submit'
          shade={5}
          theme={theme}
          size={HUGE}
          onClick={() => submit()}
          disabled={submitButtonDisabled()}
        >
          {submitting && <Loader active={true} />}
          {!submitting && (
            <>
              Accept payment&nbsp;
              {amount && getFormattedPrice(amount)}
              {amount === null && getFormattedPrice(owed)}
            </>
          )}
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default VoucherPaymentForm

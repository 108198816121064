import React from 'react'

const OutletIcon = ({ outlet = {} }) => {
  return (
    <span
      className='flex justify-start'
      style={{ width: 60 }}
    >
      <div
        className='flex justify-center color-shade-0 align-items-center font-size-4 font-weight-3'
        style={{ background: 'hsl(0,0%,90%)', borderRadius: 12, height: 44, width: 44 }}
      >
        {outlet.name[0]}
      </div>
    </span>
  )
}

export default OutletIcon

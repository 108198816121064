import React from 'react'
import classNames from 'classnames'

import Headline from 'components/shared/Headline'
import LineItem from 'components/shared/LineItem'

const ContactLineItem = ({
  contact = {},
  gridTemplateColumns = '300px 200px 1fr 1fr 1fr',
  last = false,
}) => {
  return (
    <LineItem
      className={classNames({
        "bg-white pt-2 ps-3": true,
        "rounded-bottom-2": last === true,
      })}
      gridTemplateColumns={gridTemplateColumns}
    >
      <Headline
        className='font-size-2 p-2' charLimit={100}
      >
        {contact.name}
      </Headline>
      <span className='font-weight-2 font-size-2 p-2 break-word'>
        {contact.email}
      </span>
      <span className='font-weight-2 font-size-2 p-2 break-word'>
        {contact.mobilePhone}
      </span>
      <span className='font-weight-2 font-size-2 p-1 break-word'>
        {contact.phone}
      </span>
      <span className='font-weight-2 font-size-2 break-word'>
        {contact.department}
      </span>
    </LineItem>
  )
}

export default ContactLineItem

import { REGULAR_PRODUCT } from '../components/constants'
import getProductDrop from './getProductDrop'
import getProductPricingDrop from './getProductPricingDrop'
import getProductById from 'components/products/helpers/getProductById'

export default function getOrderItemDrops(getOrder, items, products) {
  if (items === undefined) return []

  return items.map((item) => {
    if (item.productId == undefined) return getOrderItemDrop(getOrder, item)
    return getOrderItemDrop(getOrder, {...item, ...getProductById(item.productId, products)})
  })
}

function getOrderItemDrop(getOrder, item) {
  return { 
    __data: {},
    "bookable?": true,
    bookable_event: {},
    bundle_items: [],
    "bundle_lead?": false,
    id: item.id,
    "in_bundle?": false,
    name: item.name,
    order: getOrder,
    pricing: getProductPricingDrop(item),
    product: getProductDrop(item),
    quantity: item.quantity,
    "reserved_product?": item.type === REGULAR_PRODUCT,
  }
}
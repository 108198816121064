import React, { useState } from 'react'

import USERS from 'data/dummy/users'
import STORES from 'data/dummy/stores'
import SHIFTS from 'data/dummy/shifts'

import Container from './shared/Container'
import Stage from './shared/Stage'

import EndShiftFlow from 'components/shifts/EndShiftFlow'
import ShiftAddUser from 'components/shifts/ShiftAddUser'
import ShiftStartBalance from 'components/shifts/StartShiftFlow/ShiftStartBalance'
import ShiftUser from 'components/shifts/StartShiftFlow/ShiftUser'

export default function Shifts() {
  return (
    <Container heading='12. Shifts'>
      <Stage
        height={700}
        heading='Shift user'
        subheading='Path: shifts/ShiftUser.jsx'
      >
        <ShiftUser
          className='shadow-3 rounded-3 overflow-hidden'
          users={USERS}
          onSetUser={() => null}
        />
      </Stage>
      <Stage
        height={700}
        heading='Shift start balance'
        subheading='Path: shifts/ShiftStartBalance.jsx'
      >
        <ShiftStartBalance
          className='shadow-3 rounded-3 overflow-hidden'
          outlet={STORES[1]}
          user={USERS[1]}
          onSetUser={() => null}
        />
      </Stage>
      <Stage
        height={700}
        heading='End shift flow'
        subheading='Path: shifts/EndShiftFlow.jsx'
      >
        <EndShiftFlow />
      </Stage>
      <Stage
        height={700}
        heading='Add user to shift'
        subheading='Path: shifts/ShiftAddUser.jsx'
      >
        <ShiftAddUser
          users={USERS}
          shift={SHIFTS[0]}
          onBack={() => null}
          onSubmit={() => null}
        />
      </Stage>
    </Container>
  )
}

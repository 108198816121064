import { USE_DUMMY_DATA } from 'constants/settings'
import USERS from 'data/dummy/users'

import authenticatedFetch from './authenticatedFetch'
import { dir, log } from 'dev/log'

export function fetchUsers(
  onSuccess,
  onError = () => alert('Error fetching users'),
) {
  log('Fetching users...')

  if (USE_DUMMY_DATA) return onSuccess(USERS)

  if (navigator.onLine) {
    authenticatedFetch('users',
      { method: 'GET' },
      // Success
      ({ users }) => {
        dir(users)
        onSuccess(users)
      },
      // Error
      () => onError()
    )
  }
}

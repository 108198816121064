import { REGULAR_PRODUCT } from "components/constants"

export default function createCartItem({
  id,
  product,
  taxes = [],
  taxInclusive = false,
  type = REGULAR_PRODUCT,
  amount = 0,
  quantity = 1,
}) {
  const commonAttributes = {
    id,
    type,
    taxes,
    productId: product.id,
    variable_pricing_amounts: product.variable_pricing_amounts || [],
    quantity: quantity,
    discount: null,
    serial_numbers: [],
  }

  if (type === REGULAR_PRODUCT) {
    return {
      ...product,
      ...commonAttributes,
      taxes: getTaxes(product, taxes, taxInclusive),
      requires_serial: product.has_serial_number,
    }
  } else {
    return {
      ...product,
      ...commonAttributes,
      price: amount,
      type
    }
  }
}

function getTaxes(product, taxes, taxInclusive) {
  const foundTaxes = []

  if (product.taxes) {
    product.taxes.forEach(id => {
      const tax = getTaxById(id, taxes)

      if (tax && taxInEffect(tax)) {
        foundTaxes.push({
          ...tax,
          taxInclusive: taxInclusive
        })
      }
    })
  }

  return foundTaxes
}

function getTaxById(id, taxes) {
  return id ? taxes.find(tax => tax.id === id) : null
}

function taxInEffect(tax) {
  return (
    (tax.effectiveFrom === null || tax.effectiveFrom <= currentDateUTC()) &&
    (tax.effectiveTo === null || tax.effectiveTo >= currentDateUTC())
  )
}

function currentDateUTC() {
  return new Date().toISOString().replace('T', ' ').substring(0, 10)
}

import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { NEUTRAL } from 'components/constants'

import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const View = ({
  animation,
  children,
  className,
  fixed = false,
  shade = 0,
  theme = NEUTRAL,
}) => {
  return (
    <motion.div
      id="View"
      {...getAnimation(animation)}
      className={classNames({
        'x-0 y-0 flex col overflow-hidden': true,
        'absolute': fixed === false,
        'fixed': fixed === true,
        [className]: className !== undefined,
        [getThemeStyleClass(theme, shade)]: theme,
      })}>
      {children}
    </motion.div>
  )
}

export default View

import { USE_DUMMY_DATA } from 'constants/settings'
import VOUCHERS from 'data/dummy/outlets'

import authenticatedFetch from './authenticatedFetch'

export function queryVoucher(
  code,
  onSuccess,
  onOffline,
  onError = () => alert('Error fetching voucher'),
) {
  if (USE_DUMMY_DATA) return onSuccess(VOUCHERS)

  if (navigator.onLine) {
    authenticatedFetch('vouchers/' + code,
      { method: 'GET' },
      // Success
      (json) => {
        onSuccess(json)
      },
      // Error
      (response) => onError(response.status)
    )
  } else onOffline()
}

import React from 'react'

import OrderAmountLineItem from 'components/order/OrderAmountLineItem'
import OrderTaxesBlock from 'components/order/OrderTaxesBlock'
import OrderDiscountLineItem from 'components/order/OrderDiscountLineItem'

const CartTotalsBlock = ({
  cartCount,
  discount,
  orderDiscount,
  subtotal,
  taxes,
  taxInclusive,
  total,
}) => {
  return (
    <div className='shade-5 ps-3 pe-2 flex col gap-1'>
      <OrderAmountLineItem
        label="Subtotal"
        amount={subtotal}
        dataAttributeName={{ 'data-subtotal': 'data-subtotal' }}
      />
      <OrderTaxesBlock taxInclusive={taxInclusive} taxes={taxes} />
      <OrderDiscountLineItem discount={discount} orderDiscount={orderDiscount} />
      <OrderAmountLineItem
        label={cartCount < 2 ? 'Total' : `Total (${cartCount} items)`}
        amount={total}
        dataAttributeName={{ 'data-order-total': 'data-order-total' }}
      />
    </div>
  )
}

export default CartTotalsBlock

import React from 'react'

import { SMALL, CLOSE, NEUTRAL, LAYBY, REGULAR_PRODUCT, SCALE_IN } from 'components/constants'

import Article from 'components/shared/Article'
import CartLineItem from 'components/cart/CartLineItem'
import Container from 'components/shared/Container'
import OrderAmountLineItem from 'components/order/OrderAmountLineItem'
import OrderDiscountLineItem from 'components/order/OrderDiscountLineItem'
import OrderPaymentsBlock from 'components/order/OrderPaymentsBlock'
import OrderTaxesBlock from 'components/order/OrderTaxesBlock'
import Record from 'components/shared/Record'
import Tag from 'components/shared/Tag'
import Icon from 'components/shared/Icon'

import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import getTotalQuantity from 'components/helpers/getTotalQuantity'

const CheckoutSummaryModal = ({
  withRefund = false,
  refunded = 0,
  maxHeight = 500,
  maxWidth = 800,
  store = {},
  order = {},
  animation = SCALE_IN,
  onClose,
}) => {
  const paid = getAmountPaid(order)
  const owed = getAmountOwed(order)
  const items = order.items.filter((item) => item.type === REGULAR_PRODUCT)
  const { subtotal, discount, total, taxes } = getPaymentData(order.items, store.taxInclusive)
  const cartCount = getTotalQuantity(items)

  return (
    <Article
      className='expand-x z-2'
      shade={0}
      shadow={3}
      rounded={3}
      theme={NEUTRAL}
      animation={animation}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
    >
      <Container maxWidth={800} scroll={true} gap={5} className='pe-6'>
        <button
          className='absolute translate-y--50 right-0 mr-6 flex align-items-center justify-center'
          onClick={onClose}
        >
          <Icon type={CLOSE} size={18} />
        </button>
        <section className='flex gap-6 mt-4'>
          <Record label={'Order total'} data-total>
            {getFormattedPrice(convertToAbsoluteAmountValue(order.total))}
            {order.orderType === LAYBY && (
              <Tag text='layby' theme={LAYBY} size={SMALL} />
            )}
          </Record>
          <Record label={'Amount paid'} data-paid>
            {getFormattedPrice(convertToAbsoluteAmountValue(paid))}
          </Record>
          <Record label={withRefund === true ? 'Amount to refund' : 'Amount owed'} data-owed>
            {getFormattedPrice(convertToAbsoluteAmountValue(owed))}
          </Record>
          {withRefund === true && (
            <Record label={'Amount refunded'} data-paid>
              {getFormattedPrice(convertToAbsoluteAmountValue(refunded))}
            </Record>
          )}
        </section>
        {order.payments.length > 0 && (
          <section>
            <h2 className='opacity-07 font-size-2 font-weight-3 me-2'>
              Payments
            </h2>
            <OrderPaymentsBlock
              payments={order.payments}
              showHeading={false}
            />
            <div className='flex col gap-1'>
              <OrderAmountLineItem 
                label="Subtotal"
                amount={subtotal - reservedItemsTotal}
                dataAttributeName={{'data-subtotal': 'data-subtotal'}}
              />
              <OrderTaxesBlock taxInclusive={store.taxInclusive} taxes={taxes} />
              <OrderDiscountLineItem discount={discount} />
              <OrderAmountLineItem
                label={cartCount < 2 ? 'Total' : `Total (${cartCount} items)`}
                amount={total}
                dataAttributeName={{'data-order-total': 'data-order-total'}}
              />
            </div>
          </section>
        )}
        <section>
          <h2 className='opacity-07 font-size-2 font-weight-3 mb-2'>
            Checkout items
          </h2>
          <ul className='flex col overflow-y-auto' style={{ maxHeight: 200 }}>
            {items.map((item, index) => (
              <CartLineItem
                isReturnItem={item.quantity < 0}
                showImage={false}
                key={index}
                cartItem={item}
              />
            ))}
          </ul>
        </section>
      </Container>
    </Article>
  )
}

export default CheckoutSummaryModal

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import ORDERS from 'data/dummy/orders'
import { BRAND, NEUTRAL } from 'components/constants'
import { SMALL } from 'components/constants'

import Button from 'components/shared/Button'
import Main from 'components/shared/Main'
import Overlay from 'components/shared/Overlay'
import View from 'components/shared/View'
import SidebarInitButton from 'components/navigation/SidebarInitButton'

import AccordionsDropdowns from 'components/styleguide/AccordionsDropdowns'
import Cart from 'components/styleguide/Cart'
import Checkout from 'components/styleguide/Checkout'
import InputsButtons from 'components/styleguide/ButtonsInputs'
import Nav from 'components/styleguide/shared/Nav'
import Order from 'components/styleguide/Order'
import OrderSearch from 'components/styleguide/OrderSearch'
import Products from 'components/styleguide/Products'
import RecentOrders from 'components/styleguide/RecentOrders'
import Themes from 'components/styleguide/Themes'
import UserComponents from 'components/styleguide/UserComponents'
import ViewLayouts from 'components/styleguide/ViewLayouts'
import Shifts from 'components/styleguide/Shifts'

import ResetViewParents from 'components/navigation/actions/ResetViewParents'
import SetViewId from 'components/navigation/actions/SetViewId'

export default function StyleGuide() {
  const dispatch = useDispatch()
  const [showNav, setShowNav] = useState(false)
  const [view, setView] = useState(12)

  return (
    <div
      className='fixed expand'
      style={{ transform: showNav ? 'translateX(var(--sc-nav-width))' : 'translateX(0)' }}
    >
      <nav
        className='border-right fixed overflow-y-auto flex col gap-4 expand p-3'
        style={{
          width: 'var(--sc-nav-width)',
          transform: 'translateX(calc(var(--sc-nav-width) * -1))'
        }}
      >
        <Button
          shade={2}
          theme={BRAND}
          size={SMALL}
          className='expand-x'
          onClick={() => {
            dispatch(ResetViewParents())
            dispatch(SetViewId(null))
          }}
        >
          Return to app
        </Button>
        <Nav
          onClick={(id) => {
            setShowNav(false)
            setView(id)}
          }
        />
      </nav>
      <View shade={0} theme={NEUTRAL}>
        <header>
          <SidebarInitButton onClick={() => setShowNav(true)} />
        </header>
        <Main className='relative p-3'>
          <section className='relative z-0 expand-x overflow-y-auto'>
            {view === 1 && <Themes />}
            {view === 2 && <InputsButtons />}
            {view === 3 && <AccordionsDropdowns />}
            {view === 4 && <ViewLayouts />}
            {view === 5 && <Order order={ORDERS[0]} orders={ORDERS} />}
            {view === 6 && <RecentOrders orders={ORDERS} />}
            {view === 7 && <OrderSearch orders={ORDERS} />}
            {view === 8 && <Cart order={ORDERS[0]} />}
            {view === 9 && <Checkout order={ORDERS[0]} />}
            {view === 10 && <Products />}
            {view === 11 && <UserComponents />}
            {view === 12 && <Shifts />}
          </section>
        </Main>
      </View>
      {showNav && <Overlay opacity={0} onClose={() => setShowNav(false)} />}
    </div>
  )
}

import React, { useEffect, useState } from 'react'
import { v4 as uuidv4 } from 'uuid'

import { BRAND, RESERVED_PRODUCTS, SLIDE_NEXT, COLUMN, SLIDE_UP, NOTIFY } from 'components/constants'
import { CARD, CARD_INTEGRATED, CASH, CHEQUE, BANK, TRANSFER, VOUCHER } from 'components/constants'
import { PRINTER_TYPES } from 'constants/settings'

import Banner from 'components/shared/Banner'
import BankTransferForm from './BankTransferForm'
import CardPaymentForm from './CardPaymentForm'
import CashPaymentForm from './CashPaymentForm'
import ChequeForm from './ChequeForm'
import TyroPaymentForm from './TyroPaymentForm'
import VoucherPaymentForm from './VoucherPaymentForm'

import isLaybyOrder from 'components/order/helpers/isLaybyOrder'
import createCartItem from 'components/checkout/helpers/createCartItem'
import useHandlePrinting from 'hooks/useHandlePrinting'

export default function CheckoutPaymentForm({
  type,
  owed,
  theme = BRAND,
  submitFailed = false,
  submitting = false,
  store = {},
  order = {},
  cashRounding = {},
  tyroSurchargeProduct = {},
  tippingProduct = {},
  syncingOrder = false,
  applyCashRounding = false,
  isRefund = false,
  refundAmount = 0,
  onSubmit,
}) {

  const [paymentId, setPaymentId] = useState(uuidv4())
  const amountOwedOrRefund = isRefund ? refundAmount : owed

  const {
    deviceError,
    isDeviceAvailable,
    executeOpenDrawer,
    drawer,
  } = useHandlePrinting()

  // We use receipt here since drawer is connected to the receipt printer
  const drawerAvailable = isDeviceAvailable(PRINTER_TYPES.RECEIPT)

  // We do this to make sure that it's always a fresh payment for every
  // payment type change. We've now introduced an immediate sync
  // and it is possible for an immediate backend sync to fail due to lost connection,
  // voucher validation error in backend and etc, we don't want to create or add the same payment
  // to the order. This can happen when a user retries a failed payment creation.
  useEffect(() => {
    setPaymentId(uuidv4())
  }, [type])

  function submitPayment({
    amountTendered,
    amountOwed = owed,
    paymentType,
    paymentDetails = null,
    roundingDifference,
    surcharge = null,
    tip = null,
    response = null
  }) {

    // Max is always the amount owed
    // See conversation in https://storeconnect.atlassian.net/browse/POS-463 for reference.
    let amount = amountTendered > amountOwed ? amountOwed : amountTendered

    // For refunds, let's store what's actually given out
    if (isRefund) {
      amount = amountTendered
    }

    const payment = {
      id: paymentId,
      // amountTendered is the amount that the customer has given us before
      // any change may be returned. This could be the same as the recorded
      // amount.
      amountTendered: isRefund ? -amountTendered : amountTendered,
      amount: isRefund ? -amount : amount,
      created: Date.now(),
      details: paymentDetails,
      type: paymentType,
      response: response
    }

    const adjustmentItems = [
      createAdjustmentLineItem(cashRounding, RESERVED_PRODUCTS.cash_rounding, roundingDifference),
      createAdjustmentLineItem(tyroSurchargeProduct, RESERVED_PRODUCTS.tyro_surcharge, surcharge),
      createAdjustmentLineItem(tippingProduct, RESERVED_PRODUCTS.tipping, tip)
    ].filter((item) => item)
    // Passing items makes sure that we will be setting updated line items
    onSubmit(
      payment,
      adjustmentItems
    )

    // Open drawer if needed
    if (triggerDrawerOpen(paymentType)) {
      executeOpenDrawer()
    }
  }

  function createAdjustmentLineItem(product, type, amount) {
    if (amount && amount !== 0) return createCartItem({
      id: uuidv4(),
      product: product,
      type: type,
      amount: amount,
    })
  }

  function triggerDrawerOpen(type) {
    const rule = drawer.openDrawerRules.find((rule) => rule.paymentMethod === type)

    return rule.openDrawer
  }

  function renderDrawerConnectionError(type) {
    const rule = drawer.openDrawerRules.find((rule) => rule.paymentMethod === type)

    if (!rule.openDrawer) return null
    if (deviceError === null && drawerAvailable) return null

    return (
      <Banner
        direction={COLUMN}
        animation={SLIDE_UP}
        theme={NOTIFY}
        shade={3}
        message={'Oops! Please check your drawer connection.'}
      />
    )
  }

  switch (type) {
    case CASH:
      return (
        <CashPaymentForm
          isRefund={isRefund}
          animation={SLIDE_NEXT}
          drawerError={renderDrawerConnectionError(CASH)}
          owed={amountOwedOrRefund}
          theme={theme}
          store={store}
          order={order}
          payments={order.payments}
          syncingOrder={syncingOrder}
          layby={isLaybyOrder(order)}
          applyRounding={applyCashRounding}
          onSubmitPayment={submitPayment}
        />
      )
    case TRANSFER:
      return (
        <BankTransferForm
          drawerError={renderDrawerConnectionError(BANK)}
          animation={SLIDE_NEXT}
          owed={amountOwedOrRefund}
          theme={theme}
          syncingOrder={syncingOrder}
          onSubmit={submitPayment}
        />
      )
    case CHEQUE:
      return (
        <ChequeForm
          drawerError={renderDrawerConnectionError(CHEQUE)}
          animation={SLIDE_NEXT}
          owed={amountOwedOrRefund}
          payments={order.payments}
          theme={theme}
          syncingOrder={syncingOrder}
          onSubmit={submitPayment}
        />
      )
    case VOUCHER:
      return (
        <VoucherPaymentForm
          drawerError={renderDrawerConnectionError(VOUCHER)}
          animation={SLIDE_NEXT}
          owed={amountOwedOrRefund}
          payments={order.payments}
          theme={theme}
          onSubmit={submitPayment}
          submitFailed={submitFailed}
          syncingOrder={syncingOrder}
          submitting={submitting}
        />
      )
    case CARD_INTEGRATED:
      return (
        <TyroPaymentForm
          drawerError={renderDrawerConnectionError(CARD)}
          isRefund={isRefund}
          animation={SLIDE_NEXT}
          owed={amountOwedOrRefund}
          theme={theme}
          payments={order.payments}
          layby={isLaybyOrder(order)}
          syncingOrder={syncingOrder}
          onSubmit={submitPayment}
        />
      )
    case CARD:
    default:
      return (
        <CardPaymentForm
          drawerError={renderDrawerConnectionError(CARD)}
          isRefund={isRefund}
          animation={SLIDE_NEXT}
          owed={amountOwedOrRefund}
          theme={theme}
          payments={order.payments}
          layby={isLaybyOrder(order)}
          syncingOrder={syncingOrder}
          onSubmit={submitPayment}
        />
      )
  }
}

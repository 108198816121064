import React from 'react'
import classNames from 'classnames'

import { SMALL, MEDIUM, LARGE } from 'components/constants'

const Record = ({
  className,
  icon = false,
  size = MEDIUM,
  showEmptyValue = false,
  children = null,
  hasLongValue = false,
  label,
  tag,
  theme,
  tagTheme,
  ...data
}) => {
  if (
    children === null &&
    showEmptyValue === false
  ) return null

  return (
    <span {...data}
      className={classNames({
        'truncate': !hasLongValue,
        'flex col pe-2': true,
        'gap-1px': size === SMALL,
        'gap-1': size === MEDIUM,
        [className]: className,
      })}>
      <span
        className={classNames({
          'opacity-07 font-weight-2': true,
          [getSupClasses()]: true
        })}>
        {label}
      </span>
      <span
        className={classNames({
          'flex align-items-center gap-2 font-weight-3': true,
          [getHeadlineClasses()]: true,
        })}
        style={showEmptyValue && children === null ? { opacity: 0.2 } : null}>
        {children}
        {showEmptyValue && children === null && <span>&nbsp;</span>}
      </span>
    </span>
  )

  function getSupClasses() {
    switch (size) {
      case SMALL:
        return 'font-size-1'
      case MEDIUM:
        return 'font-size-1'
      case LARGE:
        return 'font-size-2'
    }
  }

  function getHeadlineClasses() {
    switch (size) {
      case SMALL:
        return 'font-size-2'
      case MEDIUM:
        return 'font-size-4'
      case LARGE:
        return 'font-size-5'
    }
  }
}

export default Record

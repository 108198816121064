import { RESERVED_PRODUCTS, VIEWABLE_ORDER_ITEMS } from 'components/constants'

import getPaymentData from 'components/orders/helpers/getPaymentData'
import getTaxSubtotal from 'components/orders/helpers/getTaxSubtotal'
import getTaxTypes from 'components/orders/helpers/getTaxTypes'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import hasRefund from 'components/order/helpers/hasRefund'
import getTotalRefunded from 'components/orders/helpers/getTotalRefunded'
import getDiscountTotal from 'components/orders/helpers/getDiscountTotal'
import getAdjustmentByTypeTotal from 'components/orders/helpers/getAdjustmentByTypeTotal'
import getReservedItemsTotal from 'components/orders/helpers/getReservedItemsTotal'

function prepareOrderForReceiptPrinting(order) {
  const { subtotal, taxes } = getPaymentData(order.items)
  const reservedItemsTotal = getReservedItemsTotal(order.items)

  let taxTotal = 0
  getTaxTypes(taxes).map((taxType) => {
    taxTotal += getTaxSubtotal(taxes, taxType)
  })

  return {
    ...order,
    submitted: new Date(order.submitted),
    created: new Date(order.created),
    updated: new Date(order.updated),
    items: order.items.filter((item) => VIEWABLE_ORDER_ITEMS.includes(item.type)),
    subtotal: subtotal - reservedItemsTotal,
    cashRoundingTotal:getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.cash_rounding),
    tyroSurchargeTotal: getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.tyro_surchared),
    tippingTotal: getAdjustmentByTypeTotal(order.items, RESERVED_PRODUCTS.tipping),
    tax: taxTotal,
    discount: getDiscountTotal(order.items),
    balance: getAmountOwed(order),
    paid: getAmountPaid(order),
    hasRefund: hasRefund(order),
    refundedAmount: getTotalRefunded(order),
  }
}

export default prepareOrderForReceiptPrinting
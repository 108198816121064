import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { XLARGE, XXLARGE } from 'components/constants'
import { NEUTRAL, BRAND, LAYBY } from 'components/constants'
import { COLUMN } from 'components/constants'
import { SLIDE_UP } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import Form from 'components/shared/Form'

import isTouchScreen from 'components/helpers/isTouchScreen'

const OrderNotesModal = ({
  animation,
  innerAnimation,
  maxWidth,
  layby = false,
  notes = '',
  theme = BRAND,
  size = XXLARGE,
  shade = 0,
  rounded = 0,
  shadow = 2,
  onBack,
  onSubmit,
  zIndex,
}) => {
  const [value, setValue] = useState(notes)

  useEffect(() => {
    if (isTouchScreen() === false) document.getElementById('order-notes').focus()
  })

  return (
    <Form
      rounded={rounded}
      shadow={shadow}
      shade={shade}
      theme={NEUTRAL}
      animation={animation}
      maxWidth={maxWidth}
      className={classNames({ ['z-' + zIndex]: !!zIndex })}
      onSubmit={() => onSubmit(value)}
    >
      <ButtonMenu className='ps-3 pt-3'>
        <Button
          shade={0}
          rounded={4}
          padding={5}
          theme={theme}
          onClick={onBack}
        >
          Back
        </Button>
      </ButtonMenu>
      <Container className='pe-4' animation={innerAnimation}>
        <Heading size={size}>
          Order notes
        </Heading>
        <textarea
          className='bg-shade-1 border-x p-5 font-size-5 rounded-3 expand-x'
          style={{ appearance: 'none', outline: 'none' }}
          onChange={event => setValue(event.target.value)}
          defaultValue={value}
          rows={3}
          id='order-notes'
        ></textarea>
      </Container>
      <ButtonMenu direction={COLUMN}>
        <Button
          type='submit'
          rounded={0}
          animation={SLIDE_UP}
          size={size}
          theme={theme}
          shade={5}
          onBlur={() => onBack()}
        >
          Confirm
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default OrderNotesModal

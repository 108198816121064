import React, { forwardRef } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { NOTIFY, WARNING, BRAND } from 'components/constants'
import { SMALL, LARGE } from 'components/constants'

import Tag from 'components/shared/Tag'
import ProductImage from 'components/products/shared/ProductImage'

import getStockCount from 'components/products/helpers/getStockCount'
import getStockCountByOutlet from 'components/products/helpers/getStockCountByOutlet'
import getTruncatedText from 'components/helpers/getTruncatedText'
import getVariantCount from 'components/products/helpers/getVariantCount'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import getVariablePriceRange from 'components/products/helpers/getVariablePriceRange'
import hasVariablePricing from './helpers/hasVariablePricing'

const HEADLINE_CHAR_LIMIT = 30

const ProductCard = forwardRef((
  {
    product = {},
    variants = [],
    disabled = false,
    outlet = {},
    onClick,
  },
  ref
) => {
  const stockCount = getStockCount(product.stock)
  const outletStockCount = getStockCountByOutlet(outlet.id, product.stock)
  const hasStockLocally = stockCount > 0 && outletStockCount > 0
  const hasStockElsewhere = stockCount > 0 && outletStockCount === 0

  return (
    <motion.button
      ref={ref}
      type='button'
      data-product-card={product.id}
      onClick={(event) => onClick(event)}
      style={{ display: 'grid', gridTemplateRows: '1fr auto auto' }}
      className={classNames({
        'rounded-3 expand p-3 flex col gap-3 align-items-center': true,
        'bg-white hover:bg-shade-1 shadow-2 focus:outline': disabled === false,
        'bg-shade-1': disabled === true
      })}
    >
      <ProductImage size={LARGE} imageSrc={product.image} />
      <div
        className='flex col gap-1'
        style={{ opacity: disabled ? 0.5 : 1 }}>
        <strong className='font-size-2 font-weight-3'>
          {getTruncatedText(product.name, HEADLINE_CHAR_LIMIT)}
        </strong>

        {hasVariablePricing(product) && (
          <div className='opacity-06 font-size-1 font-weight-2'>
            {getVariablePriceRange(product.variable_pricing_amounts)}
          </div>
        )}
        {hasVariablePricing(product) === false && (
          <div className='opacity-06 font-size-1 font-weight-2'>
            {getFormattedPrice(product.price)}
          </div>
        )}
      </div>
      <div
        className='expand-x flex justify-space-between color-shade-4 font-size-2'
        style={{ opacity: disabled ? 0.5 : 1 }}>
        <span className='font-size-1 font-weight-2 opacity-07'>
          {getVariantCount(variants)}
        </span>
        {product.track_inventory === true && (
          <span>
            <Tag
              shade={3}
              size={SMALL}
              dataTag='stock-count'
              theme={getStockTheme()}
              text={outletStockCount + '/' + stockCount}
            />
          </span>
        )}
      </div>
    </motion.button>
  )

  function getStockTheme() {
    if (hasStockLocally) return BRAND
    if (hasStockElsewhere) return WARNING
    else return NOTIFY
  }
})

export default ProductCard

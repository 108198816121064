import getSearchStr from 'components/helpers/getSearchStr'

export default function getProductByBarcode(
  value = '',
  products = [],
  onSuccess,
  onError,
) {
  const barcode = getSearchStr(value)
  const product = products.find((product) => {
    if (product.barcode === undefined) return null
    if (product.variants && product.variants.length > 0) return null
    return getSearchStr(product.barcode) === barcode
  })

  product ? onSuccess(product) : onError()
}

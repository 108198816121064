import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'
import getTotalRefunded from 'components/orders/helpers/getTotalRefunded';
import getRefundAmount from 'components/orders/helpers/getRefundAmount';
import getAmountPaid from 'components/orders/helpers/getAmountPaid';

// Let's make sure to compare totals in a positive state to make things easier.
export default function isFullySettled(order = {}) {
  if (Object.keys(order).length === 0) return false

  // This makes sure that if there's a refund then it needs to be settled as well
  const refundSettled = convertToAbsoluteAmountValue(getTotalRefunded(order)) >= convertToAbsoluteAmountValue(getRefundAmount(order))

  // We handle payments and refunds separately
  if (order.total > 0) {
    return (getAmountPaid(order) >= convertToAbsoluteAmountValue(order.total)) && refundSettled
  } else {
    return refundSettled
  }
}
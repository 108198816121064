import React from 'react'

import { BRAND, NEUTRAL, LAYBY } from 'components/constants'
import Container from 'components/styleguide/shared/Container'
import Theme from 'components/styleguide/shared/Theme'

export default function Themes() {
  return (
    <Container>
      <section className='flex col gap-3'>
        <h1>Brand</h1>
        <Theme id={BRAND} shade={2} />
        <Theme id={BRAND} shade={3} />
        <Theme id={BRAND} shade={4} />
        <Theme id={BRAND} shade={5} />
        <Theme id={BRAND} shade={6} />
        <Theme id={BRAND} shade={7} />
      </section>

      <section className='flex col gap-3'>
        <h1>Layby</h1>
        <Theme id={LAYBY} shade={2} />
        <Theme id={LAYBY} shade={3} />
        <Theme id={LAYBY} shade={4} />
        <Theme id={LAYBY} shade={5} />
        <Theme id={LAYBY} shade={6} />
        <Theme id={LAYBY} shade={7} />
      </section>

      <section className='flex col gap-3'>
        <h1>Neutral</h1>
          <Theme id={NEUTRAL} shade={2} />
          <Theme id={NEUTRAL} shade={3} />
          <Theme id={NEUTRAL} shade={4} />
          <Theme id={NEUTRAL} shade={5} />
          <Theme id={NEUTRAL} shade={6} />
          <Theme id={NEUTRAL} shade={7} />
      </section>
    </Container>
  )
}

import React from 'react'

import { INCOMING, OUTGOING, REQUEST } from 'components/shipments/constants'

import OutletIcon from 'components/outlets/OutletIcon'
import getFormattedDate from 'components/helpers/getFormattedDate'

const ShipmentOutletTransfer = ({
  shipment = {},
  type = null,
}) => {
  const outlet = getOutlet(shipment)

  return (
    <div
      className='grid'
      style={{ gridTemplateColumns: 'auto 1fr 1fr' }}
    >
      <OutletIcon outlet={type === INCOMING ? shipment.origin : shipment.destination }/>
      <span className='flex col gap-1'>
        <div className='font-size-2 font-weight-3'>
          {outlet.name}
        </div>
        <div className='font-size-1 font-weight-2 color-shade-4'>
          {(type === INCOMING || type === REQUEST) && 'to current outlet'}
          {(type === OUTGOING) && 'from current outlet'}
        </div>
      </span>
      <span className='font-size-1 font-weight-2 color-shade-4'>
        {getFormattedDate(shipment.created)}
      </span>
    </div>
  )

  function getOutlet(shipment) {
    switch (type) {
      case OUTGOING:
      case REQUEST:
        return shipment.destination
      case INCOMING:
        return shipment.origin
    }
  }
}

export default ShipmentOutletTransfer

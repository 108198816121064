import { USE_DUMMY_DATA } from 'constants/settings'
import SHIFTS from 'data/dummy/shifts'

import authenticatedFetch from './authenticatedFetch'
import { log } from 'dev/log'

export function fetchShifts(
  register = {},
  onSuccess,
  onError = () => alert('Error fetching shifts')
) {
  log('Fetching shifts...')

   if (USE_DUMMY_DATA) return onSuccess(SHIFTS)

  if (navigator.onLine) {
    authenticatedFetch(
      'registers/' + register.id + '/shifts',
      { method: 'GET' },
      // Success
      ({ shifts }) => onSuccess(shifts),
      // Error
      () => onError()
    )
  }
}

import React from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Article = ({
  animation,
  children,
  className,
  id,
  gridTemplateRows,
  gridTemplateColumns,
  rounded = 0,
  minWidth,
  maxWidth,
  maxHeight,
  shade,
  style,
  scroll = false,
  shadow = 0,
  theme,
}) => {
  return (
    <motion.article {...getAnimation(animation)}
      id={id}
      className={classNames({
        'relative grid': true,
        'overflow-y-auto': scroll === true,
        ['rounded-' + rounded]: rounded > 0,
        ['shadow-' + shadow]: shadow > 0,
        [className]: className,
        [getThemeStyleClass(theme, shade)]: theme,
      })}
      style={{
        minWidth,
        maxWidth,
        maxHeight,
        gridTemplateColumns,
        gridTemplateRows,
        ...style,
      }}>
      {children}
    </motion.article>
  )
}

export default Article

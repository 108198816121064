// Views

export const ORDER = 'ORDER'

export const ORDERS = 'ORDERS'

export const ORDER_NOTE = 'ORDER_NOTE'

export const ORDER_PAYMENTS = 'ORDER_PAYMENTS'

export const SERIALS = 'SERIALS'

export const NOTES = 'NOTES'

export const ORDER_ACTIONS = 'ORDER_ACTIONS'

// Actions

export const ADD_OR_UPDATE_ORDER = 'ADD_OR_UPDATE_ORDER'

export const REMOVE_ORDER = 'REMOVE_ORDER'

export const SET_ORDER = 'SET_ORDER'

export const RESET_ORDER = 'RESET_ORDER'

export const SET_ORDERS = 'SET_ORDERS'

export const MARK_ORDER_SYNC = 'MARK_ORDER_SYNC'

// Order status

export const LAYBY = 'LAYBY'

export const PARKED = 'PARKED'

export const COMPLETE = 'COMPLETE'

export const INCOMPLETE = 'INCOMPLETE'

export const HOLD = 'HOLD'

export const ORDER_TYPES = [
  {
    id: 1,
    headline: COMPLETE.toLowerCase(),
  },
  {
    id: 2,
    headline: LAYBY.toLowerCase(),
  },
  {
    id: 3,
    headline: PARKED.toLowerCase(),
  },
]

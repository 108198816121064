import React, { useEffect } from 'react'

const Wrapper = ({ children, offset = false }) => (
  <div
    className='fixed expand'
    style={{ transform: offset ? 'translateX(var(--sc-nav-width))' : 'translateX(0)' }}
  >
    {children}
  </div>
)

export default Wrapper

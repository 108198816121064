import React from 'react'

import { SMALL } from 'constants/size'
import { LIST } from 'components/constants'

import Button from 'components/shared/Button'
import Icon from 'components/shared/Icon'

const SidebarInitButton = ({ shade, theme, onClick }) => {
  return (
    <Button
      id='sidebar-init'
      className='gap-2'
      padding={2}
      size={SMALL}
      theme={theme}
      shade={shade}
      onClick={() => onClick()}
    >
      <Icon type={LIST} size={"2rem"} />
    </Button>
  )
}

export default SidebarInitButton

import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import getAnimation from 'helpers/getAnimation'
import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

/**
 * This component renders a <main> element with optional
 * settings to control overflow, container width, and
 * other standard layout properties.
 */

const Main = ({
  children,
  gridTemplateRows,
  gridTemplateColumns,
  theme,
  shade,
  scroll = false,
  topbar = true,
  container,
  className,
  animation
}) => {
  return (
    <motion.main
      className={classNames({
        [className]: className,
        'container': container,
        'container-skinny': container === 'skinny',
        'container-capsule': container === 'capsule',
        'overflow-y-auto overflow-x-hidden': scroll === true,
        'height-minus-topbar': topbar,
        'expand': topbar === false,
        'grid': gridTemplateRows || gridTemplateColumns,
        'flex col': !gridTemplateRows && !gridTemplateColumns,
        [getThemeStyleClass(theme, shade)]: theme,
      })}
      style={gridTemplate()}
      {...getAnimation(animation)}>
      {children}
    </motion.main>
  )

  function gridTemplate() {
    if (gridTemplateRows || gridTemplateColumns)
      return {
        gridTemplateRows: gridTemplateRows || '1fr',
        gridTemplateColumns: gridTemplateColumns || '1fr'
      }
  }
}

export default Main

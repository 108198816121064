import authenticatedFetch from './authenticatedFetch'
import getLocalCacheVersions from 'data/helpers/getLocalCacheVersions'
import setLocalCacheVersions from 'data/helpers/setLocalCacheVersions'

export function fetchCacheVersions(resource, onVersionsDiff, setOnline) {

  if (navigator.onLine) {
    authenticatedFetch(
      'cache_version',
      { method: 'GET' },
      (serverVersions) => {
        const clientVersions = getLocalCacheVersions()
        if (setOnline) setOnline(true)

        if (JSON.stringify(clientVersions[resource]) !== JSON.stringify(serverVersions[resource])) {
          onVersionsDiff()
          setLocalCacheVersions({...clientVersions, [resource]: serverVersions[resource]})
        }
      },
      () => { if (setOnline) setOnline(false) }
    )
  }
}

import React from 'react'
import { createRoot } from 'react-dom/client'
import { Provider } from 'react-redux'
import * as serviceWorker from 'services/serviceWorker'

import INITIAL_STATE from 'data/initialState'
import store from 'data/store'
import App from 'components/app'
import 'stylesheets/app.scss'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <Provider store={store(INITIAL_STATE)}>
    <App />
  </Provider>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.register()

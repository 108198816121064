import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { READY_FOR_PICKUP, REQUESTED, PICKED, COMPLETE } from 'components/fulfillments/constants'
import { columns } from 'components/fulfillments/constants'

import NumberPicker from 'components/shared/NumberPicker'
import QuantityPicker from 'components/shared/QuantityPicker'

const FulfillmentItemsBlock = ({
  className,
  gridTemplateColumns = '2fr 1fr 2fr 1fr',
  orderItems = {},
  type,
  status,
  fulfillmentUpdate,
  orderOwing
}) => {

  const [productCount, setProductCount] = useState({})
  const [resetTrigger, setResetTrigger] = useState(false)

  function handleCount(value, item) {
    setProductCount(prevState => {
      let object = {...prevState}
      object[item.id] = value
      fulfillmentUpdate(object)
      return { ...object }
    });
  }

  function productDeficit(item) {
    let deficit
    if (status == REQUESTED) { deficit = item.quantity - productCount[item.id] }
    else if (status == PICKED) { deficit = item.quantityPicked - productCount[item.id] }
    else if (status == READY_FOR_PICKUP) { deficit = item.quantityPacked - productCount[item.id] }
    else if (status == COMPLETE) { deficit = item.quantity - item.quantityReleased }
    return deficit
  }

  useEffect(() => {
    setProductCount(prevState => {
      let object = {...prevState}
      for (const property in object) {
        object[property] = 0;
      }
      return { ...object }
    });
    setResetTrigger(!resetTrigger)
  },[status])

  var renderLineItem = (item, index) => {
    const width = (item.quantity < 999) ? 40 : 50,
          objectProp = {
            REQUESTED: 'quantity',
            PICKED: 'quantityPicked',
            READY_FOR_PICKUP: 'quantityPacked',
          }
    return (
      <div key={item.id} className="grid" style={{ gridTemplateColumns: gridTemplateColumns, gridGap: "5px", marginBottom: "15px" }}>
        <span className="flex align-items-center">{item.name}</span>
        <span className="flex align-items-center justify-center">
          {item[objectProp[status] || 'quantity']}
        </span>
        
        <span className="flex align-items-center justify-center">
          {status !== COMPLETE &&
            <NumberPicker 
              max={item[objectProp[status]]}
              disabled={orderOwing}
              width={width} 
              reset={resetTrigger}
              tabIndex={index+1}
              focusOnLoad={index === 0}
              handleCount={(val) => handleCount(val, item)} 
            />
          }
          {status === COMPLETE && item.quantityReleased}
        </span>
        <span className="flex align-items-center justify-center">
          <span className={(productDeficit(item) > 0) ? "theme-notify-3 ps-2 pe-1 rounded-2" : ""}>
            {JSON.stringify(productDeficit(item))}
          </span>
        </span>
      </div>
    )
  }
  return (
    <div className={classNames({
      'sc-receipt': true,
      [className]: className,
    })}>
      <ul data-order-item id="order-items" className='overflow-hidden'>
        <header
          className={classNames({
            'grid pt-4 pb-3 ps-4': true,
            'border-bottom color-shade-5 font-size-2 font-weight-2': true,
          })}
          style={{ gridTemplateColumns: gridTemplateColumns }}>
          <span>{columns[type][status][0]}</span>
          <span className="text-center">{columns[type][status][1]}</span>
          <span className="text-center">{columns[type][status][2]}</span>
          <span className="text-center">{columns[type][status][3]}</span>
        </header>
        <form className='fulfillment-line-items grid pt-2 pb-2 ps-4'>
          {orderItems.map((item, index) => {
            return renderLineItem(item, index);
          })}
        </form>
      </ul>
    </div>
  )
}

export default FulfillmentItemsBlock
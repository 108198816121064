import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AUTHENTICATION, DASHBOARD, REGISTER } from 'constants/views'
import { START_SHIFT } from 'components/shifts/constants'
import { CHECKOUT } from 'components/checkout/constants'

import Online from 'components/online'
import Container from 'components/navigation/Container'
import Error from 'components/system/Error'
import Overlay from 'components/shared/Overlay'
import Sidebar from 'components/navigation/Sidebar'
import Views from 'components/navigation/Views'

import HideSidebar from 'components/navigation/actions/HideSidebar'
import SetViewId from 'components/navigation/actions/SetViewId'

import preventBrowserBack from 'helpers/preventBrowserBack'
import getLocalStorage from 'data/helpers/getLocalStorage'
import isAuthenticated from 'components/authentication/helpers/isAuthenticated'

export default function () {
  const dispatch = useDispatch()
  const cartItems = useSelector((state) => state.cartItems)
  const error = useSelector(state => state.error)
  const register = useSelector((state) => state.register)
  const shift = useSelector((state) => state.shift)
  const sidebar = useSelector(state => state.sidebar)
  const viewId = useSelector(state => state.viewId)

  useEffect(() => preventBrowserBack(), [])

  if (error) return <Error />

  return (
    <Online>
      <Container offset={sidebar}>
        <Sidebar
          viewId={viewId}
          shift={shift}
          onHide={() => dispatch(HideSidebar())}
          onSetView={(viewId) => dispatch(SetViewId(viewId))}
        />
        <Views viewId={viewId || getInitialViewId()} />
        {sidebar && <Overlay onClose={() => dispatch(HideSidebar())} />}
      </Container>
    </Online>
  )

  function getInitialViewId() {
    const storedViewId = getLocalStorage('viewId')
    const authenticated = isAuthenticated()

    if (authenticated === false) return AUTHENTICATION
    if (Object.keys(register).length === 0) return REGISTER
    if (Object.keys(shift).length === 0) return START_SHIFT

    switch (storedViewId) {
      case CHECKOUT:
        if (cartItems.length === 0) return DASHBOARD
        return CHECKOUT
      default:
        return storedViewId || DASHBOARD
    }
  }
}

import React, { useState, Fragment } from 'react'
import classNames from 'classnames'

import Form from 'components/shared/Form'
import Container from 'components/shared/Container'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Checkbox from 'components/shared/Checkbox'

import { BRAND, MEDIUM, NOTIFY, SUCCESS } from 'components/constants'
import { SLIDE_NEXT } from 'components/constants'
import { READY_FOR_PICKUP, REQUESTED, PICKED, modalCopy } from 'components/fulfillments/constants'

import getFriendlyName from 'components/fulfillments/helpers/getFriendlyName'

const FulfillmentSummaryModal = ({
  width = 620,
  rounded = 4,
  theme = BRAND,
  onConfirm,
  onClose,
  zIndex = 5,
  data=[],
  onCloseFulfillment,
  status,
  type
}) => {
  let collectionHasDeficits = false,
      confirmedDeficits = {},
      gridTemplateColumns = '2fr 1fr 1fr'
  const objectProp = {
          REQUESTED: 'quantity',
          PICKED: 'quantityPicked',
          READY_FOR_PICKUP: 'quantityPacked',
        }

  const [modalHasConfirmed, setModalHasConfirmed] = useState(false);

  function checkDeficits() {
    data.forEach((item) => {
      if (status == REQUESTED) { item.deficit = item.quantity - item.quantityPicked }
      else if (status == PICKED) { item.deficit = item.quantityPicked - item.quantityPacked }
      else if (status == READY_FOR_PICKUP) { item.deficit = item.quantityPacked - item.quantityReleased }
      if (item.deficit > 0) {
        confirmedDeficits[item.id] = false;
        if (!collectionHasDeficits) {
          collectionHasDeficits = true
          gridTemplateColumns = "2fr 1fr 1fr 1fr"
        }
      }
    });
  }
  checkDeficits();

  const [buttonActive, setButtonActive] = useState(collectionHasDeficits ? false : true)

  function handleCheck(val, item) {
    confirmedDeficits[item.id] = val;
    if(Object.keys(confirmedDeficits).every((k) => confirmedDeficits[k]) && !buttonActive) {
      setButtonActive(true)
    } else if (buttonActive) {
      setButtonActive(false)
    }
  }

  function handleConfirm () {
    onConfirm()
    setModalHasConfirmed(true)
  }

  function renderLineItem(item) {
    return (
      <Fragment key={item.code}>
        <span className="pe-2" key={item.name} style={{gridColumn: "1/2"}}>{item.name}</span>
        <span className="text-center pe-2" style={{gridColumn: "2/3"}}>
          {item[objectProp[status]]}
        </span>
        <span className="text-center pe-2" style={{gridColumn: "3/4"}}>
          <span className={(item.deficit > 0) ? "theme-notify-3 ps-2 pe-1 rounded-2" : ""}>
            {item.deficit || 0}
          </span>
        </span>
        {item.deficit > 0 &&
          <span className="text-center pe-2" style={{gridColumn: "4/5"}}>
            <Checkbox
              checked={false}
              onChange={(val) => handleCheck(val, item)}
            />
          </span>
        }
      </Fragment>
    )
  }
  return (
    <>
      {!modalHasConfirmed && (
        <Form
          maxWidth={width}
          shade={0}
          shadow={4}
          rounded={rounded}
          theme={theme}
          className={classNames({ ['z-' + zIndex]: !!zIndex })}
        >
          <Container
            animation={SLIDE_NEXT}
            className='p-5'
          >
            <p className="mb-4 font-size-3" dangerouslySetInnerHTML={{__html: collectionHasDeficits ? (modalCopy[status]['inDeficit']) : modalCopy[status]['allFilled']}} />
            <div className="overflow-y-scroll grid" style={{maxHeight: "40dvh", gridTemplateColumns: gridTemplateColumns }}>
              <span className="" style={{gridColumn: "1/2"}}>Item</span>
              <span className="text-center" style={{gridColumn: "2/3"}}>On Hand</span>
              <span className="text-center" style={{gridColumn: "3/4"}}>Missing</span>
              {collectionHasDeficits &&
                <span className="text-center" style={{gridColumn: "4/5"}}>Confirm</span>
              }
              <hr className={'border-bottom color-shade-5 font-size-2 font-weight-2 expand-x'} style={{gridColumn: collectionHasDeficits ? "1/5" : "1/4", borderWidth: "0 0 1px 0"}} />
              {data.map((item) => renderLineItem(item))}
            </div>
          </Container>
          <ButtonMenu className='flex mt-4'>
            <Button
              shade={3}
              rounded={0}
              disabled={!buttonActive}
              theme={theme}
              onClick={() => handleConfirm()}
              size={MEDIUM}
              className={'grow'}
            >
              Confirm
            </Button>
            <Button
              shade={2}
              rounded={0}
              theme={NOTIFY}
              onClick={() => onClose()}
              size={MEDIUM}
              className={'grow'}
            >
              Cancel
            </Button>
          </ButtonMenu>
        </Form>
      )}
      {modalHasConfirmed && (
        <Form
          maxWidth={width}
          shade={0}
          shadow={4}
          rounded={rounded}
          theme={theme}
          className={classNames({ ['z-' + zIndex]: !!zIndex })}
          fullWidth={true}
        >
          <Container
            animation={SLIDE_NEXT}
            className='p-5'
          >
            <div className='text-center'>
              <h3>This record has been updated successfully</h3>
              <p>Would you like to continue?</p>
            </div>
          </Container>
          
          <ButtonMenu className='flex'>
            <Button
              shade={3}
              rounded={0}
              theme={SUCCESS}
              onClick={() => onClose()}
              size={MEDIUM}
              style={{width: "50%"}}
            >
              Continue with<br/>{getFriendlyName(type)}
            </Button>
            <Button
              shade={3}
              rounded={0}
              disabled={!buttonActive}
              theme={theme}
              onClick={() => onCloseFulfillment()}
              size={MEDIUM}
              className={''}
              style={{width: "50%"}}
            >
              Close and<br/>return to hub
            </Button>
          </ButtonMenu>
        </Form>
      )}
    </>
  )
}

export default FulfillmentSummaryModal
import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

const LineItem = ({
  center = true,
  children,
  className,
  gap = 0,
  gridTemplateColumns,
  justify = 'space-between',
}) => {
  return (
    <motion.div
      style={{ gridTemplateColumns }}
      className={classNames({
        'expand-x regular text-left': true,
        'align-items-center': center === true,
        'grid': gridTemplateColumns !== undefined,
        'flex': gridTemplateColumns === undefined,
        ['gap-' + gap]: gap > 0,
        ['justify-' + justify]: true,
        [className]: className,
      })}>
      {children}
    </motion.div>
  )
}

export default LineItem

import { log } from 'dev/log'

export default async function authenticatedFetch(
  url = '',
  options = {},
  onSuccess,
  onError = () => alert('Error fetching data'),
  onOffline
) {
  const headers = getAuthorizedHeaders()

  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), 15000)
  try {
    const response = await fetch('/v1/' + url, { ...options, headers, signal: controller.signal})
    clearTimeout(id)

    if (response.ok) {
      const json = await response.json()
      if (json.token) setToken(json.token)
      onSuccess(json)
    } else if (response.status === 429) {
      sleep(Math.random() * 10000).then(() => {
        authenticatedFetch(url, options, onSuccess, onError)
      })
      return null
    } else onError(response)
  } catch(error) {
    log(`Timed out waiting for ${url}`)
    if (onOffline) onOffline()
  }
}

function getAuthorizedHeaders() {
  return {
    'Content-Type': 'application/json',
    'Authorization': getToken()
  }
}

export function getToken() {
  return window.localStorage.getItem('apiToken')
}

export function setToken(token) {
  window.localStorage.setItem('apiToken', token)
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms))
}
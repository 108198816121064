import { USE_DUMMY_DATA } from 'constants/settings'
import STORES from 'data/dummy/stores'

import authenticatedFetch from './authenticatedFetch'
import { dir, log } from 'dev/log'

export function fetchStore(
  onSuccess,
  onError = () => alert('Error fetching stores'),
) {
  log('Fetching store...')

  if (USE_DUMMY_DATA) return onSuccess(STORES[0])

  if (navigator.onLine) {
    authenticatedFetch('stores',
      { method: 'GET' },
      // Success
      ({ stores }) => {
        const store = stores.find((store) => store.current)

        dir(store)
        onSuccess(store)
      },
      // Error
      () => onError()
    )
  }
  // Offline
  else onError()
}

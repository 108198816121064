export default function calculateTaxes(item) {
  const foundTaxes = []

  if (item.taxes) {
    item.taxes.forEach((tax) => {
      const unitAmount = calculateTax(
        item.price - item.discount / item.quantity,
        tax.rate,
        tax.taxInclusive
      )
      foundTaxes.push({
        ...tax,
        amount: unitAmount,
        total: unitAmount * item.quantity,
      })
    })
  }

  return foundTaxes
}

function calculateTax(price, taxRate, taxInclusive) {
  if (taxInclusive) {
    return (price * taxRate) / (100 + taxRate)
  } else {
    return (price * taxRate) / 100
  }
}


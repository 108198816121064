import React from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'

import { BRAND, NEUTRAL } from 'components/constants'
import { LARGE, XLARGE, XXLARGE, HUGE } from 'components/constants'

import getAnimation from 'helpers/getAnimation'

const Heading = ({
  animation,
  children,
  className,
  theme = NEUTRAL,
  size = HUGE,
}) => {
  return (
    <motion.h1
      {...getAnimation(animation)}
      className={classNames({
        [getFontSize()]: size,
        [getTheme()]: theme,
        [className]: className,
      })}>
      {children}
    </motion.h1>
  )

  function getFontSize() {
    switch (size) {
      case LARGE:
        return 'font-size-5'
      case XLARGE:
        return 'font-size-7'
      case XXLARGE:
        return 'font-size-8'
      case HUGE:
        return 'font-size-9'
    }
  }

  function getTheme() {
    switch (theme) {
      case BRAND:
        return 'color-shade-0'
      case NEUTRAL:
        return 'color-shade-7'
    }
  }
}

export default Heading

import { UPDATE_DRAWER } from 'constants/settings'

export default function UpdateDrawer(drawer) {
  return {
    type: UPDATE_DRAWER,
    drawer,
  }
}



export default [
  {
    id: 1,
    name: 'Supplier A',
    phone: '0412345678',
    contacts: [{
      name: 'Contact A',
    }]
  },
  {
    id: 2,
    name: 'Supplier B',
    phone: '0412345678',
  },
  {
    id: 3,
    name: 'Supplier C',
    phone: '0412345678',
  },
  {
    id: 4,
    name: 'Supplier D',
    phone: '0412345678',
  },
  {
    id: 5,
    name: 'Supplier E',
    phone: '0412345678',
  }
]

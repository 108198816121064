import { CURRENCY_CODE } from 'constants/settings'

export default function getCurrencySymbol() {
  const currency = window.localStorage.getItem('currency')

  switch (currency) {
    case 'AUD':
      // Intl.NumberFormat will show A$ for AUD. In this case
      // we want to show the generic $ symbol.
      return CURRENCY_CODE
    default:
      return currency || CURRENCY_CODE
  }
}

import React from 'react'

import ProductLineItem from './ProductLineItem'

const ProductVariantSelector = ({
  name,
  variants = [],
  onDefocus,
  onSelect,
}) => {

  function handleSelect(product) {
    if (onSelect) onSelect(product)
  }

  function handleBlur(index) {
    if (index === variants.length - 1 && onDefocus) onDefocus()
  }

  return (
    <>
      {variants.map((variant, index) => {
        return (
          <ProductLineItem
            key={variant.id}
            product={variant}
            name={name}
            onBlur={() => handleBlur(index)}
            onSelect={(product) => handleSelect(product)}
          />
        )
      })}
    </>
  )
}

export default ProductVariantSelector

import React from 'react'

import { SMALL } from 'components/constants'
import { FADE } from 'components/constants'
import Button from 'components/shared/Button'

const SubtleButton = ({
  children,
  theme,
  onClick,
}) => {
  return (
    <Button
      shade={0}
      rounded={4}
      animation={FADE}
      size={SMALL}
      theme={theme}
      onClick={() => onClick()}
    >
      {children}
    </Button>
  )
}

export default SubtleButton

export const PRINTABLE_LABELS = 'PRINTABLE_LABELS'
export const SET_PRINTABLE_LABELS = 'SET_PRINTABLE_LABELS'

export const SIZE_UNITS = {
  INCHES: 'inches',
  MILLIMETERS: 'millimeters',
  INCHES_SHORT: 'in',
  MILLIMETERS_SHORT: 'mm',
}

export const MILLIMETERS_IN_AN_INCH = 25.4
 
export const LABEL_PRINT_DENSITY = {
  152: '6dpmm',
  203: '8dpmm',
  300: '12dpmm',
  600: '24dpmm'
}
import React from 'react'

import { COLUMN } from 'components/constants'

import ButtonMenu from 'components/shared/ButtonMenu'
import RegisterLineItem from './RegisterLineItem'

const RegisterButtonMenu = ({
  registers,
  theme,
  style,
  rounded = 5,
  shade,
  onClick
}) => {
  return (
    <ButtonMenu
      className='gap-2px overflow-y-auto'
      shade={shade}
      theme={theme}
      rounded={rounded}
      style={style}
      direction={COLUMN}
    >
      {registers
        .map((register, index) => (
          <RegisterLineItem
            key={index}
            register={register}
            onClick={() => onClick(register)}
          />
        ))}
    </ButtonMenu>
  )
}

export default RegisterButtonMenu

import { SET_USERS } from 'components/users/constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_USERS:
      window.localStorage.setItem('users', JSON.stringify(action.users))
      return action.users
    default:
      return state
  }
}

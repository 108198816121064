import { SET_ORDER, RESET_ORDER } from 'components/orders/constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_ORDER:
      setLocalStorage(action.order)
      return action.order
    case RESET_ORDER:
      setLocalStorage({})
      return {}
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('order', JSON.stringify(payload))
}

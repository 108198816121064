import React from 'react'

import Article from 'components/shared/Article'
import Main from 'components/shared/Main'
import OrderLineItem from 'components/orders/shared/OrderLineItem'
import Empty from 'components/shared/Empty'

const OrderList = ({
  withFilters = false,
  orders,
  onSetOrder,
  animation,
}) => {
  return (
    <Main
      scroll={true}
      animation={animation}
    >
      <Article className='ps-3 pb-4'>
        {orders.length === 0 && withFilters && (
          <div className="color-shade-3">
            <Empty message='No filtered results found' />
          </div>
        )}
        {orders.length === 0&& !withFilters && (
          <div className="color-shade-3">
            <Empty message='No recent orders' />
          </div>
        )}
        {orders.length > 0 && (
          orders.map((order, index) => (
            <OrderLineItem
              key={order.id}
              order={order}
              customer={order.customer}
              onClick={() => onSetOrder(order)}
              first={index === 0}
              last={index === orders.length - 1}
            />
          ))
        )}
      </Article>
    </Main>
  )
}

export default OrderList
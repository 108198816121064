import React from 'react'
import classNames from 'classnames'

import { MEDIUM } from 'components/constants'
import { BRAND, NEUTRAL, COLUMN } from 'components/constants'
import { NEXT } from 'components/constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import OrderLineItem from 'components/orders/shared/OrderLineItem'

import getStoreById from 'components/stores/helpers/getStoreById'
import getCustomerById from 'components/customers/helpers/getCustomerById'
import getFormattedDate from 'components/helpers/getFormattedDate'
import getTruncatedText from 'components/helpers/getTruncatedText'

const OrderRecords = ({
  animation = NEXT,
  theme = BRAND,
  shadow = 0,
  rounded = 0,
  customers = [],
  stores = [],
  params = {},
  className,
  onBack,
  onSetOrder,
  orders = [],
  width,
  height,
}) => {
  const customer = getCustomerById(params.customerId, customers)
  const store = getStoreById(params.storeId, stores)

  return (
    <Article
      gridTemplateRows='auto auto 1fr auto'
      className={classNames({
        'overflow-hidden bg-gradient expand z-3': true,
        [className]: className,
      })}
      shade={0}
      theme={NEUTRAL}
      rounded={rounded}
      shadow={shadow}
      maxWidth={width}
      maxHeight={height}
      animation={animation}
    >
      <ButtonMenu
        shade={0}
        theme={NEUTRAL}
        className='gap-1px p-4 justify-space-between align-items-center'
      >
        <Button
          shade={0}
          rounded={4}
          size={MEDIUM}
          theme={theme}
          onClick={onBack}
          style={{ width: 100 }}>
          Back
        </Button>
        <span className='grow font-weight-3 font-size-7 text-center'>
          Order Records
        </span>
        <span style={{ width: 100 }}></span>
      </ButtonMenu>
      <ButtonMenu className='ps-5 gap-2'>
        {params.startDate && params.endDate && (
          <button className='bg-brand-3 font-size-2 font-weight-3 rounded-2 ps-3 pe-2' type='text'>
            {getFormattedDate(params.startDate)} - {getFormattedDate(params.endDate)}
          </button>
        )}
        {params.startDate && !params.endDate && (
          <button className='bg-brand-3 font-size-2 font-weight-3 rounded-2 ps-3 pe-2' type='text'>
            {getFormattedDate(params.startDate)}
          </button>
        )}
        {customer !== null && customers.length > 0 && (
          <button
            type='text'
            className='bg-brand-3 font-size-2 font-weight-3 shadow-3 rounded-2 ps-3 pe-2'>
            {getTruncatedText(`${customer.firstName} ${customer.lastName}`, 26)}
          </button>
        )}
        {store !== null && stores.length > 0 && (
          <button
            type='text'
            className='bg-brand-3 font-size-2 font-weight-3 shadow-3 rounded-2 ps-3 pe-2'>
            {getTruncatedText(getStoreById(params.storeId, stores).name, 26)}
          </button>
        )}
      </ButtonMenu>
      <section
        id="orders-list"
        className='overflow-y-auto flex col justify-space-between p-4'
        style={{ display: 'grid', gridTemplateRows: '1fr', gridTemplateColumns: '1fr' }}>
        {orders.length > 0 && (
          <ButtonMenu
            className='expand-x gap-1px'
            shade={1}
            shadow={1}
            rounded={3}
            theme={NEUTRAL}
            direction={COLUMN}>
            {orders.map((order, index) => (
              <OrderLineItem
                shade={0}
                rounded={0}
                key={index}
                order={order}
                theme={NEUTRAL}
                customer={order.customer}
                onClick={() => onSetOrder(order)}
                className={classNames({
                  'rounded-top-3': index === 0,
                  'rounded-bottom-3': index === (orders.length - 1),
                })}
              />
            ))}
          </ButtonMenu>
        )}
        {orders.length === 0 && (
          <div className='ps-3 font-size-5'>No orders found</div>
        )}
        {/* NEED TO FIGURE OUT PAGINATION SEPARATELY */}
        {/* <footer className='absolute z-2 x-0 bottom-0 p-3 pb-4'>
          <Pagination />
        </footer> */}
      </section>
    </Article>
  )
}

export default OrderRecords

import React, { useState, useEffect } from 'react'
import classNames from 'classnames'
import { AsYouType } from 'libphonenumber-js'
import { COUNTRY_CODE } from 'components/constants'

import { SMALL, MEDIUM, LARGE } from 'components/constants'
import { BRAND } from 'components/constants'
import { NUMBER } from 'components/constants'

import Icon from 'components/shared/Icon'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'
import isPhoneNumber from 'components/customers/helpers/isPhoneNumber'
import isEmailAddress from 'components/helpers/isEmailAddress'

const Input = ({
  autoFocus = false,
  className,
  defaultValue = '',
  pushValue = false,
  disabled = false,
  icon,
  id,
  inputMode,
  max,
  min,
  name,
  onBlur,
  onChange,
  onFocus,
  onKeyUp,
  onSetValid,
  onSetInvalid,
  pattern,
  placeholder,
  required = false,
  shade = 0,
  role = null,
  shadow = 1,
  size = MEDIUM,
  step,
  theme = BRAND,
  type = 'text',
  valid = true,
  width,
}) => {
  const [value, setValue] = useState(defaultValue)
  const [isValid, setValid] = useState(valid)

  useEffect(() => {
    const parsedValue = parseInt(pushValue);
    if (!isNaN(parsedValue)) {
      change(parsedValue);
    }
  }, [pushValue])

  function change(value) {
    setValue(format(value))
    onChange(value)
    if (validate(value)) {
      setValid(true)
      if (onSetValid) onSetValid()
    } else {
      setValid(false)
      if (onSetInvalid) onSetInvalid()
    }
  }

  function validate(value = '') {
    switch (type) {
      case 'number':
        if (value.length === 0) return true
        if (max === 0 && parseFloat(value) > 0) return false
        let validMin = true
        let validMax = true
        if (min) validMin = parseFloat(value) >= min
        if (max) validMax = parseFloat(value) <= max
        return validMin && validMax
      case 'email':
        return isEmailAddress(value)
      default:
        return true
    }
  }

  function format(value) {    
    switch (type) {
      case 'phone':
        isPhoneNumber(value) ? getFormattedPhoneNum(value) : value
      case 'number':
        value[0] == 0 ? parseFloat(value.toString()) : value;
      default:
        return value
    }
  }

  return (

    <div
      style={{ width }}
      className={classNames({
        'flex z-0 align-items-center relative transition-bg': true,
        'bg-notify-2': isValid === false,
        'pl-3': icon !== undefined,
        'ps-4': icon === undefined,
        'shadow-disabled': shadow > 0 && disabled === true,
        'shadow-inset': shadow === -1,
        ['shadow-' + shadow]: shadow > 0 && disabled === false,
        ['bg-notify-2 color-notify-5']: isValid === false,
        [className]: className !== undefined,
        [getTheme(theme, shade)]: isValid === true,
        [getBorderRadius()]: true,
        [getSize()]: true,
      })}>
      {icon && (
        <Icon type={icon} size={getIconSize()} />
      )}
      {pushValue === false && (
        <input
          defaultValue={value}
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          inputMode={inputMode}
          max={max}
          min={min}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          role={role}
          onKeyUp={onKeyUp}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          step={step}
          type={type}
          onChange={(event) => {change(event.target.value)}}
          className={classNames({
            'pe-3 expand': true,
            ['upcase']: icon === NUMBER,
            ['input-' + size.toLowerCase()]: true,
            ['pl-1 pr-' + getSidesPadding()]: icon,
            ['pe-' + getSidesPadding()]: icon === undefined,
          })}
        />
      )}
      {pushValue !== false && (
        <input
          value={value}
          autoFocus={autoFocus}
          disabled={disabled}
          id={id}
          inputMode={inputMode}
          max={max}
          min={min}
          name={name}
          onBlur={onBlur}
          onFocus={onFocus}
          role={role}
          onKeyUp={onKeyUp}
          pattern={pattern}
          placeholder={placeholder}
          required={required}
          step={step}
          type={type}
          onChange={(event) => {change(event.target.value)}}
          className={classNames({
            'pe-3 expand': true,
            ['upcase']: icon === NUMBER,
            ['input-' + size.toLowerCase()]: true,
            ['pl-1 pr-' + getSidesPadding()]: icon,
            ['pe-' + getSidesPadding()]: icon === undefined,
          })}
        />
      )}
    </div>
  )

  function getTheme(theme, shade) {
    switch (theme) {
      case BRAND:
        return getThemeStyleClass(theme, shade)
      default:
        return 'bg-shade-0 hover:bg-shade-1 focus:bg-brand-3'
    }
  }

  function getSidesPadding() {
    switch (size) {
      case SMALL:
        return 3
      case MEDIUM:
        return 4
      case LARGE:
        return 5
    }
  }

  function getSize() {
    switch (size) {
      case SMALL:
        return 'input-height-2 font-size-4 font-weight-3'
      case MEDIUM:
        return 'input-height-3 font-size-5 font-weight-2'
      case LARGE:
        return 'input-height-4 font-size-6 font-weight-2'
    }
  }

  function getIconSize() {
    switch (size) {
      case LARGE:
        return 30
      case MEDIUM:
        return 26
      case SMALL:
        return 22
    }
  }

  function getBorderRadius() {
    switch (size) {
      case LARGE:
        return 'rounded-3'
      default:
        return 'rounded-2'
    }
  }

  function getFormattedPhoneNum(input) {
    return input.length < value.length
      ? input.slice(0, input.length)
      : new AsYouType(COUNTRY_CODE).input(input)
  }
}

export default Input

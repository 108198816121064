export const SETTINGS = 'SETTINGS'
export const COUNTRY_CODE = 'US'
export const LOCALE = 'en-US'
export const CURRENCY_CODE = 'USD'
export const PREFERRED_PRODUCT_LAYOUT = 'GRID'

// Timing (milliseconds)

export const DEFAULT_SYNC_INTERVAL = 60 * 1000
export const LOADER_TIMEOUT = 300
export const DEBOUNCE_DELAY = 300

// Developer tools

export const DEV_MODE = (process.env.NODE_ENV === 'development')
export const USE_DUMMY_DATA = false
export const SHOW_DEV_LOGS = true

// Products

export const MAX_PRODUCTS_SHOWN = 28 // 7 rows of 4 in Grid view
export const PRODUCT_COLUMNS = {
  product: '1fr',
  price: '120px',
  stock: '60px',
}

export const LIMITED_PRODUCT_COLUMNS = {
  product: '1fr',
  price: '160px',
}

export const LOCALES = {
  EN_US: 'en-US',
  EN_AU: 'en-AU',
}

export const PRINTER_TYPES = {
  LABEL: 'label',
  RECEIPT: 'receipt',
}

export const PRINTABLE_TYPES = {
  PRODUCT: 'product',
  ORDER: 'order',
}

// Contexts

export const PRODUCT_LABEL_CONTEXT = 'PRODUCT_LABEL_CONTEXT'
export const RECEIPT_CONTEXT = 'RECEIPT_CONTEXT'
export const PACKING_SLIP_CONTEXT = 'PACKING_SLIP_CONTEXT'

// Settings keys
export const LABEL_PRINTER = 'LABEL_PRINTER'
export const RECEIPT_PRINTER = 'RECEIPT_PRINTER'
export const DRAWER = 'DRAWER'

// Actions

export const ADD_PRINTER = 'ADD_PRINTER'
export const CONNECT_DRAWER_TO_PRINTER = 'CONNECT_DRAWER_TO_PRINTER'
export const UPDATE_DEVICES = 'UPDATE_DEVICES'
export const UPDATE_PRINTER = 'UPDATE_PRINTER'
export const UPDATE_DRAWER = 'UPDATE_DRAWER'
export const UPDATE_CONTEXT = 'UPDATE_CONTEXT'

// SUPPORTED PRINTER MODELS

export const SUPPORTED_PRINTER_MODELS = {
  "mPOP": {
    "type": "receipt",
    "language": "star-prnt",
    "chars": 32,
    "widthmm": 57,
    "dots": 360,
    "width": 32,
    "imageMode": "dither",
    "hasDrawer": true,
  },
  "TM-T88IV": {
    "type": "receipt",
    "language": "esc-pos",
    "chars": 42,
    "widthmm": 80,
    "dots": 512,
    "width": 42,
    "imageMode": "raster",
    "hasDrawer": false,
  },
  "ZTC ZD220-203dpi ZPL": {
    "type": "label",
  }
}

export const BARCODE_TYPE_MAP = {
  "star-prnt": {
    "CODE128": 0x06,
    "CODE39": 0x04,
    "EAN13": 0x03,
    "EAN8": 0x02,
    "UPCA": 0x01,
    "UPCE": 0x00,
  },
  "esc-pos": {
    "CODE128": 0x49,
    'CODE39': 0x04,
    'EAN13':  0x02,
    'EAN8':   0x03,
    'UPCA':   0x00,
    'UPCE':   0x01,
  },
}

export const BARCODE_POSITION_MAP = {
  "star-prnt": {
    "none": 0x0,
    "above": 0x1,
    "below": 0x2,
    "both": 0x3,
  },
  "esc-pos": {
    "none": [0x1d, 0x48, 0x00],
    "above": [0x1d, 0x48, 0x01],
    "below": [0x1d, 0x48, 0x02],
    "both": [0x1d, 0x48, 0x03],
  },
}

export const STAR_PRNT_BARCODE_VALUES = {
  START_MARKER: 0x1b,
  END_MARKER: 0x1e,
  BARCODE_MARKER: 0x62,
  BARCODE_MODE: 0x3,
}

export const ESC_POS_BARCODE_VALUES = {
  WIDTH_MARKER: [0x1d, 0x77],
  HEIGHT_MARKER: [0x1d, 0x68],
  TYPE_MARKER: [0x1d, 0x6b],
}

// TYRO

export const UPDATE_TYRO = 'UPDATE_TYRO'

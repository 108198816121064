import { USE_DUMMY_DATA } from 'constants/settings'
import OUTLETS from 'data/dummy/outlets'

import authenticatedFetch from './authenticatedFetch'
import { dir, log } from 'dev/log'

export function fetchOutlets(
  onSuccess,
  onError = () => alert('Error fetching outlets'),
) {
  log('Fetching outlets...')

  if (USE_DUMMY_DATA) return onSuccess(OUTLETS)

  if (navigator.onLine) {
    authenticatedFetch('outlets',
      { method: 'GET' },
      // Success
      ({ outlets }) => {
        dir(outlets)
        onSuccess(outlets)
      },
      // Error
      () => onError()
    )
  }
}

import React, { useState } from 'react'

import { XLARGE } from 'components/constants'
import { BRAND, CUSTOMER, EMAIL, LAYBY, COLUMN, CART, SCALE_IN } from 'components/constants'
import { NOTES, SERIALS, PARKED } from 'components/orders/constants'
import { CUSTOMER_MATCH, CUSTOMER_NAME, CUSTOMER_CONTACT, DISCOUNT } from 'components/customers/constants'

import AddCustomerFlow from 'components/checkout/shared/AddCustomerFlow'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import OrderCustomerModal from 'components/order/OrderCustomerModal'
import LaybyModal from 'components/checkout/shared/LaybyModal'
import OrderNotesModal from 'components/order/OrderNotesModal'
import CheckoutSerialsModal from 'components/checkout/shared/CheckoutSerialsModal'
import ParkOrderForm from 'components/checkout/shared/ParkOrderForm'
import CustomerEmailForm from 'components/checkout/shared/CustomerEmailForm'
import CartDiscountModal from 'components/cart/CartDiscountModal'

const MAX_WIDTH = 600

const CheckoutActionsModal = ({
  cartItems = [],
  customer,
  notes,
  payments = [],
  orderType,
  total,
  user,

  // UI display-related
  id,
  innerAnimation,
  initialAnimation = SCALE_IN,
  theme = BRAND,
  zIndex = 2,

  // Events
  onAbandon,
  onBack,
  onClose,
  onCreateCustomer,
  onEmail,
  onExit,
  onPark,
  onPrint,
  onSetCustomer,
  onSetId,
  onSetLayby,
  onResetLayby,
  onSetOrder,
  onSetOrderNotes,
  onSetSerials,
  onSetDiscount,

  // Conditions
  showBackButton = false,
  canAbandon = false,
  canClose = true,
  canEmail = true,
  canLayby = true,
  canPark = false,
  canSetNotes = true,
  canSetSerials = true,
  canSetDiscount = true,
}) => {
  const [animation, setAnimation] = useState(initialAnimation)

  switch (id) {
    case CUSTOMER:
      return (
        <OrderCustomerModal
          rounded={3}
          maxWidth={MAX_WIDTH}
          animation={animation}
          customer={customer}
          innerAnimation={innerAnimation}
          zIndex={zIndex}
          onBack={onBack}
          onSubmit={() => {
            onResetLayby()
            onSetOrder({ customer: {}, orderType: null })
            onSetCustomer({})
          }}
        />
      )
    case CUSTOMER_CONTACT:
    case CUSTOMER_NAME:
    case CUSTOMER_MATCH:
      return (
        <AddCustomerFlow
          rounded={4}
          padding={7}
          animation={animation}
          viewId={id}
          customer={customer}
          showBackButton={showBackButton}
          innerAnimation={innerAnimation}
          theme={theme === LAYBY ? LAYBY : CUSTOMER}
          zIndex={zIndex}
          onClose={() => onClose()}
          onSetCustomer={(customer) => {
            onSetCustomer(customer)
            onSetOrder({ customer })
          }}
          onCreateCustomer={(customer) => {
            onSetCustomer(customer)
            onSetOrder({ customer })
            onCreateCustomer(customer)
          }}
          onBack={() => {
            if (orderType && orderType.toUpperCase() === LAYBY) {
              onResetLayby()
              onBack()
            } else onClose()
          }}
        />
      )
    case EMAIL:
      return (
        <CustomerEmailForm
          className='z-2'
          onSubmit={(address) => onEmail(address)}
          onBack={onBack}
          layby={orderType === LAYBY}
          customer={customer}
        />
      )
    case LAYBY:
      return (
        <LaybyModal
          rounded={3}
          maxWidth={MAX_WIDTH}
          animation={animation}
          customer={customer}
          payments={payments}
          status={orderType}
          items={cartItems}
          onBack={() => {
            onSetOrder({ orderType: null })
            onClose()
          }}
          zIndex={zIndex}
          onSubmit={() => {
            onSetLayby()
            onSetOrder({ orderType: LAYBY })
            onBack()
          }}
        />
      )
    case PARKED:
      return (
        <ParkOrderForm
          rounded={3}
          expand={false}
          maxWidth={MAX_WIDTH}
          animation={animation}
          total={total}
          paid={null}
          cartCount={cartItems.length}
          theme={theme}
          innerAnimation={innerAnimation}
          onBack={() => onClose()}
          onSubmit={() => onPark()}
        />
      )
    case NOTES:
      return (
        <OrderNotesModal
          rounded={3}
          zIndex={zIndex}
          maxWidth={MAX_WIDTH}
          animation={animation}
          notes={notes}
          theme={theme}
          innerAnimation={innerAnimation}
          onBack={onBack}
          onSubmit={(notes) => {
            onSetOrderNotes(notes)
            onClose()
          }}
        />
      )
    case SERIALS:
      return (
        <CheckoutSerialsModal
          maxHeight='80%'
          maxWidth={MAX_WIDTH}
          animation={animation}
          zIndex={zIndex}
          cartItems={cartItems}
          theme={theme}
          onSubmit={onClose}
          onSetSerials={onSetSerials}
          onBack={canClose ? onBack : onExit}
        />
      )
    case DISCOUNT:
      return (
        <CartDiscountModal 
          data={{cartItems: cartItems, user: user}}
          modalContext={CART}
          onCloseOverlay={() => onClose()}
          onSetDiscount={(obj) => { onSetDiscount(obj) }}
        />
      )
    default:
      return (
        <ButtonMenu
          className='relative overflow-hidden z-2 shadow-4'
          overlay={true}
          rounded={3}
          maxWidth={MAX_WIDTH}
          direction={COLUMN}
          animation={animation}
        >
          {canLayby && (
            <Button
              className='z-1 justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => {
                if (Object.keys(customer).length > 0) {
                  onSetId(LAYBY)
                } else {
                  onSetLayby()
                  onSetId(CUSTOMER_CONTACT)
                }
              }}
            >
              Convert to layby
            </Button>
          )}
          {canPark && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => onSetId(PARKED)}
            >
              Park order
            </Button>
          )}
          {canSetDiscount && (
            <Button
              className='z-1 justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => {
                onSetId(DISCOUNT)
              }}
            >
              Set an order discount
            </Button>
          )}
          {canSetNotes && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => onSetId(NOTES)}>
              Order notes
            </Button>
          )}
          {canSetSerials && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => onSetId(SERIALS)}>
              Add or edit serial numbers
            </Button>
          )}
          {canEmail && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              theme={theme}
              size={XLARGE}
              onClick={() => onSetId(EMAIL)}>
              Email receipt
            </Button>
          )}
          {onPrint && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => onPrint()}>
              Print receipt
            </Button>
          )}
          {canAbandon && (
            <Button
              className='justify-start'
              shade={3}
              rounded={0}
              padding={6}
              fixedHeight={false}
              size={XLARGE}
              theme={theme}
              onClick={() => {
                onSetOrder(null)
                onAbandon()
              }}>
              Abandon parked order
            </Button>
          )}
        </ButtonMenu>
      )
  }
}

export default CheckoutActionsModal

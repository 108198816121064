import React from 'react'
import classNames from 'classnames'

import { TINY, SMALL, MEDIUM, LARGE } from 'components/constants'
import { BRAND, NEUTRAL } from 'components/constants'

import Icon from 'components/shared/Icon'

import getThemeStyleClass from 'components/helpers/getThemeStyleClass'

const Tag = ({
  dataTag,
  className,
  icon,
  shade = 3,
  size = MEDIUM,
  text,
  theme = BRAND,
}) => {
  return (
    <span
      data-tag={dataTag}
      className={classNames({
        'inline-flex line-height-compact align-items-center gap-2 no-shrink': true,
        'rounded-1 font-weight-2 line-height-compact': true,
        'bg-shade-2 color-shade-3': theme === NEUTRAL,
        [getThemeStyleClass(theme, shade)]: theme !== NEUTRAL,
        [getSize()]: true,
        [className]: className,
      })}>
      {text}
      {icon && <Icon size={10} theme={theme}/>}
    </span>
  )

  function getSize() {
    switch (size) {
      case TINY:
        return 'font-size-05 ps-1'
      case SMALL:
        return 'font-size-1 ps-1'
      case MEDIUM:
        return 'font-size-2 ps-1 pe-1'
      case LARGE:
        return 'font-size-3 ps-2 pe-1'
    }
  }
}

export default Tag

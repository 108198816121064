import getOrderItemDrops from './getOrderItemDrops'
import getPaymentDrop from './getPaymentDrops'
import getContactDrop from './getContactDrop'

import getAmountPaid from 'components/orders/helpers/getAmountPaid'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getPaymentData from 'components/orders/helpers/getPaymentData'
import getReservedItemsTotal from 'components/orders/helpers/getReservedItemsTotal'

import getUserDisplayName from 'components/users/helpers/getUserDisplayName'

export default function getOrderDrop(getStore, order, orderUrl, products) {

  const { subtotal, taxTotal } = getPaymentData(order.items)
  const reservedItemsTotal = getReservedItemsTotal(order.items)

  const orderDrop = { 
    __data: {},
    additional_payment_path: "",
    adjustment_items: [],
    billing_address: {},
    billing_address_lines: [],
    billing_city: "",
    billing_country: "",
    billing_postal_code: "",
    billing_state: "",
    billing_street: "",
    bookable: false,
    bookable_items: [],
    bookings: [],
    checkout_email: "",
    checkout_phone: "",
    collect_booking_attendees: false,
    collection_point: {},
    collection_time: "",
    contact: getContactDrop(order.customer),
    currency_code: "",
    customer_notes: "",
    id: order.id,
    item_count: (order.items || []).length,
    items: [],
    ordered_at: new Date(order.created),
    path: "",
    payment_surcharge_values: [],
    payments: [],
    quotable: false,
    reference: order.reference_number,
    reorder_path: "",
    ship_to_contact: {},
    shipping_address: {},
    shipping_address_lines: [],
    shipping_city: "",
    shipping_country: "",
    shipping_notes: "",
    shipping_postal_code: "",
    shipping_state: "",
    shipping_street: "",
    status: "",
    store: getStore,
    sub_total: (subtotal - reservedItemsTotal),
    surcharge_items: [],
    taxes: [],
    test_order: false,
    total: order.total,
    total_deposit: 0,
    total_deposit_points: 0,
    total_earn_points_amount: 0,
    total_fixed_term_subscriptions: 0,
    total_fixed_term_subscriptions_points: 0,
    total_non_subscriptions: 0,
    total_non_subscriptions_points: 0,
    total_paid: getAmountPaid(order),
    total_paid_points: 0,
    total_payable: getAmountOwed(order),
    total_payable_points: 0,
    total_points: 0,
    total_remaining_subscriptions: 0,
    total_remaining_subscriptions_points: 0,
    total_shipping: 0,
    total_subscriptions: 0,
    total_subscriptions_points: 0,
    total_tax: taxTotal,
    url: `${orderUrl + order.reference_number}`,
  }

  orderDrop['items'] = getOrderItemDrops(() => orderDrop, order.items, products)
  orderDrop['payments'] = getPaymentDrop(() => orderDrop, order.payments)

  // new field
  orderDrop['assisting_user'] = getUserDisplayName(order.user)

  return orderDrop
}

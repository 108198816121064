import { SET_CUSTOMER, RESET_CUSTOMER } from '../constants'

export default function (state = {}, action = {}) {
  switch (action.type) {
    case SET_CUSTOMER:
      setLocalStorage(action.customer)
      return action.customer

    case RESET_CUSTOMER:
      setLocalStorage({})
      return {}

    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('customer', JSON.stringify(payload))
}

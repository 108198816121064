import React from 'react'
import classNames from 'classnames'

import { XXLARGE } from 'components/constants'
import { COLUMN } from 'components/constants'
import { BRAND } from 'components/constants'
import { SLIDE_UP } from 'components/constants'

import Form from 'components/shared/Form'
import Accordion from 'components/shared/Accordion'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import CartItemSerialFields from 'components/checkout/shared/CheckoutSerialFields'
import LineItem from 'components/shared/LineItem'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'

import getCartItemsIncompleteSerials from 'components/checkout/helpers/getCartItemsIncompleteSerials'

const CheckoutSerialsModal = ({
  animation,
  cartItems,
  maxWidth,
  maxHeight,
  theme = BRAND,
  shade = 0,
  shadow = 3,
  rounded = 4,
  size = XXLARGE,
  zIndex,
  onSetSerials,
  onBack,
  onSubmit,
}) => {
  const required = cartItems.filter((cartItem) => cartItem.requires_serial)
  const optional = cartItems.filter(
    (cartItem) => cartItem.requires_serial === false
  )
  const disabled = getCartItemsIncompleteSerials(cartItems).length > 0

  return (
    <Form
      onSubmit={onSubmit}
      disabled={disabled}
      shade={shade}
      theme={theme}
      shadow={shadow}
      rounded={rounded}
      maxWidth={maxWidth}
      maxHeight={maxHeight}
      animation={animation}
      className={classNames({ ['z-' + zIndex]: !!zIndex })}
    >
      <ButtonMenu className='ps-3 pt-3'>
        <Button
          rounded={4}
           padding={5}
          shade={shade}
          theme={theme}
          onClick={onBack}
        >
          Back
        </Button>
      </ButtonMenu>
      <Container className='pe-4' scroll={true} gap={3}>
        {required.length > 0 ? (
          <Heading size={7}>Required serial numbers</Heading>
        ) : (
          <Heading size={7}>Optional serial numbers</Heading>
        )}
        {required.length > 0 && (
          <ul className='flex col gap pb-5'>
            {required.map((cartItem, index) => {
              return (
                <li className='rounded-2 flex col gap p-3' key={index}>
                  <LineItem
                    headline={cartItem.name}
                    showImage={true}
                    image={cartItem.image}
                    fontSize={2}
                  />
                  <CartItemSerialFields
                    id={cartItem.id}
                    required={cartItem.requires_serial}
                    serialNumbers={cartItem.serial_numbers}
                    quantity={cartItem.quantity}
                    onChange={(serials) => {
                      onSetSerials(cartItem.id, serials)
                    }}
                  />
                </li>
              )
            })}
          </ul>
        )}
        {required.length === 0 && optional.length > 0 && (
          <ul className='flex col gap'>
            {optional.map((cartItem, index) => {
              return (
                <li className='rounded-2 flex col gap' key={index}>
                  <LineItem
                    headline={cartItem.name}
                    showImage={true}
                    image={cartItem.image}
                    fontSize={2}
                  />
                  <CartItemSerialFields
                    required={cartItem.requires_serial}
                    serialNumbers={cartItem.serial_numbers}
                    quantity={cartItem.quantity}
                    autoFocus={true}
                    onChange={(serials) => onSetSerials(cartItem.id, serials)}
                  />
                </li>
              )
            })}
          </ul>
        )}
        {optional.length > 0 && required.length > 0 && (
          <Accordion headline='Optional serial numbers'>
            <ul className='flex col gap'>
              {optional.map((cartItem, index) => {
                return (
                  <li
                    className='bg-shade-1 rounded flex col gap p-3'
                    key={index}
                  >
                    <LineItem
                      headline={cartItem.name}
                      showImage={true}
                      image={cartItem.image}
                      fontSize={2}
                    />
                    <CartItemSerialFields
                      required={cartItem.requires_serial}
                      serialNumbers={cartItem.serial_numbers}
                      quantity={cartItem.quantity}
                      onChange={(serials) => onSetSerials(cartItem.id, serials)}
                    />
                  </li>
                )
              })}
            </ul>
          </Accordion>
        )}
      </Container>
      <ButtonMenu direction={COLUMN}>
        <Button
          shade={5}
          size={size}
          theme={theme}
          animation={SLIDE_UP}
          rounded={0}
          type='submit'
        >
          Continue
        </Button>
      </ButtonMenu>
    </Form>
  )
}

export default CheckoutSerialsModal

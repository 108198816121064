export default function getUserDisplayName(user) {
  if (!user || (!user.firstName && !user.lastName)) {
    return null
  }

  const firstName = (user.firstName || '').trim()
  const lastName = (user.lastName || '').trim()

  if (firstName !== '' && lastName !== '') {
    const lastInitial = lastName.charAt(0).toUpperCase()
    return `${firstName} ${lastInitial}.`
  }

  return firstName !== '' ? firstName : lastName
}

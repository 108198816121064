import React, { useState, useRef, useEffect } from 'react'
import { AnimatePresence } from 'framer-motion'
import classNames from 'classnames'

import { TINY, SMALL } from 'components/constants'
import { EXPAND } from 'components/constants'
import { LEFT, RIGHT, CENTER } from 'components/constants'

import Button from 'components/shared/Button'
import Expand from 'components/shared/Expand'
import Icon from 'components/shared/Icon'
import Tag from 'components/shared/Tag'

const ButtonSelector = ({
  border = false,
  children,
  className,
  container,
  disabled,
  expandFromRightToLeft = false,
  height,
  icon,
  id,
  label,
  onExpand,
  onMinimize,
  shade = 0,
  shadow = false,
  selected,
  selectedLabel = null,
  size = TINY,
  style,
  theme,
  width = 400,
}) => {
  const containerRef = useRef(null)
  const buttonRef = useRef(null)
  const [expanded, setExpanded] = useState(false)

  // Setup click events listener on load
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (buttonRef.current && !buttonRef.current.contains(event.target)) {
        close()
      }
    }
    document.addEventListener('click', handleClickOutside)
    return () => document.removeEventListener('click', handleClickOutside)
  }, [])

  function click() {
    setExpanded(true)
    if (onExpand) onExpand()
  }

  function close() {
    setExpanded(false)
    if (onMinimize) onMinimize()
  }

  return (
    <div className='relative' style={style} ref={containerRef} data-button-selector>
      <Button
        buttonRef={buttonRef}
        id={id}
        onClick={click}
        icon={icon}
        theme={theme}
        shade={shade}
        shadow={shadow}
        size={size}
        border={border}
        disabled={disabled}
        padding={4}
        className={classNames({
          [className]: className,
          'justify-start': true,
        })}>
        <span className='flex gap-2'>
          {label}
          {(!selectedLabel && selected) && (
            <Tag
              className='capitalize'
              size={SMALL}
              theme={theme}
              text={selected.toLowerCase()}
            />
          )}
          {(selectedLabel && selected) && (
            <Tag
              className='capitalize'
              size={SMALL}
              theme={selected}
              text={selectedLabel}
            />
          )}
        </span>
        <span className='border-left ml-4 pl-4'>
          <Icon type={EXPAND} size={20} />
        </span>
      </Button>
      <AnimatePresence>
        {expanded && (
          <Expand
            targetWidth={width}
            targetHeight={height}
            origin={containerRef.current}
            theme={theme}
            shade={0}
            orientation={getOrientation()}
            className='z-4'>
            {children}
          </Expand>
        )}
      </AnimatePresence>
    </div>
  )

  function getOrientation() {
    const leftPos = containerRef.current.offsetLeft
    const centerX = getContainer() / 2
    const quarter = centerX / 4

    if (leftPos > quarter && leftPos < 3 * quarter) return CENTER
    else if (leftPos > centerX || expandFromRightToLeft) return RIGHT
    else return LEFT
  }

  function getContainer() {
    return container ? container : window
  }
}

export default ButtonSelector

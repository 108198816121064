import { SET_STORE } from 'components/stores/constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_STORE:
      window.localStorage.setItem('store', JSON.stringify(action.store))
      window.localStorage.setItem('currency', action.store.currency)
      return action.store
    default:
      return state
  }
}

import React, { useState } from 'react'
import classNames from 'classnames'

const Stage = ({
  children,
  heading,
  subheading,
  center = false,
  overflow = false,
  width,
  height,
}) => {
  const [showProps, setShowProps] = useState(false)

  return (
    <section className={classNames({
      'flex col gap-3': true,
      'overflow-hidden': overflow === false,
    })}>
      {(heading || subheading) && (
        <header className='flex col gap-2 pe-3'>
          {heading && (
            <h3>{heading}</h3>
          )}
          {subheading && (
            <small className='font-size-2 font-weight-2 color-shade-4'>
              {subheading}
            </small>
          )}
        </header>
      )}
      {getComponents().map((component, index) => {
        return (
          <div
            className='relative bg-shade-1 rounded-4 p-6'
            key={index}
          >
            <figure
              style={{ maxWidth: width, height }}
              className={classNames({
                'relative flex col': true,
                'justify-center': center === true,
              })}
            >
              {component}
            </figure>
            {showProps && (
              <table className='expand text-left m-0'>
                <tbody>
                  {Object.keys(component.props).map((key, index) => (
                    <tr className='flex font-size-2 pe-1 ps-2' key={index}>
                      <th className='font-weight-2' style={{ width: 140 }}>
                        {key}
                      </th>
                      <td className='color-shade-3'>
                      <PropValue value={component.props[key]} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            )}
          </div>
        )
      })}
    </section>
  )

  function PropValue({ value }) {
    if (Array.isArray(value))
      return '[]'
    switch (typeof value) {
      case 'object':
        return 'object'
      default:
        return value
    }
  }

  function getComponents() {
    if (Array.isArray(children)) return children
    else return [children]
  }
}

export default Stage

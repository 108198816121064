import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { SMALL } from 'components/constants'
import { BRAND } from 'components/constants'
import { NUMBER } from 'components/constants'

import Field from 'components/shared/Field'
import Fieldset from 'components/shared/Fieldset'

import getCountWord from 'components/helpers/getCountWord'

const CheckoutSerialFields = ({
  id,
  className,
  onBlur,
  onChange,
  quantity,
  autoFocus = false,
  required = false,
  serialNumbers = [],
}) => {
  const slots = Array.from(Array(quantity))
  const [serials, setSerials] = useState(
    getSerialsArrayWithSlots(serialNumbers)
  )

  useEffect(() => {
    setSerials(getSerialsArrayWithSlots(serialNumbers))
  }, [quantity])

  function change(serial, index) {
    const updatedSerials = serials.map((s, i) => {
      if (i === index) return serial.length === 0 ? undefined : serial
      else return s
    })

    setSerials(updatedSerials)
    if (onChange) onChange(updatedSerials)
  }

  return (
    <Fieldset
      className={classNames({
        'col': true,
        [className]: className,
      })}>
      {slots.map((_count, index) => (
        <Field
          id={`serial-${id}-${index}`}
          name={`serial-${id}-${index}`}
          shadow={1}
          shade={0}
          border={true}
          icon={NUMBER}
          theme={BRAND}
          size={SMALL}
          defaultValue={serials ? serials[index] : undefined}
          required={required}
          autoFocus={autoFocus ? index === 0 : null}
          label={
            quantity > 1
              ? getCountWord(index + 1) + ' serial number'
              : 'Serial number'
          }
          onBlur={onBlur ? () => onBlur() : null}
          onChange={(value) => change(value, index)}
          key={index}
        />
      ))}
    </Fieldset>
  )

  function getSerialsArrayWithSlots(serials) {
    // Example: ['A00001', undefined, undefined]
    return slots.map((undefined, index) => serials[index])
  }
}

export default CheckoutSerialFields

import Dexie from 'dexie'

export function getIndexedDB() {
  const db = new Dexie('storeconnect_pos')

  db.version(1).stores({
    products: 'id,timestamp,data'
  })

  return db
}

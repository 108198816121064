import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { v4 as uuidv4 } from 'uuid'

import { XXLARGE } from 'components/constants'
import {
  CUSTOMER,
  CUSTOMER_MATCH,
  CUSTOMER_NAME,
  CUSTOMER_CONTACT,
} from 'components/customers/constants'

import { queryCustomers } from 'data/api/queryCustomers'

import CustomerContactForm from './CustomerContactForm'
import CustomerDetailsForm from './CustomerDetailsForm'
import CustomerMatchForm from './CustomerMatchForm'
import isPhoneNumer from 'components/customers/helpers/isPhoneNumber'

const AddCustomerFlow = ({
  animation,
  className,
  innerAnimation,
  maxHeight,
  maxWidth = 600,
  onBack,
  onClose,
  onCreateCustomer,
  onSetCustomer,
  onResetCustomer,
  padding = 6,
  rounded = 0,
  showBackButton = false,
  size = XXLARGE,
  theme = CUSTOMER,
  viewId = CUSTOMER_CONTACT,
  zIndex,
}) => {
  const [customer, setCustomer] = useState(useSelector((state) => state.customer));
  const [matches, setMatches] = useState({})
  const [view, setView] = useState(viewId)

  switch (view) {
    case CUSTOMER_CONTACT:
      return (
        <CustomerContactForm
          customer={customer}
          animation={animation}
          className={className}
          innerAnimation={innerAnimation}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          onBack={onBack}
          padding={padding}
          rounded={rounded}
          size={size}
          theme={theme}
          zIndex={zIndex}
          onResetCustomer={() => onResetCustomer()}
          onSubmit={(value) => {
        if (isPhoneNumer(value)) {
          setCustomer({ phone: value, ...customer })
        } else if (value.includes('@')) {
          setCustomer({ email: value, ...customer })
        } else {
          let names = value.split(' ')
          if (names.length < 2) {
            names.push('')
          }
          const lastName = names.pop()
          setCustomer({
            firstName: names.join(' '),
            lastName: lastName,
            ...customer,
          })
        }
            queryCustomers(value,
            // Success
            (customers) => {
              if (customers.length > 0) {
                setMatches(customers)
                setView(CUSTOMER_MATCH)
              } else {
                setView(CUSTOMER_NAME)
              }
            },
            // Offline
            () => setView(CUSTOMER_NAME)
          )}}
        />
      )
    case CUSTOMER_NAME:
      return (
        <CustomerDetailsForm
          customer={customer}
          animation={animation}
          className={className}
          innerAnimation={innerAnimation}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          padding={padding}
          rounded={rounded}
          showBackButton={showBackButton}
          size={size}
          theme={theme}
          zIndex={zIndex}
          onBack={() => setView(CUSTOMER_CONTACT)}
          onSetCustomer={(customer) => setCustomer(customer)}
          onSubmit={(customer) =>
            onCreateCustomer({ ...customer, id: uuidv4() })
          }
        />
      )
    case CUSTOMER_MATCH:
      return (
        <CustomerMatchForm
          customer={customer}
          customers={matches}
          animation={animation}
          className={className}
          innerAnimation={innerAnimation}
          maxHeight={maxHeight}
          maxWidth={maxWidth}
          padding={padding}
          rounded={rounded}
          showBackButton={showBackButton}
          size={size}
          theme={theme}
          zIndex={zIndex}
          onBack={() => setView(CUSTOMER_CONTACT)}
          onSubmit={(customer) => {
            onClose()
            onSetCustomer(customer)
          }}
        />
      )
  }
}

export default AddCustomerFlow

import React from 'react'

import { SMALL } from 'components/constants'
import { BRAND } from 'components/constants'

import Button from 'components/shared/Button'
import ProductStockList from './ProductStockList'
import ProductPriceBreakdown from './ProductPriceBreakdown'

const ProductExpandedDetails = ({
  outlets = [],
  product = {},
  onAddCart,
}) => {
  return (
    <>
      <section className='flex gap-5'>
        <ProductPriceBreakdown
          className='grow flex row gap-5'
          product={product}
          onSetPrice={(price) => onSetPrice(price)}
        />
        <Button
          shade={3}
          shadow={1}
          rounded={2}
          size={SMALL}
          theme={BRAND}
          onClick={() => onAddCart(product)}
        >
          Add to cart
        </Button>
      </section>

      <ProductStockList
        outlets={outlets}
        product={product}
      />
    </>
  )
}

export default ProductExpandedDetails

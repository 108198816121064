import React from 'react'
import classNames from 'classnames'

import { BASE, LARGE } from 'constants/size'
import getTruncatedText from 'components/helpers/getTruncatedText'

const ProductHeadline = ({
  product,
  subheadline = null,
  size = BASE,
}) => {
  return (
    <span className="grow flex col gap-1 pointer-events-x">
      <strong className={classNames({
        'font-weight-3': true,
        'font-size-2': size === BASE,
        'font-size-3': size === LARGE,
      })}>
        {getTruncatedText(product.name, 80)}
      </strong>
      <span className={classNames({
        'color-shade-4 font-size-1 font-weight-2': true,
        'font-size-1': size === BASE,
        'font-size-2': size === LARGE,
      })}>
        {subheadline || product.code}
      </span>
    </span>
  )
}

export default ProductHeadline

export const ADD_SHIFT = 'ADD_SHIFT'
export const ADD_SHIFT_ORDER = 'ADD_SHIFT_ORDER'
export const END_SHIFT = 'END_SHIFT'
export const RESET_SHIFT = 'RESET_SHIFT'
export const RESET_SHIFT_ID = 'RESET_SHIFT_ID'
export const RESET_SHIFT_ORDERS = 'RESET_SHIFT_ORDERS'
export const SET_SHIFT_ID = 'SET_SHIFT_ID'
export const SET_SHIFTS = 'SET_SHIFTS'
export const SET_SHIFT = 'SET_SHIFT'
export const SHIFT = 'SHIFT'
export const SHIFTS = 'SHIFTS'

// Views

export const ADD_USER_TO_SHIFT = 'ADD_USER_TO_SHIFT'
export const END_BALANCE = 'END_BALANCE'
export const PASSCODE = 'PASSCODE'
export const SET_END_BALANCE = 'SET_END_BALANCE'
export const SHIFT_USER = 'SHIFT_USER'
export const START_BALANCE = 'START_BALANCE'
export const START_SHIFT = 'START_SHIFT'

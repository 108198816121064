import React, { useState, useEffect } from 'react'

import { SMALL, NEUTRAL, SEARCH } from 'components/constants'
import { DEBOUNCE_DELAY } from 'components/constants'

import Input from 'components/shared/Input'
import useInputDebounce from 'hooks/useInputDebounce'
import usePrevious from 'hooks/usePrevious'

const SearchInput = ({
  id,
  className,
  defaultValue = '',
  placeholder,
  size = SMALL,
  onKeyUp,
  onChange,
  onFocus,
}) => {
  const [value, setValue] = useState(defaultValue)
  const debouncedValue = useInputDebounce(value, DEBOUNCE_DELAY)
  const prevDebouncedValue = usePrevious(debouncedValue)

  useEffect(() => {
    if (debouncedValue !== prevDebouncedValue) {
      onChange(debouncedValue || '')
    }
  }, [value, debouncedValue, prevDebouncedValue])

  return (
    <Input
      type='search'
      shade={0}
      shadow={2}
      icon={SEARCH}
      theme={NEUTRAL}
      size={size}
      id={id}
      className={className}
      placeholder={placeholder}
      defaultValue={value}
      onKeyUp={onKeyUp ? (event) => onKeyUp(event) : null}
      onChange={(value) => setValue(value)}
      onFocus={onFocus}
    />
  )
}

export default SearchInput

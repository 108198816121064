import { USE_DUMMY_DATA } from 'constants/settings'
import TAXES from 'data/dummy/outlets'

import authenticatedFetch from './authenticatedFetch'

export function fetchTaxes(
  onSuccess,
  onError = () => alert('Error fetching taxes'),
) {
  if (USE_DUMMY_DATA) return onSuccess(TAXES)

  if (navigator.onLine) {
    authenticatedFetch(
      'taxes',
      { method: 'GET' },
      // Success
      ({ taxes }) => onSuccess(taxes),
      // Error
      () => onError()
    )
  }
}

import React from 'react'

const Empty = ({ message }) => {
  return (
    <div className='expand p-4 font-2 shade-5 font-weight-2'>
      {message}
    </div>
  )
}

export default Empty
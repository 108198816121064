import React from 'react'

import ORDERS from 'data/dummy/orders'
import STORES from 'data/dummy/stores'

import Container from './shared/Container'
import Stage from 'components/styleguide/shared/Stage'
import OrderRecords from 'components/orders/OrderSearch/OrderRecords'
import OrderSearchForm from 'components/orders/OrderSearch/OrderSearchForm'

export default function OrderSearchComponents() {
  return (
    <Container heading='7. Order search'>
      <Stage
        heading='Search form'
        subheading='Path: orders/OrderSearchForm.jsx'
      >
        <OrderSearchForm
          className='shadow-3 rounded-3 overflow-hidden'
          width={800}
          height={600}
        />
      </Stage>
      <Stage
        height={600}
        heading='Search results'
        subheading='OrderSearch/OrderRecords/index.jsx'
      >
        <OrderRecords
          className='shadow-3'
          stores={STORES}
          orders={ORDERS}
          onBack={() => null}
          onSetOrder={() => null}
        />
      </Stage>
    </Container>
  )
}

import { SET_VIEW_PARENTS, RESET_VIEW_PARENTS } from '../constants'

export default function (state = [], action) {
  switch (action.type) {
    case SET_VIEW_PARENTS:
      setLocalStorage(action.parents)
      return action.parents
    case RESET_VIEW_PARENTS:
      setLocalStorage([])
      return []
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('viewParents', JSON.stringify(payload))
}

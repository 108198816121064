import liquidjs from 'liquidjs'
import { filters, parseArguments } from './filters'

export class LiquidRenderer {
  constructor(locale, currency) {
    this.locale = locale;
    this.currency = currency;

    this.engine = new liquidjs.Liquid({
      cache: true,
      strictFilters: true,
      dynamicPartials: false,
      root: '/views',
      globals: {
        locale: locale,
        currency: currency,
      },
    });

    Object.keys(filters).forEach((name) => {
      this.engine.registerFilter(name, function () {
        return filters[name](this.context, parseArguments(arguments));
      });
    })
  }

  async render(template, data) {
    const engine = this.engine;
    const render = engine.parse(template);
    const html = await engine.render(render, data);
    return html;
  }
};
import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import { NEUTRAL, NOTIFY, BRAND } from 'components/constants'
import { SMALL, LARGE } from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import QuantityPicker from 'components/shared/QuantityPicker'

import CartLineItem from './CartLineItem'

const CartListItem = ({
  onDecrement,
  onIncrement,
  onMore,
  theme = NEUTRAL,
  showImage = true,
  shade = 0,
  onRemove,
  onOpen,
  open,
  className,
  cartItem,
}) => {
  const [isOpen, setOpen] = useState(open)

  useEffect(() => setOpen(open), [open])

  return (
    <li
      data-cart-item
      data-id={cartItem.productId}
      className={classNames({
        'relative expand-x flex col no-shrink transition-bg': true,
        [className]: className,
      })}>
      <Button
        shade={shade}
        size={LARGE}
        theme={theme}
        fixedHeight={false}
        rounded={0}
        padding={2}
        onClick={() => {
          setOpen(!isOpen)
          onOpen(isOpen ? null : cartItem.id)
        }}>
        <CartLineItem
          showImage={showImage}
          isReturnItem={cartItem.quantity < 0}
          cartItem={cartItem}
        />
      </Button>
      {isOpen && (
        <ButtonMenu className='ps-3 gap-2 rounded' gridTemplateColumns='auto 1fr auto'>
          <Button
            onClick={() => onRemove(cartItem.id)}
            style={{ width: 100 }}
            size={SMALL}
            theme={NOTIFY}
            shade={3}
            shadow={true}>
            Remove
          </Button>
          <QuantityPicker
            size={SMALL}
            theme={BRAND}
            quantity={cartItem.quantity}
            onDecrement={() => onDecrement(cartItem.id)}
            onIncrement={() => onIncrement(cartItem.id)}
            skipOverZero={true}
          />
          <Button
            onClick={() => onMore(cartItem)}
            style={{ width: 100 }}
            size={SMALL}
            theme={BRAND}
            shade={3}>
            More
          </Button>
        </ButtonMenu>
      )}
    </li>
  )
}

export default CartListItem

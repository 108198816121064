import React from 'react'

import Container from 'components/styleguide/shared/Container'
import Stage from 'components/styleguide/shared/Stage'
import Cart from 'components/cart'
import CartItem from 'components/cart/CartItem'
import CartListItem from 'components/cart/CartListItem'

export default function CartComponents({ order }) {
  return (
    <Container heading='7. Cart'>
      <Stage
        width={500}
        subheading='Cart/index.jsx'
      >
        <Cart cartItems={order.items} />
      </Stage>
      <Stage
        width={500}
        heading='Cart item details'
        subheading='Cart/CartItem.jsx'
      >
        <CartItem
          className='shadow-3'
          cartItem={order.items[0]}
        />
      </Stage>
      <Stage
        width={500}
        heading='Cart list item'
        subheading='Cart/CartListItem.jsx'
      >
        <CartListItem
          className='shadow-3 bg-shade-0'
          cartItem={order.items[0]}
        />
      </Stage>
    </Container>
  )
}

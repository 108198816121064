import getCompactPrice from 'components/helpers/getCompactPrice'
import convertToFractional from './convertToFractionalAmount'

export default function getVariablePriceRange(variablePricingAmounts = []) {
  return (
    getCompactPrice(getLowest(variablePricingAmounts)) +
    ' - ' +
    getCompactPrice(getHighest(variablePricingAmounts))
  )
}

// TODO: Ideally the backend returns this variable ordered lowest to highest
// and these reduce functions can be simplified to just return the first and
// last by array index.
function getLowest(amountsArray) {
  return amountsArray.reduce((acc, amount) => {
    amount = convertToFractional(amount)
    return parseFloat(amount.fractional) < acc ? parseFloat(amount.fractional) : acc
  }, Infinity)
}

function getHighest(amountsArray) {
  return amountsArray.reduce((acc, amount) => {
    amount = convertToFractional(amount)
    return parseFloat(amount.fractional) > acc ? parseFloat(amount.fractional) : acc
  }, 0)
}

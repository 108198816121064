import React, { useState, useEffect, useRef } from 'react'
import classNames from 'classnames'
import { motion } from 'framer-motion'

import { EXPAND, CLOSE } from 'components/constants'
import Icon from 'components/shared/Icon'

const ExpandableListItem = ({
  expandable=true,
  renderLineItem,
  renderExpandedSection,
  id,
  limitedColumns = false,
  expanded = false,
  onExpand,
  onMinimise,
}) => {
  const container = useRef(null)
  const [isExpanded, setExpanded] = useState(expanded)

  useEffect(() => setExpanded(expanded), [expanded])

  return (
    <motion.li
      { ...getAnimationProps() }
      ref={container}
      data-list-item-id={id}
      className={classNames({
        'transition-scale-shadow': true,
        'relative align-items-center overflow-hidden': true,
        'hover:z-2 hover:rounded-2 hover:bg-shade-1 hover:shadow-3': !isExpanded,
        'bg-shade-0 rounded-2 shadow-4 z-2': isExpanded,
        'z-3': isExpanded,
        [classNames]: classNames,
      })}
    >
      <div className='relative'>
        {renderLineItem(isExpanded, setExpanded)}
        {!limitedColumns && expandable && getTriggerButton() }
      </div>
      {isExpanded && renderExpandedSection && (
        <aside
          className='border-top p-3'
        >
          {renderExpandedSection()}
        </aside>
      )}
    </motion.li>
  )

  function getTriggerButton() {
    const size = { width: 70, height: 70 }

    return (
      <button
        data-expand
        tabIndex='-1'
        className='absolute translate-y--50 top-50 right-0 flex align-items-center justify-center'
        style={{ ...size }}
        onClick={() => {
          isExpanded ? onMinimise && onMinimise() : onExpand && onExpand()
          setExpanded(!isExpanded)
        }}
      >
        {isExpanded === false && <Icon type={EXPAND} size={20} />}
        {isExpanded && <Icon type={CLOSE} size={18} />}
      </button>
    )
  }

  function getAnimationProps() {
    return {
      transition: { duration: 0.005 },
      animate: { scale: isExpanded ? 1.02 : 1 },
      whileHover: { scale: isExpanded ? 1.02 : 1.01 },
    }
  }
}

export default ExpandableListItem

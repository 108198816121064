import REGISTERS from 'data/dummy/registers'
import { USE_DUMMY_DATA } from 'constants/settings'

import { dir, log } from 'dev/log'
import authenticatedFetch from './authenticatedFetch'

export function fetchRegisters(
  onSuccess,
  onError = () => alert('Error fetching registers')
) {
  log('Fetching registers...')

  if (USE_DUMMY_DATA) return onSuccess(REGISTERS)

  if (navigator.onLine) {
    authenticatedFetch(
      'registers',
      { method: 'GET' },
      // Success
      ({ registers }) => {
        dir(registers)
        onSuccess(registers)
      },
      // Error
      () => onError()
    )
  }
  // Offline
  else onError()
}

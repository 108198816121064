import React, { useRef, useEffect } from 'react'
import { motion } from 'framer-motion'
import classNames from 'classnames'
import getAnimation from 'helpers/getAnimation'
import getCartItemElemById from 'components/checkout/helpers/getCartItemElemById'

const ScrollableList = ({ className, focusedElemId, children, transition }) => {
  const list = useRef(null)

  // TODO

  useEffect(() => {
    const focusedElem = getCartItemElemById(focusedElemId, list.current)

    if (list.current === null) return
    if (focusedElemId && focusedElem) setScrollPosition(focusedElem)
  })

  function setScrollPosition(focusedElem) {
    list.current.scrollBottom = focusedElem.offsetTop
  }

  return (
    <motion.ul
      ref={list}
      className={classNames({
        'relative expand-x depth-neutral height-100': true,
        [className]: className
      })}
      style={{ scrollBehavior: focusedElemId ? 'auto' : 'smooth', overflowY: 'auto' }}
      {...getAnimation(transition)}
    >
      {children}
    </motion.ul>
  )
}

export default ScrollableList

import React, { useState } from 'react'

import { SMALL } from 'components/constants'

import ProductPricingSelector from 'components/products/ProductPricingSelector'
import DropdownSelector from 'components/shared/DropdownSelector'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const ProductPricingDropdown = ({
  product = {},
  name,
  onSetPrice,
}) => {
  const [price, setPrice] = useState(product.price)

  function select(amount) {
    setPrice(amount)
    onSetPrice(amount)
  }

  function createOptions() {
    return product.variable_pricing_amounts.map((amount) => {
      const floatAmount = parseFloat(amount.fractional)
      return {
        label: getFormattedPrice(floatAmount),
        value: floatAmount,
      }
    })
  }

  return (
    <DropdownSelector
      label='Unit price'
      rounded={2}
      shadow={1}
      relative={false}
      size={SMALL}
      text={price}
      options={createOptions()}
      selectedOption={{ label: getFormattedPrice(price), value: price }}
      onSelect={(amount) => select(amount)}
    >
      <ProductPricingSelector
        name={name}
        product={product}
        onChange={(amount) => select(amount)}
      />
    </DropdownSelector>
  )
}

export default ProductPricingDropdown

import React from 'react'

import { HUGE } from 'components/constants'
import { BRAND } from 'components/constants'
import { COLUMN } from 'components/constants'

import ButtonMenu from 'components/shared/ButtonMenu'
import Container from 'components/shared/Container'
import Heading from 'components/shared/Heading'
import Loader from 'components/shared/Loader'
import Topbar from 'components/navigation/Topbar'
import UserButtonLineItem from 'components/users/shared/UserButtonLineItem'
import View from 'components/shared/View'

const ShiftUser = ({
  fetchingUsers = false,
  animation,
  className,
  theme = BRAND,
  shade = 5,
  users = {},
  onSetUser,
  onBack,
}) => {
  return (
    <View
      gridTemplateRows='auto 1fr'
      animation={animation}
      theme={theme}
      shade={shade}
      className={className}
    >
      <Topbar
        transitional={true}
        padding={3}
        className='ps-4'
        theme={theme}
        shade={shade}
        onBack={onBack}
      />
      <Container gap={4} maxWidth={700} center={true} className='expand'>
        {fetchingUsers && <Loader />}
        {!fetchingUsers && (
          <>
            <Heading theme={theme} size={HUGE}>
              Select a user
            </Heading>
            <ButtonMenu
              rounded={4}
              direction={COLUMN}
              className='gap-3px overflow-y-auto'
              style={{ maxHeight: 320 }}
            >
              {Object.keys(users).map((userId, index) => (
                <UserButtonLineItem
                  key={index}
                  theme={theme}
                  shade={shade}
                  user={users[userId]}
                  onClick={() => onSetUser(users[userId])}
                />
              ))}
            </ButtonMenu>
          </>
        )}
      </Container>
    </View>
  )
}

export default ShiftUser

import { LAYBY } from 'components/constants'
import { COMPLETE, INCOMPLETE, HOLD, PARKED } from 'components/orders/constants'

export default function getOrderStatusText(orderType = null) {
  if (orderType === null) return 'Complete'
  switch (orderType.toUpperCase()) {
    case PARKED:
      return 'Parked'
    case HOLD:
      return 'Hold'
    case LAYBY:
      return 'Layby'
    case INCOMPLETE:
      return 'Incomplete'
    case COMPLETE:
      return 'Complete'
    default:
      return 'Unknown'
  }
}

import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import SHIPMENTS from 'data/dummy/fulfillments'

import { SMALL } from 'components/constants'
import { NEXT } from 'constants/motion'
import { INCOMING, OUTGOING } from './constants'
import { REQUEST } from './constants'

import Topbar from 'components/navigation/Topbar'
import View from 'components/shared/View'
import Main from 'components/shared/Main'
import Input from 'components/shared/Input'

import ShipmentList from './ShipmentsList'
import ShipmentsFilterMenu from './ShipmentsFiltersMenu'

import MarkShipmentRead from './actions/MarkShipmentRead'
import ShipmentArticle from './ShipmentArticle'
import SetShipment from './actions/SetShipment'

import getIncomingShipments from './helpers/getIncomingShipments'
import getOutgoingShipments from './helpers/getOutgoingShipments'
import getStockRequests from './helpers/getStockRequests'

const TEST_STORE_ID = 'sydney' // TEMP

const Shipments = ({ initialAnimation = NEXT }) => {
  const dispatch = useDispatch()
  const shipment = useSelector((state) => state.shipment)
  const shipments = SHIPMENTS
  const stockRequests = getStockRequests(TEST_STORE_ID, shipments)
  const incoming = getIncomingShipments(TEST_STORE_ID, shipments)
  const outgoing = getOutgoingShipments(TEST_STORE_ID, shipments)

  const [view, setView] = useState(getViewFromShipment(shipment))

  return (
    <View animation={initialAnimation}>
      <Topbar className='border-bottom' />
      <Main gridTemplateColumns='70px 2fr 3fr'>
        <ShipmentsFilterMenu
          onClick={(view) => setView(view)}
          stockRequestCount={stockRequests.length}
        />
        <section className='relative border-right bg-shade-0 expand overflow-y-auto'>
          <div className='sticky top-0 p-3'>
            <Input
              className='border'
              placeholder='Search stock transfers'
              size={SMALL}
              shadow={2}
            />
          </div>
          <header className='font-size-2 font-weight-3 color-shade-6 pe-2 ps-3'>
            {getHeadlineByView(view)}
          </header>
          <ShipmentList
            gridTemplateColumns='1fr auto'
            view={view}
            shipments={getShipmentsByView(view)}
            activeShipment={shipment}
            onClick={(shipment) =>{
              dispatch(MarkShipmentRead(shipment.id))
              dispatch(SetShipment(shipment))
            }}
          />
        </section>
        <div className='flex col gap-1px'>
          {Object.keys(shipment).length > 0 && (
            <ShipmentArticle shipment={shipment} view={view} />
          )}
        </div>
      </Main>
    </View>
  )

  function getShipmentsByView(id) {
    switch (id) {
      case INCOMING: return incoming
      case OUTGOING: return outgoing
      case REQUEST: return stockRequests
    }
  }

  function getHeadlineByView(id) {
    switch (id) {
      case INCOMING: return 'Incoming stock transfers'
      case OUTGOING: return 'Outgoing stock transfers'
      case REQUEST: return 'Stock requests'
    }
  }

  function getViewFromShipment(shipment) {
    if (Object.keys(shipment).length === 0) return REQUEST
    if (shipment.origin.id === TEST_STORE_ID && shipment.status === REQUEST) return REQUEST
    else if (shipment.origin.id === TEST_STORE_ID) return OUTGOING
    else if (shipment.destination.id === TEST_STORE_ID) return INCOMING
    else return REQUEST
  }
}

export default Shipments

import { LOCALE, CURRENCY_CODE } from 'constants/settings'

export default function getFormattedPrice(
  amount = null,
  stripTrailingZeros = false,
) {
  if (amount === null) return null

  const options = {
    style: 'currency',
    currency: getCurrencySymbol(),
    minimumFractionDigits: stripTrailingZeros ? 0 : 2,
    maximumFractionDigits: 2,
    trailingZeroDisplay: stripTrailingZeros ? 'stripIfInteger' : 'auto',
  }

  return new Intl.NumberFormat(LOCALE, options).format(amount)
}

function getCurrencySymbol() {
  const currency = window.localStorage.getItem('currency')

  switch (currency) {
    case 'AUD':
      // Intl.NumberFormat will show A$ for AUD. In this case
      // we want to show the generic $ symbol.
      return CURRENCY_CODE
    default:
      return currency || CURRENCY_CODE
  }
}

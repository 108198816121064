import { USER_ANG, USER_ANDY } from 'data/dummy/users'

export default [
  {
    id: 1,
    storeId: 1,
    startedAt: '2022-01-01',
    endedAt: '2022-01-02',
    startBalance: 100,
    endBalance: 0,
    user: USER_ANG,
    users: [USER_ANG],
  },
  {
    id: 2,
    storeId: 2,
    startedAt: '2022-01-02',
    startBalance: 1000,
    endBalance: 0,
    user: USER_ANDY,
    users: [USER_ANDY],
  }
]

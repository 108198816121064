import SETTINGS_DATA from 'data/settingsData'

// This aims to pick up changes in SETTINGS_DATA which is declared
// in our codebase but at the same time retain existing settings in browser storage
function getUpdatedSettings(settingsStored, settingsFromCode) {
  const printerKeysSet = new Set(settingsStored.printers.map(printer => printer.key))
  const printers = [
    ...settingsStored.printers,
    ...settingsFromCode.printers.filter(printer => !printerKeysSet.has(printer.key))
  ]

  let existingContexts = settingsStored.contexts ? settingsStored.contexts : []
  const contextKeysSet = new Set(existingContexts.map(context => context.key))

  // Add the new attribute to each existing context
  existingContexts = existingContexts.map(context => {
    let updatedContext = context
    const matchingContext = settingsFromCode.contexts.find(c => c.key === context.key)
    if (matchingContext) {
      updatedContext = {...matchingContext, ...updatedContext}
    }
    return updatedContext
  })

  const contexts = [
    ...existingContexts,
    ...settingsFromCode.contexts.filter(context => !contextKeysSet.has(context.key))
  ]

  const drawer = { ...settingsStored.drawer }
  const rulePaymentMethodsSet = new Set(drawer.openDrawerRules.map(rule => rule.paymentMethod))
  const rules = [
    ...drawer.openDrawerRules,
    ...settingsFromCode.drawer.openDrawerRules.filter(rule => !rulePaymentMethodsSet.has(rule.paymentMethod))
  ]

  const tyro = {
    ...settingsFromCode.tyro,
    ...settingsStored.tyro
  }

  return {
    ...settingsStored,
    printers,
    contexts,
    drawer: { ...drawer, openDrawerRules: rules },
    tyro
  };
}

function buildSettings() {
  const currentSettingsStored = window.localStorage.getItem('settings')
  if (currentSettingsStored === null) return SETTINGS_DATA
  return getUpdatedSettings(JSON.parse(currentSettingsStored), SETTINGS_DATA)
}

export default buildSettings
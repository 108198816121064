import { USE_DUMMY_DATA } from 'constants/settings'
import REGISTERS from 'data/dummy/registers'

import authenticatedFetch from 'data/api/authenticatedFetch'
import { dir, log } from 'dev/log'

export function connectRegister(
  register = {},
  onSuccess,
  onError = () => alert('Error connecting register'),
) {
  log('Connecting register...')

  if (USE_DUMMY_DATA) return onSuccess({ ...REGISTERS[0], connected: true })

  if (navigator.onLine) {
    authenticatedFetch('registers/' + register.id,
      {
        method: 'PATCH',
        body: JSON.stringify({ connected: true }),
      },
      // Success
      () => {
        dir({ ...register, connected: true })
        onSuccess({ ...register, connected: true })
      },
      // Error
      () => onError()
    )
  }
  // Offline
  else onError()
}

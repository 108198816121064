import React from 'react'
import classNames from 'classnames'

const StickyHeader = ({
  children,
  className,
  zIndex = 2,
}) => {
  return (
    <header className={classNames({
      'sc-sticky-header sticky top-0': true,
      ['z-' + zIndex]: true,
      [className]: className,
    })}>
      {children}
    </header>
  )
}

export default StickyHeader

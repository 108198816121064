import React from 'react'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const OrderDiscountLineItem = ({ discount, orderDiscount }) => {
  let array = []
  if (discount) {
    let priceString = discount < 0 ? `+${getFormattedPrice(discount*-1)}` : `-${getFormattedPrice(discount)}`,
        titleString = discount < 0 ? "Price Adjustment" : "Discount Total"
    array.push (
      <div className='flex row align-items-baseline justify-space-between' data-total-discount key="line-item-discounts">
        <span>{titleString}</span>
        <span className='color-notify-5'>
          {priceString}
        </span>
      </div>
    )
  }
  if (orderDiscount) {
    let priceString = orderDiscount < 0 ? `+${getFormattedPrice(orderDiscount*-1)}` : `-${getFormattedPrice(orderDiscount)}`,
        titleString = orderDiscount < 0 ? "Order Price Adjustment" : "Order Discount Total"
    array.push (
      <div className='flex row align-items-baseline justify-space-between' data-total-discount key="order-level-discounts">
        <span>{titleString}</span>
        <span className='color-notify-5'>
          {priceString}
        </span>
      </div>
    )
  }
  if (!discount && !orderDiscount) { 
    return null 
  } else {
    return [...array]
  }
}

export default OrderDiscountLineItem

import React, { useState } from 'react'
import { useDispatch } from 'react-redux'

import { NEXT } from 'constants/motion'
import { BRAND, NEUTRAL, LARGE } from 'components/constants'
import { REGISTER } from 'components/registers/constants'

import SetViewAnimation from 'components/navigation/actions/SetViewAnimation'
import SetViewId from 'components/navigation/actions/SetViewId'

import Container from 'components/shared/Container'
import Loader from 'components/shared/Loader'
import Field from 'components/shared/Field'
import Form from 'components/shared/Form'
import Button from 'components/shared/Button'

import authenticate from 'data/api/authenticate'

const Authentication = ({
  theme = NEUTRAL,
  shade = 1,
}) => {
  const dispatch = useDispatch()
  const [username, setUsername] = useState(null)
  const [secret, setSecret] = useState(null)
  const [pin, setPin] = useState(null)
  const [loading, setLoading] = useState(false)

  function handleSubmit() {
    setLoading(true)
    authenticate(
      { username, secret, pin },
      () => {
        setLoading(false)
        dispatch(SetViewAnimation(NEXT))
        dispatch(SetViewId(REGISTER))
      },
      () => {
        setLoading(false)
        alert('Invalid email, register code, or pin code.')
      }
    )
  }

  if (loading) return <Loader />

  return (
    <Form
      className='expand'
      theme={theme}
      shade={shade}
      onSubmit={(event) => {
        event.preventDefault()
        handleSubmit()
      }}
    >
      {loading ? 'loading' : ''}
      <Container maxWidth={700} center={true} gap={5}>
        <fieldset className='flex col gap-3'>
          <Field
            label='Username'
            type='email'
            id='username'
            shade={0}
            theme={BRAND}
            onChange={(value) => setUsername(value)}
            defaultValue={username}
            shadow={1}
            required={true}
          />
          <div className='flex gap-3'>
            <Field
              label='Register code'
              type='text'
              id='secret'
              shade={0}
              shadow={1}
              required={true}
              theme={BRAND}
              defaultValue={secret}
              onChange={(value) => setSecret(value)}
            />
            <Field
              id='pin'
              type='password'
              label='Pin code'
              shade={0}
              shadow={1}
              required={true}
              theme={BRAND}
              defaultValue={pin}
              onChange={(value) => setPin(value)}
            />
          </div>
        </fieldset>
        <Button
          type='submit'
          shade={5}
          theme={BRAND}
          size={LARGE}
          autoFocus={true}
        >
            Log in
        </Button>
      </Container>
    </Form>
  )
}

export default Authentication

import React from 'react'
import classNames from 'classnames'

import { SMALL } from 'components/constants'
import { CHEVRON } from 'components/constants'
import { COMPLETE } from 'components/orders/constants'

import ButtonLineItem from 'components/shared/ButtonLineItem'
import Icon from 'components/shared/Icon'
import Tag from 'components/shared/Tag'
import Record from 'components/shared/Record'

import getRemainingRefundAmount from 'components/orders/helpers/getRemainingRefundAmount'
import isFullySettled from 'components/orders/helpers/isFullySettled'
import convertToAbsoluteAmountValue from 'components/helpers/convertToAbsoluteAmountValue'
import getDateLocalTimezone from 'components/helpers/getDateLocalTimezone'
import getCustomerDisplayName from 'components/customers/helpers/getCustomerDisplayName'
import getOrderTheme from 'components/orders/helpers/getOrderTheme'
import getOrderStatusText from 'components/orders/helpers/getOrderStatusText'
import getAmountOwed from 'components/orders/helpers/getAmountOwed'
import getFormattedPrice from 'components/helpers/getFormattedPrice'
import hasRefund from 'components/order/helpers/hasRefund'

const OrderLineItem = ({
  customer,
  first,
  last,
  gridTemplateColumns = '300px 100px 200px 1fr auto',
  order = {},
  onClick,
}) => {
  const amountOwed = getAmountOwed(order)
  const amountToRefund = getRemainingRefundAmount(order)
  const _hasRefund = hasRefund(order)

  function showAmountOwed() {
    return !_hasRefund && order.orderType && order.orderType !== COMPLETE
  }

  function showAmountToRefund() {
    return _hasRefund && isFullySettled(order) === false
  }

  return (
    <ButtonLineItem
      padding={4}
      onClick={onClick}
      gridTemplateColumns={gridTemplateColumns}
      className={classNames({
        'bg-shade-0': true,
        'rounded-top-2': first === true,
        'rounded-bottom-2': last === true,
      })}
    >
      <span className='flex col gap-1'>
        <div className='font-weight-3'>{order.reference_number}</div>
        <div className='font-size-1 color-shade-4'>
          Created {getDateLocalTimezone(order.created)}
        </div>
        <div className='font-size-1 color-shade-4'>
          Register: {order.register_name}
        </div>
        <div className='font-size-1 color-shade-4'>
          Register User: {order.register_shift_user_name}
        </div>
      </span>
      <span>
        <Tag
          shade={3}
          size={SMALL}
          text={getOrderStatusText(order.orderType)}
          theme={getOrderTheme(order.orderType)}
        />
      </span>
      <span className='font-weight-2 opacity-07'>
        {customer && getCustomerDisplayName(customer)}
      </span>
      <span className='flex gap-5 pr-4'>
        {showAmountOwed() && (
          <Record label='Amount to pay' showEmptyValue={true} size={SMALL}>
            {getFormattedPrice(convertToAbsoluteAmountValue(amountOwed))}
          </Record>
        )}
        {showAmountToRefund() && (
          <Record label='Amount to refund' showEmptyValue={true} size={SMALL}>
            {getFormattedPrice(convertToAbsoluteAmountValue(amountToRefund))}
          </Record>
        )}
        <Record label='Total' showEmptyValue={true} size={SMALL}>
          {getFormattedPrice(order.total)}
        </Record>
      </span>
      <Icon className='opacity-06' type={CHEVRON} size={14} rotate={270} />
    </ButtonLineItem>
  )
}

export default OrderLineItem

import getUserFullName from 'components/users/helpers/getUserFullName'

export default function getContactDrop(customerData) {
  // Customer may be undefined for anonymous sales
  const customer = customerData || {}

  return {
    __data: {},
    account: {},
    campaigns: [],
    can_purchase_for_account: false,
    can_use_account_credit: false,
    can_use_account_points: false,
    email: customer.email || "",
    firstname: customer.firstname || "",
    has_login: false,
    id: customer.id,
    lastname: customer.lastname || "",
    mailing_address_lines: [],
    mailing_city: "",
    mailing_country: "",
    mailing_postal_code: "",
    mailing_state: "",
    mailing_street: "",
    membership: {},
    mobile_phone: customer.phone || "",
    name: getUserFullName({firstName: customer.firstName, lastName: customer.lastname}),
    orders: [],
    phone: customer.phone || "",
    subscriptions: [],
    username: customer.email || "",
  }
}
import { RESERVED_PRODUCTS } from 'components/constants'

export default function getReservedItemsTotal(items = []) {
  if (items === undefined) return 0

  const reservedProducts = Object.values(RESERVED_PRODUCTS)

  return items
    .filter(item => reservedProducts.includes(item.type))
    .map(item => item.price)
    .reduce((a, b) => a + b, 0)
}


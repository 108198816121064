import React from 'react'
import classNames from 'classnames'

import {
  VIEWABLE_ORDER_ITEMS,
  UPDATE,
  LARGE,
  BRAND,
  CLOSE,
  NOTIFY,
  SMALL,
} from 'components/constants'

import LineItem from 'components/shared/LineItem'
import CheckoutPrice from 'components/checkout/shared/CheckoutPrice'
import Icon from 'components/shared/Icon'

import getFormattedPrice from 'components/helpers/getFormattedPrice'

const OrderItemsBlock = ({
  actionable = false,
  className,
  gridTemplateColumns = '4fr 1fr 2fr 2fr',
  adjustmentItems = [],
  items = [],
  onSelect,
  onDelete,
}) => {
  const filteredItems = items.filter((item) => VIEWABLE_ORDER_ITEMS.includes(item.type))

  let _gridTemplateColumns = gridTemplateColumns

  if (actionable) {
    _gridTemplateColumns = '4fr 1fr 2fr 2fr 1fr'
  }

  function itemCurrentlyAdjusing(item) {
    const adjustment = adjustmentItems.find((adjustmentItem) => adjustmentItem.originalItemId === item.id)
    return adjustment !== undefined
  }

  function renderOrderItem(item, index) {
    return (
      <li
        className={classNames({
          'flex relative': true,
          'border-top': index > 0,
          'hover:bg-shade-1': onSelect !== undefined,
          'color-notify-5': item.quantity < 0 && item.newAdjustment === true,
          'color-brand-5': item.quantity > 0 && item.newAdjustment === true,
        })}
        style={{ minHeight: 80 }}
        key={item.id}
      >
        <LineItem
          gridTemplateColumns={_gridTemplateColumns}
          divider={true}
          className={classNames({
            'sc-receipt-line-item pe-3 ps-4': true,
          })}
        >
          <span className='flex col gap-1 pr-4'>
            <strong className='sc-receipt-headline font-weight-3'>{item.name}</strong>
            <div className='sc-receipt-subheadline'>{getSerialNumbers(item)}</div>
          </span>
          <span id='quantity' className='sc-receipt-qty font-weight-2 font-size-2'>
            {item.quantity}
          </span>
          <span className='sc-receipt-price font-weight-2 font-size-2'>
            {getFormattedPrice(item.price)}
          </span>
          <CheckoutPrice
            price={item.price}
            discount={item.discount}
            quantity={item.quantity}
          />
          {actionable && !itemCurrentlyAdjusing(item) && (
            <span className="flex row justify-end">
              <button
                id='adjust'
                onClick={() => {
                  if (onSelect) onSelect(item)
                }}
              >
                <Icon
                  type={UPDATE}
                  size={LARGE}
                  theme={BRAND}
                  shade={3}
                />
              </button>

              {item.newAdjustment && (
                <button
                  id='remove'
                  onClick={() => {
                    if (onDelete) onDelete(item)
                  }}
                >
                  <Icon
                    type={CLOSE}
                    size={SMALL}
                    theme={NOTIFY}
                    shade={5}
                  />
                </button>
              )}
            </span>
          )}
        </LineItem>
      </li>
    )
  }

  return (
    <div className={classNames({
      'sc-receipt': true,
      [className]: className,
    })}>
      <ul data-order-item id="order-items" className='overflow-hidden'>
        <header
          className={classNames({
            'grid pt-4 pb-3 ps-4': true,
            'border-bottom color-shade-5 font-size-2 font-weight-2': true,
          })}
          style={{ gridTemplateColumns: _gridTemplateColumns }}>
          <span>Product name</span>
          <span>Qty</span>
          <span>Unit price</span>
          <span className='sc-checkout-price text-right'>Total price</span>
          {actionable && (
            <span />
          )}
        </header>
        <div className='sc-receipt-line-items'>
          {filteredItems.map((item, index) => {
            return renderOrderItem(item, index)
          }
          )}
        </div>
      </ul>
    </div>
  )

  function getSerialNumbers(item) {
    if (item.serial_numbers.length === 0) return null
    if (item.serial_numbers.length === 1)
      return 'Serial number: ' + item.serial_numbers[0]
    else
      return (
        'Serial numbers: ' +
        item.serial_numbers
          .slice(0, -1)
          .join(', ')
          .replace(/, ([^,]*)$/, ', $1.')
      )
  }
}

export default OrderItemsBlock

import { SET_SHIPMENT } from '../constants'

export default function (state = {}, action) {
  switch (action.type) {
    case SET_SHIPMENT:
      return setLocalStorage(action.shipment)
    default:
      return state
  }
}

function setLocalStorage(payload) {
  window.localStorage.setItem('shipment', JSON.stringify(payload))
  return payload
}

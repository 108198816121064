import ThermalPrinterEncoder from 'thermal-printer-encoder/dist/thermal-printer-encoder.esm.js';

import { log } from 'dev/log'
import {
  BARCODE_POSITION_MAP,
  BARCODE_TYPE_MAP,
  STAR_PRNT_BARCODE_VALUES,
  ESC_POS_BARCODE_VALUES,
} from 'constants/settings'

import parseTPML from 'helpers/tpmlParser.mjs'

class ReceiptPrinter {
  constructor(liquifiedTemplate, printer, device, failedCallback) {
    this.template = liquifiedTemplate
    this.printer = printer
    this.device = device
    this.failedCallback = failedCallback
  }

  print(callback = null) {
    let instructions = this.buildIntructions(this.template)

    const documentIndex = instructions.findIndex(i => i.name == 'document')
    const doc = instructions[documentIndex]

    // remove document from instructions
    instructions.splice(documentIndex, 1)

    const encoder = new ThermalPrinterEncoder({
      language: this.printer.language,
      width: this.printer.width,
      wordWrap: doc.attributes.wordWrap,
      imageMode: this.printer.imageMode
    })

    this.loadImages(instructions).then((images) => {
      let commands = encoder.initialize()
      instructions.push(...this.endCommands())
      for (const instruction of instructions) {
        commands = this.receiptCommand(instruction, commands, doc, images);
      }

      this.device.transferOut(1, commands.encode())
        .then(() => {
          if (callback) callback()
        })
        .catch(error => {
          log(error)
          this.failedCallback()
        })
    })
  }

  // This can be handy if we want to test this parser
  buildIntructions(template) {
    return parseTPML(template)
  }

  endCommands() {
    return [
      { name: "bottom-margin" },
      { name: "cut", value: "partial" },
    ]
  } 

  receiptCommand(command, chain, document, images) {
    const name = command.name
    const attributes = command.attributes
    const value = command.value
    const off = command.off === true
  
    switch (name) {
      case "center":
        return chain.align('center')
      case "left":
        return chain.align('left')
      case "right":
        return chain.align('right')
      case "image":
        let image = images.shift()
        if (!image)
          return chain
  
        let width = attributes.width
        let height = attributes.height
        let size = attributes.size
        if (size) {
          // size is a percentage of the width
          // calculate the width based on the percentage of printer width (dots)
          width = parseInt((size / 100) * this.printer.dots)
  
          // calculate the height based on the aspect ratio of the image
          height = parseInt((image.height / image.width) * width)
  
          // ensure both width and height are multiples of 24
          width = parseInt(width / 24) * 24
          height = parseInt(height / 24) * 24
        }

        return chain.image(
          image,
          width,
          height,
          attributes.dither
        );
      case "line":
        return chain.line(value || "");
      case "rule":
        if (attributes.line == "dashed") {
          let character = attributes.style == "double" ? "=" : "-"
  
          return chain.line(character.repeat(attributes.width || this.printer.chars))
        } else {
          return chain.rule({ width: attributes.width || this.printer.chars, style: attributes.style })
        }
      case "text":
        return chain.text(value || "")
      case "bold":
        return chain.bold(!off)
      case "underline":
        return chain.underline(!off)
      case "invert":
        return chain.invert(!off)
      case "italic":
        return chain.italic(!off)
      case "small":
        if (off)
          return chain.size("small")
        else
          return chain.size("normal")
      case "height":
        return chain.height(value)
      case "width":
        return chain.width(value)
      case "barcode":
        return chain.raw(this.encodeBarcode(document.attributes, attributes))
      case "qrcode":
        return chain.qrcode(attributes.data, attributes.model, attributes.size, attributes.errorLevel)
      case "table":
        const margin = attributes.margin || 0
        let columns = []
        let widthAvailable = this.printer.chars
        let splitWidth = parseInt(this.printer.chars / attributes.cols)
  
        for (var i = 0; i < attributes.cols; i++) {
          let align = attributes.align ? attributes.align[i] : "left"
          let width
          let colMargin = margin
  
          // final column doesn't need margin
          if (i + 1 == attributes.cols) {
            colMargin = 0
          }
  
          if (attributes.width) {
            if (attributes.width[i] === undefined) {
              // no more widths are set so split remaining width evenly
              width = parseInt(widthAvailable / (attributes.cols - i)) - colMargin;
            } else if (attributes.width[i] === "*") {
              // width is set to wildcard (*) so expand to fill remaining width
              let remainingWidths = attributes.width.slice(i + 1)
              if (remainingWidths.length > 0) {
                let remainingWidth = remainingWidths.reduce((total, item) => total + item)
                let remainingMargin = (attributes.cols - i - 1) * margin
                width = widthAvailable - remainingWidth - remainingMargin
              } else {
                width = widthAvailable
              }
            } else {
              width = attributes.width[i]
            }
          } else {
            // no widths set so split evenly, factoring in margin
            width = splitWidth - colMargin
          }
          widthAvailable -= (width + colMargin)
  
          columns.push({
            align: align,
            marginRight: colMargin,
            width: width,
          })
        }
  
        return chain.table(
          columns,
          attributes.rows
        )
      case "bottom-margin":
        return chain.newline().newline().newline().newline().newline().newline()
      case "cut":
        return chain.cut(value)
      default:
        log(`Unknown command type: ${name}`)
    }
  
    return chain;
  }

  encodeBarcode(_document, attributes) {
    let language = this.printer.language
    let type = BARCODE_TYPE_MAP[language][attributes.type.toUpperCase()]
    let position = BARCODE_POSITION_MAP[language][attributes.position]

    let barcodeData = attributes.data.split("").map(function (c) { return c.charCodeAt(0) })

    switch (language) {
      case "star-prnt":
        return [
          STAR_PRNT_BARCODE_VALUES.START_MARKER,
          STAR_PRNT_BARCODE_VALUES.BARCODE_MARKER,
          type,
          position,
          STAR_PRNT_BARCODE_VALUES.BARCODE_MODE,
          attributes.height,
          ...barcodeData,
          STAR_PRNT_BARCODE_VALUES.END_MARKER
        ]
      case "esc-pos":
        const width = 3
        let widthMarker = ESC_POS_BARCODE_VALUES.WIDTH_MARKER
        let heightMarker = ESC_POS_BARCODE_VALUES.HEIGHT_MARKER
        let typeMarker = [...ESC_POS_BARCODE_VALUES.TYPE_MARKER, type]

        if (attributes.type.toUpperCase() == "CODE128") {
          barcodeData = [barcodeData.length + 2, 0x7b, 0x42, ...barcodeData]
        } else if (type > 0x40) {
          barcodeData = [barcodeData.length, ...barcodeData]
        } else {
          barcodeData = [ ...barcodeData, 0x00]
        }

        return [
          position,
          heightMarker,
          attributes.height,
          widthMarker,
          width,
          typeMarker,
          ...barcodeData
        ].flat()
      default:
        return ""
    }
  }

  async loadImages(instructions) {
    let images = []

    for (const instruction of instructions) {
      if (instruction.name == "image") {
        images
          .push(await this.loadImage(instruction.attributes.src)
          .catch(error => {
            log(error)
            return null
          }
        ))
      }
    }

    return images
  }

  loadImage(src) {
    return new Promise((resolve, reject) => {
      let image = new Image();
      image.crossOrigin = "anonymous";
      image.onload = () => resolve(image)
      image.onerror = (error) => {
        reject("couldn't load image")
        log(error)
      };
      image.src = src
    });
  }
}

export default ReceiptPrinter
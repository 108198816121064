import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import ORDERS from 'data/dummy/orders'
import { USE_DUMMY_DATA } from 'constants/settings'
import { dir, log } from 'dev/log'

import { PARKED } from 'components/orders/constants'
import { fetchCacheVersions } from 'data/api/fetchCacheVersions'
import { fetchOrders } from 'data/api/fetchOrders'
import { syncOrder } from 'data/api/syncOrder'

import getLocalStorage from 'data/helpers/getLocalStorage'
import getSyncTimeout from 'data/helpers/getSyncTimeout'
import setLocalStorage from '../data/helpers/setLocalStorage'
import SetSyncInProgress from '../components/online/actions/SetSyncInProgress'
import SetOnline from '../components/online/actions/SetOnline'


const useSyncOrders = ({ poll = false }) => {
  const dispatch = useDispatch()
  const syncTimeout = getSyncTimeout()
  const [orders, setOrders] = useState(getLocalStorage('orders', []))
  const [fetchingOrders, setFetchingOrders] = useState(false)
  const [syncingOrder, setSyncingOrder] = useState(false)

  let syncInterval = null

  useEffect(() => {
    if (USE_DUMMY_DATA) return setOrders(ORDERS)

    if (navigator.onLine) {
      // Fetch orders from server and update client. Orders are
      // synced on checkout so we should not have any existing
      // unsynced orders on the client.
      if (orders.length === 0) {
        setFetchingOrders(true)
        fetchOrders((orders) => {
          safelySetOrders(orders)
        })
      }
      if (poll === false) return
      // While online we check periodically for shift and order
      // changes on the server and sync them to the client. In
      // most cases where we are just accessing or setting the
      // client state this is not necessary.
      syncInterval = setInterval(() => {
        fetchCacheVersions('orders', () => {
          setFetchingOrders(true)
          fetchOrders((orders) => {
            safelySetOrders(orders)
          })
        },
          (online) => { dispatch(SetOnline(online)) }
        )
      }, syncTimeout)
    }
    return () => clearInterval(syncInterval)
  }, [])

  // Update local storage when orders change
  useEffect(() => {
    setFetchingOrders(false)

    const sortedOrders = sortOrders(orders)
    setOrders(sortedOrders)
    setLocalStorage('orders', sortedOrders)
  }, [orders])

  useEffect(() => {
    dispatch(SetSyncInProgress('orders', fetchingOrders))
  }, [fetchingOrders])

  /**
   * Methods
   */

  // This will be important so that we can retain orders such
  // as parked orders and currently on sync state orders
  function safelySetOrders(persistedOrders) {
    const parkedOrders = orders.filter((order) => order.orderType === PARKED)
    const onSyncOrders = orders.filter((order) => order.sync === true)
    let updatedOrders = [...parkedOrders, ...persistedOrders]

    // We need to retain orders currently sync true
    onSyncOrders.map((syncOrder) => {
      const matchedPersistedOrderIndex = updatedOrders.findIndex((persistedOrder) => persistedOrder.id === syncOrder.id)
      if (matchedPersistedOrderIndex > -1) {
        updatedOrders[matchedPersistedOrderIndex] = {...updatedOrders[matchedPersistedOrderIndex], sync: true}
      } else {
        updatedOrders = [...updatedOrders, syncOrder]
      }
    })

    setOrders(sortOrders(updatedOrders))
  }

  function createOrUpdateOrder(
    order,
    onSuccess = () => log('Order created or updated'),
    onError = null,
  ) {
    findOrder(order.id, orders) ?
      updateOrder(order, onSuccess, onError) :
      createOrder(order, onSuccess, onError)
  }

  function createOrder(
    order,
    onSuccess,
    onError
  ) {
    if (order.sync) {
      setSyncingOrder(true)
      syncOrder(order,
        // Success
        () => {
          log('Order created:')
          dir(order)
          if (onSuccess) onSuccess()
          setOrders([ { ...order, sync: false }, ...orders])
          setSyncingOrder(false)
        },
        // Error
        (response) => {
          log('Error syncing order:')
          dir(order)
          onError(response)
          setOrders([ { ...order, sync: true }, ...orders])
          setSyncingOrder(false)
        },
        // Offline
        () => {
          log('Order created but not synced:')
          dir(order)
          onSuccess()
          setOrders([ { ...order, sync: true }, ...orders])
          setSyncingOrder(false)
        }
      )
    } else {
      // If order is not marked to sync we just store on client
      const updatedOrders = [...orders, order]
      setOrders(updatedOrders)
      // We execute this here in case caller of this hooks unmounted.
      // When unmounted, hooks lifecycle will be gone as well preventing
      // new orders to be added in local storage.
      setLocalStorage('orders', updatedOrders)
    }
  }

  function updateOrder(
    order,
    onSuccess,
    onError
  ) {
    if (order.sync) {
      setSyncingOrder(true)
      syncOrder(order,
        // Success
        () => {
          setOrders(getUpdatedOrders({ ...order, sync: false }))
          onSuccess()
          setSyncingOrder(false)
        },
        // Error
        (response) => {
          setOrders(getUpdatedOrders({ ...order, sync: true }))
          onError(response)
          setSyncingOrder(false)
        },
        // Offline
        () => {
          setOrders(getUpdatedOrders({ ...order, sync: true }))
          onSuccess()
          setSyncingOrder(false)
        }
      )
    } else {
      // If order is not marked to sync we just store on client
      const updatedOrders = getUpdatedOrders(order)
      setOrders(updatedOrders)
      // We execute this here in case caller of this hooks unmounted.
      // When unmounted, hooks lifecycle will be gone as well preventing
      // new orders to be added in local storage.
      setLocalStorage('orders', updatedOrders)
    }
  }

  function sortOrders(orders) {
    return orders.sort((orderA, orderB) => new Date(orderB.created) - new Date(orderA.created))
  }

  function abandonParkedOrder(orderId) {
    // Parked orders are not synced to the server so we only
    // need to ensure it is removed from our client state
    setOrders(orders.filter((o) => o.id !== orderId))
  }


  function findOrder(id, orders) {
    return orders.find((o) => o.id === id)
  }

  function getUpdatedOrders(order) {
    return orders.map((o) => o.id === order.id ? order : o)
  }

  return {
    orders,
    fetchingOrders,
    createOrUpdateOrder,
    abandonParkedOrder,
    createOrder,
    updateOrder,
    syncingOrder
  }
}

export default useSyncOrders

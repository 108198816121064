import React, { useEffect, useState } from 'react'
import classNames from 'classnames'

import { BRAND } from 'components/constants'
import { DECREMENT, INCREMENT } from 'components/constants'
import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Icon from 'components/shared/Icon'

const QuantityPicker = ({
  className,
  size,
  theme,
  quantity,
  disableDeductButton = false,
  disableAddButton = false,
  onDecrement,
  onIncrement,
  skipOverZero = false,
}) => {

  const [lastInput, setLastInput] = useState(undefined)
  
  useEffect(() => {
    if (quantity === 0 && skipOverZero === true) {
      lastInput == DECREMENT && onDecrement()
      lastInput == INCREMENT && onIncrement()
    }
  }, [quantity])

  function handleDecrement() {
    setLastInput(DECREMENT)
    onDecrement()
  }

  function handleIncrement() {
    setLastInput(INCREMENT)
    onIncrement()
  }

  return (
    <ButtonMenu
      rounded={2}
      theme={BRAND}
      shade={6}
      className={classNames({
        'overflow-hidden grid cols-3 gap-2px': true,
        [className]: className,
      })}>
      <Button
        shade={5}
        theme={theme}
        size={size}
        disabled={disableDeductButton}
        onClick={handleDecrement}
        autoFocus={quantity > 1}
        rounded={0}
        data-quantity-decrement>
        <Icon type={DECREMENT} size={20} />
      </Button>
      <Button
        shade={5}
        theme={theme}
        size={size}
        rounded={0}
        tabIndex={-1}
        data-qty>
        {quantity}
      </Button>
      <Button
        shade={5}
        theme={theme}
        size={size}
        disabled={disableAddButton}
        onClick={handleIncrement}
        rounded={0}
        data-quantity-increment>
        <Icon type={INCREMENT} size={20} />
      </Button>
    </ButtonMenu>
  )
}

export default QuantityPicker

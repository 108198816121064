import React, { useEffect, useState } from 'react'

import { HUGE } from 'components/constants'
import { BRAND } from 'components/constants'
import { REQUEST, INCOMING } from './constants'

import Article from 'components/shared/Article'
import Button from 'components/shared/Button'
import ShipmentOutletTransfer from './ShipmentOutletTransfer'

const ShipmentArticle = ({
  animation,
  shipment = {},
  view = INCOMING,
}) => {
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    setLoading(true)
    const timer = setTimeout(() => setLoading(false), 100)
    return () => clearTimeout(timer)
  }, [shipment])

  if (loading) return null

  return (
    <Article
      animation={animation}
      gridTemplateRows='1fr auto'
      className='bg-shade-0 expand'
    >
      <section className='flex col gap-4 pe-4 ps-5'>
        <header className='flex col gap-4'>
          {view === INCOMING && (
            <h1 className='font-size-5'>
              Incoming stock transfer from {shipment.origin.name} for {shipment.product.name} ({shipment.qty} {shipment.qty > 1 ? 'units' : 'unit'})
            </h1>
          )}
          {view === REQUEST && (
            <h1 className='font-size-5'>
              Stock request from {shipment.destination.name} for {shipment.product.name} ({shipment.qty} {shipment.qty > 1 ? 'units' : 'unit'})
            </h1>
          )}
          <ShipmentOutletTransfer
            shipment={shipment}
            type={view}
           />
        </header>
      </section>
      {shipment.status === REQUEST && (
        <Button
          shade={5}
          size={HUGE}
          theme={BRAND}
        >
          Accept stock request
        </Button>
      )}
    </Article>
  )
}

export default ShipmentArticle

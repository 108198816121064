import React from 'react'
import classNames from 'classnames'

import ShipmentLineItem from './ShipmentLineItem'

const ShipmentsList = ({
  shipments = [],
  activeShipment = {},
  view = null,
  onClick,
  className,
}) => {
  return (
    <ul className={classNames({
      'flex col gap-1px': true,
      [className]: className,
    })}>
      {shipments.map((shipment, index) => (
        <ShipmentLineItem
          view={view}
          key={index}
          active={shipment.id === activeShipment.id}
          shipment={shipment}
          onClick={() => onClick(shipment)}
        />
      ))}
    </ul>
  )
}

export default ShipmentsList

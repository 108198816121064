import { ADD_TO_CART } from 'components/checkout/constants'

export default function AddToCart(product, taxes, taxInclusive) {
  return {
    type: ADD_TO_CART,
    product,
    taxes,
    taxInclusive
  }
}

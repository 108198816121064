import React, { useState, useEffect } from 'react'
import classNames from 'classnames'

import {
  BRAND,
  NEUTRAL,
} from 'components/constants'

import Button from 'components/shared/Button'
import ButtonMenu from 'components/shared/ButtonMenu'
import Overlay from 'components/shared/Overlay'
import StickyHeader from 'components/shared/StickyHeader'
import Empty from 'components/shared/Empty'
import ProductList from 'components/products/ProductList'
import SearchInput from 'components/shared/SearchInput'

import getFilteredProducts from 'components/products/helpers/getFilteredProducts'

const ProductSelection = ({
  batchSelectedAction,
  limitedColumns = false,
  batchButtonActionLabel = 'Select',
  currentOutlet,
  defaultKeyword = '',
  preSelectedProducts = [],
  products = [],
  outlets = [],
  onSelectedProductsChange,
  onKeywordChange,
}) => {
  const [filteredProducts, setFilteredProducts] = useState([])
  const [focusedProductId, setFocusedProductId] = useState(null)
  const [showOverlay, setShowOverlay] = useState(false)
  const [selectedProducts, setSelectedProducts] = useState(preSelectedProducts)
  const [keyword, setKeyword] = useState(defaultKeyword)
  const [_loading, setLoading] = useState(products.length === 0)

  useEffect(() => {
    if (defaultKeyword.length > 0) {
      setFilteredProducts(getFilteredProducts(products, keyword))
      setSelectedProducts(preSelectedProducts)
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    setFilteredProducts(getFilteredProducts(products, keyword))
  }, [products])

  useEffect(() => {
    setLoading(false)
  }, [filteredProducts])

  useEffect(() => {
    if (onSelectedProductsChange) {
      onSelectedProductsChange(selectedProducts)
    }
  }, [selectedProducts])

  function handleKeyUp(event) {
    if (event.target.value.length > 1) setLoading(true)
  }

  function filter(keyword) {
    setLoading(true)
    setKeyword(keyword)

    if (onKeywordChange !== undefined) {
      onKeywordChange(keyword)
    }

    if (keyword.length > 0) {
      setFilteredProducts(getFilteredProducts(products, keyword))
    } else {
      setFilteredProducts(getFilteredProducts(products))
    }

    // Empty out selected products for every filter
    setSelectedProducts([])
  }

  function onMainCheckboxChange(checked) {
    if (filteredProducts.length === 0) return
    if (checked) {
      setSelectedProducts(filteredProducts.map((product) => product))
    } else {
      setSelectedProducts([])
    }
  }

  function onSelect(product) {
    const selectedProductIds = selectedProducts.map((product) => product.id)
    if (selectedProductIds.includes(product.id)) {
      setSelectedProducts(selectedProducts.filter((_product) => _product.id !== product.id))
    } else {
      setSelectedProducts([product, ...selectedProducts])
    }
  }

  return (
    <section
      id='ProductSearch'
      style={{ gridTemplateRows: 'auto 1fr' }}
      className='expand z-0 overflow-hidden'
    >
      <StickyHeader
        className={classNames({
          'flex wrap gap-2': true,
          'bg-shade-1 pt-2 ps-3': true,
        })}>
        <SearchInput
          id='search'
          className='grow'
          defaultValue={keyword}
          onChange={(value) => filter(value)}
          onKeyUp={(event) => handleKeyUp(event)}
          placeholder='Filter products by name or code'
        />
        <ButtonMenu
          className='shadow-3 rounded-2 gap-1px overflow-hidden'
          shade={1}
          theme={NEUTRAL}>
          <Button
            id="product-grid-button"
            onClick={() => batchSelectedAction(selectedProducts)}
            size={2}
            theme={BRAND}
            padding={4}
            rounded={0}
            shade={5}
            disabled={selectedProducts.length === 0}>
            {batchButtonActionLabel}
          </Button>
        </ButtonMenu>
      </StickyHeader>
      <div className="expand">
        {filteredProducts.length > 0 && keyword.length === 0 && (
          <div className="color-shade-3">
            <Empty message="Filter and select products" />
          </div>
        )}

        {filteredProducts.length > 0 && keyword.length > 0 && (
          <ProductList
            className="ps-3"
            batchSelectable={true}
            outlets={outlets}
            outlet={currentOutlet}
            onAddCart={onSelect}
            keyword={keyword}
            limitedColumns={limitedColumns}
            showOverlay={showOverlay}
            focusedProductId={focusedProductId}
            selectedProducts={selectedProducts}
            products={filteredProducts}
            onSetOverlay={setShowOverlay}
            onSetFocusedProductId={setFocusedProductId}
            onMainCheckboxChange={onMainCheckboxChange}
          />
        )}
        {filteredProducts.length === 0 && keyword.length > 0 && (
          <div className="color-shade-3">
            <Empty message={`No results found for "${keyword}"`} />
          </div>
        )}
      </div>
      {showOverlay && (
        <Overlay
          zIndex={2}
          fixed={true}
          active={showOverlay}
          onClose={() => {
            setFocusedProductId(null)
            setShowOverlay(false)
          }}
        />
      )}
    </section>
  )
}

export default ProductSelection

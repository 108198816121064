import React from 'react'
import classNames from 'classnames'

import { BRAND } from 'components/constants'
import { LARGE } from 'components/constants'
import Button from 'components/shared/Button'

const PasscodeButton = ({
  children,
  theme = BRAND,
  size= LARGE,
  shade = 2,
  shadow = true,
  type = 'button',
  onClick,
}) => {
  return (
    <Button
      onClick={onClick}
      theme={theme}
      shade={shade}
      type={type}
      size={size}
      fixedHeight={false}
      padding={null}
      className={classNames({
        'font-size-7 expand flex align-items-center justify-center no-shrink': true,
        'hover:scale-zoom hover:shadow-3 transition-scale-shadow': true,
      })}
      style={{
        borderRadius: '100%',
        boxShadow: shadow ? '0 2px 2px 2px hsla(0, 0%, 0%, 0.2)' : null,
      }}>
      {children}
    </Button>
  )
}

export default PasscodeButton
